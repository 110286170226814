<template>
  <div class="index">
    <!-- <van-nav-bar left-text="流失客户" left-arrow @click-left="back" /> -->
    <div class="content" style="margin-bottom: 60px">
      <div v-for="(i, is) in list" :key="is" class="list">
        <div class="left">
          <img :src="i.avatar" />
          <div>
            <div style="color: #444; font-size: 15px;height: 40px;line-height: 20px;">{{ i.name }}</div>
            <div style="color: #9b9b9b">删除时间：{{ i.createTime }}</div>
          </div>
        </div>
        <div class="right">
          <span @click="seeContactDetail(i)">></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lossContact } from "../../api/lostCustomers";
import { openUserProfile } from "@/utils/wxCodeAuth";

export default {
  data() {
    return {
      finished: true,
      list: [],
    };
  },
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    back() {
      this.$router.back();
    },
    seeContactDetail(data) {
      openUserProfile(2, data.wxExternalUserid);
    },
    getData() {
      lossContact().then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__content {
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon {
  color: #fff;
}
/deep/.van-nav-bar__text {
  color: #fff;
}
/deep/.van-nav-bar__title {
  color: #fff;
}
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #f1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}

/deep/ .tabs .van-cell__title {
  width: 56px !important;
  flex: none !important;
  white-space: nowrap;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f2f3f7;
  overflow: auto;
  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 12px;
  }

  .fs14 {
    font-size: 14px;
  }

  .header-tool {
    padding: 0 18.2347px;
    height: 40px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background-color: #fff;
    .clickable {
      font-size: 12px;
    }
    .title {
      font-size: 14px;
      margin: 0 !important;
    }

    .select-all {
      font-size: 12px;
      color: #1890ff;
    }
  }

  .content {
    img {
      width: 22px;
      height: 22px;
    }
    .list {
      width: 94%;
      height: 91px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 5px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        margin-left: 3%;
        display: flex;
        width: 92%;
      }
      .right {
        margin-right: 3%;
        float: right;
        span {
          display: inline-block;
          width: 6px;
          height: 10px;
        }
      }
      img {
        margin-right: 11px;
        width: 66px;
        height: 66px;
        border-radius: 4px;
      }
    }
  }
}
</style>
