<template>
  <div class="all">
    <div class="switch">
      <div class="content">
        <div class="left">名片信息</div>
        <div class="right">
          <span>展示/隐藏</span><van-switch v-model="checked" />
        </div>
      </div>
    </div>
    <div class="gray"></div>
    <div class="switch">
      <div class="content" style="border-bottom: 1px solid #d8d8d8">
        <div class="left">文字信息</div>
        <div class="right">
          <span>展示/隐藏</span><van-switch v-model="checked2" />
        </div>
      </div>
    </div>
    <van-field
      v-model="message"
      rows="6"
      placeholder="请输入"
      type="textarea"
      maxlength="100"
      show-word-limit
    />
    <div class="huifu">恢复默认</div>
    <div class="circle">
      <div class="circleContent">
        <div class="left">
          <img src="../../../assets/images/bkhx.png" alt="" />
        </div>
        <div class="right">
          <div class="rightTop">
            <div class="rightTopLeft">班克小助</div>
            <div class="rightTopRight">10月8日 12:23</div>
          </div>
          <div class="rightTitle">
            文案内容文案文案内容文案内容文案内容文案内容文案内容
          </div>
          <div class="text" v-if="checked2 == true">{{ message }}</div>
          <div class="rightposter">
            <img class="img1" src="../../../assets/file.png" alt="" />
            <div class="userInfo">
              <img
                class="img2"
                v-if="checked == true"
                src="../../../assets/info.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div class="rightBot">
            <img src="../../../assets/images/avatar-room.svg" alt="" /><img
              src="../../../assets/images/bkhx.png"
              alt=""
            /><span>1000人已分享</span>
          </div> -->
          <div class="rightLast">分享转发</div>
        </div>
      </div>
    </div>
    <div class="last" @click="save">保存设置</div>
  </div>
</template>
    <script>
import { getSet, set } from "@/api/circle";

export default {
  data() {
    return {
      checked: false,
      checked2: false,
      message: "",
    };
  },
  created() {
    this.getCircleSet();
  },
  methods: {
    getCircleSet() {
      getSet().then((res) => {
        this.checked = res.data.card_display == 1 ? true : false;
        this.checked2 = res.data.words_display == 1 ? true : false;
        this.message = res.data.words;
      });
    },
    save() {
      console.log("checked", this.checked);
      console.log("checked2", this.checked2);
      let card_display = "";
      let words_display = "";
      if (this.checked == true) {
        card_display = 1;
      } else {
        card_display = 0;
      }
      if (this.checked2 == true) {
        words_display = 1;
      } else {
        words_display = 0;
      }
      set({ card_display, words_display, words: this.message }).then((res) => {
        this.$router.go(-1);
        this.$toast("设置成功");
      });
    },
  },
};
</script>
    <style lang="less" scoped>
/deep/ .van-cell::after {
  border: none;
}
/deep/ .van-switch {
  width: 56px;
  height: 23px;
}
/deep/ .van-switch__node {
  width: 23px;
  height: 22px;
}
.switch {
  .content {
    margin: 0 auto;
    height: 44px;
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      font-size: 13px;
      color: #4a4a4a;
    }
    .right {
      display: flex;
      justify-content: center;
      span {
        display: inline-block;
        margin-right: 10px;
        font-size: 13px;
        color: #a5a5a5;
      }
    }
  }
}
.gray {
  width: 100%;
  height: 10px;
  background: #f2f3f7;
}
.huifu {
  float: right;
  margin-right: 3%;
  font-size: 13px;
  color: #a5a5a5;
  margin-bottom: 10px;
}
.circle {
  width: 100%;
  height: 586px;
  background: #f2f3f7;
  overflow: hidden;
  .circleContent {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    background-color: #fff;
    width: 94%;
    min-height: 330px;
    margin-top: 18px;
    .left {
      margin-left: 10px;
      margin-top: 16px;
      width: 34px;
      img {
        width: 34px;
        height: 34px;
      }
    }
    .right {
      margin-right: 20px;
      margin-left: 14px;
      margin-top: 16px;
      width: 310px;
      min-height: 245px;
      border-bottom: 1px solid #f1f1f1;
      .rightTop {
        display: flex;
        justify-content: space-between;
        .rightTopLeft {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .rightTopRight {
          font-size: 13px;
          color: #888888;
        }
      }
      .rightTitle {
        margin-top: 6px;
        min-height: 24px;
        font-size: 14px;
        color: #4a4a4a;
      }
      .rightMain {
        margin-top: 12px;
        width: 300px;
        height: 62px;
        background: #f3f4f8;
        border-radius: 4px;
        display: flex;
        align-items: center;
        .RightMainleft {
          margin-left: 6px;
          img {
            width: 36px;
            height: 36px;
          }
        }
        .rightMainRig {
          margin: 0 8px;
          font-size: 14px;
          color: #4a4a4a;
        }
      }
      .rightBot {
        margin-top: 12px;
        img {
          width: 23px;
          height: 23px;
          margin-left: -10px;
          border-radius: 50%;
        }
        span {
          display: inline-block;
          margin-left: 9px;
          font-size: 13px;
          color: #888888;
        }
      }
      .rightLast {
        float: right;
        margin-top: 9px;
        padding: 5px;
        width: 76px;
        text-align: center;
        background: #f5f7ff;
        border-radius: 12px;
        font-size: 12px;
        color: #486ffb;
        margin-bottom: 16px;
      }
    }
  }
}
.last {
  position: fixed;
  bottom: 10px;
  left: 3%;
  width: 94%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #3974c6;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}
.text {
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 36px;
  margin-left: -22%;
  width: 130%;
  min-height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(30, 30, 30, 0.08);
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  color: #4a4a4a;
}
.rightposter {
  .img1 {
    width: 188px;
  }
  .img2 {
    height: 70px;
    width: 188px;
  }
}
</style>