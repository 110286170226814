<template>
  <div class="all">
    <div class="top">
      <van-search
        class="input"
        v-model="inputSearch"
        shape="round"
        placeholder="请输入企业名称或关键词搜索"
        @click="skip"
      />
    </div>
    <p class="grayBackground"></p>
    <div class="binding">已绑定企业信息</div>
    <div v-if="bindCorp.length > 0">
      <div
        class="result"
        v-for="(item, index) in bindCorp"
        :key="index"
        @click.stop="skipDetail(item.creditNo)"
      >
        <div class="left">{{ item.corpName }}</div>
        <div class="right"><span  @click.stop="skipDetail(item.creditNo)">查看详情&nbsp;&nbsp;</span><span  @click.stop="del(item.creditNo)">删除</span></div>
        <!-- <div class="right" @click.stop="del(item.creditNo)">删除</div> -->
      </div>
      <div class="bottom">剩余查询次数：{{ bindNum }}</div>
    </div>
    <div v-else>
      <van-empty description="暂无~" />
    </div>
    <van-dialog v-model="show" show-cancel-button @confirm="confirm">
      <p class="bind">确认删除吗?</p>
    </van-dialog>
  </div>
</template>
<script>
import { publicBusinessApi } from "../../api/publicBusiness";
export default {
  data() {
    return {
      inputSearch: "",
      contactId: "",
      bindCorp: [],
      bindNum: "",
      show: false,
      corpCreditNo: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.contactId = this.$route.query.contactId;
      publicBusinessApi
        .bindCropList({ contactId: this.contactId })
        .then((res) => {
          console.log("绑定", res);
          const { data } = res;
          this.bindNum = data.surplusBindNum;
          this.bindCorp = data.bindCorp;
        });
      console.log("iddd", this.contactId);
    },
    skipDetail(detailCreditNo) {
      this.detailCreditNo = detailCreditNo;
      this.$router.push({
        path: "/contact/business",
        query: {
          contactId: this.contactId,
          detailCreditNo: this.detailCreditNo,
          times:this.bindNum
        },
      });
    },
    skip() {
      this.$router.push({
        path: "/contact/search",
        query: {
          contactId: this.contactId,
          times:this.bindNum
        },
      });
    },
    del(creditNo) {
      this.corpCreditNo = creditNo;
      this.show = true;
    },
    confirm() {
      publicBusinessApi
        .unbindCorp({ creditNo: this.corpCreditNo, contactId: this.contactId })
        .then((res) => {
          console.log("删除成功");
          if (res.code == 200) {
            this.$toast("删除成功");
            this.getData();
          } else {
            this.$toast(res.msg);
          }
        });
    },
  },
};
</script>
  <style lang="less" scoped>
.all {
  overflow: hidden;
}
.grayBackground {
  width: 100%;
  height: 11px;
  background: #f2f3f7;
}
.binding {
  margin: 0 auto;
  width: 94%;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}
.result {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 92%;
  height: 40px;
  background-color: #f7f7f7;
  line-height: 40px;
  margin-top: 10px;
  margin-left: 10px;
  .left {
    padding-left: 10px;
    font-size: 13px;
    color: #4a4a4a;
  }
  .right {
    padding-right: 10px;
    font-size: 13px;
    color: #3974c6;
  }
}
.bottom {
  text-align: center;
  margin-top: 12px;
  font-size: 13px;
  color: #9b9b9b;
}
.bind {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  color: #4a4a4a;
}
</style>