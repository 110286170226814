<template>
    <div class="page">
        <van-popup v-model="show" class="popup">
            <div class="content">
                <span>{{text}}</span>
            </div>
            <div class="line"></div>
            <div class="btn" @click="showPopup">我知道了</div>
        </van-popup>
    </div>
</template>
<script>
    import { onScanQRCode } from "@/utils/wxCodeAuth";
    import { writeOff } from "@/api/shareDraw";
    export default {
        data() {
            return {
                show: false,
                text: '核销成功'
            }
        },
        created() {
            var skip = (res) => {
                    this.writeOffFun(res)
                };
            onScanQRCode(skip);
        },
        methods: {
            showPopup() {
                this.show = false;
                this.$router.back();
            },
            //兑奖核销
            writeOffFun(code) {
                writeOff({ receive_code: code }).then((res) => {
                    if(res.code==200){
                       //判断code是否存在字符串 lottery//抽奖  workfission企微任务宝 roomclickin群打卡 roomfission群烈变
                      if(code.indexOf('lottery')!=-1){
                        this.text='抽奖活动奖励核销成功！';
                      }else if(code.indexOf('workfission')!=-1){
                        this.text='企微任务宝奖励核销成功！';
                      }else if(code.indexOf('roomclickin')!=-1){
                        this.text='群打卡奖励核销成功！';
                      }else if(code.indexOf('roomfission')!=-1){
                        this.text='群烈变奖励核销成功！';
                      }else if(code.indexOf('question')!=-1){
                        this.text='问答活动奖励核销成功！';
                      }
                      this.show = true;
                    }
                })
            },
        },

    }
</script>

<style lang="less" scoped>
    .page {
        padding: 0 40px;
        width: 100%;
        height: 100%;
        // position: relative;
        display: flex;
        justify-content: center;

        .popup {
            width: 80%;
            height: 150px;
            background: #FFFFFF;
            border-radius: 8px;

            .content {
                height: 97px;
                width: 100%;
                display: flex;
                flex-direction: column;
                font-size: 16px;
                padding-top: 25px;
                font-weight: 400;
                color: rgba(10, 17, 26, 0.5);

                span {
                    text-align: center;
                }
            }

            .line {
                width: 100%;
                height: 0.5px;
                background: #E6E7E8;
                // border-top: 1px solid ##EFF0F1;
            }

            .btn {
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 500;
                color: #267EF0;
            }
        }

        /deep/ .van-popup {
            left: 50% !important;
        }
    }

    //样式
    .qrcode {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 150px;
        width: 150px;
        background: rgba(0, 0, 0, 0.48);
    }

    #reader {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
</style>