import request from "@/utils/request";
export class shareDrawApi {
    // 抽奖列表
    static drawList(params) {
      return request({
        url: "/lottery/index",
        method: "get",
        params: params,
      });
    }
}
// 兑奖核销
export function writeOff(params) {
  return request({
      url: '/lottery/check',
      method: 'post',
      data: params
  })
}