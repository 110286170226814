<template>
    <div class="page">
        <van-tabs title-active-color="#fff" color="#fff" background="#3975C6" @click="onClick">
            <van-tab title="商机内容" name="a">
                <div class="conBox">
                    <div class="box" style="white-space: pre-wrap;">
                        {{text}}
                    </div>
                </div>
            </van-tab>
            <van-tab title="客户画像" name="b">
                <detailpage></detailpage>
            </van-tab>
        </van-tabs>
        <div class="foot" v-if="title=='商机内容'">
            <div class="copyClass" @click="copyFun()">一键复制</div>
            <div class="sendClass" @click="sendMsg()">发送给客户</div>
        </div>
    </div>
</template>

<script>
    import detailpage from '../contact/detailPage.vue'
    import { getNiche } from "@/api/medium";
    import { openUserProfile } from "@/utils/wxCodeAuth";
    import { ContactApi } from "@/api/contact";
    export default {
        components: {
            detailpage
        },
        data() {
            return {
                radar_id: null,
                agentId: null,
                contactId: null,
                title: '商机内容',
                text: '',
                wxExternalUserid: ''
            };
        },
        created() {
            this.radar_id = this.$route.query.radar_id;
            this.contactId = this.$route.query.contactId;
            this.agentId = this.$route.query.agentId;
            this.getData()
        },
        methods: {
            getData() {
                getNiche({ radar_id: this.radar_id }).then(res => {
                    if (res.code == 200) {
                        this.text = res.data.nicheContent
                    }
                })
                ContactApi.show({ contactId: this.contactId }).then((res) => {
                    this.wxExternalUserid = res.data.wxExternalUserid;
                })
            },
            onClick(name, title) {
                this.title = title
            },
            copyFun() {
                let oInput = document.createElement('textarea')
                oInput.value = this.text;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$message.success("复制成功");
                oInput.remove();
            },
            async sendMsg() {
                await openUserProfile(2, this.wxExternalUserid);
            }
        },
    };
</script>

<style lang="less" scoped>
    /deep/ .van-tabbar-item {
        color: #c1c9d0;
    }

    /deep/ .van-tabbar-item--active {
        color: #3975c6 !important;
    }

    /deep/ .van-tabbar-item {
        color: #c1c9d0;
    }

    /deep/ .van-tab {
        font-size: 16px;
        color: #fff;
    }

    /deep/ .van-tabs {
        margin-top: -4px;
    }

    /deep/ .van-tabs__nav--line {
        padding-bottom: 0px;
    }

    /deep/ .van-tabs__line {
        bottom: 1px;
    }

    /deep/.van-tabbar {
        box-sizing: content-box !important;
        margin: 0 auto;
        width: 100%;
        max-width: 500px;
        left: none;
    }

    .van-tabbar--fixed {
        left: unset !important;
    }

    .page {
        width: 100%;
        height: 100%;
        background: #F2F3F7;
        position: relative;


    }

    .conBox {
        width: 100%;
        height: 100%;
        padding: 15px;
        position: relative;

        .box {
            width: 100%;
            padding: 10px;
            min-height: 200px;
            background: #FFFFFF;
            border-radius: 6px;
            font-size: 15px;
            color: #000000;

        }

    }

    .foot {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 106px;
        background: #FFFFFF;
        box-shadow: 0px 1 3px 0px rgba(0, 0, 0, 0.26);
        padding: 17px;
        display: flex;
        justify-content: space-around;

        .copyClass {
            width: 45%;
            height: 48px;
            line-height: 48px;
            background: #F1F2F6;
            border-radius: 5px;
            border: 1px solid #D7D7D9;
            font-weight: 500;
            font-size: 17px;
            color: #595A5E;
            text-align: center;
        }

        .sendClass {
            width: 45%;
            background: #3974C6;
            height: 48px;
            line-height: 48px;
            border-radius: 5px;
            font-weight: 500;
            font-size: 17px;
            color: #FFFFFF;
            text-align: center;
        }
    }
</style>