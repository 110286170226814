<template>
  <div>
    <Popup
      v-model:show="signTagPop"
      position="bottom"
      closeable
      round
      :close-on-click-overlay="false"
      :style="{ height: '500px' }"
    >
      <div
        style="
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          padding-top: 15px;
        "
      >
        添加企业标签
      </div>
      <Divider />
      <van-tabs v-model="activeName" color="#1989fa">
        <van-tab title="企业标签" name="a">
          <div class="tag_page">
            <div class="show_tag_row" style="height: 345px">
              <div
                class="group_row"
                v-for="(item, index) in tagsGroupData"
                :key="index"
              >
                <div class="group_name">{{ item.groupName }}：</div>
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    class="tag_name"
                    v-for="(obj, idx) in item.tags"
                    :key="idx"
                    @click="labelAdd(obj)"
                    :class="[addTagArr.indexOf(obj) != -1 ? 'select_tag' : '']"
                  >
                    {{ obj.name }}
                  </div>
                </div>
              </div>
            </div>
            <van-button
              type="info"
              block
              @click="saveTagSet"
              style="margin-top: 10px"
              >保存</van-button
            >
          </div>
        </van-tab>
        <van-tab title="支行标签" name="b">
          <div class="tag_page">
            <div class="show_tag_row" style="height: 345px">
              <div
                class="group_row"
                v-for="(item, index) in businessTagData"
                :key="index"
              >
                <div class="group_name">{{ item.groupName }}：</div>
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    class="tag_name"
                    v-for="(obj, idx) in item.tags"
                    :key="idx"
                    @click="labelAdd2(obj)"
                    :class="[addTagArr.indexOf(obj) != -1 ? 'select_tag' : '']"
                  >
                    {{ obj.name }}
                  </div>
                </div>
              </div>
            </div>
            <van-button
              type="info"
              block
              @click="saveTagSet"
              style="margin-top: 10px"
              >保存</van-button
            >
          </div>
        </van-tab>
      </van-tabs>
    </Popup>
  </div>
</template>
<script>

import { Popup, Divider, Toast } from "vant";

import { ContactApi } from "@/api/contact";
export default {
  components: {
    Popup,
    Divider,
  },
  data() {
    return {
      activeName: "a",
      signTagPop: false,
      alltag: [],
      tagsGroupData: [],
      businessTagData: [],
      addTagArr: [],
    };
  },
  methods: {
    // 获取业务标签列表
    getBusinessTag() {
      ContactApi.businessTag({ contactId: this.contactId }).then((res) => {
        console.log("业务标签", res);
        this.businessTagData = res.data.list;
        this.getSelectTabs()
      });
    },
    // 初始化标签数组
    getSelectTabs() {
      this.addTagArr = [];
      this.alltag = [].concat(this.tagsGroupData, this.businessTagData);
      this.alltag.forEach((item, index) => {
        item.tags.forEach((obj, idx) => {
          if (obj.isSelect == 1) {
            this.addTagArr.push(obj);
          }
        });
        console.log("企业标签", this.addTagArr);
      });
    },
    show(contactId) {
      this.signTagPop = true;
      this.contactId = contactId;
      // 初始化数据
      this.getTabList();
      this.getBusinessTag();
    },
    // 获取标签列表
    getTabList() {
      console.log("this.contactId", this.contactId);
      ContactApi.allTag({
        contactId: this.contactId,
      }).then((res) => {
        this.tagsGroupData = res.data;
        this.getSelectTabs();
      });
    },
    saveTagSet() {
      const tagIds = this.addTagArr.map((item, index) => {
        return item.id;
      });
      console.log("tagsid", tagIds);
      if (tagIds.length != 0) {
        ContactApi.createContactTag({
          contactId: this.contactId,
          tagIds,
        }).then((res) => {
          Toast("设置成功");
          this.signTagPop = false;
          this.$emit("change", true);
        });
      } else {
        Toast("请选择标签");
      }
    },
    labelAdd(obj) {
      const tagIndex = this.addTagArr.indexOf(obj);
      if (tagIndex == -1) {
        this.addTagArr.push(obj);
      } else {
        this.addTagArr.splice(tagIndex, 1);
      }
    },
    labelAdd2(obj) {
      const tagIndex = this.addTagArr.indexOf(obj);
      if (tagIndex == -1) {
        this.addTagArr.push(obj);
      } else {
        this.addTagArr.splice(tagIndex, 1);
      }
    },
  },
};
</script>
<style scoped lang="less">
.tag_page{
  padding: 0 35px;
  font-size: 16px;
  color: #333;
}
.tag_flex{
  display: flex;
  justify-content:space-between;
}
.group_name{
  color: #747474;
  margin: 25px 0 15px 0;
  border-left: 8px solid #1989FA;
  padding-left: 8px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.tag_name{
  border: 1px solid #CDE5F9;
  background: #F7F7F7;
  padding: 5px 10px;
  border-radius: 8px;
  margin-top: 15px;
  margin-right: 18px;
  font-size: 14px;
  cursor: pointer;
}
.show_tag_row{
  //height: 750px;
  overflow-y: scroll;
  padding-bottom: 25px;
}
.group_row{
  margin-top: 20px;
}
.group_row:first-child{
  margin-top: 0;
}
.select_tag{
  background: #1890FF;
  color: #fff;
  border: 1px solid #1890FF;
}
</style> 

