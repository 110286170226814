import request from "@/utils/request";

export function notAddRecord(params) {
    return request({
        url: '/contactBatchAdd/notAddRecord',
        method: 'get',
        params: params
    })
}
export function updateAbnormal(params) {
    return request({
        url: '/contactBatchAdd/UpdateAbnormal',
        method: 'put',
        data: params
    })
}
//修改手机号备注
export function edit(params) {
    return request({
      url: '/contactBatchAdd/UpdateRemark',
      method: 'put',
      data: params
    })
  }