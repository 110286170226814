<template>
  <van-popup v-model="showfilter" position="bottom" :style="{ height: '100%' }">
    <div class="range">
      <div class="banxin">
        <div class="top">
          <van-radio-group v-model="radio">
            <van-cell-group>
              <van-cell
                title="全部客户"
                clickable
                @click="radio = '1'"
                style="display: flex"
              >
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
              <van-cell
                title="按条件筛选客户"
                clickable
                @click="radio = '2'"
                style="display: flex"
              >
                <template #right-icon>
                  <van-radio name="2" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <div class="filter" v-show="radio==2">
          <div class="content" style="width: 98%; margin: 0 auto">
            <van-field
              style="padding: 0; margin-bottom: 12px"
              readonly
              v-model="value2"
              input-align="right"
              right-icon="arrow"
              label="添加时间"
              placeholder="请选择"
              @click="$refs.calendar.$show()"
            />

            <div class="form">
              <div class="label">按标签筛选：</div>
              <div class="input">
                <selectTag ref="tags" />
              </div>
            </div>
            <div class="form">
              <div class="label">排除客户：</div>
              <div class="input">
                <selectTag ref="excludeTags" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btnBox">
        <div class="cancel" @click="cellback()">取消</div>
        <div class="submit" @click="btnClick()">确定</div>
      </div>
      <Calendar ref="calendar" @changeTime="changeT" />
    </div>
  </van-popup>
</template>
<script>
import selectTag from "@/components/select/tag/select";
import selectEmployee from "@/components/select/employee/select";
import Calendar from "@/components/calendar.vue";

import { getSendUserCount, add } from "@/api/contactMessageBatchSend";
export default {
  components: {
    selectTag,
    selectEmployee,
    Calendar,
  },
  data() {
    return {
      showfilter: false,
      time: [],
      radio: "1",
      count: null,
      value2: "",
      addTimeStart: "",
      addTimeEnd: "",
    };
  },
  methods: {
    show() {
      this.showfilter = true;
    },
    changeT(s, e) {
      this.addTimeStart = s;
      this.addTimeEnd = e;
      this.value2 = `${s} 至 ${e}`;
    },
    //确认数据
    btnClick() {
      const tags = this.$refs.tags.get().map((v) => {
        return v.id;
      });
      console.log("tags", tags);
      const removeTags = this.$refs.excludeTags.get().map((v) => {
        return v.id;
      });
      const selectData = {
        tags,
        excludeContacts: removeTags,
        addTimeStart: this.addTimeStart,
        addTimeEnd: this.addTimeEnd,
      };
      console.log("子组件选中数据", selectData);
      this.$emit("filterData", selectData,this.radio);
      this.showfilter = false;
    },
    //取消
    cellback() {
      this.showfilter = false;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-field__control--right {
  text-align: left;
}
/deep/ .van-cell-group {
  border-radius: 4px;
}
// /deep/ .van-cell {
//   display: flex !important;
// }

.range {
  width: 100%;
  height: 100%;
  background: #f2f3f7;

  .banxin {
    width: 94%;
    margin: 0 auto;
    background: #f2f3f7;
    padding-top: 12px;
  }
  .filter {
    width: 100%;
    margin: 0 auto;
    margin-top: 12px;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
  }
}

.date-input {
  display: flex;
  align-items: center;

  .box {
    margin-right: 6px;
    margin-bottom: 6px;
  }
}
.count {
  font-size: 16px;
}
.block {
  margin-bottom: 32px;

  .title {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 13px;
      background: #1890ff;
      margin-right: 6px;
    }
  }

  .tips {
    width: 100%;
    padding-left: 11px;
    max-width: 654px;
    font-size: 15px;
    line-height: 24px;
    background: #f7fbff;
    border: 1px solid #cce9ff;
    border-radius: 6px;
    color: #000000a6;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 16px;

    p {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;

      &:before {
        width: 3px;
        height: 3px;
        background: #95cbf3;
        content: "";
        display: inline-block;
        margin-left: 9px;
        margin-right: 4px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.form {
  margin-bottom: 16px;

  .label {
    color: #323233;
    font-size: 14px;
    margin-bottom: 6px;
  }

  .text {
    font-size: 14px;
    color: #00000073;
    margin-left: 6px;
  }
}

.input-block-box {
  border: 1px solid #eee;
  max-width: 370px;
  background: #fbfbfb;

  .textarea {
    width: 100%;
    height: 178px;
    overflow: auto;
    outline: none;
    padding: 8px 16px;
    font-size: 14px;
    background: none;
    border: none;
  }

  .add {
    padding: 8px 16px;
    border-top: 1px solid #eee;
    font-size: 16px;
  }
}

.msg-list {
  border-top: 1px solid #eee;

  .item {
    font-size: 16px;
    padding: 8px 16px;
  }

  i {
    cursor: pointer;
  }

  img {
    width: 30px;
    height: 30px;
  }
}
.btnBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 106px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 10px 12px;

  .cancel {
    width: 30%;
    height: 48px;
    padding: 12px 0;
    text-align: center;
    background: #f1f2f6;
    border-radius: 5px;
    border: 1px solid #d7d7d9;
    font-size: 17px;
    font-weight: 500;
    color: #595a5e;
  }

  .submit {
    width: 65%;
    height: 48px;
    text-align: center;
    padding: 12px 0;
    background: #3974c6;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>