<template>
  <div class="all">
      <div class="top">
        <img v-if="detailData.fullPoster!=''" :src="detailData.fullPoster" alt="">
        <img
        v-else
          src="../../../assets/images/electronicCard/specialTopic.png"
          alt=""
        />
      </div>
      <div class="detail">
        <div class="title">{{ detailData.name }}</div>
        <div class="abstruct">{{ detailData.summary }}</div>
      </div>
    <div
      class="list"
      v-for="(item, index) in detailData.informationList"
      :key="index"
    >
      <div
        class="common"
        v-if="item.content_type == 1"
        @click="skipNewsDetail(item.id)"
      >
        <div class="left">
          <div class="leftTop">{{ item.title }}</div>
          <div class="leftBot">
            <span class="type">资讯类型:内容</span><span class="num"></span>
          </div>
        </div>
        <div class="right">
          <img :src="item.fullCover" alt="" />
        </div>
      </div>
      <div class="sec" v-else-if="item.content_type == 2" @click="skipNewsDetail(item.id)">
        <div class="secTop">{{ item.title }}</div>
        <div class="right">
          <span class="type">资讯类型:雷达</span><span class="num"></span>
        </div>
      </div>
      <div class="poster" v-else>
        <div class="title">{{ item.title }}</div>
        <div class="rightposter">
          <img :src="item.fullPoster"  @click.stop="createPoster(item.fullPoster)" alt="" />
          <div class="userInfo" v-if="avatar!=''">
            <div class="left">
              <img class="avatar" :src="userInfo.fullImage" alt="">
              <div class="info">
                <div class="name">{{ userInfo.name }}</div>
                <div class="m12">{{ userInfo.departmentName }}</div>
              </div>
            </div>
            <div class="right">
              <img class="qrcode" :src="userInfo.qrCode" alt="">
            </div>
          </div>
        </div>
        <div class="right">
          <span class="type">资讯类型:海报</span><span class="num"></span>
        </div>
      </div>
    </div>
    <div
      class="list"
      v-for="(item, index) in detailData.radarList"
      :key="index"
    >
      <div
        class="sec"
        @click="skipRadarDetail(item.url)"
      >
      <div class="secTop">{{ item.title }}</div>
        <div class="right">
          <span class="type">资讯类型:雷达</span><span class="num"></span>
        </div>
      </div>
    </div>
    <div style="height: 64px"></div>
    <div class="last">
      <div class="lastContent">
        <!-- <div>
          <img
            src="../../../assets/images/electronicCard/dianzan1.png"
            alt=""
          /><span>感兴趣 {{ detailData.likesNum }}</span>
        </div>
        <div>
          <img
            src="../../../assets/images/electronicCard/dianzan2.png"
            alt=""
          />
        </div> -->
        <div class="btn" @click="sendCircle">分享</div>
      </div>
    </div>
  </div>
</template>
    <script>
import { topicDetail,shareCallBack } from "@/api/specialTopic";
import { shareToExternalMoments,shareMyData } from "@/utils/wxCodeAuth";
import { vcardIndex,getbase64 } from "@/api/electronicCard";

export default {
  data() {
    return {
      detailData: "",
      id: "",
      shareUrl:'',
      userInfo:'',
      avatar:'',
      qrcode:''
    };
  },
  created() {
    this.id = this.$route.query.id   
    this.getDetail();
    this.get64()
  },
  methods: {
    get64() {
      getbase64().then((res) => {
        this.qrcode = res.data.base64QrCode;
        console.log("23434", this.qrcode);
        this.$forceUpdate();

      });
    },
    getUserInfo(){
      vcardIndex().then((res) => {
        this.userInfo = res.data.employee
        this.avatar = res.data.employee.fullImage
        const data = res.data;
        const shareData = {
          title: `${data.employee.departmentName}金融管家：${data.employee.name}为您服务！`,
          description: data.summary,
          imgUrl: data.employee.fullImage,
          url: this.shareUrl,
        };
        console.log("fenxiang", shareData);
        shareMyData(shareData);
      })
    },
    createPoster(imgurl){
      console.log('imgUrl',imgurl) 
      if(this.avatar!=''){
        this.$router.push({ path: "/poster/create", query: { imgurl,qrcode:this.qrcode,from:'topicDetail' } });
      }
    },
    sendCircle(){
      let time = new Date()
      let shareData = {};
      let text = "";
      shareData.type = 3;
      shareData.content = {};
      shareData.content.title =
        this.detailData.name;
      shareData.content.imageFullPath = this.userInfo.fullImage;
      shareData.content.imageLink = this.shareUrl;
      console.log("shareData.content", shareData);
      shareToExternalMoments(text, shareData).then((res) => {
        console.log("res", res);
        shareCallBack({id:this.id}).then(()=>{
          console.log('回调')
        })
        this.$message.success("发送成功");
      });
    },
    getDetail() {
      topicDetail({ id: this.id }).then((res) => {
        this.detailData = res.data;
        this.shareUrl = res.data.shareUrl
        this.getUserInfo()
      });
    },
    skipRadarDetail(url){
      location.href = url
    },
    skipNewsDetail(id) {
      this.$router.push({
        path: "/electronicCard/caijing/detail",
        query: {
          id,
          employeeId: this.id,
        },
      });
    },
  },
};
</script>
    <style lang="less" scoped>
.all {
  height: 100%;
  overflow: auto;
  background-color: #f7f7f7;
}
.top {
  /* position: absolute;
  z-index: 1; */
  width: 100%;

  img {
    width: 100%;
    height: 150px;
  }
}
.detail {
    /* position: absolute; */
    /* left: 3%; */
    width: 100%;
    /* height: 104px; */
    background: #ffffff;
    border-radius: 3px;
    padding: 12px 20px;
    /* overflow: auto;
    margin-top: 120px; */
    .title {
      color: #333333;
      font-size: 17px;
  font-weight: 500;
  }
  .abstruct {
      /* width: 94%; */
      min-height: 20px;
      /* margin: 0 auto; */
      font-size: 14px;
      color: #4a4a4a;
      margin-top: 9px;
      /* margin-bottom: 10px; */
      /* margin-top: 6px; */
    }
  }
.list {
  .common {

    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;
    height: 85px;
    background: #ffffff;
    border-radius: 3px;
    margin-top: 10px;
    padding: 12px 10px;
    .left {
      /* margin-left: 10px;
      margin-top: 8px; */
      width: 68%;
      .leftTop {
        /* height: 41px; */
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      .leftBot {
      }
    }
    .right {
      /* margin-right: 10px;
      margin-top: 8px; */
      width: 23%;
      img {
        width: 100%;
        height: 57px;
      }
    }
  }
  .sec {
    margin: 0 auto;
    margin-top: 10px;
    width: 94%;
    /* height: 85px; */
    background: #ffffff;
    border-radius: 3px;
    overflow: hidden;
    padding: 12px 10px;
    .secTop {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 17px;
    }
  }
  .poster {
    width: 94%;
    margin: 0 auto;
    min-height: 389px;
    margin-top: 10px;

    background: #ffffff;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    overflow: hidden;
    padding: 12px 10px;
    .title {
      width: 100%;
      min-height: 21px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 17px;
    }
    img {
      width: 144px;
      height: 304px;
      margin: 8px;
    }
  }
  .type {
    display: inline-block;
    font-size: 11px;
    color: #333333;
    margin-right: 8px;
  }
  .num {
    font-size: 11px;
    color: #959595;
  }
}
.last {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: #ffffff;
  .lastContent {
    height: 64px;
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      margin-right: 6px;
      width: 21px;
      height: 21px;
    }
    span {
      font-size: 11px;
      color: #c1c9d0;
    }
    .btn {
      text-align: center;
      line-height: 42px;
      width: 100%;
      height: 42px;
      background: #3974c6;
      border-radius: 5px;
      font-size: 14px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.rightposter {
  img {
    width: 144px;
    height: 304px;
  }
  .userInfo{
    display: flex;
    justify-content: space-between;
    width: 144px;
    /* height: 100px; */
    .left{
      display: flex;
      width:80%;
      .avatar{
        width: 30%;
        height: 40px;
      }
      .info{
        margin-left: 3px;
        .name{
          margin-top: 8px;
          font-size: 10px;
        }
        .m12{
          font-size: 8px;
        }
      }
    }
    .right{
      margin-top: 3px;
      width: 20%;
      .qrcode{
        width: 90%;
        height: 30px;
      }
    }
  }
}
</style>