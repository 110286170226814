<template>
    <div>
        <div class="filter">
            <div class="filterLeft"></div>
            <div class="filterRight" @click="openFliter">
                <img src="../../../assets/filter.png" class="icon" alt="" /><span>筛选</span>
            </div>
        </div>
        <div class="main">
            <div class="con">
                <div class="container">
                    <div class="item">
                        <div class="t1">{{dataJson.total}}</div>
                        <div class="t2">巡检总数</div>
                    </div>
                    <div class="item">
                        <div class="t1">{{dataJson.approved}}</div>
                        <div class="t2">审核通过</div>
                    </div>
                    <div class="item">
                        <div class="t1">{{dataJson.notInspected}}</div>
                        <div class="t2">未巡检</div>
                    </div>
                </div>
                <div class="container">
                    <div class="item">
                        <div class="t1">{{dataJson.pendingReview}}</div>
                        <div class="t2">未审核</div>
                    </div>
                    <div class="item">
                        <div class="t1">{{dataJson.reviewRejection}}</div>
                        <div class="t2">审核驳回</div>
                    </div>
                    <div class="item">
                        <div class="t1">{{dataJson.inspectionExpired}}</div>
                        <div class="t2">已过期</div>
                    </div>
                </div>
            </div>
            <div class="con m10">
                <div class="title">异常工单</div>
                <div class="container">
                    <div class="item">
                        <div class="t1 c1">{{dataJson.approved}}</div>
                        <div class="t2">审核通过</div>
                    </div>
                    <div class="item">
                        <div class="t1 c1">{{dataJson.approvedAbnormal}}</div>
                        <div class="t2">异常工单</div>
                    </div>
                </div>
            </div>
        </div>
        <statisFilter ref="fit" @filterData="getFliterData" />
    </div>
</template>

<script>
    import statisFilter from "./statisFilter.vue";
    import { getAnalysis } from "@/api/Inspection";
    export default {
        components: { statisFilter },
        data() {
            return {
                dataJson: {
                    notInspected: 0,
                    pendingReview: 0,
                    approved: 0,
                    reviewRejection: 0,
                    inspectionExpired: 0,
                    approvedAbnormal: 0,
                    total: 0
                },
                parmas:{}
            }

        },
        watch: {},
        created() {
            this.getData()
        },
        methods: {
            getData() {
                getAnalysis(this.params).then((res) => {
                    this.dataJson = res.data
                })
            },
            getFliterData(e) {
                this.params=e
                this.getData()
            },
            openFliter(){
                this.$refs.fit.$show(this.params)
            }
        }
    }
</script>

<style lang="less" scoped>
    .filter {
        width: 100%;
        height: 49px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border-top: 1px solid #e8e8e8;

        .filterLeft {
            margin-left: 4%;
            font-size: 12px;
            color: #a5a5a5;
        }

        .filterRight {
            margin-right: 4%;
            font-size: 12px;
            color: #515151;

            .icon {
                width: 11px;
                height: 11px;
                margin-right: 6px;
                margin-top: -2px;
            }
        }
    }

    .main {
        height: calc(100vh - 161px);
        background: #F2F3F7;
        padding: 10px 12px;

        .con {
            background: linear-gradient(to right bottom, #EDF2FF 0%, #FFFFFF 50%, #FFFFFF 100%);
            border-radius: 10px;
            width: 100%;
            padding: 15px 10px;
        }

        .m10 {
            margin-top: 10px;
        }

        .title {
            font-weight: bold;
            font-size: 15px;
            color: #4A4A4A;
        }

        .container {
            display: flex;
            justify-content: space-between;
        }

        .item {
            flex: 1;
            position: relative;
            /* 为伪元素定位设置相对定位 */
            padding: 10px;
            /* 根据需要调整内边距 */

            &:not(:last-child)::after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                width: 1px;
                /* 竖线的宽度 */
                height: 25px;
                /* 竖线的高度 */
                background: #EEEEEE;
                transform: translateY(-50%);
                /* 垂直居中对齐 */
            }

            .t1 {
                font-weight: bold;
                font-size: 17px;
                color: #000000;
                text-align: center;
            }

            .c1 {
                color: #3974C7;
            }

            .t2 {
                font-weight: 500;
                font-size: 10px;
                color: #9B9B9B;
                text-align: center;
                margin-top: 6px;
            }
        }
    }
</style>