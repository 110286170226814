<template>
    <div class="page">
        <van-tabs v-model="active" background="#FFFFFF" title-active-color="#3974C6" color="#3974C6" @click="change">
            <van-tab title="海报样式"></van-tab>
            <van-tab title="原码样式"></van-tab>
        </van-tabs>
        <div v-if="active==0" class="cardBox" :style="{'margin-top':show?'0px':''}">
            <img :src="img" alt="" v-if="img!=''" :style="{'width':imgWidth+'px','height':imgheight+'px'}">
            <div id="shareNode" v-else ref="shareImg">
                <div class="boxHeard"
                    :style="{'background-image': 'url(' + configImg + ')','background-repeat':'no-repeat','background-size':'100% 100%' }">
                    <div class="title">
                        <span>{{codeData.name}}</span>
                        <img v-if="logo!=''" :src="logo" alt="">
                    </div>
                    <div class="tips">{{tips}}</div>
                </div>
                <div class="boxBom">
                    <img v-if="codeData.fullImage" :src="codeData.fullImage" alt="" />
                    <img v-else :src="codeData.qrcodeUrl" alt="" />
                </div>
            </div>
        </div>
        <div class="tipsBox" v-if="active==0">
            <div  class="tipTitle">长按海报保存到相册</div>
            <div class="editBtn" @click="editImg">编辑</div>
        </div>
        <!-- 原码样式 -->
        <div v-if="active==1" class="cardBox" :style="{'margin-top':show?'0px':''}">
            <div class="boxBom source">
                <img v-if="codeData.fullImage" :src="codeData.fullImage" alt="" />
                <img v-else :src="codeData.qrcodeUrl" alt="" />
            </div>
            <div class="tipTitle">长按二维码保存到相册</div>
        </div>
        <van-popup v-model="show" position="bottom" round :overlay="false" :style="{ height: '45%' }">
            <div class="popBox">
                <div class="minBox">
                    <div class="title">选择样式</div>
                    <div class="listbox">
                        <div class="itemBox" v-for="(item,index) of liveCode" :key="index" @click="selectImg(item)">
                            <div class="box"> <img :src="item.img" alt=""></div>
                            <div class="name">样式{{index+1}}</div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <!-- <div class="minBox">
                    <div class="title">标题</div>
                    <van-field v-model="message" rows="1" autosize type="textarea" placeholder="请输入标题" />
                </div> -->
                <div class="minBox">
                    <div class="title">引导语</div>
                    <van-field v-model="tips" rows="1" autosize type="textarea" placeholder="请输入引导内容" maxlength="11"
                        show-word-limit />
                </div>
                <div class="line"></div>
                <div class="minBox">
                    <div class="title">活码头像</div>
                    <van-uploader :after-read="afterReadImg" :max-size="2000 * 1024" @oversize="onOversize"
                        :max-count="1" />
                </div>
                <div class="btnBox">
                    <div class="cancel" @click="cellback()">重置</div>
                    <div class="submit" @click="saveImg()">确定</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
    import html2canvas from 'html2canvas';
    export default {
        components: {
            html2canvas
        },
        data() {
            return {
                active: 0,
                show: true,
                message: '',
                codeData: {},
                tips: '',
                logo: '',
                img: '',
                liveCode: [
                    {
                        id: 1,
                        img: require('../../../assets/images/liveCode/1@3x.png')
                    },
                    {
                        id: 2,
                        img: require('../../../assets/images/liveCode/2@3x.png')
                    },
                    {
                        id: 3,
                        img: require('../../../assets/images/liveCode/3@3x.png')
                    },
                    {
                        id: 4,
                        img: require('../../../assets/images/liveCode/4@3x.png')
                    },
                    {
                        id: 5,
                        img: require('../../../assets/images/liveCode/5@3x.png')
                    },
                    {
                        id: 6,
                        img: require('../../../assets/images/liveCode/6@3x.png')
                    }
                ],
                configImg: require('../../../assets/images/liveCode/1@3x.png'),
                imgWidth: 0,
                imgheight: 0,

            };
        },
        created() {
            //表示选择活码样式  
            this.codeData = this.$route.query.codeData
        },
        methods: {
            //选择样式
            selectImg(row) {
                this.configImg = row.img
            },
            change(e) {
                e === 0?this.img='':''
                this.show = e === 0;
            },
            saveImg() {
                var that = this;
                var node = document.getElementById("shareNode");
                this.imgWidth = this.$refs.shareImg.clientWidth
                this.imgheight = this.$refs.shareImg.clientHeight
                html2canvas(node, {
                    allowTaint: true,
                    useCORS: true,
                    logging: true,
                    taintTest: false,
                    scale: 6,
                    backgroundColor: "transparent"
                }).then(function (canvas) {
                    // const link = document.createElement('a')
                    that.img = canvas.toDataURL("image/png")
                    that.show = false
                }).catch(function (error) {
                    that.$message.error('保存失败');
                });
            },
            // 文件大小
            onOversize(file) {
                this.$message.warning("文件大小不能超过 2MB");
            },
            afterReadImg(file) {
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                this.logo = file.content
                file.status = "done";
            },
            cellback() {
                this.tips = ''
                this.logo = ''
                this.configImg = require('../../../assets/images/liveCode/1@3x.png')
            },
            editImg(){
                this.img=''
                this.show=true
            }
        }
    };
</script>
<style lang="less" scoped>
    /deep/ .van-cell {
        margin-top: 10px;
        background: #F2F3F7;
        border-radius: 5px;
    }

    /deep/ .van-uploader {
        margin-top: 10px;
    }

    .page {
        width: 100%;
        height: 100vh;
        background: #F2F3F7;

        .test {
            border-radius: 12px 12px 0px 0px;
            background: #3974C6;
            color: #fff;
        }

        .cardBox {
            margin-top: 40px;
            padding: 32px;

            .boxHeard {
                width: 100%;
                height: 183px;
                border-radius: 12px 12px 0px 0px;
                padding: 30px 23px;

                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    span {
                        font-size: 17px;
                        font-weight: 400;
                        color: #999999;
                    }

                    img {
                        width: 41px;
                        height: 41px;
                    }
                }

                .tips {
                    margin-top: 15px;
                    font-size: 23px;
                    font-weight: 500;
                    color: #999999;
                }
            }

            .boxBom {
                padding: 60px;
                background: #FFFFFF;
                border-radius: 0px 0px 12px 12px;
                display: flex;
                justify-content: center;
                align-content: center;

                img {
                    width: 196px;
                    height: 196px;
                }
            }

            .source {
                background: #F2F3F7;
            }
            .tipTitle {
            font-size: 15px;
            text-align: center;
            font-weight: 400;
            color: #9B9B9B;
           }
        }
        .tipsBox{
            .tipTitle {
            font-size: 15px;
            text-align: center;
            font-weight: 400;
            color: #9B9B9B;
           }
        .editBtn{
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
height: 42px;
background: #3974C6;
border-radius: 5px;
font-size: 15px;
font-weight: bold;
color: #FFFFFF;
margin: 0 auto;
text-align: center;
line-height: 42px;
        }
        }
     
    }

    .popBox {
        padding: 6px 0;
        width: 100%;
        /* height: 100%; */

        .minBox {
            padding: 11px 17px 0 17px;

            .title {
                font-size: 17px;
                font-weight: 400;
                color: #717174;
            }

            .listbox {
                margin-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: row;
                white-space: nowrap;
                overflow: hidden;
                overflow-x: scroll;

                .itemBox {
                    &:nth-child(n+2) {
                        margin-left: 12px;
                    }

                    .box {
                        width: 79px;
                        height: 52px;
                        background: #D8D8D8;
                        border-radius: 4px;

                        img {
                            width: 79px;
                            height: 52px;
                        }
                    }

                    .name {
                        margin-top: 5px;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #999999;
                    }
                }
            }
        }


        .line {
            margin-top: 12px;
            width: 100%;
            height: 1px;
            background: #E8E8E8;
        }

        .btnBox {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 12px 10px 12px;

            .cancel {
                width: 30%;
                padding: 12px 0;
                text-align: center;
                background: #F1F2F6;
                border-radius: 5px;
                border: 1px solid #D7D7D9;
                font-size: 17px;
                font-weight: 500;
                color: #595A5E;
            }

            .submit {
                width: 65%;
                text-align: center;
                padding: 12px 0;
                background: #3974C6;
                border-radius: 5px;
                font-size: 17px;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }

    /deep/ .van-tabs {
        height: 49px;
        background: #FFFFFF;
    }

    /deep/ .van-tabs__line {
        bottom: 0;
    }

    /deep/ .van-tab--active span {
        font-weight: bold;
        color: #3974C6;
    }

    /deep/ .van-tab__text {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }

    /deep/ .van-tabs__nav--line {
        padding-bottom: 0;
    }
</style>