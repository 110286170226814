import request from "../plugins/axios";

//理财首页
export function getFina(params) {
    return request({
        url: '/financial/index',
        method: 'get',
        params
    })
}
//理财分类
export function getCate(params) {
    return request({
        url: '/financial/getWkCategory',
        method: 'get',
        params
    })
}
//理财详情
export function getDetail(params) {
    return request({
        url: '/financial/detail',
        method: 'get',
        params
    })
}
//存款首页
export function getDeposit(params) {
    return request({
        url: '/deposit/index',
        method: 'get',
        params
    })
}
