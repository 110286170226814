<template>
  <div ref="scrollContainer" class="index" id="target">
    <!-- 锚点 -->
    <!-- <a href="#bottom" ref="clickLink"></a> -->
    <div class="roof">
      <div class="logo">
        <img
          class="openAi"
          src="../../assets/images/xiaoban/openAi.png"
          alt=""
        />
      </div>
      <div class="asideRight">
        剩余提问次数:{{ qaTimes }} <a @click="skipToRecharge">&nbsp;充值</a>
      </div>
    </div>
    <div class="tips">【每天24:00更新一次提问机会，仅限当天有效】</div>
    <div class="top">
      <div class="topRight">
        <img class="robot" src="../../assets/images/xiaoban/robot.png" alt="" />
      </div>
      <div class="topLeft">
        智能营销机器人小班上线啦!由最先进的人工智能ChatGPT支持，有任何问题都可以来问Ta，快来试试吧!
      </div>
    </div>
    <!-- <div class="aside">
      <div class="qa">
        <div class="asideLeft">输入你的问题</div>        
      </div>
    </div> -->
    <div class="question">
      <div style="min-height: 283px">
        <div class="content" v-for="(item, index) in qaList" :key="index">
          <div class="chatRight" v-if="item.question">
            <div class="rightQa">
              {{ item.question }}
            </div>
            <img class="chatRobot2" :src="avatar" alt="" />
          </div>

          <div class="wait2" v-show="item.showWait == 1">燃烧cpu中...</div>
          <div class="chatLeft" v-if="item.answer">
            <img
              class="chatRobot"
              src="../../assets/images/xiaoban/robot.png"
              alt=""
            />
            <div class="leftQa">
              {{ item.answer }}
              <span
                v-show="item.copy != 0"
                @click="copy(item.answer)"
                style="font-size: 13px; color: #3974c6; margin-left: 6px"
                >复制</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="sendMsg">
        <van-field
          v-model="question"
          placeholder="输入您的问题"
          :right-icon="sendicon"
          @blur="fixBlur"
        />
      </div>
      <div id="bottom" class="bottom">
        <div class="bottomLeft" @click="createNew">创建新的会话</div>
        <div class="rightActive" v-if="tiwen" @click="handleTiwen">
          发起提问
        </div>
        <div class="bottomRight" v-else>发起提问</div>
      </div>
    </div>
    <!-- <div class="tips">【每天24:00更新一次提问机会，仅限当天有效】</div> -->
    <van-overlay :show="showWait">
      <van-loading type="spinner" color="#1989fa">请耐心等待...</van-loading>
    </van-overlay>
    <!-- <div class="wait" v-show="showWait">请耐心等待...</div> -->
    <!-- <div v-show="showAnswer">
      <div class="aside">
        <div class="qa">
          <div class="asideLeft">小班</div>
          <div class="asideRight"></div>
        </div>
      </div>
      <div class="answer">
        {{ answer }}
      </div>
      <div class="copy" @click="copy(answer)">复制</div>
    </div> -->
    <div style="height: 82px"></div>
    <van-dialog
      v-model="showPupoup"
      show-cancel-button
      confirmButtonText="去充值"
      confirmButtonColor="#267EF0"
      @confirm="goPay"
    >
      <div class="p">每人每日最多可发起提问一次！ 明天再来吧~</div>
    </van-dialog>
    <van-tabbar v-model="active" :safe-area-inset-bottom="true" class="tab">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/home-active.png" />
          <img v-else src="../../assets/images/home.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/contact/index" icon="search" v-show="isShow3">
        <span>客户</span>
        <template #icon="props">
          <img
            v-if="props.active"
            src="../../assets/images/customer-active.png"
          />
          <img v-else src="../../assets/images/customer.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/xiaoban/index" icon="setting-o">
        <span>AI助手</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/index/aiActive.png" />
          <img v-else src="../../assets/index/ai.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tool/index" icon="setting-o">
        <span>营销台</span>
        <template #icon="props">
          <img
            v-if="props.active"
            src="../../assets/index/operate-active.png"
          />
          <img v-else src="../../assets/index/operate.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="setting-o">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/my-active.png" />
          <img v-else src="../../assets/images/my.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import {
  remianTimes,
  initiateQuestions,
  createNewSession,
} from "@/api/xiaoban";
import { judgePermission } from "@/utils/index";
export default {
  data() {
    return {
      isShow3: true,
      qaList: [],
      question: "",
      showPupoup: false,
      qaTimes: 0,
      tiwen: true,
      showWait: false,
      showAnswer: false,
      active: 2, // tab 切换
      sendicon: require("../../assets/send.png"),
      avatar: "",
      qaLength: 0,
    };
  },
  created() {
    this.getTimes();
    // 控制客户列表权限
    this.isShow3 =
      judgePermission("system:wrnTo") || judgePermission("system:zSTgX");
  },
  methods: {
    fixBlur() {
      console.log("222333");
      //元素下边框与视窗底部齐平，不传参则表示元素上边框与视窗顶部齐平
      // window.scrollTo(0, 0);
    },
    skipToRecharge() {
      this.$router.push("/xiaoban/recharge");
    },
    getTimes() {
      remianTimes().then((res) => {
        this.qaTimes = res.data.chatgptQuestionTimes;
        this.avatar = res.data.avatar;
        res.data.chatRecord.unshift({
          answer:
            "“请尽可能详细的描述问题，但不需要过于严密的组织语言，小班能理解您的意思”\n例如: 我是银行客户经理，请帮我设计5条针对中老年客户的理财产品销售话术，需要不同的风格，并适当添加一些emoji表情\n创建新的会话即结束本次连续聊天哦～",
          copy: 0,
        });
        this.qaList = res.data.chatRecord;
        // 获取a标签的元素
        // const link = this.$refs.clickLink;
        // // 触发点击事件
        // link.click();
        this.$nextTick(() => {
          window.scrollTo(0, document.body.scrollHeight);
          console.log("000", document.body.scrollHeight);
        });
      });
    },
    copy(content) {
      let oInput = document.createElement("textarea");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success("复制成功");
      oInput.remove();
    },
    goPay() {
      this.$router.push("/xiaoban/recharge");
    },
    handleTiwen() {
      if (this.question == "") {
        this.$toast("问题不能为空");
        return;
      } else {
        //提问次数要大于0
        if (this.qaTimes > 0) {
          this.qaLength = this.qaList.length;
          this.qaList[this.qaLength] = {};
          this.qaList[this.qaLength].question = this.question;
          //控制最后一个提问携带 燃烧cpu...
          this.qaList[this.qaLength].showWait = 1;
          this.tiwen = false;
          this.$forceUpdate();
          initiateQuestions({ question: this.question }).then((res) => {
            if (res.code == 200) {
              //-99:没有次数了
              if (res.data.ret == -99) {
                this.tiwen = true;
                // this.showWait = false;
                this.showPupoup = true;
              } else if (res.data.ret == -88) {
                this.tiwen = true;
                // this.showWait = false;
                this.$toast(res.data.msg);
              } else {
                // this.showWait = false;
                this.qaList[this.qaLength].showWait = 0;
                this.question = "";
                this.showAnswer = true;
                this.tiwen = true;
                this.getTimes();
              }
            }
          });
        } else {
          this.showPupoup = true;
        }
      }
    },
    createNew() {
      createNewSession().then((res) => {
        this.getTimes();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.van-tabbar--fixed {
  left: unset !important;
}
/deep/.van-tabbar {
  box-sizing: content-box !important;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}
/deep/ .van-cell {
  width: 96%;
  margin: 0 auto;
  margin-top: 16px;
  background-color: #fff;
  border-radius: 4px;
}
/deep/ .van-loading {
  width: 140px;
  text-align: center;
  height: 40px;
  margin: 0 auto;
  margin-top: 80%;
}
/deep/ .van-tabbar-item {
  color: #c1c9d0;
}
/deep/ .van-tabbar-item--active {
  color: #3975c6;
}
.index {
  position: relative;
  width: 100%;
  height: 100%; /*设置合适的高度*/
  // overflow-y: scroll; /*出现垂直滚动条*/
  background: url("../../assets/images/xiaoban/bg.png") no-repeat;
  background-size: 100% 100%;
  // padding-bottom: 30px;
}

.roof {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 49px;
  width: 88%;
  margin: 0 auto;
  padding-top: 19px;

  .asideRight {
    font-size: 14px;
    color: #4a4a4a;
  }
}
.logo {
  // width: 100%;
  height: 26px;
  margin: 16px 0;
  .openAi {
    float: right;
    // margin-right: 33px;
    width: 82px;
    height: 26px;
  }
}
.top {
  position: relative;
  width: 94%;
  height: 106px;
  background: linear-gradient(
    145deg,
    rgba(171, 198, 252, 0.1),
    rgba(241, 240, 245, 0.9)
  );
  border-radius: 26px 26px 0 0px;
  margin: 0 auto;
  margin-top: 22px;
  .topRight {
    position: absolute;
    left: 16px;
    top: -15px;
    .robot {
      width: 88px;
      height: 100px;
    }
  }
  .topLeft {
    float: right;
    width: 228px;
    margin: 20px 12px 16px 13px;
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    line-height: 19px;
  }
}
.aside {
  width: 94%;
  height: 49px;
  margin: 0 auto;
  margin-top: 16px;
  border-radius: 6px 6px 0 0;
  background: linear-gradient(
    to right,
    rgba(213, 225, 250, 0.1),
    rgba(241, 240, 245, 0.2)
  );
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
  .qa {
    width: 94%;
    height: 49px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .asideLeft {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
  }
}
.question {
  width: 94%;
  min-height: 362px;
  // flex: 1;
  // height: 80%;
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  box-shadow: none;
  white-space: pre-wrap;

  .content {
    width: 96%;
    min-height: 53px;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 14px;
    border-radius: 12px;
    font-size: 14px;
    color: #000000;
    line-height: 22px;
    letter-spacing: 1px;
    .chatLeft {
      display: flex;

      .leftQa {
        padding: 8px;
        margin-left: 9px;
        margin-top: 10px;
        width: 80%;
        min-height: 36px;
        background: #f2f3f7;
        border-radius: 0px 14px 14px 14px;
        font-size: 13px;
      }
    }
    .chatRobot {
      width: 36px;
      height: 40px;
    }
    .chatRight {
      margin-top: 14px;
      display: flex;
      justify-content: flex-end;
      .chatRobot2 {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      .rightQa {
        font-size: 13px;
        padding: 8px;
        margin-right: 6px;
        min-width: 159px;
        max-width: 80%;
        min-height: 36px;
        background: #5c93fc;
        border-radius: 14px 0px 14px 14px;
        color: #fff;
      }
    }
  }

  .sendMsg {
    width: 100%;
    height: 72px;
    background: #f5f6f7;
    overflow: hidden;
  }
  .bottom {
    width: 100%;
    height: 49px;
    line-height: 49px;
    font-size: 14px;
    color: #999999;
    text-align: center;
    background: #ffffff;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.07);
    .bottomLeft {
      border-right: 1px solid rgba(6, 15, 26, 0.1);
    }
    .bottomLeft,
    .bottomRight {
      float: left;
      width: 50%;
    }
    .rightActive {
      float: left;
      width: 50%;
      color: #3974c6;
    }
  }
}
.wait {
  margin-top: 26px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000;
}
.wait2 {
  text-align: center;
  font-size: 12px;
  color: #999999;
}
.answer {
  width: 94%;
  margin: 0 auto;
  background-color: #fff;
  min-height: 108px;
  padding: 10px;
  font-size: 14px;
  color: #000000;
  line-height: 22px;
  letter-spacing: 1px;
  white-space: pre-wrap;
}
.copy {
  width: 94%;
  margin: 0 auto;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: #ffffff;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.07);
  font-size: 14px;
  color: #3974c6;
}
.p {
  margin: 30px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(10, 17, 26, 0.5);
  line-height: 21px;
}
.tips {
  margin-right: 4%;
  font-size: 12px;
  color: #999999;
  text-align: right;
}
</style>