<template>
  <div class="share">
    <div class="top">
      <van-search
        shape="round"
        v-model="value"
        @search="searchDraw"
        placeholder="请输入搜索关键词"
      />
    </div>
    <div class="total">
      <div class="text">共{{ total }}个活动</div>
    </div>
    <div style="background: #f2f3f7; height: 100vh; padding-top: 10px">
      <div class="main">
        <div v-if="drawInfo.length > 0">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="content" v-for="(item, index) in drawInfo" :key="index">
              <div class="contentTop">
                <div class="CLeft">{{ item.name }}</div>
                <div class="CRight">{{ item.status }}</div>
              </div>
              <div class="contentBot">
                <div class="BLeft">
                  <div class="m16">创建时间：{{ item.created_at }}</div>
                  <div class="m16">活动时间：{{ item.time }}</div>
                </div>
              </div>
              <div class="last">
                <div class="BRight">
                  <button class="btn2" @click="copy(item)">复制链接</button>
                  <button class="btn1" @click="share(item, obj)">分享</button>
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div v-else>
          <van-empty description="描述文字" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { shareDrawApi } from "../../api/shareDraw";
import {
  shareToExternalContact,
  openExistedChatWithMsg,
} from "../../utils/wxCodeAuth";
export default {
  data() {
    return {
      total:0,
      value: "",
      drawInfo: [],
      status: 0,
      name: "",
      loading: false,
      finished: false,
      page: 1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    copy(item) {
      var input = document.createElement("input"); // 创建input对象
      input.value = item.share_link; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    getList() {
      shareDrawApi
        .drawList({
          page: this.page,
          name: this.name,
          status: this.status,
          perPage: 10,
        })
        .then((res) => {
          console.log("抽奖", res);
          this.drawInfo.push(...res.data.list); // 将数据放入list中
          console.log("this.drawInfo", this.drawInfo);
          this.loading = false;
          this.total = res.data.page.total
          if (this.drawInfo.length >= res.data.page.total) {
            this.finished = true; // 结束加载状态
          }
        });
    },
    // 被 @load调用的方法
    onLoad() {
      // 若加载条到了底部
      this.page++; // 分页数加一
      this.getList(); // 调用上面方法,请求数据
    },
    searchDraw() {
      this.page =1
      this.drawInfo = []
      this.name = this.value;
      this.getList();
    },
    share(item) {
      item.type = 3;
      console.log("分享内容", item);
      shareToExternalContact(item);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-search {
  padding: 16px;
  background-color: #3975c6;
}
/deep/ .van-search__content {
  background-color: #fff;
  opacity: 0.51;
}
.top {
  height: 64px;
  background: #3975c6;
  border-bottom: 1px solid #f1f1f1;
}
.total {
  width: 100%;
  height: 49px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(195, 195, 195, 0.26);
  overflow: hidden;
  .text {
    margin: 16px 15px;
    font-size: 12px;
    color: #9b9b9b;
  }
}
.main {
  width: 94%;
  height: 100%;
  margin: 0 auto;
  .content {
    width: 100%;
    height: 169px;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 10px;
    .contentTop {
      width: 90%;
      height: 56px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      line-height: 56px;
      border-bottom: 1px solid #f1f1f1;
      .CLeft {
        font-size: 16px;
        font-weight: bold;
        color: #323232;
      }
      .CRight {
        font-size: 14px;
        color: #3974c6;
      }
    }
    .contentBot {
      width: 90%;
      height: 69px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f1f1f1;
      .m16 {
        margin-top: 10px;
        font-size: 14px;
        color: #4a4a4a;
      }
      .m16:nth-child(2){
        margin-top: 5px;

      }
    }
    .last {
      height: 43px;
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      .BRight {
        color: #3975c6;
        text-align: center;
        button {
          background-color: #f4f5f7;
          font-size: 12px;
          border-radius: 9px;
          border: none;
          padding: 4px 12px;
        }
        .btn2 {
          color: #999999;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>