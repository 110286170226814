<template>
  <div class="radar">
    <div class="top">
      <div class="top_left">访客统计</div>
      <div class="top_right">
        <div :class="day == 0 ? 'active' : 'm16'" @click="day = 0">今日</div>
        <div :class="day == 7 ? 'active' : 'm16'" @click="day = 7">近7日</div>
        <div :class="day == 30 ? 'active' : 'm16'" @click="day = 30">
          近30日
        </div>
      </div>
    </div>
    <div class="number">
      <div class="number_box">
        <div class="people">访问人数</div>
        <div class="num">{{ userNum }}</div>
      </div>
      <div class="number_box">
        <div class="people">访问次数</div>
        <div class="num">{{ userTimes }}</div>
      </div>
    </div>
    <div class="contact">客户动态</div>
    <div v-if="radarList.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="contact_list"
          v-for="(item, index) in radarList"
          :key="index"
        >
          <div class="list_left"><img :src="item.avatar" alt="" /></div>
          <div class="list_center">
            <div class="uname">
              <div class="left">
                <span class="m12">{{ item.nickname }}</span>
                <span v-if="item.type == 1" class="m13">我的客户</span>
                <span v-else class="m14">访客</span>
              </div>
              <div class="list_right">{{ item.createdAt }}</div>
            </div>
            <div class="radarName">{{ item.title }}</div>
            <div style="height: 20px">
              <span class="time" v-if="day==0">本日访问了{{ item.click_num }}次 </span>
              <span class="time" v-if="day==7">7日内访问了{{ item.click_num }}次 </span>
              <span class="time" v-else>30日内访问了{{ item.click_num }}次 </span>
              <!-- <span class="time">本日转发了11次</span> -->
              <span class="time" v-show="item.readTime != null"
                >查看了{{ item.readTime }}
              </span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div v-else>
      <van-empty description="暂无数据" />
    </div>
  </div>
</template>
<script>
import { getRadarList } from "@/api/contact";
export default {
  data() {
    return {
      day: 0,
      radarList: [],
      userNum: 0,
      userTimes: 0,
      loading: false,
      finished: false,
      page: 1,
    };
  },
  watch: {
    day: {
      immediate: true,
      handler(newValue, oldValue) {
        console.log("newValue", newValue, oldValue);
        this.page = 1
        this.radarList = []
        this.getData();
      },
    },
  },
  // created() {
  //   this.getData();
  // },
  methods: {
    onLoad() {
      this.page++;
      this.getData();
    },
    getData() {
      getRadarList({ day: this.day, page: this.page, perPage: 10 }).then(
        (res) => {
          this.userNum = res.data.count_user;
          this.userTimes = res.data.count_num;
          this.radarList.push(...res.data.list);
          this.loading = false;
          if (this.radarList.length >= res.data.page.total) {
            this.finished = true; // 结束加载状态
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
.radar {
  width: 93%;
  margin: 0 auto;
  overflow: auto;
  height: 100%;
}
.top {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 24px;
  .top_left {
    width: 68px;
    height: 23px;
    font-size: 16px;
    font-weight: bold;
    color: #454f59;
  }
  .top_right {
    width: 136px;
    height: 20px;
    line-height: 18px;
    background: #f9f9f9;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    font-size: 11px;
    color: #bbbbbb;
  }
  .m16 {
    display: inline-block;
    width: 33.3%;
    text-align: center;
  }
  .active {
    margin-top: -1px;
    display: inline-block;
    text-align: center;
    width: 33.3%;
    height: 21px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #3974c7;
    font-size: 11px;
    color: #3974c7;
  }
}
.number {
  width: 100%;
  display: flex;
  justify-content: space-around;
  .number_box {
    text-align: center;
    width: 89px;
    height: 60px;
    background: rgba(57, 116, 199, 0.08);
    .people {
      margin-top: 5px;
      height: 24px;
      font-size: 11px;
      color: #8c99a7;
      line-height: 24px;
    }
    .num {
      height: 24px;
      font-size: 16px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 24px;
    }
  }
}
.contact {
  height: 24px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  color: #454f59;
  margin: 24px 0 21px 0;
}
.contact_list {
  margin-top: 10px;
  width: 100%;
  height: 74px;
  border-bottom: 1px solid #f1f1f1;
  .list_left {
    float: left;
    img {
      width: 44px;
      height: 44px;
      margin-right: 10px;
      border-radius: 4px;
    }
  }
  .list_center {
    width: 292px;
    float: left;
    align-items: center;
    .uname {
      height: 20px;
      .left {
        float: left;
        height: 20px;
      }
      .list_right {
        float: right;
        height: 15px;
        font-size: 11px;
        color: #999999;
        line-height: 15px;
      }
      .m12 {
        margin-right: 5px;
        vertical-align: top;
        font-size: 14px;
        font-weight: bold;
        color: #303030;
      }
      .m13 {
        vertical-align: top;
        padding: 2px;
        height: 13px;
        background: #ffeae7;
        border-radius: 2px;
        font-size: 9px;
        color: #d81e06;
      }
      .m14 {
        display: inline-block;
        vertical-align: top;
        padding: 4px;
        font-size: 9px;
        color: #999999;
        line-height: 10px;
        background: #f3f3f3;
      }
    }
    .radarName {
      text-align: left !important;
      height: 16px;
      font-size: 11px;
      color: #444448;
      margin: 2px 0 5px 0;
    }
    .time {
      vertical-align: top;
      height: 14px;
      line-height: 14px;
      display: inline-block;
      font-size: 9px;
      color: #999999;
      margin-right: 10px;
    }
  }
}
</style>