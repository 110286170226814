<template>
    <div class="all">
      <div v-for="(res,index) in circleList" :key="index">
      <div class="circle" v-if="res.contentType==1 || res.contentType==2" @click.stop="skipDetail(res.id,res.url)">
        <div class="left"><img src="../../assets/images/bkhx.png" alt="" /></div>
        <div class="right">
          <div class="rightTop">
            <div class="rightTopLeft">班克小助</div>
            <div class="rightTopRight">{{ res.createdAt }}</div>
          </div>
          <div class="rightTitle">
            {{ res.name }}
          </div>
          <div class="rightMain">
            <div class="RightMainleft">
              <img v-if="res.fullCover!=''" :src="res.fullCover" alt="" />
            </div>
            <div class="rightMainRig">
              {{ res.summary }}
            </div>
          </div>
          <div class="rightBot">
            <img v-for="(img,i) in res.headImg" :key="i" :src="img" alt="" /><span>{{ res.shareNum }}人已分享</span>
          </div>
          <!-- <div class="rightLast"></div> -->
        </div>
      </div>
      <div class="circle" v-else>
        <div class="left"><img src="../../assets/images/bkhx.png" alt="" /></div>
        <div class="right">
          <div class="rightTop">
            <div class="rightTopLeft">班克小助</div>
            <div class="rightTopRight">{{ res.createdAt }}</div>
          </div>
          <div class="rightTitle">
            {{ res.name }}
          </div>
          <div class="rightposter">
            <img :src="res.fullPoster" @click.stop="preview(res.fullPoster)" alt="" />
          </div>
          <div class="rightBot">
            <img v-for="(img,i) in res.headImg" :key="i" :src="img" alt="" /><span>{{ res.shareNum }}人已分享</span>
          </div>
          <!-- <div class="rightLast"></div> -->
        </div>
      </div>
    </div>
    </div>
  </template>
      <script>
  import { history } from "../../api/circle";
  import { ImagePreview } from 'vant';
  export default {
    data() {
      return {
        categoryList: [],
        oneCategoryList: [],
        list: [],
        twoCategoryList: [],
        twoCategoryId:'',
        oneCategoryId:'',
        activeColor:false,
        clickIndex:0,
        circleList:[],
        circleId:'',
        circleId2:''
      };
    },
    created() {
      this.getCircleList()
    },
    methods: {
      preview(img){
        ImagePreview([img]);
      },
      getCircleList(){
        history().then(res=>{
            this.circleList = res.data.list
            
        })
      },
    },
  };
  </script>
      <style lang="less" scoped>
  .all {
    height: 100%;
    overflow: auto;
    background-color: #fff;
  }
  .gray {
    width: 100%;
    height: 10px;
    background: #f2f3f7;
  }
  .oneCategory {
    display: flex;
    width: 100%;
    height: 75px;
    overflow-x: auto;
    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25%;
      
      .icon {
        img {
          width: 26px;
          height: 26px;
        }
      }
      .one {
        margin-top: 6px;
        font-size: 12px;
        color: #4a4a4a;
      }
    }
  }
  .twoCategory {
    height: 40px;
    line-height: 30px;
    width: 350px;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom:12px;
    .m16 {
      display: inline-block;
      margin-right: 8px;
      width: 25%;
      text-align: center;
      height: 28px;
      font-size: 12px;
      font-weight: bold;
      color: #4A4A4A;
      // color: #ffffff;
      line-height: 28px;
      // background-color: #3974c6;
      border-radius: 12px;
    }
    .m17 {
      display: inline-block;
      margin-right: 8px;
      width: 25%;
      text-align: center;
      height: 28px;
      font-size: 12px;
      font-weight: bold;
      // color: #4A4A4A;
      color: #ffffff;
      line-height: 28px;
      background-color: #3974c6;
      border-radius: 12px;
    }
  }
  .circle {
    width: 94%;
    margin: 0 auto;
    min-height: 260px;
    display: flex;
    justify-content: space-between;
    .left {
      margin-top: 16px;
      width: 34px;
      img {
        width: 34px;
        height: 34px;
      }
    }
    .right {
      margin-top: 16px;
      width: 300px;
      min-height: 245px;
      border-bottom: 1px solid #f1f1f1;
      .rightTop {
        display: flex;
        justify-content: space-between;
        .rightTopLeft {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .rightTopRight {
          font-size: 13px;
          color: #888888;
        }
      }
      .rightTitle {
        margin-top: 6px;
        min-height: 24px;
        font-size: 14px;
        color: #4a4a4a;
      }
      .rightMain {
        margin-top: 12px;
        width: 300px;
        height: 62px;
        background: #f3f4f8;
        border-radius: 4px;
        display: flex;
        align-items: center;
        .RightMainleft {
          margin-left: 6px;
          img {
            width: 36px;
            height: 36px;
          }
        }
        .rightMainRig {
          margin: 0 8px;
          font-size: 14px;
          color: #4a4a4a;
        }
      }
      .rightBot {
        margin-top: 12px;
        img {
          width: 23px;
          height: 23px;
          margin-left: -10px;
          border-radius: 50%;
        }
        span {
          display: inline-block;
          margin-left: 9px;
          font-size: 13px;
          color: #888888;
        }
      }
      .rightLast {
        float: right;
        margin-top: 9px;
        padding: 5px;
        width: 76px;
        text-align: center;
        background: #f5f7ff;
        border-radius: 12px;
        font-size: 12px;
        color: #486ffb;
        margin-bottom: 16px;
      }
    }
  }
  .rightposter {
    img {
      width: 144px;
      height: 304px;
    }
  }
  .set {
    .btn1 {
      position: fixed;
      right: 2.6%;
      bottom: 45%;
    }
    .btn2 {
      position: fixed;
      right: 2.6%;
      bottom: 30%;
    }
    img {
      width: 56px;
      height: 56px;
    }
    .setting {
      font-size: 10px;
      color: #486ffb;
    }
  }
  </style>