<!--
 * @Author: Null
 * @Date: 2022-06-23 08:38:47
 * @Description: 地址搜索下拉框
-->
<template>
  <div class="MySearchAddress">
    <div class="MySearchAddress-searchBox" flex="cross:center main:center">
      <van-search
        v-model="bKeyword"
        shape="round"
        class="MySearchAddress-searchBox__input"
        clearable
      />
      <van-button
        class="btn"
        :disabled="btnStatus"
        type="info"
        @click="handleConfirm"
        >确认</van-button
      >
      <!-- <MyButton :icon="showMap? 'el-icon-arrow-up':'el-icon-arrow-down'" @click="showMap = !showMap" /> -->
    </div>
    <div class="MySearchAddress-map">
      <MyBaiduMap
        :type="type"
        :location="bLocation"
        :keyword="bKeyword"
        @resultshtmlset="resultshtmlset"
        @searchcomplete="searchcomplete"
        @infohtmlset="infohtmlset"
        @emitLngLat="emitLngLat"
      />
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "MySearchAddress",
  components: {
    MyBaiduMap: () => import("./myBaiduMap.vue"),
    //   MyButton: () => import('@/components/MyButton/index.vue')
  },
  props: {
    // String, Point, None 	location表示检索区域，其类型可为空、坐标点或城市名称的字符串。当参数为空时，检索位置由当前地图中心点确定，且搜索结果的标注将自动加载到地图上，并支持调整地图视野层级；当参数为坐标时，检索位置由该点所在位置确定；当参数为城市名称时，检索会在该城市内进行。
    location: {
      type: [String, Object],
      default: "深圳龙岗",
    },
    // String, Array 搜索关键字。当keyword为数组时将同时执行多关键字的查询，最多支持10个关键字
    keyword: {
      type: [String, Array],
      default: "",
    },
    // searchMap 表示有搜索下拉框的地图 ； search 表示只有搜索下拉框；  map表示只有地图
    type: {
      type: String,
      default: "searchMap",
    },
  },
  data() {
    return {
      bKeyword: "",
      showMap: true, // 是否展示地图
      btnStatus: false,
      //BMap类
      BMap: null,
      center: { lng: 116.404, lat: 39.915 },
    };
  },
  computed: {
    bLocation() {
      return this.location || "深圳";
    },
  },
  watch: {
    keyword: {
      handler(newVal) {
        if (newVal) {
          this.bKeyword = newVal;
        }
      },
      immediate: true,
    },
  },
  methods: {
    emitLngLat(data) {
      //创建地址解析器实例
      this.gc = new BMap.Geocoder();
      console.log("获取的地址", data);
      this.getPointInfo(data);
    },
    // 获取点信息
    getPointInfo(point) {
      this.center.lng = point.lng;
      this.center.lat = point.lat;
      // // 解析坐标点--获取坐标点所在的区域名称（城市）
      var that = this;
      this.gc.getLocation(point, function (res) {
        console.log("ddfss", res);
        that.bKeyword = res.address;
        that.btnStatus = false;
        console.log("address", that.bKeyword);
      });
    },
    handleConfirm() {
      this.$emit("mapSave", this.bKeyword);
    },
    /**
     * @description: 结果列表添加完成后的回调函数
     * @param {*} ele
     * @return {*}
     */
    resultshtmlset(ele) {
      //   console.log("结果列表添加完成后的回调函数=====>", ele);
    },

    /**
     * @description: 检索完成后的回调函数。如果是多关键字检索，回调函数参数返回一个LocalResult的数组，数组中的结果顺序和检索中多关键字数组中顺序一致
     * @param {*} ele
     * @return {*}
     */
    searchcomplete(ele) {
      //   console.log("检索完成后的回调函数=====>", ele);
    },
    /**
     * @description: 标注气泡内容创建后的回调函数
     * @param {*} ele
     * @return {*}
     */
    infohtmlset(ele) {
      console.log("标注气泡内容创建后的回调函数=====>", ele);
      this.bKeyword = ele.address;
      this.btnStatus = false;
    },
  },
};
</script>
  
  <style lang="less" scoped>
.MySearchAddress-searchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 94%;
  height: 64px;
  padding-top: 5px;
  margin: 0 auto;
  .MySearchAddress-searchBox__input {
    width: 80%;
  }
  .btn {
    border-radius: 20px;
    width: 18%;
    height: 30px;
  }
}
.MySearchAddress {
  position: relative;
  // width: @baiduMapSearchBoxWidth;
  &-searchBox {
    margin-bottom: 4px;
  }
  &-map {
    width: 100%;
    position: absolute;
    background: #fff;
    z-index: 10;
  }
}
</style>