<template>
  <div class="deposit">
    <div class="classify" v-if="tabList.length!=0">
      <div class="classifyList" v-for="(item, index) in firstFiveCategories" :key="index">
        <div v-if="index<=3" @click="handleTabChange(item,index)">
          <img class="icon" :src="item.avatar" alt="" />
          <div class="name">{{item.name}}</div>
        </div>
        <div v-if="index==4" @click="goPage(item)">
          <img class="icon" :src="item.avatar" alt="" />
          <div class="name">更多理财</div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="bannerTitle">{{currentGroupInfo.title}}</div>
      <img class="bannerImg" src="../../assets/images/manageMoney/banner.png" alt="" />
      <div class="describe">{{currentGroupInfo.abstract}}</div>
    </div>
    <!-- <div class="middleBox">
      <div class="box" v-for="i in 4" :key="i">
        <div class="cName">天利22号</div>
        <div class="tag">保本保息</div>
        <div class="lilv">3.788%</div>
        <div class="time">七日年化</div>
        <div class="feature">低波资源型产品</div>
      </div>
    </div> -->
    <div class="selection">
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item v-model="value1" :options="option1" />
        <van-dropdown-item v-model="value2" :options="option2" />
        <van-dropdown-item v-model="value3" :options="option3" />
      </van-dropdown-menu>
    </div>
    <van-empty description="暂无数据" v-if="finaData.length==0" />
    <div v-else>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-swipe-cell v-for="(i,index) in finaData" :key="index">
          <div class="info" @click="goDetail(i)">
            <div class="top"></div>
            <div class="infoContent">
              <div class="topTitle">
                兴业银行天天利22号 <span class="daixiao">代销</span>
              </div>
              <div class="infoMiddle">
                <div class="lilv">新品热销</div>
                <div class="shen">5个月</div>
                <div class="shen">1元起</div>

              </div>
              <div class="infoMiddle">
                <div class="time">新启航系列</div>
                <div class="money">投资期限</div>
                <div class="money">起购金额</div>
              </div>
              <div class="infoBot">
                <showMore :length="50" :longStr="longStr" />
              </div>
            </div>
          </div>
          <template #right>
            <div class="rightBtn">
              <div class="btn send">发送</div>
              <div class="btn roomSend">群发</div>
            </div>
          </template>
        </van-swipe-cell>
      </van-list>
    </div>
  </div>
</template>
<script>
  import showMore from "../manageMoney/components/showMore.vue";
  import { getDeposit, getCate } from "@/api/financial";
  export default {
    components: { showMore },
    data() {
      return {
        longStr:
          "：本产品主要投资于货币市场工具，根据货币市场工具当前以及未来本产品主要投资于货币市场工具，根据货币市场工具当前以及未来一段时期的收益率水平预测，设定业",
        tabIndex: 0,
        value1: 0,
        value2: 0,
        value3: 0,
        option1: [
          { text: "排序", value: 0 },
          { text: "推荐", value: 1 },
          { text: "收益率从高到低", value: 2 },
          { text: "起购金额从低到高", value: 3 },
          { text: "期限从长到短", value: 4 },
        ],
        option2: [
          { text: "起购金额", value: 0 },
          { text: "小于1万", value: 1 },
          { text: "1-5万", value: 2 },
          { text: "5-20万", value: 3 },
          { text: "20-100万", value: 4 },
          { text: "100-300万", value: 5 },
          { text: "300-1000万", value: 6 },
          { text: "大于1000万", value: 7 },
        ],
        option3: [
          { text: "期限", value: 0 },
          { text: "1个月内", value: 1 },
          { text: "1-3月", value: 2 },
          { text: "3-6月", value: 3 },
          { text: "6-9月", value: 4 },
          { text: "9-12月", value: 5 },
          { text: "1年以上", value: 26 },
        ],
        tabList: [],
        finaData: [{}],
        currentGroupInfo: {},
        queryParams: {
          groupid: undefined,
          page: 1,
          perPage: 10
        },
        loading: false,
        finished: false,
      };
    },
    computed: {
      firstFiveCategories() {
        return this.tabList.slice(0, 5);
      }
    },
    created() {
      this.getGroupData()
    },
    methods: {
      onLoad() {
        this.getData()
      },
      //获取分类数据
      getGroupData() {
        getCate().then((res) => {
          this.tabList = res.data
          if (res.data.length != 0) {
            this.queryParams.groupid = res.data[0].id
            this.currentGroupInfo = res.data[0]
            this.getData()
          }
        })
      },
      //获取列表数据
      getData() {
        getDeposit(this.queryParams).then((res) => {
          let newData = res.data.list.map((i) => {
            i.keywords = i.keywords.split(', ')
            return i
          })
          setTimeout(() => {
            this.queryParams.page++
            // this.finaData.push(...newData)
            this.loading = false;
            if (this.finaData.length >= res.data.page.total) {
              this.finished = true;
              return
            }
          }, 1000);
        })
      },
      handleTabChange(row, index) {
        this.resultQuery()
        this.tabIndex = index;
        this.queryParams.groupid = row.id
        this.currentGroupInfo = row
        this.getData()
      },
      resultQuery() {
        this.value1 = 0;
        this.value2 = 0;
        this.value3 = 0;
        this.finaData = []
        this.queryParams = {
          groupid: this.currentGroupInfo.id,
          page: 1,
          perPage: 10
        }
      },
      //前往详情页
      goDetail(row) {
        this.$router.push({
          path: "/deposit/detail",
          query: {
            id: row.id,
          },
        });
      },
      //前往列表详情
      goPage() {
        this.$router.push({
          path: "/financial/list",
          query: {
            employeeId: this.employeeId
          },
        });
      },
    }
  };
</script>
<style lang="less" scoped>
  /deep/ .van-swipe-cell__right {
    top: calc(50% - 25px) !important;
  }

  .deposit {
    background-color: #fff;
  }

  .classify {
    display: flex;
    background-color: #f9f9f9;

    .classifyList {
      margin: 18px 0 10px 0;
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .icon {
      width: 38px;
      height: 36px;
    }

    .name {
      max-width: 50px;
      margin-top: 12px;
      font-size: 12px;
      color: #4a4a4a;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }

  .banner {
    width: 94%;
    margin: 0 auto;
    margin-top: 15px;
    position: relative;

    .bannerTitle {
      position: absolute;
      top: 20px;
      left: 16px;
      font-weight: 500;
      font-size: 22px;
      color: #ffffff;
    }

    .describe {
      width: 160px;
      position: absolute;
      top: 56px;
      left: 16px;
      font-size: 13px;
      color: #ffffff;
      line-height: 17px;
    }
  }

  .bannerImg {
    width: 100%;
    height: 130px;
  }

  .middleBox {
    width: 94%;
    height: 361px;
    margin: 0 auto;
    margin-top: 12px;
    background: linear-gradient(#fee8ca 100%, #fff 0%);
    border-radius: 4px;
    border: 1px solid #ffffff;

    .box {
      display: inline-block;
      margin: 10px 10px 0 10px;
      width: 160px;
      height: 158px;
      background: linear-gradient(180deg, #ffecdc 0%, #ffffff 100%);
      border-radius: 2px;
      border: 1px solid #ffffff;
      text-align: center;
      overflow: hidden;

      .cName {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        margin: 10px 0 8px 0;
      }

      .tag {
        margin: 0 auto;
        width: 55px;
        height: 18px;
        line-height: 18px;
        border-radius: 2px;
        border: 1px solid #ef4f3b;
        font-size: 12px;
        color: #f04834;
        margin-bottom: 6px;
      }

      .lilv {
        font-weight: 600;
        font-size: 20px;
        color: #d81e06;
      }

      .time {
        font-size: 12px;
        color: #999999;
        margin-bottom: 6px;
      }

      .feature {
        width: 112px;
        margin: 0 auto;
        padding: 6px;
        background: #f1f1f1;
        border-radius: 2px;
        font-size: 12px;
        color: #999999;
      }
    }
  }

  .info {
    width: 94%;
    margin: 0 auto;
    position: relative;
    min-height: 186px;
    border-radius: 4px;
    margin-top: 11px;
    overflow: hidden;
    border: 1px solid #fff4e7;

    .top {
      width: 100%;
      position: absolute;
      height: 30px;
      background: linear-gradient(#fee8ca 0%, #ffffff 100%);
      z-index: 1;
    }

    .infoContent {
      position: relative;
      width: 92%;
      margin: 0 auto;
      z-index: 99;
    }

    .topTitle {
      margin-top: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }

    .daixiao {
      display: inline-block;
      font-weight: 400;
      font-size: 10px;
      color: #9b9b9b;
      padding: 1px 4px;
      border: 1px solid #adacac;
      border-radius: 2px;
    }

    .lilv {
      width: 30%;
      font-weight: 600;
      font-size: 16px;
      color: #d81e06;
    }

    .shen {
      width: 30%;
      font-size: 14px;
      color: #000000;
    }

    .time {
      width: 30%;
      font-size: 14px;
      color: #515151;
    }

    .money {
      width: 30%;
      font-size: 12px;
      color: #515151;
    }

    .infoMiddle {
      display: flex;
      min-height: 26px;
      align-items: center;
      margin: 12px 0;
    }

    .infoBot {
      min-height: 34px;
      font-size: 12px;
      color: #515151;
      line-height: 17px;
      text-align: left;
      line-height: 18px;
    }
  }

  .rightBtn {
    padding: 0 10px;
  }

  .btn {
    display: block;
    width: 50px;
    height: 23px;
    line-height: 23px;
    border-radius: 12px;
    border: 1px solid #3974c6;
    font-size: 12px;
    color: #3974c6;
    text-align: center;
    margin-bottom: 10px;
  }

  .roomSend {
    background: #3974c6;
    color: #fff;
  }
</style>