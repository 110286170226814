<template>
  <div>
    <div class="top">
      <div class="topContent">
        <div style="width: 96%; margin: 0 auto">
          <div class="name">
            <span class="icon">资讯 </span>{{ circleInfo.name }}
          </div>
          <div class="share">
            {{ circleInfo.shareTotalNum }}人已转发，{{
              circleInfo.clickTotalNum
            }}人已阅读
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="mainDay">
        <div class="left">客户统计</div>
        <div class="right">
          <span
            v-for="(item, index) in dayList"
            :key="index"
            class="day"
            @click="changeDay1(index)"
            :class="{ activeSpan: spanIndex1 == index }"
            >{{ item.value }}</span
          >
        </div>
      </div>
      <div class="dataContent">
        <div class="flex_box">
          <div class="num">{{ clickPeopleNum }}</div>
          <div class="m16">访问人数</div>
        </div>
        <div class="flex_box">
          <div class="num">{{ clickTotalNum }}</div>
          <div class="m16">访问次数</div>
        </div>
        <div class="flex_box">
          <div class="num">{{ shareTotalNum }}</div>
          <div class="m16">分享次数</div>
        </div>
      </div>
      <div class="customerinfo">
        <div v-if="visitorInfo.length > 0">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="我也是有底线的~"
            @load="onLoad"
          >
            <div class="list" v-for="(item, index) in visitorInfo" :key="index">
              <div class="listContent">
                <div style="display: flex">
                  <img class="avatar" :src="item.userAvatar" alt="" />
                  <div class="info">
                    <div class="uname">{{ item.userName }}</div>
                    <div class="time">{{ item.createdAt }}</div>
                  </div>
                </div>
                <div class="forward" v-show="item.isShare == 1">转发</div>
              </div>
            </div>
          </van-list>
        </div>
        <div v-else>
          <van-empty description="暂无数据" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  circleData,
  circleVisitor,
} from "@/api/dataBurialPoint";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      spanIndex1: 0,
      dayList: [
        { id: 1, value: "今日" },
        { id: 2, value: "近7日" },
        { id: 3, value: "近30天" },
      ],
      active: 0,
      id: "",
      circleInfo: {},
      visitorInfo: [],
      page: 1,
      clickPeopleNum: 0,
      clickTotalNum: 0,
      shareTotalNum: 0,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
    this.getVisitor();
    this.getNum()
  },
  methods: {
    changeDay1(index) {
      this.spanIndex1 = index;
      this.visitorInfo= []
      this.page = 1
      this.getVisitor();
      this.getNum()
    },
    getData() {
      circleData({ id: this.id }).then((res) => {
        this.circleInfo = res.data;
      });
    },
    getNum() {
      circleVisitor({
        id: this.id,
        searchType: this.spanIndex1 + 1,
        page: 1,
      }).then((res) => {
        this.clickPeopleNum = res.data.clickPeopleNum;
        this.clickTotalNum = res.data.clickTotalNum;
        this.shareTotalNum = res.data.shareTotalNum;
      });
    },
    getVisitor() {
      circleVisitor({
        id: this.id,
        searchType: this.spanIndex1 + 1,
        page: this.page,
        perPage: 10,
      }).then((res) => {
        this.visitorInfo.push(...res.data.list);
        this.loading = false;
        if (this.visitorInfo.length >= res.data.page.total) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      this.page += 1;
      this.getVisitor();
    },
  },
};
</script>
  <style lang="less" scoped>
.top {
  height: 124px;
  background: #f2f3f7;
  overflow: hidden;
  .topContent {
    width: 93%;
    height: 96px;
    border-radius: 3px;
    margin: 0 auto;
    margin-top: 14px;
    background-color: #fff;
    overflow: hidden;
    .name {
      height: 44px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin: 12px 0;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical; //盒子中内容竖直排列
      .icon {
        display: inline-block;
        padding: 4px;
        margin-right: 2px;
        // height: 20px;
        line-height: 18px;
        background: #174f99;
        border-radius: 5px;
        font-size: 11px;
        color: #ffffff;
      }
    }
    .share {
      height: 15px;
      font-size: 11px;
      color: #a5a5a5;
      line-height: 15px;
    }
  }
}
.main {
  .mainDay {
    height: 66px;
    line-height: 64px;
    width: 93%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left {
      font-family: PingFangSC-Medium, PingFang SC;
      height: 21px;
      font-size: 15px;
      font-weight: 500;
      color: #000000;
    }
    .right {
      float: right;
      font-size: 12px;
      color: #9b9b9b;
    }
    .day {
      padding-left: 12px;
    }
  }
  .dataContent {
    width: 93%;
    margin: 0 auto;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 0px 2px 0px rgba(171, 171, 171, 0.3);
    border-radius: 14px;
    .flex_box {
      display: inline-block;
      width: 33.3%;
      text-align: center;
      margin-top: 27px;
      .num {
        height: 24px;
        font-size: 21px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #000000;
        line-height: 24px;
      }
      .m16 {
        height: 24px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #4a4a4a;
        line-height: 24px;
      }
    }
  }
  .customerinfo {
    width: 93%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 154px;
    background: #ffffff;
    box-shadow: 0px 0px 2px 0px rgba(171, 171, 171, 0.3);
    border-radius: 14px;
  }
}

.activeSpan {
  font-size: 12px;
  color: #000000;
}
.list {
  height: 77px;
  border-bottom: 1px solid #efefef;
  .listContent {
    width: 94%;
    margin: 0 auto;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .info {
      margin-left: 10px;
      .uname {
        font-size: 15px;
        font-weight: bold;
        color: #262628;
      }
      .time {
        font-size: 12px;
        color: #a5a5a5;
      }
    }
    .forward {
      float: right;
      font-size: 13px;
      color: #9b9b9b;
    }
  }
}
</style>