<template>
  <div class="all">
    <a-spin tip="更新中..." :spinning="loading">
      <div class="top">
        <van-tabs v-model="active" @click="change">
          <van-tab
            v-for="(res1, idx1) in bindCorpList"
            :title="res1.corpName"
            :name="res1.creditNo"
            :key="idx1"
          >
            <!-- 工商照面 -->
            <div class="m16" v-if="businessFace != 0">
              <p class="grayBackground"></p>
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">工商照面</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.businessFace.last_update_time.slice(0, 10)
                    }}</i
                  >
                </div>
                <div class="right" @click="update">更新信息</div>
              </div>
              <div class="title">{{ businessFace.format_name }}</div>
              <div class="main">
                <span class="mainLeft">法定代表人&nbsp;</span>
                <i class="mainRight">&emsp;{{ businessFace.operName }}</i>
              </div>
              <div class="main">
                <span class="mainLeft">代表人职务&nbsp;</span>
                <i class="mainRight">&emsp;{{ businessFace.title }}</i>
              </div>
              <!-- <div class="main">
              <span class="mainLeft">上市公司&nbsp;</span>
              <i class="mainRight">&emsp;&emsp;{{businessFace.tags}}</i>
            </div> -->
              <div class="main">
                <span class="mainLeft">经营状态&nbsp;</span>
                <i class="mainRight"
                  >&emsp;&emsp;{{ businessFace.new_status }}</i
                >
              </div>
              <div class="main">
                <span class="mainLeft">注册资本&nbsp;</span>
                <i class="mainRight"
                  >&emsp;&emsp;{{ businessFace.registCapi }}</i
                >
              </div>
              <div class="main">
                <span class="mainLeft">实缴资本&nbsp;</span>
                <i class="mainRight"
                  >&emsp;&emsp;{{ businessFace.actualCapi }}</i
                >
              </div>
              <div class="main">
                <span class="mainLeft">经营范围&nbsp;</span>
                <i class="mainRight">&emsp;&emsp;{{ businessFace.scope }}</i>
              </div>
              <div class="main">
                <span class="mainLeft">公司地址&nbsp;</span>
                <i class="mainRight">&emsp;&emsp;{{ businessFace.address }}</i>
              </div>
              <div class="main">
                <span class="mainLeft"> 企业注册号&nbsp;</span>
                <i class="mainRight">&emsp;&emsp;{{ businessFace.regNo }}</i>
              </div>
              <div class="main">
                <span class="mainLeft"> 组织机构号&nbsp;</span>
                <i class="mainRight">&emsp;&emsp;{{ businessFace.orgNo }}</i>
              </div>
              <div class="main">
                <span class="mainLeft"> 企业类型代码&nbsp;</span>
                <i class="mainRight">&emsp;&emsp;{{ businessFace.econKind }}</i>
              </div>
            </div>
            <!-- 公司股东 -->
            <div class="m16" v-if="shareholder != 0">
              <p class="grayBackground"></p>
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">工商股东</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.shareholder.last_update_time.slice(0, 10)
                    }}</i
                  >
                </div>
              </div>
              <div v-for="(item, index) in shareholder" :key="index">
                <div class="title" style="color: #3974c6">{{ item.name }}</div>
                <div>
                  <div class="shareholder">
                    <span class="left">股比&nbsp;</span>
                    <span class="right">股东类型&nbsp;</span>
                  </div>
                  <div class="black">
                    <span class="left">{{ item.stockPercent }}&nbsp;</span>
                    <span class="right">{{ item.stockType }}&nbsp;</span>
                  </div>
                  <div class="shareholder">
                    <span class="left">出资时间&nbsp;</span>
                    <span class="right">认缴出资额&nbsp;</span>
                  </div>
                  <div class="black">
                    <span class="left">&nbsp;</span>
                    <span class="right"
                      >{{ item.totalShouldCapi }}元人民币&nbsp;</span
                    >
                  </div>
                  <div class="shareholder">
                    <span class="left">实缴出资额&nbsp;</span>
                    <span class="right">总认缴&nbsp;</span>
                  </div>
                  <div class="black">
                    <span class="left"> {{ item.stockPercent }}&nbsp;</span>
                    <span class="right">{{ item.totalRealCapi }}&nbsp;</span>
                  </div>
                </div>
              </div>
              <!-- <p class="grayLine"></p> -->
            </div>
            <!-- 主要人员 -->
            <div class="m16" v-if="keyPerson != 0">
              <p class="grayBackground"></p>
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">主要人员</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.keyPersonnel.last_update_time.slice(0, 10)
                    }}</i
                  >
                </div>
              </div>
              <div style="margin: 0 auto; width: 96%">
                <div
                  class="memberInfo"
                  v-for="(item, index) in keyPerson"
                  :key="index"
                >
                  <div class="name">{{ item.name }}</div>
                  <div class="position">职位</div>
                  <div class="positionName">{{ item.title }}</div>
                </div>
              </div>
            </div>
            <!-- 联系方式 -->
            <div class="m16" v-if="contact != 0">
              <!-- <p class="grayBackground"></p> -->
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">联系方式</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.contact.last_update_time.slice(0, 10)
                    }}</i
                  >
                </div>
              </div>
              <div class="shareholder">
                <span class="left">电话&nbsp;</span>
                <span class="right">邮件&nbsp;</span>
              </div>
              <div class="black">
                <span class="left">{{ contact.telephone }}&nbsp;</span>
                <span class="right">{{ contact.email }}&nbsp;</span>
              </div>
            </div>
            <!-- 社保信息 -->
            <div class="m16" v-if="socialSecurityInformation != 0">
              <p class="grayBackground"></p>
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">社保信息</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.socialSecurityInformation.last_update_time.slice(
                        0,
                        10
                      )
                    }}</i
                  >
                </div>
              </div>
              <div class="main">
                <span class="mainLeft">参保人数&nbsp;</span>
                <i class="mainRight">&emsp;{{ socialSecurityInformation }}</i>
              </div>
            </div>
            <!-- 经营异常 -->
            <div class="m16" v-if="businessRisk.length > 0">
              <p class="grayBackground"></p>
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">经营异常</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.businessRisk.last_update_time.slice(0, 10)
                    }}</i
                  >
                </div>
              </div>
              <div v-for="(item, index) in businessRisk" :key="index">
                <div class="main">
                  <span class="mainLeft">省份&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.province }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">列入时间&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.inDate }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">做出决定机关&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.department }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">移出时间&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.outDate }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">注册号&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.regNo }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">当前公示信息&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.disabled }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">历史公示信息&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.disabled }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">列入原因&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.inReason }}</i>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="grayBackground"></p>
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">经营异常</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.businessRisk.last_update_time.slice(0, 10)
                    }}</i
                  >
                </div>
              </div>
              <div style="font-size: 16px; margin: 0 0 16px 16px">无</div>
            </div>
            <!-- 被执行企业 -->
            <div class="m16" v-if="executedEnterprise.length > 0">
              <p class="grayBackground"></p>
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">被执行企业</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.executedEnterprise.last_update_time.slice(0, 10)
                    }}</i
                  >
                </div>
              </div>
              <div v-for="(item, index) in executedEnterprise" :key="index">
                <div class="main">
                  <span class="mainLeft">执行ID&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.id }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">执行依据文号&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.case_number }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">执行状态&nbsp;</span>
                  <i class="mainRight"
                    >&emsp;{{ item.status == 0 ? "执行中" : "已结案" }}</i
                  >
                </div>
                <div class="main">
                  <span class="mainLeft">执行法院&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.court }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">立案日期&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.case_date }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft">执行金额&nbsp;</span>
                  <i class="mainRight">&emsp;{{ item.amount }}</i>
                </div>
                <div class="main">
                  <span class="mainLeft"
                    >{{
                      item.disabled == 0 ? "当前公示信息" : "历史公示信息"
                    }}&nbsp;</span
                  >
                  <!-- <i class="mainRight">&emsp;200人</i> -->
                </div>
              </div>
            </div>
            <div v-else>
              <p class="grayBackground"></p>
              <div class="topUp">
                <div class="left">
                  <span class="zhaomian">被执行企业</span>
                  <img :src="require('../../assets/update.png')" alt="" />
                  <i class="updateTime">
                    &nbsp;更新时间:{{
                      info.executedEnterprise.last_update_time.slice(0, 10)
                    }}</i
                  >
                </div>
              </div>
              <div style="font-size: 16px; margin: 0 0 16px 16px">无</div>
            </div>
            <!-- <div class="bottom">更新对公业务</div> -->
          </van-tab>
        </van-tabs>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { publicBusinessApi } from "../../api/publicBusiness";

export default {
  data() {
    return {
      active: 0,
      creditNo: "",
      contactId: "",
      businessFace: {},
      shareholder: [],
      keyPerson: [],
      contact: {},
      socialSecurityInformation: "",
      businessRisk: [],
      executedEnterprise: "",
      loading: false,
      bindCorpList: "",
      info: {},
      times: "",
    };
  },
  created() {
    this.creditNo = this.$route.query.detailCreditNo;
    this.active = this.creditNo;
    this.contactId = this.$route.query.contactId;
    this.times = this.$route.query.times;
    this.getBindCorpList();
    this.getInfo();
  },
  methods: {
    //获取绑定公司列表
    getBindCorpList() {
      publicBusinessApi
        .bindCropList({ contactId: this.contactId })
        .then((res) => {
          const { data } = res;
          this.bindCorpList = data.bindCorp;
          console.log("绑定", this.bindCorpList);
        });
    },
    getInfo() {
      publicBusinessApi
        .bindCorpDetail({ creditNo: this.active, contactId: this.contactId })
        .then((res) => {
          console.log("详情", res);
          const { data } = res;
          this.info = data;
          // console.log('dddd',this.info.businessFace.last_update_time)
          this.businessFace = data.businessFace.data;
          this.shareholder = data.shareholder.data;
          this.businessRisk = data.businessRisk.data;
          console.log("length", this.businessRisk);
          console.log("length", this.businessRisk.length);

          this.executedEnterprise = data.executedEnterprise.data;
          this.contact = data.contact.data;
          this.keyPerson = data.keyPersonnel.data;
          this.socialSecurityInformation = data.socialSecurityInformation.data;
        });
    },
    change() {
      console.log("this.active", this.active);
      this.getInfo();
    },
    update() {
      if (this.times > 0) {
        this.loading = true;
        publicBusinessApi
          .updateCorp({ creditNo: this.creditNo, contactId: this.contactId })
          .then((res) => {
            if (res.code == 200) {
              this.getInfo();
              this.loading = false;
              this.$toast("更新成功");
            } else {
              this.loading = false;
              this.$toast(res.msg);
            }
          });
      } else {
        this.$toast("更新次数不足，请充值");
      }
    },
  },
};
</script>
<style scoped lang="less">
.all {
  height: 100%;
  overflow: auto;
  background-color: #fff;
}
/deep/ .van-tabs__line {
  bottom: 0;
  background-color: #3975c6;
}
i,
em {
  font-style: normal;
}
.top {
  height: 506px;
  background-color: #fff;

  .topUp {
    display: flex;
    justify-content: space-between;
    width: 96%;
    height: 41px;
    margin: 0 auto;

    .left {
      .zhaomian {
        font-size: 16px;
        font-weight: bold;
        color: #303030;
      }
      img {
        display: inline-block;
        margin-left: 18px;
        width: 11px;
        height: 11px;
      }
      .updateTime {
        display: inline-block;
        margin-right: 68px;
        font-size: 12px;
        color: #929292;
      }
    }
    .right {
      font-size: 13px;
      color: #3974c6;
      height: 41px;
      line-height: 41px;
    }
  }
  .grayBackground {
    margin: 0;
    width: 100%;
    height: 8px;
    background: #f2f4f6;
  }
  .title {
    width: 96%;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #303030;
    line-height: 23px;
    letter-spacing: 1px;
  }
  .main {
    width: 96%;
    margin: 0 auto;
    .mainLeft {
      font-size: 13px;
      color: #929292;
    }
    .mainRight {
      font-size: 13px;
      color: #303030;
    }
  }
  .shareholder {
    width: 96%;
    margin: 0 auto;
    .left {
      display: inline-block;
      width: 50%;
      font-size: 13px;
      color: #929292;
    }
    .right {
      display: inline-block;
      border-left: 1px solid #d8d8d8;
      padding-left: 10px;
      width: 50%;
      font-size: 13px;
      color: #929292;
    }
  }
  .black {
    width: 96%;
    margin: 0 auto;
    .left {
      display: inline-block;
      width: 50%;
      font-size: 13px;
      color: #303030;
    }
    .right {
      display: inline-block;
      border-left: 1px solid #d8d8d8;
      padding-left: 10px;
      width: 50%;
      font-size: 13px;
      color: #303030;
    }
  }
  .grayLine {
    margin: 0 auto;
    width: 90%;
    height: 8px;
    background: #f2f4f6;
  }
  .memberInfo {
    float: left;
    width: 50%;
    .name {
      font-size: 16px;
      font-weight: bold;
      color: #3974c6;
      margin-top: 13px;
      margin-bottom: 7px;
    }
    .position {
      font-size: 13px;
      color: #929292;
    }
    .positionName {
      font-size: 13px;
      color: #303030;
      margin-top: 9px;
      margin-bottom: 13px;
    }
  }

  .topBot {
    height: 465px;
    background-color: blue;
  }
}
.bottom {
  height: 30px;
  font-size: 13px;
  color: #3974c6;
  text-align: center;
}
</style>