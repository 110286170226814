import { render, staticRenderFns } from "./mapPage.vue?vue&type=template&id=152d53b2&scoped=true&"
import script from "./mapPage.vue?vue&type=script&lang=js&"
export * from "./mapPage.vue?vue&type=script&lang=js&"
import style0 from "./mapPage.vue?vue&type=style&index=0&id=152d53b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152d53b2",
  null
  
)

export default component.exports