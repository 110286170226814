
<template>
  <div class="post">
    <!-- 海报html元素 -->
    <div style="overflow: hidden; width: 90%; margin: 0 auto">
      <a-spin :spinning="bool" tip="海报生成中...">
        <div class="qrcodeposter_wrap" v-if="showDom" id="canvasPoster">
          <div style="width: 100%">
            <div style="text-align: center">
              <img
                crossOrigin="anonymous"
                class="avatar_pic"
                :src="imgUrl"
                @load="imgLoad"
              />
            </div>
            <div class="footer">
              <div class="ftop">
                <div class="left">
                  <img crossOrigin="anonymous" :src="postData.avatar" alt="" />
                  <canvas
                    id="canvas1"
                    width="20"
                    height="20"
                    style="display: none"
                  ></canvas>
                  <div class="lr">
                    <div class="name">{{ postData.user_name }}</div>
                    <div class="m16">{{ postData.bank_name }}</div>
                    <div class="m16">{{ postData.department_name }}</div>
                    <div class="m16">{{ postData.mobile }}</div>
                  </div>
                </div>
                <div class="right">
                  <!-- <div ref="qrCodeDiv"> -->
                  <img :src="postData.QRcode" crossOrigin="anonymous" alt="" />
                  <!-- </div> -->
                </div>
              </div>
              <div class="ftbot">添加我的企业微信，为您提供专业的服务。</div>
              <!-- <div style="height: 12px"></div> -->
            </div>
          </div>
        </div>

        <div class="tip">长按保存海报至手机</div>
        <div style="text-align: center">
          <img style="width: 100%" :src="dataImg" v-if="dataImg" alt />
        </div>
      </a-spin>
    </div>
  </div>
</template>
<script>
// import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { posterApi } from "../../api/poster";
import { vcardIndex } from "@/api/electronicCard";
import { shareToExternalMoments } from "@/utils/wxCodeAuth";
import { shareCallBack } from "@/api/circle";

export default {
  data() {
    return {
      bool: true,
      imgUrl: "",
      imgUrl2: "",
      showDom: true,
      dataImg: "",
      postData: {
        QRcode: "",
      },
      from: "",
      canvas: {},
    };
  },
  created() {
    this.from = this.$route.query.from;
    this.getInfo();
  },

  methods: {
    image2Base64(img, id) {
      console.log(44344);
      img.setAttribute("crossOrigin", "anonymous");
      var canvas = document.getElementById(id);
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");
      console.log("344");
      return dataURL;
    },
    getInfo() {
      if (this.from == "topicDetail") {
        vcardIndex().then((res) => {
          console.log("数据3", res);
          const data = res.data.employee;
          console.log("name", data);
          this.postData.user_name = data.name;
          this.postData.bank_name = data.position;
          this.postData.department_name = data.departmentName;
          this.postData.mobile = data.mobile;
          console.log("数据4", this.postData);
          this.imgUrl =
            this.$route.query.imgurl + "?tamp=" + new Date().valueOf();
          this.postData.QRcode = this.$route.query.qrcode;
          console.log("this.postData.QRcode", this.postData.QRcode);
          var avatarbase64 = "";
          var img = new Image();
          var that = this;
          img.src = res.data.employee.fullImage;

          img.setAttribute("crossOrigin", "Anonymous");
          img.onload = function () {
            avatarbase64 = that.image2Base64(img, "canvas1");
            that.postData.avatar = avatarbase64;
            that.$forceUpdate();
            // console.log("this.avatar", that.postData.avatar);
          };
        });
      } else {
        posterApi.posterInfo().then((res) => {
          console.log("数据", res);
          this.postData = res.data;
          console.log("数据2", this.postData);
          this.imgUrl =
            this.$route.query.imgurl + "?tamp=" + new Date().valueOf();
          console.log("this.imgUrl", this.imgUrl);
        });
      }
    },
    // 创建海报
    createPoster() {
      const poster = document.getElementById("canvasPoster");
      html2canvas(poster, {
        dpi: window.devicePixelRatio * 2,
        backgroundColor: "#fff",
        useCORS: true,
        scale: 2,
        async: true,
      }).then((canvas) => {
        if (this.$route.query.circleId) {
          this.dataImg = canvas.toDataURL("image/png");
          this.showDom = false;         
          posterApi.createMediaId({ base64ImageContent: this.dataImg }).then((res) => {
            this.bool = false;
            let shareData = {};
            shareData.type = 2;
            shareData.mediaid = res.data.mediaId;
            console.log('shareData2',shareData)
            shareToExternalMoments(this.$route.query.text,shareData).then((item) => {
              console.log("res11", item);
              shareCallBack({ id: this.$route.query.circleId }).then(() => {
                console.log("回调");
              });
              this.$message.success("发送成功");
            });
          });
        } else {
          this.dataImg = canvas.toDataURL("image/jpeg");
          this.showDom = false;
          this.bool = false;
        }
        console.log("执行了生成海报1", this.dataImg);
      });
    },
    imgLoad() {
      // console.log('this.imgUrl',this.imgUrl)
      this.createPoster();
    },
  },
};
</script>
<style lang="less" scoped>
.post {
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  background: #fff;
}
.qrcodeposter_wrap {
  overflow: hidden;
  height: auto;
  width: 100%;
  margin: 6px auto;
  .avatar_pic {
    width: 100%;
    height: 601px;
  }
  .footer {
    width: 91%;
    height: 120px;
    margin: 0 auto;
    margin-top: 24px;
  }
  .ftop {
    height: 70px;
  }
  .left {
    width: 75%;
    float: left;
    .lr {
      float: left;
      margin-left: 8px;
    }
    img {
      float: left;
      width: 65px;
      height: 64px;
    }
    .name {
      height: 21px;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #303030;
      line-height: 21px;
      letter-spacing: 1px;
    }
    .m16 {
      height: 13px;
      font-size: 9px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #717174;
      line-height: 13px;
    }
  }
  .right {
    float: right;
    img {
      margin-top: 10px;
      width: 51px;
      height: 51px;
    }
  }
  .ftbot {
    margin-top: 10px;
    height: 13px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #4a4a4a;
    line-height: 13px;
  }
}
.tip {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  font-size: 13px;
  height: 50px;
  line-height: 50px;
  background: #ffffff;
  border-radius: 13px 13px 0px 0px;
  text-align: center;
}
</style>