import request from "../plugins/axios";

// 节点关怀分类
export function getCategory(params) {
    return request({
        url: '/nodeCare/getCategory',
        method: 'get',
        params: params
    })
}
//获取banner
export function getBanner(params) {
    return request({
        url: '/nodeCare/getBanner',
        method: 'get',
        params: params
    })
}
//获取最近使用
export function getLog(params) {
    return request({
        url: '/nodeCare/getLog',
        method: 'get',
        params: params
    })
}
//二级目录
export function getTwoCategory(params) {
    return request({
        url: '/nodeCare/getTwoCategory',
        method: 'get',
        params: params
    })
}
//海报详情
export function posterDetail(params) {
    return request({
        url: '/nodeCare/detail',
        method: 'get',
        params: params
    })
}
//创建最近使用
export function addLog(params) {
    return request({
        url: '/nodeCare/addLog',
        method: 'post',
        data: params
    })
  }
  //更新状态
  export function saveStatus(params) {
    return request({
        url: '/nodeCare/saveStatus',
        method: 'post',
        data: params
    })
  }
  //获取客户详情
  export function birthdayRemind(params) {
    return request({
        url: '/nodeCare/birthdayRemind',
        method: 'get',
        params: params
    })
}