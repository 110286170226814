<template>
  <div class="filter">
    <van-action-sheet v-model="showPopup" title="筛选条件">
      <div class="border"></div>
      <div class="content">
        <div class="top">
          <div class="m16">发送方式</div>
          <div class="way" :class="[wayIndex == index ? 'Active' : 'noActive']" v-for="(item, index) in way"
            :key="index" @click="selectWay(index)">
            {{ item.name }}
          </div>
        </div>
        <!-- <div class="top">
          <div class="m16">任务状态</div>
          <div class="status" :class="[item.statusAct == true ? 'Active' : 'noActive']" v-for="(item, index) in status"
            :key="index" @click="selectStatus(index)">
            {{ item.name }}
          </div>
        </div> -->
        <van-cell-group>
          <!-- <van-field
              readonly
              v-model="value1"
              label="创建人"
              right-icon="arrow"
              input-align="right"
              placeholder="请选择"
            /> -->
          <div class="filed">
            <div class="top_filed">创建人</div>
            <div class="input">
              <selectEmployee @change="getCount" ref="groups" />
            </div>
          </div>
        </van-cell-group>
        <van-cell-group class="creatT">
          <van-field readonly v-model="value2" input-align="right" right-icon="arrow" label="创建时间" placeholder="请选择"
            @click="$refs.calendar.$show()" />
        </van-cell-group>
      </div>
      <div class="bottom">
        <div class="del" @click="reset">取消</div>
        <div class="save" @click="confirm">确定</div>
      </div>
    </van-action-sheet>
    <Calendar ref="calendar" @changeTime="changeT" />
  </div>
</template>
<script>
  import Calendar from "../../../components/calendar.vue";
  import selectEmployee from "@/components/select/employee/select";

  export default {
    components: { Calendar, selectEmployee },
    data() {
      return {
        wayIndex: -1,
        statusIndex: 0,
        showPopup: false,
        filterParam:{
          sendType:'',
          // sendStatus:'',
          userId:'',
          startTime:'',
          endTime:''
        },
        way: [
          { name: "员工一键发送", value: 1 },
          { name: "发给我的客户", value: 2 },
        ],
        status: [
          { name: "未开始", value: 0, statusAct: true },
          { name: "进行中", value: 1, statusAct: false },
          { name: "已结束", value: 2, statusAct: false },
        ],
        value1: "",
        value2: "",
        employeeIds: [],
        
      };
    },
    methods: {
      $show() {
        this.showPopup = true;
      },
      // 获取员工Id
      getCount() {
        this.filterParam.userId = this.$refs.groups.get().map((v) => {
          return v.id;
        });
      },
      selectWay(index) {
        this.wayIndex = index;
        this.filterParam.sendType = index+1
      },
      selectStatus(index) {
        this.status[index].statusAct = !this.status[index].statusAct;
      },
      changeT(s, e) {
        this.filterParam.startTime = s
        this.filterParam.endTime = e
        this.value2 = `${s} 至 ${e}`;
      },
      reset() {
        this.showPopup = false
      },
      confirm(){
        this.$emit('filterData',this.filterParam)
        this.showPopup = false
      }
    },
  };
</script>
<style lang="less" scoped>
  /deep/ .van-action-sheet {
    height: 400px;
  }

  /deep/ .van-action-sheet__header {
    font-weight: bold;
  }

  /deep/ .van-field__label {
    color: #000;
  }

  /deep/ .van-hairline--top-bottom::after {
    border: none;
    border-bottom: 1px solid #ebedf0;
  }

  /deep/ .van-cell {
    padding-left: 0;
    padding-right: 0;
  }

  /deep/ .van-action-sheet__content {
    margin-bottom: 70px;
  }

  /deep/ .van-cell-group {
    margin-top: 10px;
  }

  .border {
    width: 100%;
    height: 1px;
    border: 1px solid #e8e8e8;
  }

  .content {
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .m16 {
    height: 54px;
    line-height: 54px;
    font-size: 14px;
    color: #000000;
  }

  .filed {
    width: 100%;
    min-height: 83px;
    background-color: #fff;
    font-size: 14px;

    .top_filed {
      width: 100%;
      margin: 0 auto;
      padding-top: 10px;
      margin-bottom: 12px;
      height: 20px;
      font-size: 14px;
      color: #000000;
      line-height: 20px;
    }

    .input {
      display: flex;
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
    }
  }

  .way {
    display: inline-block;
    text-align: center;
    width: 46%;
    border-radius: 14px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    margin-right: 7px;
  }

  .status {
    width: 103px;
    height: 28px;
    line-height: 28px;
    display: inline-block;
    text-align: center;
    background: #ecf0fc;
    border-radius: 14px;
    border: 1px solid #e2e5fa;
    margin-right: 15px;
    font-size: 12px;
  }

  .status:nth-child(3n + 1) {
    margin-right: 0;
  }

  .Active {
    background: #ecf0fc;
    color: #3974c6;
  }

  .noActive {
    background: #f1f2f6;
    color: #4a4a4a;
  }

  .creattime {
    margin-bottom: 70px;
  }

  .bottom {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #ffffff;
    position: fixed;
    bottom: 10px;
    margin-right: 10px;

    .del {
      text-align: center;
      line-height: 46px;
      width: 83px;
      height: 46px;
      background: #f1f2f6;
      border-radius: 5px;
      border: 1px solid #d7d7d9;
      font-size: 16px;
      font-weight: 500;
      color: #595a5e;
    }

    .save {
      text-align: center;
      width: 230px;
      height: 46px;
      line-height: 46px;
      background: #3974c6;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }
</style>