<template>
  <div class="enter-text-msg">
    <div class="box">
      <div class="header">
        <div class="item" @click="addNickName">插入客户昵称</div>
        <div class="item" @click="addName">插入员工姓名</div>
        <div class="item" @click="addPhone">插入员工手机号</div>
      </div>
      <div class="content">
        <textarea
          ref="textarea"
          v-model="text"
          @input="emitData"
          placeholder="请输入"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
    };
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["value"],
  watch: {
    value() {
      if (this.value.type === "emit") return false;

      this.setData(this.value);
    },
  },
  methods: {
    addNickName() {
      const value = "##客户名称##";
      const textarea = this.$refs.textarea;

      const startPos = parseInt(textarea.selectionStart);

      this.text = `${this.text.slice(0, startPos)}${value}${this.text.substr(
        startPos
      )}`;

      this.emitData();
    },
    addName() {
      const value = "##员工姓名##";
      const textarea = this.$refs.textarea;

      const startPos = parseInt(textarea.selectionStart);

      this.text = `${this.text.slice(0, startPos)}${value}${this.text.substr(
        startPos
      )}`;

      this.emitData();
    },
    addPhone() {
      const value = "##员工手机号##";
      const textarea = this.$refs.textarea;

      const startPos = parseInt(textarea.selectionStart);

      this.text = `${this.text.slice(0, startPos)}${value}${this.text.substr(
        startPos
      )}`;

      this.emitData();
    },
    emitData() {
      const params = {
        words: this.text,
        type: "emit",
      };
      this.$emit("input", params);
    },

    setData(data) {
      console.log("setData", data);
      this.text = data.words;
    },
  },
};
</script>

<style lang="less" scoped>
.enter-text-msg {
  width: 100%;
}

.box {
  width: 100%;
  border: 1px solid #eee;
  background: #fbfbfb;
}

.files {
  padding: 12px 0;
  border-top: 1px solid #eee;

  .item {
    padding: 0 16px;
    margin-bottom: 12px;

    .left {
      color: #565656;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.header {
  border-bottom: 1px dashed #e9e9e9;
  padding: 8px 16px;

  .item {
    display: inline-flex;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 50px;
    background: #f1f2f3;
    font-size: 12px;
    box-shadow: 0 2px 2px #0000001f;
    transition: 0.2s;
    margin-right: 12px;
    color: #4b4b4b;

    &:hover {
      box-shadow: 0 1px 1px #0000001f;
    }

    &:active {
      transform: translateY(3px);
      box-shadow: 0 1px 1px #0000001f;
    }
  }
}

.content {
  padding: 8px 16px;
  height: 330px;

  textarea {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    resize: none;
  }
}

.option {
  padding: 8px 16px;
  color: #1890ff;
  font-size: 14px;
  border-top: 1px solid #eee;
  cursor: pointer;
}

/deep/ .ant-dropdown-menu-item {
  padding: 12px;
  font-size: 14px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}
</style>
