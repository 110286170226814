import request from "../plugins/axios";

// 朋友圈分类列表
export function category(params) {
    return request({
        url: '/friendsCircleInformation/category',
        method: 'get',
        params: params
    })
}
// 朋友圈列表
export function circleList(params) {
    return request({
        url: '/friendsCircleInformation/index',
        method: 'get',
        params: params
    })
}
// 历史朋友圈
export function circleHistory(params) {
    return request({
        url: '/friendsCircleInformation/history',
        method: 'get',
        params: params
    })
}
// 获取名片设置
export function getSet(params) {
    return request({
        url: '/friendsCircleInformation/getSetting',
        method: 'get',
        data: params
    })
}
// 设置名片设置
export function set(params) {
    return request({
        url: '/friendsCircleInformation/setSetting',
        method: 'post',
        data: params
    })
}
// 分享回调
export function shareCallBack(params) {
    return request({
        url: '/friendsCircleInformation/setShare',
        method: 'post',
        data: params
    })
}
// 历史朋友圈
export function history(params) {
    return request({
        url: '/friendsCircleInformation/history',
        method: 'get',
        params
    })
}
//富文本详情
export function getRichDetail(params) {
    return request({
        url: '/friendsCircleInformation/detail',
        method: 'get',
        params
    })
}