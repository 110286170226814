-<template>
  <div class="index">
    <div class="top">
      <div class="topContent">
        <div class="topLeft" @click="changeTeam" v-if="showMy == false">
          <span class="team">团队</span>
          <img class="change" src="../../assets/qiehuan.png" alt="" />
        </div>
        <div class="topLeft" @click="changeMy" v-else>
          <span class="team">我的</span>
          <img class="change" src="../../assets/qiehuan.png" alt="" />
        </div>
        <div>
          <div class="topRight">
            <van-search
              shape="round"
              v-model="searchVal"
              @search="handleSearch"
              placeholder="搜索"
            />
            <img
              class="add"
              @click="showAction = true"
              src="../../assets/add.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tab">
      <van-tabs
        v-model="active"
        title-inactive-color="#4A4A4A"
        color="#3974C6"
        title-active-color="#3974C6"
        @change="handleTabChange"
      >
        <van-tab
          v-if="getTabPermission(item)"
          :title="item"
          v-for="(item, index) in tabs"
          :key="index"
        >
          <div class="filter">
            <div class="filterLeft">共 {{ length }} 个结果</div>
            <div class="filterRight" @click="$refs.fit.$show()">
              <img src="../../assets/filter.png" class="icon" alt="" /><span
                >筛选</span
              >
            </div>
          </div>
          <div class="main">
            <div v-if="qunfaListData.length > 0">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
              >
                <div style="width: 94%; margin: 0 auto">
                  <div class="mainTop">我所在管理组创建的群发任务</div>
                  <div
                    class="mainList"
                    v-for="(res, ind) in qunfaListData"
                    :key="ind"
                    @click="skipDetail(res)"
                  >
                    <div class="content" style="width: 94%; margin: 0 auto">
                      <div class="listTop">
                        <div class="name">
                          {{ res.batchTitle ? res.batchTitle : res.name }}
                        </div>
                        <div class="status" v-if="res.sendStatus == 1">
                          进行中
                        </div>
                        <div class="completed" v-else>已结束</div>
                      </div>
                      <div class="m16">
                        共
                        <span style="color: #000000">{{
                          res.sendTotal + res.notSendTotal
                        }}</span>
                        名员工需发送 完成率<span
                          style="color: #3975c6"
                          v-if="res.sendTotal + res.notSendTotal != 0"
                          >{{
                            (
                              res.sendTotal /
                              (res.sendTotal + res.notSendTotal)
                            ).toFixed(2) * 100
                          }}%</span
                        >
                        <span style="color: #3975c6" v-else>0%</span>
                      </div>
                      <div class="m16">
                        {{ res.createdAt.slice(0, 10) }} 由
                        {{ res.userName }} 创建
                      </div>
                    </div>
                  </div>
                </div>
              </van-list>
            </div>
            <div v-else>
              <van-empty description="暂无数据" />
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-action-sheet
      v-model="showAction"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSelect"
    />
    <Moment ref="fit" @filterData="getFliterData" />
  </div>
</template>
<script>
import Moment from "./components/filter.vue";
import { qunfaList, roomList, qunfaCircleList } from "@/api/qunfa";
import { judgePermission } from "@/utils/index";
export default {
  components: { Moment },
  data() {
    return {
      time: false,
      tabs: ["群发客户", "群发客户群", "群发朋友圈"],
      loading: false,
      finished: false,
      page: 1,
      searchVal: "",
      showMy: false,
      active: 0,
      showAction: false,
      actions: [
        { name: "群发客户", id: 1 },
        { name: "群发客户群", id: 2 },
        { name: "群发朋友圈", id: 3 },
      ],
      length: 0,
      qunfaListData: [],
      type: 1,
      filterParam: {},
    };
  },
  created() {
    this.getList();
  },
  beforeRouteLeave(to, from, next) {
    console.log("to from", to, from);
    if (to.path === "/qunfa/selectWay" || to.path === "/qunfa/directSend") {
      from.meta.keepAlive = false;
    } else {
      from.meta.keepAlive = true;
    }
    next();
  },
  methods: {
    getTabPermission(tab) {
      // 在这里根据 Tab 的名称返回对应的权限
      if (tab === "群发客户") {
        return judgePermission("system:17nr5");
      } else if (tab === "群发客户群") {
        return judgePermission("system:ptLDc");
      } else if (tab === "群发朋友圈") {
        return judgePermission("system:xov6q");
      }
    },
    getFliterData(e) {
      console.log("e", e);
      this.filterParam = e;
      this.qunfaListData = [];
      this.finished = false;
      this.page = 1;
      this.getList();
    },
    //切换我的
    changeMy() {
      this.showMy = !this.showMy;
      this.type = 1;
      this.qunfaListData = [];
      this.finished = false;
      this.page = 1;
      this.getList();
    },
    changeTeam() {
      this.qunfaListData = [];
      this.finished = false;
      this.page = 1;
      this.showMy = !this.showMy;
      this.type = 0;
      this.getList();
    },
    getList() {
      console.log("active", this.active);
      if (this.active == 0) {
        qunfaList({
          ...this.filterParam,
          page: this.page,
          perPage: 10,
          searchStr: this.searchVal,
          type: this.type,
          // sendType: this.type, //1代表一键发送 2代表发送给我的客户
        }).then((res) => {
          this.qunfaListData.push(...res.data.list);
          this.length = res.data.page.total;
          // 加载状态结束
          this.loading = false;
          // 数据全部加载完成
          if (this.qunfaListData.length >= res.data.page.total) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
      } else if (this.active == 1) {
        roomList({
          page: this.page,
          perPage: 10,
          searchStr: this.searchVal,
          type: this.type,
          ...this.filterParam,
        }).then((res) => {
          this.qunfaListData.push(...res.data.list);
          this.length = res.data.page.total;
          // 加载状态结束
          this.loading = false;
          // 数据全部加载完成
          if (this.qunfaListData.length >= res.data.page.total) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
      } else {
        qunfaCircleList({
          ...this.filterParam,
          page: this.page,
          perPage: 10,
          searchStr: this.searchVal,
          type: this.type,
        }).then((res) => {
          this.qunfaListData.push(...res.data.list);
          this.length = res.data.page.total;
          // 加载状态结束
          this.loading = false;
          // 数据全部加载完成
          if (this.qunfaListData.length >= res.data.page.total) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
      }
    },
    skipDetail(res) {
      //跳转客户统计
      if (this.active == 0) {
        this.$router.push({
          path: "/qunfa/customstatistics",
          query: { id: res.id, name: res.name, sendStatus: res.sendStatus },
        });
      }
      //客户群统计
      if (this.active == 1) {
        this.$router.push({
          path: "/qunfa/kehuqunStatistic",
          query: {
            id: res.id,
            name: res.batchTitle,
            sendStatus: res.sendStatus,
          },
        });
      }
      //朋友圈统计
      console.log("xx", res);
      if (this.active == 2) {
        this.$router.push({
          path: "/qunfa/circleStatic",
          query: { id: res.id, name: res.name, sendStatus: res.sendStatus },
        });
      }
    },
    handleTabChange() {
      this.qunfaListData = [];
      this.finished = false;
      this.page = 1;
      this.getList();
    },
    onLoad() {
      this.page++;
      this.getList();
    },
    //搜索功能
    handleSearch() {
      this.qunfaListData = [];
      this.finished = false;
      this.page = 1;
      this.getList();
    },
    onSelect(item) {
      if (item.id == 1) {
        this.$router.push({
          path: "/qunfa/selectWay",
          query: { selectWay: "toCustom" },
        });
      } else if (item.id == 2) {
        this.$router.push({
          path: "/qunfa/selectWay",
          query: { selectWay: "tokehuqun" },
        });
      } else {
        this.$router.push({
          path: "/qunfa/selectWay",
          query: { selectWay: "toCircle" },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-search {
  width: 242px;
  padding: 16px 0 14px 0;
  background-color: #3975c6;
  margin-right: 16px;
}
/deep/ .van-search__content {
  background-color: #fff;
  opacity: 0.51;
}
/deep/ .van-tabs__nav--line {
  line-height: 48px;
  padding-bottom: 0;
}
/deep/ .van-tabs__line {
  bottom: 0;
}
.index {
  height: 100%;
}
.top {
  width: 100%;
  height: 64px;
  background: #3975c6;

  .topContent {
    width: 92%;
    height: 64px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .topLeft {
    height: 64px;
    line-height: 64px;
    display: flex;
    align-items: center;
    .team {
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      margin-right: 6px;
    }
    .change {
      width: 14px;
      height: 11px;
    }
  }
  .topRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .add {
    width: 25px;
    height: 25px;
  }
}
.tab {
  height: 48px;
  line-height: 48px;
}
.filter {
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #e8e8e8;

  .filterLeft {
    margin-left: 4%;
    font-size: 12px;
    color: #a5a5a5;
  }
  .filterRight {
    margin-right: 4%;
    font-size: 12px;
    color: #515151;
    .icon {
      width: 11px;
      height: 11px;
      margin-right: 6px;
      margin-top: -2px;
    }
  }
}
.main {
  height: 100vh;
  background: #f2f3f7;
  .mainTop {
    font-size: 12px;
    color: #9b9b9b;
  }
  .mainList {
    width: 100%;
    height: 129px;
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .listTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #f1f1f1;
    .name {
      height: 56px;
      line-height: 56px;
      width: 88%;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .status {
      font-size: 12px;
      font-weight: 500;
      color: #3975c6;
    }
    .completed {
      font-size: 12px;
      font-weight: 500;
      color: #a5a5a5;
    }
  }
  .m16 {
    height: 18px;
    font-size: 12px;
    color: #9b9b9b;
    margin-bottom: 5px;
  }
}
</style>