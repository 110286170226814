import request from "../plugins/axios";

//列表
export function getCredit(params) {
    return request({
        url: '/wholeVillageCredit/index',
        method: 'get',
        params
    })
}
//提交
export function postCredit(params) {
    return request({
        url: '/wholeVillageCredit/submit',
        method: 'post',
        data:params
    })
}
//详情
export function creditDetail(params) {
    return request({
        url: '/wholeVillageCredit/show',
        method: 'get',
        params:params
    })
}
//更新打卡位置
export function updLocate(params) {
    return request({
        url: '/wholeVillageCredit/updLocate',
        method: 'post',
        data:params
    })
}
