<template>
  <div >
    <!-- 模块展示 -->
    <div class="page" >
    <div class="heard">
      <div class="title">
        <img src="../../assets/fina/icon.png" alt="">
        <div>{{jsonData.name}}</div>
      </div>
      <div class="infoMiddle topHeight">
        <div class="lilv">
          {{jsonData.annualizedRate}}%
        </div>
        <div class="shen">起购金额</div>
      </div>
      <div class="infoMiddle">
        <div class="time">{{jsonData.annualizedNotes}}
          <img class="icon" src="../../assets/fina/guanyu.png" alt="" />
        </div>
        <div class="money">{{jsonData.startAmount}}{{jsonData.startAmountType==1?'元':'美元'}}起购</div>
      </div>
      <div class="infoBot">
        <showMore :length="50" :longStr="jsonData.briefIntroduction" :color="'#FFFFFF'" />
      </div>
      <div class="tag">
        <div class="tagList" v-for="tagI in jsonData.keywords">{{tagI}}</div>
      </div>
    </div>
    <div v-if="jsonData.detailType==1">
    <!-- 产品特色 -->
    <div class="product" v-if="jsonData.detail.selectData.includes('1')">
      <div class="title">产品特色</div>
      <div class="card" v-for="(i,index) in jsonData.detail.productList" :key="index">
        <div class="imgBox">
          <img :src="i.avatar" alt="">
        </div>
        <div class="textBox">
          <div class="name">{{i.title}}</div>
          <div class="abstract">{{i.abstract}}</div>
        </div>
      </div>
    </div>
    <!-- 周期 -->
    <div class="cycle" v-if="jsonData.detail.selectData.includes('2')">
      <div class="title">产品周期</div>
      <van-steps class="con">
        <van-step>开售日
          <p>{{getFormattedDate(jsonData.detail.cycle.opendate)}}</p>
        </van-step>
        <van-step>截止日
          <p>{{getFormattedDate(jsonData.detail.cycle.deadline)}}</p>
        </van-step>
        <van-step>投资起始日
          <p>{{getFormattedDate(jsonData.detail.cycle.investmentDate)}}</p>
        </van-step>
        <van-step>到期日
          <p>{{getFormattedDate(jsonData.detail.cycle.dueDate)}}</p>
        </van-step>
      </van-steps>
      <div class="tips">产品周期仅供参考，请以实际日期为准。</div>
    </div>
    <!-- 交易规则 -->
    <div class="rule" v-if="jsonData.detail.selectData.includes('3')">
      <div class="title">交易规则</div>
      <div class="buy">
        <div class="name">买入规则</div>
        <div class="cont">
          <div class="cont-left">申购规划</div>
          <div class="cont-right">{{jsonData.detail.buyingRules.subscription}}</div>
        </div>
        <div class="cont">
          <div class="cont-left">起购金额</div>
          <div class="cont-right">{{jsonData.detail.buyingRules.startingPurchase}}{{jsonData.startAmountType==1?'元':'美元'}}</div>
        </div>
        <div class="cont">
          <div class="cont-left">购买费率</div>
          <div class="cont-right">{{jsonData.detail.buyingRules.rate}}%</div>
        </div>
        <div class="cont">
          <div class="cont-left">收益规则</div>
          <div class="cont-right">{{jsonData.detail.buyingRules.income}}</div>
        </div>
        <div class="cont">
          <div class="cont-left">撤单规则</div>
          <div class="cont-right">{{jsonData.detail.buyingRules.killanorder}}</div>
        </div>
        <div class="cont">
          <div class="cont-left">分红规则</div>
          <div class="cont-right">{{jsonData.detail.buyingRules.dividends}}</div>
        </div>
      </div>
      <div class="buy">
        <div class="name">卖出规则</div>
        <div class="cont">
          <div class="cont-left">赎回规则</div>
          <div class="cont-right">{{jsonData.detail.sellingRules.rule}}</div>
        </div>
        <div class="cont">
          <div class="cont-left">赎回费率</div>
          <div class="cont-right">{{jsonData.detail.sellingRules.rate}}%</div>
        </div>
        <div class="cont">
          <div class="cont-left">到账规则</div>
          <div class="cont-right">{{jsonData.detail.sellingRules.Received}}</div>
        </div>
      </div>
    </div>
    <!-- 投资策略 -->
    <div class="strategy" v-if="jsonData.detail.selectData.includes('4')">
      <div class="title">投资策略</div>
      <div class="cont">
        <div class="cont-left">骑乘策略</div>
        <div class="cont-right">{{jsonData.detail.strategy.text}}</div>
      </div>
    </div>
    <!-- 产品公告 -->
    <div class="announcement" v-if="jsonData.detail.selectData.includes('5')" @click="goPage(jsonData.detail.announcement.url,'announcement')">
      <div class="title">产品公告</div>
      <img class="img" src="../../assets/fina/jiantou.png" alt="">
    </div>
    <!-- 产品说明书 -->
    <div class="announcement" v-if="jsonData.detail.selectData.includes('6')" @click="goPage(jsonData.detail.instructions.url,'instructions')">
      <div class="title">产品说明书</div>
      <img class="img" src="../../assets/fina/jiantou.png" alt="">
    </div>
    <!-- 底部提示 -->
    <div class="bomTips">*理财非存款，产品有风险，投资需谨慎</div>
  </div>

  <!-- 富文本||链接地址 -->
  <div class="richBox" v-if="jsonData.detailType==2||jsonData.detailType==3">
    <div class="box" v-html="jsonData.detail" v-if="jsonData.detailType==2"></div>
    <div class="text" v-if="jsonData.detailType==3">
      链接地址：{{jsonData.detail}}
    </div>
    <div class="btnBox">
      <div class="submit" @click="shareFun()">分享</div>
  </div>
  </div>
</div>
  </div>
</template>
<script>
  import showMore from "./components/showMore.vue";
  import { getDetail } from "@/api/financial";
  import { shareToExternalMoments } from "@/utils/wxCodeAuth";
  import { getVcardData } from "@/api/electronicCard";
  export default {
    components: { showMore },
    data() {
      return {
        jsonData: {},
        employeeId:''
      };
    },
    created() {
      this.getData()
      this.getEmplyeeId()
    },
    methods: {
      getEmplyeeId(){
      getVcardData().then(res=>{
        this.employeeId = res.data.id
      })
    },
      getData() {
        getDetail({ id: this.$route.query.id }).then((res) => {
          if(res.data.detailType==1){
            res.data.detail = JSON.parse(res.data.detail)
          }
          res.data.keywords = res.data.keywords.split(', ')
          this.jsonData = res.data
        })
      },
      getFormattedDate(dateString) {
        return dateString.split(' ')[0];
      },
      goPage(url){
        this.$router.push({
          path: "/file/detail",
          query: {
            url: url,
          },
        });
      },
      shareFun(){
        let link=''
        if(this.jsonData.detailType==3){
          link=this.jsonData.detail
        }
        if(this.jsonData.detailType==2){
          link=this.jsonData.shareUrl+'?id='+this.jsonData.id+'&employeeId='+this.employeeId
        }
        let shareData = {
          type:3,
          content:{
            title:this.jsonData.name,
            imageLink:link
          }
        };
        shareToExternalMoments(this.jsonData.name, shareData).then((res) => {
        this.$message.success("发送成功");
      });
      }

    }
  };
</script>
<style lang="less" scoped>
  .page {
    width: 100%;
    min-height: 100vh;
    background-color: #F2F3F7;
  }

  .heard {
    width: 100%;
    height: 376px;
    background-image: url('../../assets/fina/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 16px;
    padding-top: 25px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 93px;
        height: 71px;
      }

      div {
        font-weight: 600;
        font-size: 19px;
        color: #FFFFFF;
      }
    }

    .lilv {
      font-size: 20px;
      color: #FFFFFF;
      width: 30%;
      font-weight: 600;
      text-align: center;
    }

    .icon {
      width: 11px;
      height: 11px;
      margin-left: 4px;
    }

    .shen {
      width: 30%;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
    }

    .time {
      width: 30%;
      font-size: 12px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .money {
      width: 30%;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
    }

    .infoMiddle {
      display: flex;
      min-height: 30px;
      align-items: center;
      justify-content: center;
    }

    .topHeight {
      margin-top: 10px;
    }

    .infoBot {
      font-size: 12px;
      color: #515151;
      line-height: 17px;
      text-align: left;
      line-height: 18px;
      background: rgba(255, 255, 255, 0.2);
      padding: 10px;
      border-radius: 2px;
      margin-top: 22px;
    }

    .tag {
      margin-top: 17px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;

      .tagList {
        /* background: #fff8e9; */
        border-radius: 2px;
        font-size: 12px;
        color: #FFFFFF;
        padding: 2px 4px;
        margin: 2px 10px 2px 0;
        border: 1px solid #FFFFFF;
      }
    }
  }

  .product {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: -55px;
    width: calc(100% - 30px);

    .title {
      font-weight: 600;
      font-size: 17px;
      color: #000000;
    }

    .card {
      margin-top: 10px;
      padding: 14px 10px;
      background: linear-gradient(90deg, #FFFBF8 0%, #FDEFDF 100%);
      border-radius: 2px;
      display: flex;
      flex-direction: row;

      .imgBox img {
        width: 36px;
        height: 36px;
      }

      .textBox {
        margin-left: 15px;
        width: calc(100% - 15px);

        .name {
          font-weight: 500;
          font-size: 15px;
          color: #360A02;
        }

        .abstract {
          font-weight: 400;
          font-size: 14px;
          color: #8C6452;
        }
      }
    }
  }

  .cycle {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    width: calc(100% - 30px);

    .title {
      font-weight: 600;
      font-size: 17px;
      color: #000000;
    }

    .con {
      margin-top: 10px;
      padding-bottom: 0px;
    }

    .tips {
      margin-top: 15px;
      width: 100%;
      height: 27px;
      background: #F1F1F1;
      border-radius: 2px;
      line-height: 27px;
      text-align: center;
      font-weight: 400;
      font-size: 11px;
      color: #999999;
    }
  }

  /deep/.van-step--horizontal .van-step__circle-container {
    top: 50px;
  }

  /deep/.van-step__line {
    top: 50px;
  }

  /deep/ .van-step--horizontal .van-step__icon {
    color: #9C9D9F;
  }

  /deep/ .van-steps--horizontal {
    padding: 0px 0px;
  }

  /deep/ .van-steps__items {
    padding-bottom: 0px;
  }

  /deep/.van-step__title--active {
    color: #000000;
  }

  /deep/ .van-step {
    color: #000000;
  }

  .rule {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    width: calc(100% - 30px);

    .title {
      font-weight: 600;
      font-size: 17px;
      color: #000000;
    }

    .buy {
      margin-top: 15px;
      padding: 12px;
      background: #F7F9FF;
      border-radius: 2px;

      .name {
        font-weight: 500;
        font-size: 15px;
        color: #333333;
      }

      .cont {
        margin-top: 15px;
        display: flex;
        flex-direction: row;

        .cont-left {
          width: 65px;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
        }

        .cont-right {
          width: calc(100% - 65px);
          font-weight: 400;
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }

  .strategy {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    width: calc(100% - 30px);

    .title {
      font-weight: 600;
      font-size: 17px;
      color: #000000;
    }

    .cont {
      padding: 0 12px;
      margin-top: 15px;
      display: flex;
      flex-direction: row;

      .cont-left {
        width: 65px;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
      }

      .cont-right {
        width: calc(100% - 65px);
        font-weight: 400;
        font-size: 12px;
        color: #333333;
      }
    }
  }

  .announcement {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 600;
      font-size: 17px;
      color: #000000;
    }

    .img {
      width: 5px;
      height: 10px;
    }
  }

  .bomTips {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 11px;
    color: #999999;
    padding: 10px 0px;
  }
  .richBox{

  .box{
    padding: 10px;
    font-size:12px;
    padding-bottom: 106px;
  }
  .text{
    padding: 20px;
    font-weight: 400;
    font-size: 17px;
    color: #000000;
    width: 100%;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-all;
  }
  .btnBox {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 106px;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 10px 12px;
        .submit {
            width: 100%;
            height: 48px;
            text-align: center;
            padding: 12px 0;
            background: #3974C6;
            border-radius: 5px;
            font-size: 17px;
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 16px;
        }
    }
  }
</style>