import request from "../plugins/axios";

//查询客户列表
export function getCustom(params) {
    return request({
        url: '/gridCustom/record',
        method: 'get',
        params
    })
}
//网格下拉
export function gridRecord(params) {
    return request({
        url: '/gridCustom/gridRecord',
        method: 'get',
        params
    })
}
//网格详情
export function gridDetail(params) {
    return request({
        url: '/gridCustom/gridDetail',
        method: 'get',
        params
    })
}
//客户详情
export function customDetail(params) {
    return request({
        url: '/gridCustom/detail',
        method: 'get',
        params
    })
}
//提交
export function expand(params) {
    return request({
        url: '/gridCustom/expand',
        method: 'post',
        data:params
    })
}
//客户列表
export function getCustomlist(params) {
    return request({
        url: '/gridCustom/recordByPage',
        method: 'get',
        params
    })
}


