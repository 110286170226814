<template>
  <div class="page">
    <div class="item">
      <div class="title">规则名称：</div>
      <van-field v-model="ruleName" placeholder="请输入规则名称" />
    </div>
    <div class="item filed">
      <div class="top_filed title">客服成员：</div>
      <div class="input">
        <selectEmployee @change="getCount" ref="groups" />
      </div>
    </div>
    <div class="tips">*以下客服成员的新客户触发了规则后，将在侧边栏快 捷回复提醒该规则的内容。</div>
    <div class="borderBox" v-for="(rItem,rindex) in ruleList" :key="rindex">
      <div class="item">
        <div class="title">设置内容名称：
          <div class="iconBtn" @click="delRule(rindex)" v-if="rindex!=0">
            <img src="@/assets/images/sop/delete.png" alt="">
            <!-- <van-icon name="delete-o" size="18" color="#ee0a24" /> -->
          </div>
        </div>
        <van-field class="vantName" v-model="rItem.name" placeholder="请输入名称" />
      </div>
      <div class="checkBox m15">
        <div class="title">设置发送时间：</div>
        <van-radio-group v-model="rItem.time.type">
          <div v-if="createType==1">
            <!-- <div class="groupBox">
              <div class="groupLeft mline">
                客户添加企业客服<input v-model="rItem.hour.first" placeholder=""
                  :style="{width:textWidth(rItem.hour.first)}" />小时
                <input v-model="rItem.hour.last" placeholder="" :style="{width:textWidth(rItem.hour.last)}" />分钟后提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'0'"></van-radio>
              </div>
            </div> -->
            <div class="groupBox">
              <div class="groupLeft mline">
                客户添加企业客服后<input v-model="rItem.day.first" placeholder=""
                  :style="{width:textWidth(rItem.day.first)}" />天后，当日
                <input readonly @click="timepopFun(rindex,1)" v-model="rItem.day.last" placeholder="选择时间"
                  style="width: 85px;" />提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'3'"></van-radio>
              </div>
            </div>
          </div>
          <!-- 原群发 -->
          <div v-if="createType==2">
            <div class="groupBox">
              <div class="groupLeft mline">
                设置<input readonly style="width: 85px;" placeholder="选择标签" @click="selectTag(rindex,'set')" />后在，<input
                  v-model="rItem.time.data.typeFirst4" placeholder=""
                  :style="{width:textWidth(rItem.time.data.typeFirst4)}" />日
                <input readonly @click="timepopFun(rindex,4)" v-model="rItem.time.data.typeLast4" placeholder="选择时间"
                  style="width: 85px;" />提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'4'"></van-radio>
              </div>
            </div>
            <div class="groupBox">
              <div class="groupLeft mline">
                设置包含<input readonly style="width: 85px;" placeholder="选择标签"
                  @click="selectTag(rindex,'contain')" />且不包含标签
                <input readonly style="width: 85px;" placeholder="选择标签" @click="selectTag(rindex,'notContain')" />后，在
                <input v-model="rItem.time.data.typeFirst5" placeholder=""
                  :style="{width:textWidth(rItem.time.data.typeFirst5)}" />日<input readonly style="width: 85px;"
                  placeholder="选择时间" v-model="rItem.time.data.typeLast5" @click="timepopFun(rindex,5)" />提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'5'"></van-radio>
              </div>
            </div>
            <div class="groupBox">
              <div class="groupLeft mline">
                设置<input readonly style="width: 85px;" placeholder="选择标签" @click="selectTag(rindex,'set')" />后在，
                <input readonly @click="openAction(rindex)" v-model="rItem.selectValue" placeholder="选择时间"
                  style="width: 85px;" />
                <input v-if="rItem.selectValue=='每周'" readonly @click="selectSet(rindex,'week')"
                  v-model="rItem.selectopValue" placeholder="选择周期" style="width: 85px;" />&nbsp;
                <input v-if="rItem.selectValue=='每月'" readonly @click="selectSet(rindex,'mouth')"
                  v-model="rItem.labelData.mouth" placeholder="选择月份" style="width: 85px;" />
                <span v-if="rItem.selectValue=='每月'">日</span>
                <input v-if="rItem.selectValue=='每年'" readonly @click="selectSet(rindex,'year')"
                  v-model="rItem.labelData.mouthDay" placeholder="选择月日" style="width: 85px;" />&nbsp;
                <input readonly @click="timepopFun(rindex,6)" v-model="rItem.time.data.typeLast6" placeholder="选择时间"
                  style="width: 85px;" />
                提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'6'"></van-radio>
              </div>
            </div>
          </div>
          <div v-if="createType==3">
            <div class="groupBox">
              <div class="groupLeft mline">
                sop创建成功开启<input v-model="rItem.day.first" placeholder=""
                  :style="{width:textWidth(rItem.day.first)}" />天，
                  设置<input readonly style="width: 85px;" placeholder="选择标签" @click="selectTag(rindex,'set')" />
                  后，当日
                <input readonly @click="timepopFun(rindex,1)" v-model="rItem.day.last" placeholder="选择时间"
                  style="width: 85px;" />提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'7'"></van-radio>
              </div>
            </div>
          </div>
        </van-radio-group>
        <div v-if="rItem.tags&&rItem.tags.length!=0">
          <div v-if="rItem.time.type==4||rItem.time.type==6||rItem.time.type==7">
            <div class="title">选中标签:</div>
            <div class="tags">
              <div class="tag" v-for="(v,i) in rItem.tags">
                <a-tag @close="closeTagChange(rindex,'set',v,i)" closable>{{ v.name }}</a-tag>
              </div>
            </div>
          </div>
        </div>
        <div v-if="rItem.tags5&&rItem.tags5.length!=0&&rItem.time.type==5">
          <div class="title">包含标签:</div>
          <div class="tags">
            <div class="tag" v-for="(v,i) in rItem.tags5">
              <a-tag @close="closeTagChange(rindex,'contain',v,i)" closable>{{ v.name }}</a-tag>
            </div>
          </div>
        </div>
        <div v-if="rItem.notTags&&rItem.notTags.length!=0&&rItem.time.type==5">
          <div class="title">不包含标签:</div>
          <div class="tags">
            <div class="tag" v-for="(v,i) in rItem.notTags">
              <a-tag @close="closeTagChange(rindex,'notContain',v,i)" closable>{{ v.name }}</a-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="title">设置发送内容：
        </div>
        <van-field v-model="rItem.content[0].value" type="textarea" maxlength="50" placeholder="请输入留言" show-word-limit
          class="textareaBox" />
        <div class="title" v-if="rItem.content[2]&&rItem.content[2].value!=''">图片: </div>
        <div class="imgBox" v-if="rItem.content[2]&&rItem.content[2].value!=''">
          <img :src="rItem.content[2].value" alt="">
        </div>
        <div v-if="rItem.content[1]&&rItem.content[1].childMediumData&&rItem.content[1].childMediumData.length!=0">
          <div class="title">附件：</div>
          <div class="filesitem" v-for="(v,index) in rItem.content[1].childMediumData" :key="index">
            <div class="filesleft">
              【{{v.initialType?v.initialType:v.type}}】：{{v.content?(v.content.title?v.content.title:v.title):v.title}}
            </div>
            <div class="filesright fz16">
              <a-icon type="delete" class="pointer" @click="deleteMaterial(rindex,v,index)" />
            </div>
          </div>
        </div>
        <div class="item_flex" @click="selectMate(rindex)">
          <div class="addbox">
            <img src="@/assets/images/sop/add.png" alt="">
          </div>
          <div class="name">选择素材</div>
        </div>
      </div>
    </div>
    <div class="item_flex ruleBox" @click="addRuleBtn">
      <div class="addrule">
        <img src="@/assets/images/sop/add.png" alt="">
      </div>
      <div class="name">添加规则</div>
    </div>
    <div class="btnBox">
      <div class="cancel" @click="cellback()">取消</div>
      <div class="submit" @click="btnClick()">确定</div>
    </div>
    <van-action-sheet v-model="showAction" :actions="actions" cancel-text="取消" close-on-click-action
      @select="onSelect" />
    <!--  员工  -->
    <employeeIndex ref="employee" @change="userChange" />
    <!--  标签  -->
    <popup ref="popup" @change="choseTags" />
    <!-- 选择时间 -->
    <van-popup v-model="timeShow" round position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker v-model="dataTime" type="time" title="选择时间" @confirm="confirmTime" @cancel="cancelTime" />
    </van-popup>
    <!-- 选择周期 -->
    <van-popup v-model="weekShow" round position="bottom" :style="{ height: '50%' }">
      <van-picker title="周期" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" />
    </van-popup>
    <!-- 选择月份 -->
    <van-popup v-model="mouthShow" round position="bottom" :style="{ height: '50%' }">
      <van-picker title="月份" show-toolbar :columns="mouthColumns" @confirm="mouthonConfirm" @cancel="mouthonCancel" />
    </van-popup>
    <!-- 选择月日 -->
    <van-popup v-model="yearShow" round position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker v-model="yearColumns.currentDate" type="month-day" title="选择月日" :formatter="formatter"
        @confirm="yearonConfirm" @cancel="yearonCancel" />
    </van-popup>
    <!-- 素材库 -->
    <van-popup v-model="matePop" position="bottom" :style="{ height: '100%',background:'#F2F3F7' }">
      <matePopup ref="mateChild" @closePop="closePop" @popSubmit="popSubmit" :key="datekey"></matePopup>
    </van-popup>
    <van-popup v-model="mateSele" round position="bottom" closeable :style="{ height: '50%',background:'#FFFFFF' }">
      <popPage ref="matebpx" @popBtn="popBtn" @imagesData="imagesData"></popPage>
    </van-popup>
  </div>
</template>
<script>
  import employeeIndex from "../../channelCode/components/employeeIndex";
  import { storeContactSop, sopUpdate } from "@/api/sopList";
  import popup from "@/components/select/tag/popup";
  import matePopup from "@/components/select/material/index";
  import popPage from "@/components/select/material/popPage";
  import { Dialog } from 'vant';
  import selectEmployee from "@/components/select/employee/select";
  export default {
    components: {
      employeeIndex,
      popup,
      matePopup,
      Dialog,
      popPage,
      selectEmployee
    },
    data() {
      return {
        radio: '1',
        message: '',
        // 请选择成员
        serviceList: [],
        upperStepper: [],
        upperSteppert: [],
        // 备用员工
        StandbyStaffList: [],
        showAction: false,
        actions: [{ name: '每日', id: 1 }, { name: '每周', id: 2 }, { name: '每月', id: 3 }, { name: '每年', id: 4 }],
        // 自动上下线
        autoSxs: [
          {
            user: [],
            workWeek: [],
            workWeekName: "",
            goTime: "请选择",
          },
        ],
        ruleList: [
          {
            name: '',
            push_type: 1,
            hour: {
              first: 0,
              last: 0,
            },
            day: {
              first: 0,
              last: null,
            },
            time: {
              type: '3',
              data: {
                first: 0,
                last: null,
                typeFirst3: 0,
                typeFirst4: 0,
                typeLast4: null,
                typeFirst5: 0,
                typeLast5: null,
                typeLast6: null
              },
            },
            content: [{ type: 'text', value: '' }, { type: 'sucai', value: '' }, { type: 'image', value: '' }],
            tags: [],
            tags5: [],
            notTags: [],
            selectValue: '每日',
            selectopValue: '周一',
            labelData: {
              day: null,
              mouth: '1',
              year: null,
              mouthDay: null
            },
          }
        ],

        content: {
          type: 'text',
          value: ''
        },//内容
        conList: [{ type: 'text', value: '' }],
        dataTime: null,
        tags: [],
        timeShow: false,
        tagsValueEdit: false,
        submitLoad: false,//提交状态
        ruleName: '',//规则名称,
        showEmployee: '',//成员
        tagsType: '',//选择标签类型
        containTags: [],//包含标签
        NotContainTags: [],//不包含标签
        employeeIds: [],
        timePopType: undefined,
        matePop: false,//素材库弹窗
        selectMetedata: [],//已选素材库
        currentGroup: 'text',//当前选择
        selectMateIndex: 0,//当前内容选项
        currentrItem: 0,//当前规则下标
        datekey: Date.now(),
        currentTimerItem: 0,//时间选择规则列表下标
        currentTagsrItem: 0,//标签选择规则列表下标
        mateSele: false,
        editStatus: false,//是否为编辑 false新增数据 true编辑修改
        editId: undefined,//编辑ID
        createType: '',//创建类型
        weekShow: false,
        columns: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        mouthShow: false,
        mouthColumns: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
        yearShow: false,
        yearColumns: {
          minDate: new Date(2020, 0, 1),
          maxDate: new Date(2025, 10, 1),
          currentDate: new Date(),
        },
        currentCycle: 0,//当前选项数组下标
        currentCycleType: '',//当前选项数组类型
        cycleIndex: '',//日 周 月 年  类型下标
      };
    },
    computed: {
      // 动态设置输入框宽度，防止内容过长
      textWidth() {
        return function (value) {
          if (value == '' || value == 0 || String(value).length <= 2) {
            return '40px'
          } else {
            return String(value).length * 0.42 + 'rem'
          }
        }
      }
    },
    created() {
      //获取创建类型，并根据类型设置单选默认显示选项
      if (this.$route.query.type) {
        this.createType = this.$route.query.type// 1显示0 3选项  2显示4 5 6选项  3显示7选项
        let typeData = ['3', '4', '7']//默认1类型为3  2类型为4   3类型为7
        this.ruleList[0].time.type = typeData[this.$route.query.type - 1]
      }
      //存在编辑信息 将数据回显
      if (this.$route.query.data) {
        this.$nextTick(() => {
          this.editStatus = true  //是否为编辑 false新增数据 true编辑修改
          let editInfo = JSON.parse(this.$route.query.data) //设置编辑信息
          this.editId = editInfo.id
          this.ruleName = editInfo.name
          this.showEmployee = editInfo.creatorName
          this.serviceList = editInfo.employees
          this.ruleList = editInfo.setting
          this.$refs.groups.returnBack(editInfo.employees)
          //处理发送方式
          editInfo.setting.forEach(item => {
            if (item.push_type == 0 && item.time.type == 0) {
              item.hour = item.time.data
              item.day = {
                first: 0,
                last: null,
              }
            } else if (item.push_type == 0 && item.time.type == 3) {
              item.day = item.time.data
              item.hour = {
                first: 0,
                last: 0,
              }
            } else if (item.push_type == 1 && item.time.type == 0) {
              item.hour = item.time.data
              item.day = {
                first: 0,
                last: null,
              }
            } else if (item.push_type == 1 && item.time.type == 3) {
              item.day = item.time.data
              item.hour = {
                first: 0,
                last: 0,
              }
            }
            // else if (item.push_type == 1 && item.time.type == 3) {
            //   item.time.data.typeFirst3 = item.time.data.first
            //   //默认数据
            //   item.time.data.typeFirst4 = 0
            //   item.time.data.typeLast4 = null
            //   item.time.data.typeFirst5 = 0
            //   item.time.data.typeLast5 = null
            // }
            else if (item.push_type == 1 && item.time.type == 4) {
              item.time.data.typeFirst4 = item.time.data.first
              item.time.data.typeLast4 = item.time.data.last
              item.tags = item.fullTags
              item.tags5 = []
              item.notTags = []
              //默认数据
              item.time.data.typeFirst3 = 0
              item.time.data.typeFirst5 = 0
              item.time.data.typeLast5 = null
            } else if (item.push_type == 1 && item.time.type == 5) {
              item.time.data.typeFirst5 = item.time.data.first
              item.time.data.typeLast5 = item.time.data.last
              item.tags = []
              item.tags5 = item.fullTags
              item.notTags = item.notFullTags
              //默认数据
              item.time.data.typeFirst3 = 0
              item.time.data.typeFirst4 = 0
              item.time.data.typeLast4 = null
            } else if (item.push_type == 1 && item.time.type == 6) {
              item.selectValue = this.actions.filter((res) => res.id == item.time.data.cycle)[0].name
              item.time.data.typeLast6 = item.time.data.last
              if (item.time.data.cycle == 2) {
                let weekData = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
                item.selectopValue = weekData[item.time.data.date - 1]
              } else if (item.time.data.cycle == 3) {
                item.labelData = { mouth: item.time.data.date }
              } else if (item.time.data.cycle == 4) {
                item.labelData = { mouthDay: item.time.data.month + '-' + item.time.data.date }
              }
              item.tags = item.fullTags
              item.tags5 = []
              item.notTags = []
              //默认数据
              item.time.data.typeFirst4 = 0
              item.time.data.typeLast4 = null
              item.time.data.typeFirst5 = 0
              item.time.data.typeLast5 = null
            } else if (item.push_type == 1 && item.time.type == 7) {
              item.day = item.time.data
              item.hour = {
                first: 0,
                last: 0,
              }
            }
          })
        })
      }
    },
    methods: {
      //返回
      cellback() {
        this.$router.back();
      },
      // 选择员工
      userChange(data, info) {
        this.showEmployee = data[0].name
        if (info.name == "serviceList") {
          this.serviceList = data;
          const value = [...data];
          data.forEach((item) => {
            item.stepper = 1;
          });
          this.upperStepper = value;
        } else if (info.name == "StandbyStaffList") {
          this.StandbyStaffList = data;
        } else if (typeof info.name == "number") {
          this.autoSxs[info.name].user = data;
          const value = [...data];
          data.forEach((item) => {
            item.stepper = 1;
          });
          this.upperSteppert.push(...value);
        }
      },
      // 时间组件弹窗
      timepopFun(rindex, type) {
        //rindex规则下标 type设置时间类型
        this.currentTimerItem = rindex;
        this.timePopType = type
        this.timeShow = true
      },
      // 时间选择确认事件
      confirmTime(e) {
        this.dataTime = e
        //将数据回显到对应内容上
        if (this.timePopType == 4) {
          this.ruleList[this.currentTimerItem].time.data.typeLast4 = e;
        } else if (this.timePopType == 5) {
          this.ruleList[this.currentTimerItem].time.data.typeLast5 = e;
        } else if (this.timePopType == 6) {
          this.ruleList[this.currentTimerItem].time.data.typeLast6 = e;
        } else if (this.timePopType == 1) {
          this.ruleList[this.currentTimerItem].day.last = e;
        }
        this.timeShow = false
      },
      cancelTime() {
        this.timeShow = false
      },
      //添加数据
      async btnClick() {
        if (this.submitLoad) return;
        if (this.ruleName == '') {
          this.$message.error('请输入规则名称')
          return false
        }
        if (this.employeeIds.length == 0) {
          this.$message.error('请选择客服成员')
          return false
        }
        //循环判断必填条件
        let judgeData = await this.judgeFun(this.ruleList)
        if (!judgeData) return
        //移除非必要数据
        let newData = JSON.parse(JSON.stringify(this.ruleList))
        let resNonData = await this.resNonFun(newData)
        //处理文件格式
        let resFileData = await this.resFileFun(resNonData)
        let employeeId = this.employeeIds
        let params = {
          name: this.ruleName,//规则名称
          employees: JSON.stringify(employeeId),//员工数据
          setting: JSON.stringify(resFileData),//设置参数
        }
        this.submitLoad = true
        if (this.editStatus) {
          //编辑删除多余字段
          let newParams = await this.editDelFun(resFileData)
          params.setting = JSON.stringify(newParams),//设置参数
            params.id = this.editId
          sopUpdate(params).then((res) => {
            if (res.code == 200) {
              this.$message.success("编辑成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500)
            }
            this.submitLoad = false
          })
        } else {
          params.type = this.createType
          storeContactSop(params).then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");
              setTimeout(() => {
                this.$router.go(-2);
              }, 1500)
            }
            this.submitLoad = false
          })
        }
      },
      //循环判断必填条件
      judgeFun(data) {
        let status = data.every(item => {
          if (item.name == '') {
            this.$message.error('请输入内容名称')
            return false
          } else if (item.time.type == 0 && item.hour.first < 1) {
            this.$message.error('提醒天数不能小于1')
            return false
          } else if (item.time.type == 0 && item.hour.last == 0) {
            this.$message.error('请填写提醒时间')
            return false
          } else if (item.time.type == 3 && item.day.first < 1) {
            this.$message.error('提醒天数不能小于1')
            return false
          } else if (item.time.type == 3 && item.day.last == null) {
            this.$message.error('请选择提醒时间')
            return false
          }
          // else if (item.time.type == 3 && item.time.data.typeFirst3 < 1) {
          //   this.$message.error('提醒天数不能小于1')
          //   return false
          // }
          else if (item.time.type == 4 && item.time.data.typeFirst4 < 1) {
            this.$message.error('提醒天数不能小于1')
            return false
          } else if (item.time.type == 4 && item.time.data.typeLast4 == null) {
            this.$message.error('请选择提醒时间')
            return false
          } else if (item.time.type == 5 && item.time.data.typeFirst5 < 1) {
            this.$message.error('提醒天数不能小于1')
            return false
          } else if (item.time.type == 5 && item.time.data.typeLast5 == null) {
            this.$message.error('请选择提醒时间')
            return false
          } else if (item.time.type == 6 && item.selectValue == '每年' && item.labelData.mouthDay == null) {
            this.$message.error('请填写每年提醒日期')
            return false
          } else if (item.time.type == 6 && item.time.data.typeLast6 == null) {
            this.$message.error('请选择提醒时间')
            return false
          } else if (item.time.type == 7 && item.day.first < 1) {
            this.$message.error('提醒天数不能小于1')
            return false
          } else if (item.time.type == 7 && item.day.last == null) {
            this.$message.error('请选择提醒时间')
            return false
          } else if (item.content[0].type == 'text' && item.content[0].value == '') {
            this.$message.error('请输入发送内容')
            return false
          } else {
            return true
          }
        })
        return status
      },
      //移除非必要数据
      resNonFun(data) {
        return new Promise(resolve => {
          let resNonData = data.map((item) => {
            // hour:3   day:4   time：
            if (item.time.type == 0) {
              item.time.data = item.hour
            } else if (item.time.type == 3) {
              item.time.data = item.day
            }
            // else if (item.time.type == 3) {
            //   item.time.data.first = item.time.data.typeFirst3
            // }
            else if (item.time.type == 4) {
              item.time.data.first = item.time.data.typeFirst4
              item.time.data.last = item.time.data.typeLast4
            } else if (item.time.type == 5) {
              item.time.data.first = item.time.data.typeFirst5
              item.time.data.last = item.time.data.typeLast5
            } else if (item.time.type == 6) {
              if (item.selectValue == '每日') {
                item.time.data = { first: 0, last: item.time.data.typeLast6, cycle: 1 }
              } else if (item.selectValue == '每周') {
                let weekData = [{ id: 1, value: '周一' }, { id: 2, value: '周二' }, { id: 3, value: '周三' }, { id: 4, value: '周四' }, { id: 5, value: '周五' }, { id: 6, value: '周六' }, { id: 7, value: '周日' }]
                let date = weekData.filter((res) => res.value == item.selectopValue)[0].id
                item.time.data = { first: 0, last: item.time.data.typeLast6, date: date, cycle: 2 }
              } else if (item.selectValue == '每月') {
                item.time.data = { first: 0, last: item.time.data.typeLast6, date: item.labelData.mouth, cycle: 3 }
              } else if (item.selectValue == '每年') {
                var index = item.labelData.mouthDay.lastIndexOf("-");
                var front = item.labelData.mouthDay.substring(0, index);
                var after = item.labelData.mouthDay.substring(index + 1, item.labelData.mouthDay.length);
                item.time.data = { first: 0, last: item.time.data.typeLast6, date: after, month: front, cycle: 4 }
              }
            } else if (item.time.type == 7) {
              item.time.data = item.day
            }
            if (item.tags != undefined && item.tags.length != 0) {
              item.fullTags = item.tags
              item.tagIds = item.tags.map((item) => {
                return item.id
              })
              item.tagIdName = item.tags.map((item) => {
                return item.name
              })
            }
            if (item.tags5 != undefined && item.tags5.length != 0) {
              item.fullTags = item.tags5
              item.tagIds = item.tags5.map((item) => {
                return item.id
              })
              item.tagIdName = item.tags5.map((item) => {
                return item.name
              })
            }
            if (item.notTags != undefined && item.notTags.length != 0) {
              item.notFullTags = item.notTags
              item.notTagIds = item.notTags.map((item) => {
                return item.id
              })
              item.notTagName = item.notTags.map((item) => {
                return item.name
              })
            }
            //格式化后删除多余字段
            delete item['day']
            delete item['hour']
            delete item.time.data['typeFirst3']
            delete item.time.data['typeFirst4']
            delete item.time.data['typeLast4']
            delete item.time.data['typeFirst5']
            delete item.time.data['typeLast5']
            delete item.time.data['typeLast6']
            delete item['selectValue']
            delete item['selectopValue']
            delete item['labelData']
            // 删除空内容 防止提交空数据
            var arr = item.content;
            var i = arr.length;
            while (i--) {
              if (arr[i].type == 'text' && arr[i].value == '') {
                arr.splice(i, 1);
              } else if (arr[i].type == 'sucai' && arr[i].childMediumData == undefined) {
                arr.splice(i, 1);
              } else if (arr[i].type == 'image' && arr[i].value == '') {
                arr.splice(i, 1);
              }
            }
            return item
          })
          resolve(resNonData);
        });
      },
      //处理文件格式
      resFileFun(data) {
        return new Promise(resolve => {
          data.forEach((row) => {
            row.content.forEach((item) => {
              if (!item.childMediumData) return
              item.childMediumData = item.childMediumData.map((item) => {
                // XX||XX 编辑||新增
                if (item.type == '文本' || item.type == 'text') {
                  return item = {
                    content: item.content.content ? item.content.content : item.content,
                    type: 'text',
                    title: item.title || item.content.title,//回显需要
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '图文' || item.type == 'link') {
                  return item = {
                    title: item.title || item.content.title,
                    picurl: item.picurl || item.content.imageFullPath,
                    desc: item.desc || item.content.description,
                    url: item.url || item.content.imageLink,
                    type: 'link',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '图片' || item.type == 'images') {
                  return item = {
                    title: item.title || item.content.title,//回显需要
                    media_id: item.media_id || item.mediaId,
                    id: item.id,
                    pic_url: item.pic_url || item.content.imageFullPath,
                    value: item.value || item.content.imageFullPath,
                    imagePath: item.imagePath || item.content.imagePath,
                    type: 'images',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '音频' || item.type == 'voice') {
                  return item = {
                    title: item.title || item.content.title,
                    media_id: item.media_id || item.mediaId,
                    id: item.id,
                    pic_url: item.pic_url || item.content.voiceFullPath,
                    type: 'voice',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '视频' || item.type == 'video') {
                  return item = {
                    title: item.title || item.content.title,
                    media_id: item.media_id || item.mediaId,
                    id: item.id,
                    pic_url: item.pic_url || item.content.videoFullPath,
                    type: 'video',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '文件' || item.type == 'file') {
                  return item = {
                    title: item.title || item.content.title,
                    pic_url: item.pic_url || item.content.fileFullPath,
                    media_id: item.media_id || item.mediaId,
                    id: item.id,
                    type: 'file',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '小程序' || item.type == 'miniprogram') {
                  return item = {
                    title: item.title || item.content.title,
                    pic_media_id: item.pic_media_id || item.content.imageFullPath,
                    id: item.id,
                    appid: item.appid || item.content.appid,
                    page: item.page || item.content.page,
                    type: 'miniprogram',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.typeName) {//雷达素材
                  return item = {
                    title: item.title || item.link_title,
                    picurl: item.picurl || item.link_cover,
                    desc: item.desc || item.link_description,
                    url: item.url || item.linkUrl,
                    type: 'link',
                    initialType: item.initialType || item.type,//回显需要
                  }
                }
              })
            })
          })
          resolve(data);
        });

      },
      //编辑状态删除多余字段
      editDelFun(row) {
        return new Promise(resolve => {
          let resNonData = row.map((v) => {
            let newitem = {
              name: v.name,
              push_type: v.push_type,
              time: v.time,
              content: v.content,
            }
            if (v.tags != undefined && v.tags.length != 0) {
              // newitem.fullTags=v.tags
              newitem.tagIds = v.tags.map((item) => {
                return item.id
              })
              newitem.tagIdName = v.tags.map((item) => {
                return item.name
              })
            }
            if (v.tags5 != undefined && v.tags5.length != 0) {
              // newitem.fullTags=v.tags5
              newitem.tagIds = v.tags5.map((item) => {
                return item.id
              })
              newitem.tagIdName = v.tags5.map((item) => {
                return item.name
              })
            }
            if (v.notTags != undefined && v.notTags.length != 0) {
              // newitem.notFullTags=v.notTags
              newitem.notTagIds = v.notTags.map((item) => {
                return item.id
              })
              newitem.notTagName = v.notTags.map((item) => {
                return item.name
              })
            }
            v = newitem
            return v
          })
          resolve(resNonData);
        })

      },
      //选择标签
      selectTag(rindex, type) {
        this.currentTagsrItem = rindex;
        this.tagsType = type;
        this.$refs.popup.show()
      },
      // 标签选择回调
      choseTags(data) {
        if (this.tagsType == 'contain') {//包含标签
          this.$set(this.ruleList[this.currentTagsrItem], 'tags5', data)
        } else if (this.tagsType == 'notContain') {//不包含标签
          this.$set(this.ruleList[this.currentTagsrItem], 'notTags', data)
        } else {//设置标签
          this.$set(this.ruleList[this.currentTagsrItem], 'tags', data)
        }
      },
      closeTagChange(rindex, type, e, i) {
        if (type == 'set') {
          this.ruleList[rindex].tags.splice(i, 1)
        } else if (type == 'contain') {
          this.ruleList[rindex].tags5.splice(i, 1)
        } else {
          this.ruleList[rindex].notTags.splice(i, 1)
        }
      },
      //选择素材库
      selectMate(rIndex) {
        this.currentrItem = rIndex;//规则下标
        // this.selectMateIndex = i;//内容下标
        this.mateSele = true;
        this.$nextTick(() => { this.$refs.matebpx.deltelocalfun([2, 3, 4, 5, 6]) })
      },
      //关闭弹窗
      closePop() {
        this.matePop = false
      },
      //s素材库选择确认
      popSubmit(data) {
        this.matePop = false
        let arr = this.ruleList[this.currentrItem].content[1]
        arr.childMediumData == undefined ? arr.childMediumData = data : arr.childMediumData = [...arr.childMediumData, ...data]
      },
      //素材删除
      deleteMaterial(rindex, v, index) {//i,
        //rindex规则下标 内容父下标  index子下标
        this.ruleList[rindex].content[1].childMediumData.splice(index, 1)
        let newData = this.ruleList[rindex].content[1].childMediumData
        this.$delete(this.ruleList[rindex].content[1], 'childMediumData')
        this.$set(this.ruleList[rindex].content[1], 'childMediumData', newData)
      },
      chengeGroup(rindex, index, type) {
        this.currentGroup = type
        type == 'text' ? this.$delete(this.ruleList[rindex].content[index], 'selectMetedata') : this.$delete(this.ruleList[rindex].content[index], 'value')
      },
      //添加内容
      addContent(rItem) {
        this.ruleList[rItem].content.push({ type: 'text', value: '' })
      },
      //添加规则
      addRuleBtn() {
        let json = {
          name: '',
          push_type: 1,
          hour: {
            first: 0,
            last: 0,
          },
          day: {
            first: 0,
            last: null,
          },
          time: {
            type: this.createType == 1 ? '3' : (this.createType == 3 ? '7' : '4'),
            data: {
              first: 0,
              last: null,
              typeFirst3: 0,
              typeFirst4: 0,
              typeLast4: null,
              typeFirst5: 0,
              typeLast5: null,
              typeLast6: null
            },
          },
          content: [{ type: 'text', value: '' }, { type: 'sucai', value: '' }, { type: 'image', value: '' }],
          tags: [],
          tags5: [],
          notTags: [],
          selectValue: '每日',
          selectopValue: '周一',
          labelData: {
            day: null,
            mouth: '1日',
            year: null,
            mouthDay: null
          }
        }
        this.ruleList.push(json)
      },
      //删除规则
      delRule(i) {
        let that = this
        Dialog.confirm({
          title: '提示',
          message: '是否删除当前规则内容？',
        })
          .then(() => {
            that.ruleList.splice(i, 1)
          })
          .catch(() => {
          });
      },
      //素材库选项
      popBtn(row) {
        this.matePop = true
        this.mateSele = false;
        this.datekey = Date.now();//刷新子组件
        this.$nextTick(() => {
          this.$refs.mateChild.selectPop = true //修改子组件data
          this.$refs.mateChild.currentID = row.id  //修改子组件变量
          this.$refs.mateChild.activeName = row.id   //修改子组件选中tab
          this.$refs.mateChild.oldSelectData = this.ruleList[this.currentrItem].content[1].childMediumData || []   //传递已选择数据
        })
      },
      //子组件图片数据
      imagesData(data) {
        if (this.ruleList[this.currentrItem].content[2] != undefined) {
          this.$delete(this.ruleList[this.currentrItem].content[2], 'value')
          this.$set(this.ruleList[this.currentrItem].content[2], 'value', data)
        } else {
          this.ruleList[this.currentrItem].content[2] = { type: 'image', value: data }
        }
        this.mateSele = false;
        this.mateSele = false;
      },
      //周期弹窗确认
      onConfirm(value, index) {
        // console.log(`当前值：${value}, 当前索引：${index}`);
        this.ruleList[this.currentCycle].selectopValue = value
        this.weekShow = false
      },
      //周期弹窗取消
      onCancel() {
        this.weekShow = false
      },
      //月份弹窗确认
      mouthonConfirm(value, index) {
        // console.log(`当前值：${value}, 当前索引：${index}`);
        this.ruleList[this.currentCycle].labelData.mouth = value
        this.mouthShow = false
      },
      //月份弹窗取消
      mouthonCancel() {
        this.mouthShow = false
      },
      //年份弹窗确认
      yearonConfirm(e) {
        const year = e.getFullYear();
        const month = (e.getMonth() + 1) < 10 ? '0' + (e.getMonth() + 1) : (e.getMonth() + 1);    //js从0开始取
        const date = (e.getDate()) < 10 ? '0' + (e.getDate()) : (e.getDate());
        const hour = (e.getHours()) < 10 ? '0' + e.getHours() : e.getHours();
        const minutes = (e.getMinutes()) < 10 ? '0' + e.getMinutes() : e.getMinutes();
        this.ruleList[this.currentCycle].labelData.mouthDay = month + '-' + date
        this.yearShow = false
      },
      //年份弹窗取消
      yearonCancel() {
        this.yearShow = false
      },
      //月日组件格式化内容
      formatter(type, val) {
        if (type === 'month') {
          return `${val}月`;
        } else if (type === 'day') {
          return `${val}日`;
        }
        return val;
      },
      //选择其他设置
      selectSet(index, type) {
        this.currentCycle = index;//当前选项数组下标
        this.currentCycleType = type;//当前选项类型
        if (type == 'week') {
          this.weekShow = true
        } else if (type == 'mouth') {
          this.mouthShow = true
        } else if (type == 'year') {
          this.yearShow = true
        }
      },
      //打开周期选项弹窗
      openAction(index) {
        this.cycleIndex = index
        this.showAction = true
      },
      // 日周月年 按钮选项事件
      onSelect(item) {
        this.ruleList[this.cycleIndex].selectValue = item.name
      },
      // 获取员工Id
      getCount() {
        this.employeeIds = this.$refs.groups.get().map((v) => {
          return v.id;
        });
      },
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .van-popup__close-icon {
    top: 22px !important;
  }

  /deep/.van-cell::after {
    border-bottom: none
  }

  /deep/[class*=van-hairline]::after {
    border: none
  }

  /deep/.van-cell__title {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }

  /deep/.van-field--min-height .van-field__control {
    min-height: 100px;
  }


  .page {
    width: 100%;
    background: #FFFFFF;
    padding: 5px 0;
    font-size: 16px;

    .borderBox {
      width: 95%;
      border: 1px dashed #C0C0C0;
      /* padding: 0px 10px; */
      margin: 0 auto;
    }

    .item {
      margin-top: 17px;
      border-bottom: 1px solid #F5F5F5;
      padding: 0 12px 10px 12px;

      .vantName {
        padding: 10px 0px;
        margin-top: 5px;
        font-size: 15px;
      }

      .title {
        font-size: 15px;
        font-weight: 400;
        color: #515151;
        display: inline-flex;

        .iconBtn {
          float: right;
          position: absolute;
          right: 22px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .conBox {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        img {
          width: 6px;
          height: 10px;
          margin-right: 10px;
        }
      }

      .textareaBox {
        margin: 20px 0;
        padding: 15px 20px;
        background: #F2F3F7;
        min-height: 100px;
        border-radius: 12px;
      }

      .imgBox {
        margin: 10px 0;

        img {
          width: 70px;
          height: 70px;
        }
      }

      .addbox {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #F2F3F7;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }

      }

      .name {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        margin-left: 12px;
      }
    }

    .item_flex {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .tips {
      margin-top: 12px;
      font-size: 15px;
      font-weight: 400;
      color: #A5A5A5;
      padding: 0 12px 10px 12px;
    }

    .checkBox {
      margin-top: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid #F5F5F5;
      padding: 0 12px 10px 12px;

      .title {
        font-size: 15px;
        font-weight: 400;
        color: #515151;
        margin-bottom: 5px;
      }

      .groupBox {
        display: flex;
        // justify-content: space-between;
        margin: 24px 0;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        position: relative;

        .groupLeft {
          width: 90%;
          word-break: break-all;
          overflow: hidden;
          font-size: 15px;

          input {
            background: #F2F3F7;
            border-radius: 2px;
            height: 25px;
            border: none;
            text-align: center;
            padding: 0 10px;
            width: 40px;

          }
        }

        .mline {
          // line-height: 2.5;
        }

        .groupRight {
          width: 10%;
          position: absolute;
          top: 5px;
          right: -15px;
        }
      }
    }

    .m15 {
      margin-top: 17px;
    }

    .btnBox {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 12px 10px 12px;

      .cancel {
        width: 30%;
        padding: 12px 0;
        text-align: center;
        background: #F1F2F6;
        border-radius: 5px;
        border: 1px solid #D7D7D9;
        font-size: 17px;
        font-weight: 500;
        color: #595A5E;
      }

      .submit {
        width: 65%;
        text-align: center;
        padding: 12px 0;
        background: #3974C6;
        border-radius: 5px;
        font-size: 17px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }

  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 8px;

    .tag {
      margin-bottom: 6px;
    }
  }

  .filesitem {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filesleft {
      color: #565656;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .ruleBox {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;

    .name {
      font-size: 15px;
      color: #3974C6;
      margin-left: 5px
    }
  }

  .addrule {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    border: 1px dashed #F2F3F7;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
    }

  }

  .space {
    width: 100%;
    height: 10px;
  }
</style>