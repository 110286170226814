<template>
  <div class="contact-page">
    <div class="backBox"></div>
    <div v-show="showContact" @click="closeDown" class="show-cont">
      <div class="show-customer">
        <!-- <van-nav-bar title="客户详情" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="personal_page">
          <!--      用户信息-->
          <div class="page_flex">
            <div style="display: flex;position: relative;">
              <img :src="infoDetail.avatar" alt=""  style="width: 69px; height: 69px;border-radius: 8px;" />
              <div  class="overlay" v-if="infoDetail.isBlack==2">黑</div>
              <div style="margin-left: 10px">
                <div style="font-size: 16px">
                  {{ infoDetail.name }}
                  <span style="color: #72c871">@微信</span>
                </div>
                <img src="../../assets/male.png" alt="" style="width: 15px; height: 15px; margin-top: 5px"
                  v-if="infoDetail.gender == 1" />
                <img src="../../assets/female.png" alt="" style="width: 15px; height: 15px; margin-top: 5px"
                  v-if="infoDetail.gender == 2" />
              </div>
            </div>
            <span style=" margin-top: 15px; ">
              <span @click="showContactDetail"><van-icon name="arrow" /></span>
            </span>
          </div>
          <!--   信息   -->
          <div style="margin-top: 15px; padding: 10px;background: #F1F2F6;border-radius: 4px;" class="infoBox"
            :class="{ 'has-triangle': infoDetail.wealth&&infoDetail.wealth.showWealth==1 }">
            <div class="page_flex" v-if="infoDetail.wealth&&infoDetail.wealth.showWealth==1">
              <div style="width: 90%">
                <span class="info_name">所属机构：&nbsp;</span>{{ infoDetail.departmentName }}
              </div>
            </div>
            <div class="page_flex" style="margin-top: 10px">
              <div style="width: 90%">
                <span class="info_name">添加时间：&nbsp;</span>{{ infoDetail.createTime }}
              </div>
            </div>

            <!--<div style="margin-top: 10px" class="page_flex">
                <div style="width: 50%; display: flex">
                  <div class="info_name" style="width: 50px">年龄：</div>
                  <input
                    type="text"
                    class="info_input"
                    placeholder="-"
                    v-model="infoDetail.fieldPivot.age"
                    @blur="setAge"
                    style="width: calc(100% - 50px)"
                  />
                </div>-->
            <!--<div style="width: 50%; display: flex">
                <div class="info_name" style="width: 50px">邮箱：</div>
                <input
                  type="text"
                  class="info_input"
                  placeholder="-"
                  v-model="infoDetail.fieldPivot.email"
                  @blur="setMailbox"
                  style="width: calc(100% - 50px)"
                />
              </div>
            </div>-->
            <div style="margin-top: 10px">
              <span class="info_name">联系电话：</span>
              <span>{{ phoneArr[0] ? phoneArr[0] + "" : "" }}&nbsp;</span>
              <span>{{ phoneArr[1] ? phoneArr[1] + "" : "" }}&nbsp;</span>
              <span>{{ phoneArr[2] ? phoneArr[2] + "" : "" }}&nbsp;</span>
              <div style="margin-left: 44px" v-if="phoneArr[3]">
                <span>{{ phoneArr[3] ? phoneArr[3] + "" : "" }}&nbsp;</span>
              </div>
            </div>
          </div>
          <!--    跟进状态  -->
          <div style="padding: 0px 0px 0px 0px; margin-top: 15px; cursor: pointer">
            <div @click="$refs.contactFollowStage.show(contactId)" class="page_flex">
              <div style="color: #999">跟进状态</div>
              <div>
                {{ textFoll(infoDetail.followUpStatus)
                }}<van-icon name="arrow" />
              </div>
            </div>
          </div>
        </div>
        <!--    对公业务  -->
        <div style="margin-top: 15px; cursor: pointer;" class="duigong">
          <div @click="skipBusiness" class="duiContent">
            <div style="color: #999">对公业务</div>
            <div v-if="isCorpData==0">个人客户<van-icon name="arrow" /></div>
            <div v-else>企业客户<van-icon name="arrow" /></div>
          </div>
        </div>
        <div style="margin-top: 15px; cursor: pointer;padding: 0px 15px 15px 15px;" class="duigong"
          v-if="infoDetail.wealth&&infoDetail.wealth.showWealth==1">
          <div class="titleBox">
            <div class="title">财富体验</div>
          </div>
          <div class="tipsBox">
            <div class="lineBox"></div>
            <div class="lineTitle">财富自由度：</div>
            <div class="lineCon">{{infoDetail.wealth.wealth}}</div>
          </div>
          <div class="tipsBox">
            <div class="lineBox"></div>
            <div class="lineTitle">风险偏好：</div>
            <div class="lineCon">{{infoDetail.wealth.risk}}</div>
          </div>
          <div class="Echarts" style="padding-left: 12px;margin-top: 15px;">
            <div id="myChartCategory" style="width: 335px;height: 250px;"></div>
          </div>
          <div class="evaluat"><img src="../../assets/msg.png" alt=""><span>评测结果：</span></div>
          <div class="evaluatCon">{{infoDetail.wealth.result}}</div>
        </div>
        <div style="margin-top: 15px; cursor: pointer;padding: 15px;" class="duigong"
          v-if="infoDetail.wealth&&infoDetail.wealth.showWealth==1">
          <div class="assetBox">总资产(元)</div>
          <div class="assetMoney">{{formattedNumber(infoDetail.wealth.money)}}</div>
          <div class="Echarts" style="padding-left: 12px;">
            <div id="myChartCategory2" style="width: 350px;height: 280px;"></div>
          </div>
          <div class="tipsBtn">客户当前配置比例如图</div>
        </div>
        <div style="margin-top: 15px; cursor: pointer;padding: 15px;" class="duigong"
          v-if="infoDetail.wealth&&infoDetail.wealth.showWealth==1">
          <div class="assetBox">总负债(元)</div>
          <div class="assetMoney">{{formattedNumber(infoDetail.wealth.liabilitiesMoney)}}</div>
          <div class="Echarts" style="padding-left: 12px">
            <div id="myChartCategory3" style="width: 350px;height: 250px;"></div>
          </div>
          <div class="tipsBtn">客户当前配置比例如图</div>
        </div>

        <div style="margin-top: 15px; cursor: pointer;padding: 15px;" class="duigong">
          <div class="trendHeard">
            <div class="title">客户趋势</div>
            <div class="action" @click="trendEdit">{{trendTitle}}</div>
          </div>
          <div class="container">
            <div class="linetext">
              <div>交往密切</div>
              <div>有效互动</div>
              <div>初识建联</div>
            </div>
            <div class="grid-container">
              <div v-for="(item, index) in 9" :key="index" class="grid-item"   @click="activeChange(index)"  :class="{ active: activeIndex === index }">
                <img src="../../assets/select.png" alt="" class="selectImg" v-if="activeIndex === index">
              </div>
            </div>
          </div>
          <div class="rowtext">
            <div>潜力客户</div>
            <div>成长客户</div>
            <div>价值客户</div>
          </div>
        </div>

        <div class="personal_page" @click="showFriendRoom" style="cursor: pointer; margin-top: 10px">
          <div class="info_name">Ta添加的：</div>
          <div class="page_flex" style="margin-top: 10px; align-items: center">
            <div style="display: flex" class="personal-tip">
              <div v-for="(item, index) in infoDetail.employeeName" :key="index">
                <img :src="item.avatar" alt="" class="employee-img" v-if="index < 3" />
              </div>
              共{{ employees.length }}个员工
            </div>
            <div class="personal-tip">
              共{{ infoDetail.roomName.length }}个群聊<van-icon name="arrow" />
            </div>
          </div>
        </div>
        <div class="personal_page" style="margin-top: 10px">
          <div style="margin-top: 5px">
            <div class="page_flex">
              <div class="row_name">企业标签</div>
              <div class="add_btn_style" style="
                    width: 69px;
                    height: 33px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  " @click="$refs.corpTag.show(contactId)">
                + 添加
              </div>
            </div>
            <!--      设置标签-->
            <div style="display: flex; justify-content: space-between"
              v-for="(item, index) in infoDetail.contactCorpTag" :key="index">
              <div style="margin-left: 10px; margin-top: 8px">
                {{ item.groupName }}：
              </div>
              <div style="display: flex; flex-wrap: wrap; margin-top: 2px">
                <div class="tag_name" v-for="(obj, idx) in item.tags" :key="idx">
                  {{ obj.tagName }}
                  <van-icon name="clear" class="close-icon" @click.stop="delEnterpriseTag(obj)" />
                </div>
              </div>
            </div>
          </div>
          <Divider style="margin-top: 10px" />
          <div style="margin-top: 10px">
            <div class="page_flex">
              <div class="row_name">个人标签</div>
              <div style="
                    width: 69px;
                    height: 33px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: -3px;
                  " class="add_btn_style" @click="$refs.employeeTag.show(contactId)">
                + 添加
              </div>
            </div>
            <!--      设置标签-->
            <div style="display: flex; flex-wrap: wrap; margin-top: 5px">
              <div class="tag_name" v-for="(item, index) in infoDetail.contactEmployeeTag" :key="index">
                {{ item.name }}
                <van-icon name="clear" class="close-icon" @click.stop="delContactTag(item)" />
              </div>
            </div>
          </div>
          <Divider style="margin-top: 10px" />
          <div style="margin-top: 10px">
            <div class="page_flex">
              <div class="row_name">朋友圈互动</div>
              <div style="font-size: 14px; color: #999">
                更新于：{{ refreshTime }}
                <span style="font-size: 14px; cursor: pointer" @click="updatePage(true)"><van-icon
                    name="replay" /></span>
              </div>
            </div>
            <div style="margin-top: 15px; display: flex">
              <div class="interaction_num">
                点赞次数
                <Popover v-model:show="giveNum" :actions="giveTips" placement="top-start">
                  <template #reference>
                    <van-icon name="question-o" style="cursor: pointer" @mouseenter="giveNum = true"
                      @mouseout="giveNum = false" />
                  </template>
                </Popover>
                ：<span>{{ infoDetail.moment.likeNumber }}</span>
              </div>
              <div class="interaction_num" style="margin-left: 15px">
                评论次数
                <Popover v-model:show="commentNum" :actions="commentTips" placement="top-start">
                  <template #reference>
                    <van-icon name="question-o" style="cursor: pointer" @mouseenter="commentNum = true"
                      @mouseout="commentNum = false" />
                  </template>
                </Popover>
                ：<span>{{ infoDetail.moment.commentNumber }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="background: #fff; margin-top: 10px">
          <div class="personal_page" style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              ">
            <div class="row_name">动态</div>
            <div class="drop-box" style="font-size: 14px">
              <dropDownCase :optionData="optionData" @change="selectContactTrack" ref="dropDownCase" />
            </div>
          </div>
          <div style="position: relative">
            <div class="show-list">
              <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoadData">
                <van-cell v-for="(item, index) in trackList" :key="index">
                  <div style="display: flex">
                    <div>{{ item.week }}</div>
                    <div style="margin-left: 15px">{{ item.day }}</div>
                  </div>
                  <Steps direction="vertical" active-icon="clock" inactive-icon="clock">
                    <Step v-for="(obj, idx) in item.track" :key="idx">
                      <div style="position: relative">
                        <div class="group_time left-time" style="left: -69px; margin-top: 16px">
                          {{ obj.createdAt }}
                        </div>
                        <div class="trail-icon">
                          <img src="../../assets/clockImg.png" alt="" v-if="obj.event == 8" />
                          <img src="../../assets/tagsImg.png" alt="" v-else />
                        </div>
                        <div class="group_event" v-if="obj.isNew == 0">
                          {{ obj.content }}
                        </div>
                        <div class="group_event" v-if="obj.isNew == 1">
                          {{ obj.content.title }}
                        </div>
                      </div>
                      <div style="display: flex; justify-content: space-between">
                        <div class="group_time" v-if="obj.isNew == 1">
                          <span v-if="obj.todoTime">[{{ obj.todoTime }}]</span>
                          {{ obj.content.content }}
                        </div>
                        <div style="color: #74bcff; cursor: pointer; width: 35px"
                          v-if="obj.event == 6 || obj.event == 7" @click="$refs.contactFollowModify.show(obj)">
                          修改
                        </div>
                      </div>
                      <div class="group_time" v-if="obj.createName">
                        {{ obj.createName }} 设置
                      </div>
                    </Step>
                  </Steps>
                </van-cell>
              </van-list>
            </div>
          </div>
        </div>
      </div>
      <div class="seek-row">
        <van-button class="infoBtn" type="info" block @click="seeContactDetail" style="margin-bottom: 10px">查看客户</van-button>
        <div class="blackBtn" @click="addBlack" v-if="infoDetail.isBlack==1">加入黑名单</div>
        <div class="blackBtn" @click="addBlack" v-if="infoDetail.isBlack==2">查看黑名单备注</div>
      </div>
    </div>
    <div class="load-box" :style="{ height: clientHeight + 'px' }" v-if="!showContact">
      <van-loading type="spinner" color="#1989fa" class="load-img" v-if="!showContact" />
    </div>
    <!--    客户详情-->
    <contactDetail ref="contactDetail" @change="updatePage(true)" />
    <!--    客户评分-->
    <contactScore ref="contactScore" />
    <!--    客户积分-->
    <contactIntegral ref="contactIntegral" />
    <!--    选择跟进状态-->
    <contactFollowStage ref="contactFollowStage" @change="updatePage" />
    <!--    好友和群聊-->
    <friendRoom ref="friendRoom" />
    <!--    添加企业标签-->
    <corpTag ref="corpTag" @change="updatePage" />
    <!--    个人标签-->
    <employeeTag ref="employeeTag" @change="updatePage" />
    <!--    添加待办-->
    <contactTodo ref="contactTodo" @change="updatePage" />
    <!--    添加跟进-->
    <contactFollow ref="contactFollow" @change="updatePage" />
    <!--    修改跟进-->
    <contactFollowModify ref="contactFollowModify" @change="updatePage" />
    <!--    转接客户-->
    <contactTransfer ref="contactTransfer" />
    <!--    模板拉群-->
    <roomTemplatePull ref="roomTemplatePull" />
    <!-- 黑名单 -->
    <blackPage ref="blackPage" :infoDetail="infoDetail" :contactId="contactId" @change="getInfo"/>
  </div>
</template>
<script>
  import { ContactApi } from "@/api/contact";
  // eslint-disable-next-line no-unused-vars
  import { ContactTrackApi } from "@/api/contactTrack";
  import { Divider, Step, Steps, Tabbar, TabbarItem, Toast, Popover } from "vant";
  import dropDownCase from "@/components/dropDownCase/dropDownCase";
  import contactDetail from "./components/contactDetail";
  import contactScore from "./components/contactScore";
  import contactIntegral from "./components/contactIntegral";
  import contactFollowStage from "./components/contactFollowStage";
  import friendRoom from "./components/friendRoom";
  import corpTag from "./components/corpTag";
  import employeeTag from "./components/employeeTag";
  import contactTodo from "./components/contactTodo";
  import contactFollow from "./components/contactFollow";
  import contactFollowModify from "./components/contactFollowModify";
  import contactTransfer from "./components/contactTransfer";
  import roomTemplatePull from "./components/roomTemplatePull";
  import blackPage from './components/blackDetail'
  // eslint-disable-next-line no-unused-vars
  import { openUserProfile } from "@/utils/wxCodeAuth";
  export default {
    components: {
      contactDetail,
      roomTemplatePull,
      contactTransfer,
      Divider,
      Step,
      Steps,
      Popover,
      contactScore,
      contactIntegral,
      contactFollowStage,
      friendRoom,
      corpTag,
      employeeTag,
      contactTodo,
      contactFollow,
      Tabbar,
      TabbarItem,
      dropDownCase,
      contactFollowModify,
      blackPage
    },
    data() {
      return {
        phoneArr: [],
        infoDetail: {
          fieldPivot: {},
          roomName: [],
          employeeName: [],
          moment: {},
        },
        isCorpData: '',
        trackList: [],
        employees: [],
        clientEvent: 0,
        showContact: false,
        finished: false,
        loading: false,
        page: 1,
        clientHeight: "",
        optionData: [
          {
            id: 0,
            name: "全部",
          },
          {
            id: 1,
            name: "客户动态",
          },
          {
            id: 3,
            name: "修改信息",
          },
          {
            id: 6,
            name: "跟进记录",
          },
          {
            id: 8,
            name: "待办事项",
          },
          {
            id: 7,
            name: "模板记录",
          },
        ],
        //  更新时间
        refreshTime: "",
        //  点赞次数
        giveNum: false,
        giveTips: [
          {
            text: "企业微信朋友圈点赞总次数",
          },
        ],
        //  评论次数
        commentNum: false,
        commentTips: [
          {
            text: "企业微信朋友圈评论总次数",
          },
        ],
        tabActive: 0,
        activeIndex: null, // 用来存储当前选中的格子的索引
        trendTitle:'编辑',
      };
    },
    async created() {
      this.contactId = this.$route.query.contactId;
      this.wxExternalUserid = this.$route.query.wxExternalUserid;
      this.showContact = false;
      this.trackList = [];
      this.clientHeight = document.documentElement.clientHeight;
      // const entry = await getContext()
      // const agentId = this.$route.query.agentId
      // if (entry === 'group_chat_tools') {
      //   this.$router.push({ path: '/room', query: { agentId } })
      //   return
      // }
      // if (entry !== 'single_chat_tools') {
      //   this.$toast({ position: 'top', message: '请从单聊会话的工具栏进入' })
      //   return
      // }
      // await this.getContactInfo()
      // if (!this.contactId) {
      //   this.$toast({ position: 'top', message: '获取客户信息失败，请重新进入应用' })
      //   return
      // }

      // this.showContact = true
      // 基本信息
      this.getInfo();
      // 互动轨迹
      setTimeout(() => {
        this.$refs.dropDownCase.setOption(0);
        this.onLoadData();
      }, 1000);
      //  客户详情信息
      this.getDetail();
    },
    mounted() {

    },
    methods: {
      skipBusiness() {
        this.$router.push({
          path: "/contact/selectBinding",
          query: {
            contactId: this.contactId,
          },
        });
      },
      async seeContactDetail() {
        await openUserProfile(2, this.wxExternalUserid);
      },
      onClickLeft() {
        this.$router.back();
      },
      // 获取当前时间
      getCurrentTime() {
        const date = new Date();
        const year = date.getFullYear();
        const month = new Date().getMonth() + 1;
        const day =
          new Date().getDate() < 10
            ? "0" + new Date().getDate()
            : new Date().getDate();
        const hh =
          new Date().getHours() < 10
            ? "0" + new Date().getHours()
            : new Date().getHours();
        const mm =
          new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes();
        this.refreshTime = year + "-" + month + "-" + day + " " + hh + ":" + mm;
      },
      // 客户画像
      getDetail() {
        ContactApi.getContactPortrait({
          contactId: this.contactId,
        }).then((res) => {
          this.detail = res.data;
        });
      },
      // 修改客户信息
      modifyContact() {
        ContactApi.updateContactPortrait({
          contactId: this.contactId,
          userPortrait: JSON.stringify(this.detail),
        }).then((res) => {
          Toast("修改成功");
          this.updatePage(true);
        });
      },
      // 设置电话
      setPhone() {
        if (!/^1[3456789]\d{9}$/.test(this.infoDetail.fieldPivot.phone)) {
          Toast("手机号格式错误");
          return false;
        }
        this.detail.map((item, index) => {
          if (item.name == "手机号") {
            item.value = this.infoDetail.fieldPivot.phone;
          }
          return item;
        });
        this.modifyContact();
      },
      // 设置年龄
      setAge() {
        if (!/^[0-9]*$/.test(this.infoDetail.fieldPivot.age)) {
          Toast("年龄格式错误");
          return false;
        }
        this.detail.map((item, index) => {
          if (item.name == "年龄") {
            item.value = this.infoDetail.fieldPivot.age;
          }
          return item;
        });
        this.modifyContact();
      },
      // 设置邮箱
      setMailbox() {
        if (this.infoDetail.fieldPivot.email == "") {
          Toast("邮箱格式错误");
          return false;
        }
        this.detail.map((item, index) => {
          if (item.name == "邮箱") {
            item.value = this.infoDetail.fieldPivot.email;
          }
          return item;
        });
        this.modifyContact();
      },
      // 关闭下拉
      closeDown() {
        this.$refs.dropDownCase.closeOption();
      },
      // 更新页面
      updatePage(e) {
        if (e) {
          this.getInfo();
          this.trackList = [];
          this.page = 1;
          this.onLoadData();
        }
      },
      // 处理互动轨迹数据
      handTrackList(data) {
        if (data.length != 0) {
          data.forEach((item, index) => {
            item.track.forEach((obj, idx) => {
              if (obj.isNew == 1) {
                obj.content = JSON.parse(obj.content);
              }
            });
            this.trackList.push(item);
          });
          this.loading = false;
          this.page++;
        } else {
          this.finished = true;
        }
      },
      // 选择客户动态
      selectContactTrack(e) {
        this.clientEvent = e;
        this.trackList = [];
        this.page = 1;
        this.onLoadData();
      },
      // 下拉加载
      onLoadData() {
        this.loading = true;
        this.finished = false;
        this.getTrackData();
      },
      // 互动轨迹
      getTrackData() {
        ContactTrackApi.index({
          contactId: this.contactId,
          event: this.clientEvent,
          page: this.page,
          // perPage: 10
        }).then((res) => {
          this.handTrackList(res.data.list);
        });
      },
      // 删除企业标签
      delEnterpriseTag(obj) {
        ContactApi.deleteContactTagApi({
          contactId: this.contactId,
          tagId: obj.tagId,
        }).then((res) => {
          Toast("删除成功");
          this.updatePage(true);
        });
      },
      // 删除个人标签
      delContactTag(item) {
        ContactApi.employeeTagDeleteApi({
          contactId: this.contactId,
          tagId: item.id,
        }).then((res) => {
          Toast("删除成功");
          this.updatePage(true);
        });
      },
      // 显示用户详情
      showContactDetail() {
        const contactDetailInfo = {
          contactId: this.contactId,
          avatar: this.infoDetail.avatar,
          name: this.infoDetail.name,
        };
        this.$refs.contactDetail.show(contactDetailInfo);
      },
      // 显示好友群聊
      showFriendRoom() {
        const roomData = {
          roomName: this.infoDetail.roomName,
          employeeName: this.employees,
        };
        this.$refs.friendRoom.show(roomData);
      },
      // 客户详情基本信息
      getInfo() {
        // 获取当前时间
        this.getCurrentTime();
        const params = {
          contactId: this.contactId,
        };
        ContactApi.show(params).then((res) => {
          this.activeIndex=res.data.trend?res.data.trend-1:null;
          this.infoDetail = res.data;
          this.phoneArr = this.infoDetail.phone.split(",");
          this.isCorpData = this.infoDetail.isCorpContact
          console.log("电话", this.phoneArr);
          this.employees = this.infoDetail.employees;
          this.wxExternalUserid = this.infoDetail.wxExternalUserid;
          setTimeout(() => {
            this.showContact = true;
            this.showEach()
          }, 500);
        });
      },
      showEach() {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(
          document.getElementById("myChartCategory")
        );
        let myChart2 = this.$echarts.init(
          document.getElementById("myChartCategory2")
        );
        let myChart3 = this.$echarts.init(
          document.getElementById("myChartCategory3")
        );
        let { wealthExperience, assetRatio, debtRatio } = this.infoDetail.wealth
        // let wealthExperience=this.infoDetail.wealth.
        // 绘制图表
        myChart.setOption({
          title: {
            // text: '财富体验'
          },
          legend: {
            // data: ['Allocated Budget', 'Actual Spending']
          },
          radar: {
            shape: 'circle',
            indicator: [
              { name: '流动性', max: 200 },
              { name: '心愿管理', max: 200 },
              { name: '保障性', max: 200 },
              { name: '资产配置', max: 200 }
            ],
            center: ['50%', '50%'],
            radius: 95,
            splitArea: {
              areaStyle: {
                color: ['#EFF0FF'],
                shadowColor: 'rgba(0, 0, 0, 0.2)',
                shadowBlur: 10
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(153,153,153, 0.8)'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(213,213,255, 0.8)'
              }
            }
          },
          color: ['#7279F3'],
          series: [
            {
              name: '',
              type: 'radar',
              data: [
                {
                  value: [wealthExperience.liquidity, wealthExperience.wishManagement, wealthExperience.supportability, wealthExperience.assetAllocation],
                  label: {
                    show: true,
                    formatter: function (params) {
                      return params.value;
                    }
                  },
                  areaStyle: {
                    color: 'rgba(112,119,243, 0.6)'
                  }
                },
              ]
            }
          ]
          //         color: ['#67F9D8', '#FFE434', '#56A3F1', '#FF917C'],
          // title: {
          //   text: 'Customized Radar Chart'
          // },
          // legend: {},
          // radar: [
          //   {
          //     indicator: [
          //       { text: 'Indicator1' },
          //       { text: 'Indicator2' },
          //       { text: 'Indicator3' },
          //       { text: 'Indicator4' },
          //       { text: 'Indicator5' }
          //     ],
          //     center: ['25%', '50%'],
          //     radius: 120,
          //     startAngle: 90,
          //     splitNumber: 4,
          //     shape: 'circle',
          //     axisName: {
          //       formatter: '【{value}】',
          //       color: '#428BD4'
          //     },
          //     splitArea: {
          //       areaStyle: {
          //         color: ['#77EADF', '#26C3BE', '#64AFE9', '#428BD4'],
          //         shadowColor: 'rgba(0, 0, 0, 0.2)',
          //         shadowBlur: 10
          //       }
          //     },
          //     axisLine: {
          //       lineStyle: {
          //         color: 'rgba(211, 253, 250, 0.8)'
          //       }
          //     },
          //     splitLine: {
          //       lineStyle: {
          //         color: 'rgba(211, 253, 250, 0.8)'
          //       }
          //     }
          //   },
          //   {
          //     indicator: [
          //       { text: 'Indicator1', max: 150 },
          //       { text: 'Indicator2', max: 150 },
          //       { text: 'Indicator3', max: 150 },
          //       { text: 'Indicator4', max: 120 },
          //       { text: 'Indicator5', max: 108 },
          //       { text: 'Indicator6', max: 72 }
          //     ],
          //     center: ['75%', '50%'],
          //     radius: 120,
          //     axisName: {
          //       color: '#fff',
          //       backgroundColor: '#666',
          //       borderRadius: 3,
          //       padding: [3, 5]
          //     }
          //   }
          // ],
          // series: [
          //   {
          //     type: 'radar',
          //     emphasis: {
          //       lineStyle: {
          //         width: 4
          //       }
          //     },
          //     data: [
          //       {
          //         value: [100, 8, 0.4, -80, 2000],
          //         name: 'Data A'
          //       },
          //       {
          //         value: [60, 5, 0.3, -100, 1500],
          //         name: 'Data B',
          //         areaStyle: {
          //           color: 'rgba(255, 228, 52, 0.6)'
          //         }
          //       }
          //     ]
          //   }
          // ]
        });
        myChart2.setOption({
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '85%',
            left: 'center',
            itemWidth: 10,
            itemHeight: 10
          },
          color: ['#4F5A93', '#D55984', '#7590E1', '#7F47D6'],
          series: [
            {
              name: '资产比例百分比',
              type: 'pie',
              radius: ['40%', '55%'],
              center: ['50%', '50%'],
              startAngle: 180,
              endAngle: 360,
              data: [
                { value: assetRatio.currentDeposit, name: '活期存款' },
                { value: assetRatio.timeDeposits, name: '定期存款' },
                { value: assetRatio.stockBalance, name: '股金余额' },
                { value: assetRatio.financialBalance, name: '理财余额' },
              ],
            }
          ]

        });
        myChart3.setOption({
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '85%',
            left: 'center',
            itemWidth: 10,
            itemHeight: 10
          },

          color: ['#65A4F5', '#67C9D0', '#F0C172'],
          series: [
            {
              name: '负债比例百分比',
              type: 'pie',
              radius: ['40%', '55%'],
              center: ['50%', '50%'],
              startAngle: 180,
              endAngle: 360,
              data: [
                { value: debtRatio.creditCard, name: '信用卡' },
                { value: debtRatio.loan, name: '贷款' },
                { value: debtRatio.consumerFinance, name: '消费金融' },
              ],
            }
          ]
        });
      },
      changeBar(type) {
        if (type == 1) {
          this.$refs.contactTodo.show(this.contactId);
        }
        if (type == 2) {
          this.$refs.contactFollow.show(this.contactId);
        }
        if (type == 3) {
          this.$refs.contactTransfer.show(this.contactId);
        }
        if (type == 4) {
          const userInfo = {
            contactId: this.contactId,
            avatar: this.infoDetail.avatar,
            name: this.infoDetail.name,
            wxExternalUserid: this.infoDetail.wxExternalUserid,
          };
          this.$refs.roomTemplatePull.show(userInfo);
        }
      },
      textFoll(e) {
        switch (e) {
          case 1:
            return "新客户";
            break;
          case 2:
            return "初步沟通";
            break;
          case 3:
            return "意向客户";
            break;
          case 4:
            return "付款客户";
            break;
          case 5:
            return "无意向客户";
            break;
          default:
            return "暂无状态";
        }
      },
      formattedNumber(row) {
        var num = parseInt(row, 10);
        return '¥' + num.toLocaleString('en-US');
      },
      activeChange(index) {
        if(this.trendTitle=='完成'){
          this.activeIndex = index;
        }
      },
      trendEdit(){
        if(this.trendTitle=='完成'){
          ContactApi.trendEdit({
          contactId: this.contactId,
          trend:this.activeIndex+1
        }).then((res) => {
        });
        }
        this.trendTitle=this.trendTitle=='编辑'?'完成':'编辑'
      },
      //加入黑名单
      addBlack(){
        this.$refs.blackPage.show(this.infoDetail)
      }
    },
  };
</script>
<style lang="less" scoped>
  /deep/ .van-tabbar-item--active {
    color: #3975C6 !important;
  }

  /deep/ .van-tab {
    font-size: 16px;
    color: #fff;
  }

  /deep/ .van-tabs {
    margin-top: -4px;
  }

  /deep/ .van-tabs__nav--line {
    padding-bottom: 0px;
  }

  /deep/ .van-tabs__line {
    bottom: 1px;
  }

  /deep/ .van-nav-bar__left {
    font-size: 16px;
  }

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .duigong {
    padding: 10px 0;
    background: #ffffff;
    font-size: 13px;
    color: #4a4a4a;
  }

  .duiContent {
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
  }

  .van-nav-bar__right {
    div {
      color: #fff !important;
    }
  }

  .contact-page {
    font-size: 16px;

    .backBox {
      width: 100%;
      height: 124px;
      background: linear-gradient(180deg, #3974C7 0%, #E2EEFF 74%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top: 40px;
    }
  }

  .show-cont {
    position: relative;
    z-index: 19;
  }

  .show-customer {
    padding: 0 12px;
    height: calc(100vh - 150px);
    overflow-y: scroll;
  }

  .seek-row {
    background: #fff;
    padding: 14px 30px;
    .infoBtn{
      background: #3974C6;
      border-radius: 5px;
    }
    .blackBtn{
      font-weight: 400;
      font-size: 13px;
      color: #9B9B9B;
      width: 100%;
      text-align: center;
    }
  }

  .personal-tip {
    font-size: 16px;
    color: #666;
  }

  .employee-img {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    border-radius: 50%;
  }

  .drop-box {
    //position: absolute;
    //top: 10px;
    //right: 15px;
    z-index: 200;
  }

  .contact-page {
    background: #f3f4f8;
  }

  .load-box {
    width: 100%;
    position: relative;
  }

  .load-img {
    position: absolute;
    top: 40%;
    left: 50%;
  }

  .page_flex {
    display: flex;
    justify-content: space-between;
  }

  .personal_page {
    font-size: 14px;
    padding: 20px;
    background: #fff;
    color: #666;
    border-radius: 6px;
    margin-top: 14px;
  }

  .info_input {
    border: 0;
    background: #fff;
  }

  .info_name {
    color: #999;
  }

  .row_name {
    color: #333;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
  }

  .add_btn_style {
    height: 40px;
    width: 110px;
    font-size: 16px;
    color: #999;
    text-align: center;
    line-height: 43px;
    cursor: pointer;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-sizing: content-box;
  }

  .add_btn_style:hover {
    border: 1px dashed #e8e8e8;
  }

  .interaction_num {
    span {
      font-size: 16px;
      color: #2791ff;
    }
  }

  .show-list {}

  .show-list /deep/ .van-steps__items {
    padding-left: 50px;
  }

  .left-time {
    position: absolute;
    top: -15px;
    left: -125px;
    z-index: 100;
  }

  .trail-icon {
    position: absolute;
    top: 0;
    left: -30px;
    z-index: 100;
    background: #fff;
    width: 20px;
    height: 20px;
    border: 1px solid #42a4ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 10px;
      height: 10px;
    }
  }

  .show-list /deep/ .van-step__icon--active {
    //color: #42A4FF;
    color: #fff;
  }

  .show-list /deep/ .van-step__circle-container {
    font-size: 16px;
    //color: #42A4FF;
    color: #fff;
  }

  .show-list /deep/ .van-step__title {
    color: #333;
    margin-left: 5px;
  }

  .group_event {
    font-weight: bold;
  }

  .group_time {
    margin-top: 15px;
    color: #747474;
  }

  .option_row {
    width: 270px;
    height: 50px;
    border: 1px solid #cccccc;
    position: relative;
  }

  .option_row select {
    position: absolute;
    top: 0;
    /*清除select的边框样式*/
    border: none;
    /*清除select聚焦时候的边框颜色*/
    outline: none;
    /*将select的宽高等于div的宽高*/
    width: 100%;
    height: 50px;
  }

  .option_row option {
    height: 10px;
  }

  .show_user_data {
    width: 50%;
    margin-top: 5px;
  }

  /deep/ .van-field__label {
    color: #919191;
    width: 90px;
  }

  .picture-wrapper,
  .check-wrapper {
    align-items: center;
    margin-top: 16px;
    margin-left: 25px;
    display: flex;
    color: #919191;

    .title {
      width: 90px;
      font-size: 16px;
      display: inline-block;
    }

    .picture {
      width: 128px;
      height: 128px;
      margin-left: 20px;
    }

    .text {
      color: #cac8c8;
      font-size: 16px;
    }

    .check-group {
      .check-item {
        margin: 10px 0;
      }
    }
  }

  .tag_name {
    border: 1px solid #cce5ff;
    background: #f6faff;
    padding: 8px 17px;
    border-radius: 8px;
    margin-top: 10px;
    margin-left: 25px;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    position: relative;
    cursor: pointer;
    letter-spacing: 1px;
    color: #5c5c5c;
    box-sizing: content-box;
    display: flex;
    align-items: center;
  }

  .close-icon {
    display: none;
    font-size: 16px;
    margin-left: 5px;
    position: absolute;
    top: -13px;
    right: -15px;
    z-index: 100;
    color: #7e99b0;
  }

  .tag_name:hover .close-icon {
    display: block;
  }

  .Echarts {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .infoBox {
    position: relative;
    overflow: hidden;
  }

  .infoBox.has-triangle::before {
    content: "专属";
    position: absolute;
    top: -8px;
    right: -26px;
    z-index: 1;
    padding: 14px 22px 2px;
    background-color: #3974C6;
    transform: rotate(45deg);
    font-size: 12px;
    color: #FFFFFF;
  }

  .titleBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    .title {
      text-align: center;
      width: 98px;
      height: 30px;
      background: linear-gradient(#615BFC 0%, #97B3FF 100%);
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 30px;
      clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, calc(10% - 1px) 100%);
    }
  }

  .tipsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    height: 20px;
    line-height: 20px;

    .lineBox {
      width: 4px;
      height: 12px;
      background: linear-gradient(200deg, #8991FA 0%, #6770FF 100%);
      border-radius: 2px;
      margin-right: 8px;
    }

    .lineTitle {
      font-weight: 400;
      font-size: 13px;
      color: #515151;
    }

    .lineCon {
      font-weight: bold;
      font-size: 14px;
      color: #3B44ED;
    }
  }

  .evaluat {
    height: 20px;
    line-height: 20px;

    img {
      width: 19px;
      height: 15px;
    }

    span {
      margin-left: 5px;
      font-weight: 800;
      font-size: 14px;
      color: #000000;
    }
  }

  .evaluatCon {
    margin-top: 10px;
    width: 100%;
    background: #F5F5FF;
    border-radius: 4px;
    padding: 8px 10px;
    font-weight: 400;
    font-size: 13px;
    color: #515151;
  }

  .assetBox {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    color: #9B9B9B;
    text-align: center;
  }

  .assetMoney {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 25px;
    color: #000000;
  }

  .tipsBtn {
    width: 100%;
    padding: 12px;
    text-align: center;
    background: #EEF1F9;
    border-radius: 24px;
    font-weight: bold;
    font-size: 15px;
    color: #3974C6;
  }

  .trendHeard {
    display: flex;
    justify-content: space-between;

    .title {
      font-weight: 800;
      font-size: 16px;
      color: #000000;
    }

    .action {
      font-weight: 400;
      font-size: 14px;
      color: #9B9B9B;
    }
  }

  .container-box {
    width: 100%;

  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    position: relative;
    right: 10px;
  }

  .linetext {
    display: flex;
    flex-direction: row;
    writing-mode: vertical-rl;

    /* 从上到下，从右到左 */
    div {
      width: 40px;
      height: 76px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rowtext {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    justify-content: center;
    position: relative;
    right: 10px;
    margin-bottom: 30px;
    div {
      width: 76px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 230px;
  height: 230px;
  gap: 0; /* 确保网格项之间没有间隙 */
}

.grid-item {
  position: relative;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333;
  /* 移除直接指定的宽度和高度 */
  padding: 1px; /* 为内部内容留出空间，避免被边框覆盖 */
  box-sizing: border-box; /* 确保padding计算在宽度和高度内 */
}

.grid-item::before {
  content: "";
  position: absolute;
  inset: -1px; /* 使用inset替代具体的上下左右，以适应元素的实际尺寸 */
  border: 1px solid #CBCBFF;
  pointer-events: none;
}

.selectImg{
  position: absolute;
  width: 58px;
  height: 58px;
  top: -10px;
  left: 37%;
  z-index: 999;
}

  .active {
    background: radial-gradient( 0% 40% at 50% 44%, rgba(255,255,255,0) 50%, #D9DAFF 100%);
  }
  .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 72px;
  height: 72px;
  background: rgba(0,0,0,0.5);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* 文本颜色 */
  font-weight: bold;
}
</style>