<template>
    <div class="page">
        <van-tabs v-model="active" title-inactive-color="#4A4A4A" color="#3974C6" title-active-color="#3974C6">
            <van-tab title="整村授信进件">
                <formPage @change="handleTabChange"></formPage>
            </van-tab>
            <van-tab title="历史进件">
                <historyForm ref="listPage"></historyForm>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
    import formPage from "./components/formPage.vue";
    import historyForm from "./components/historyForm.vue";
    export default {
        components: { formPage, historyForm },
        data() {
            return {
                active: 0,
            }
        },
        methods: {
            handleTabChange() {
                this.active=1
                this.$refs.listPage.getList()
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-tabs__nav--line {
        line-height: 48px;
        padding-bottom: 0;
    }

    /deep/ .van-tabs__line {
        bottom: 0;
    }

    .page {
        background: #F2F3F7;
        width: 100%;
        min-height: 100vh;
    }
</style>