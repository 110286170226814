import request from "../plugins/axios";

export function inspectionIndex(params) {
  return request({
    url: '/merchantInspection/index',
    method: 'get',
    params: params
  })
}
//巡检提交
export function inspectionSubmit(params) {
  return request({
    url: '/merchantInspection/submit',
    method: 'post',
    data: params
  })
}
//签名提交
export function merchantImgSig(params) {
  return request({
    url: '/merchantInspection/merchantImgSig',
    method: 'post',
    data: params
  })
}
//base64转换
export function uploadBase64Image(params) {
  return request({
    url: '/merchantInspection/uploadBase64Image',
    method: 'post',
    data: params
  })
}
//巡检详情
export function inspDetail(params) {
  return request({
    url: '/merchantInspection/woShow',
    method: 'get',
    params: params
  })
}
//商户详情
export function merchantDetail(params) {
  return request({
    url: '/merchant/show',
    method: 'get',
    params: params
  })
}
//转接
export function transfer(params) {
  return request({
    url: '/merchantInspection/transfer',
    method: 'get',
    params: params
  })
}
//商户列表
export function merchantList(params) {
  return request({
    url: '/merchant/record',
    method: 'get',
    params: params
  })
}
//历史巡检
export function woHistory(params) {
  return request({
    url: '/merchantInspection/woHistory',
    method: 'get',
    params: params
  })
}
//编辑商户
export function merchantEdit(params) {
  return request({
    url: '/merchant/edit',
    method: 'post',
    data: params
  })
}
//获取位置
export function setLocation(params) {
  return request({
    url: '/merchant/setLongitudeLatitude',
    method: 'post',
    data: params
  })
}
//巡检统计
export function getAnalysis(params) {
  return request({
    url: '/merchantInspection/analysis',
    method: 'get',
    params: params
  })
}
//任务列表
export function taskRecord(params) {
  return request({
    url: '/merchantInspection/taskRecord',
    method: 'get',
    params: params
  })
}









