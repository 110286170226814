<template>
  <div class="all">
    <van-form @submit="onSubmit">
        <div class="avatar">
          <span><i style="color: red">*</i>头像</span>
          <van-uploader
            v-model="upfileList"
            multiple
            :max-count="1"
            :after-read="afterRead">
            </van-uploader>
        </div>
      <van-field
        v-model="username"
        required
        name="员工名称"
        label="员工名称"
        readonly
        :rules="[{ required: true, message: '请填写员工名称' }]"
      />
      <van-field
        v-model="department"
        required
        name="部门"
        label="部门"
        readonly
        :rules="[{ required: true, message: '请填写部门名称' }]"
      />
      <van-field
        v-model="position"
        name="职位"
        label="职位"
        readonly
      />
      <van-field
        v-model="phoneNum"
        required
        name="手机号"
        label="手机号"
        readonly
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <van-field
        v-model="intruction"
        rows="2"
        autosize
        label="个人简介"
        type="textarea"
        placeholder="请输入个人简介"
        show-word-limit
      />
      <van-field name="资质证书" label="资质证书" placeholder="请输入资质名称">
        <template #input>
          <div v-if="certificate.length > 0">
            <div class="item flex" v-for="(item, i) in certificate" :key="i" style="margin-top:4px">
              <div class="input" style="display:inline-block">
                <a-input
                  placeholder="请输入证书名称"
                  v-model="certificate[i]"
                />
              </div>
              <div class="icons" style="display:inline-block">
                <img src="../../assets/images/electronicCard/reduce.png" @click="del(i)" alt="">
                <img src="../../assets/images/electronicCard/plus.png" @click="add(i)" alt="">
              </div>
            </div>
          </div>
          <div v-else>
            <a @click="add">
              <a-icon type="plus-circle" />
              添加证书
            </a>
          </div>
        </template>
      </van-field>
      <van-field
        v-model="motto"
        rows="2"
        autosize
        label="服务格言"
        type="textarea"
        placeholder="请输入服务格言"
        show-word-limit
      />
      <van-field
        v-model="status"
        name="状态"
        label="状态"
        readonly
      />
      <div style="margin: 16px; margin-top: 100px">
        <van-button round block type="info" native-type="submit"
          >保存信息</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { upload } from "../../api/contactWelcome";
import { getVcardData, editVcard } from "@/api/electronicCard";

export default {
  data() {
    return {
      upfileList: [],
      username: "",
      department: "",
      position: "",
      phoneNum: "",
      intruction: "",
      certificate: [],
      motto: "",
      uploader: "",
      status:''
    };
  },
  created(){
    this.getData()
  },
  methods: {
    getData(){
      getVcardData().then(res=>{
        console.log('res,,',res)
        this.upfileList[0] = {}    
        this.upfileList[0].isImage=true
        this.upfileList[0].path = res.data.image;
        this.upfileList[0].url = res.data.fullImage
        console.log(' this.fileList[0]344', this.upfileList[0].url)
        this.username = res.data.name
        this.department = res.data.departmentName
        this.position = res.data.position
        this.phoneNum = res.data.mobile
        this.intruction = res.data.introduction
        this.certificate = res.data.certificates
        this.motto = res.data.serviceMotto
        this.status = res.data.auditStatus
      })
    },

    onSubmit() {
      console.log('提交')
      const data = {
        image: this.upfileList[0].path,
        introduction: this.intruction,
        certificates: this.certificate,
        serviceMotto: this.motto
      }
      console.log('data',data)
      editVcard( data ).then(res=>{
        console.log('res',res)
        this.$toast('保存成功')
        this.$router.push('/electronicCard/index')
      })
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        // this.items.title = res.data.name
        this.upfileList[0] = {
          url: res.data.fullPath,
          path: res.data.path,
        };
        console.log('fileList',this.upfileList[0])
      });
    },
    del(i) {
      if (this.certificate.length === 1) {
        this.$message.error("不能删除最后一个哦");
        return false;
      }
      // this.form.certificate.push(this.form.tags[i])
      this.certificate.splice(i, 1);
    },
    add() {
      this.certificate.push("");
    },
  },
};
</script>
<style lang="less" scoped>
.icons{
  img{
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
  
}
.avatar{
  display: flex;
  justify-content: space-between;
  width:96%;
  margin:0 auto;
  padding-top: 10px;
  span{
    color: #646566;
    font-size:13px !important
  }
}
</style>
  