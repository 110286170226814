<template>
    <div class="page">
        <div class="herad">
            你还有{{addRecord.count}}位客户待处理，快去复制电话号码添加客户吧，管理员在后台可查看添加状态哦～
        </div>
        <div class="title">共{{addRecord.count}}个客户</div>
        <div class="tableBox">
            <table class="table">
                <thead>
                    <tr>
                        <th>客户电话</th>
                        <th>添加状态</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in addRecord.list" :key="item.id">
                        <td>
                            <img @click="editInfo(item)" class="editIcon" src="../../assets/editIcon.png" />
                            <span>{{ item.phone }}</span>
                        </td>
                        <td>
                            <span v-if="item.status==0">待分配</span>
                            <span v-if="item.status==1">待添加</span>
                            <span v-if="item.status==2">待通过</span>
                            <span v-if="item.status==3">已添加</span>
                        </td>
                        <td>
                            <div class="btnBox">
                                <div class="copy" @click="copyBtn(item)">复制</div>
                                <div class="abnormal" @click="abnormalBtn(item)">异常</div>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 编辑弹窗 -->
        <van-dialog v-model="showEdit" title="客户信息修改" show-cancel-button confirmButtonText="确认修改"
            confirmButtonColor="#267EF0" @confirm="confirmEdit">
            <div class="editPopup">
                <van-cell-group inset>
                    <van-field v-model="phoneNum" label="电话号码" placeholder="后台设置的号码" />
                </van-cell-group>
                <van-cell-group inset>
                    <van-field v-model="remark" label="客户备注" placeholder="后台设置的备注" />
                </van-cell-group>
            </div>
        </van-dialog>
        <!-- 异常弹窗 -->
        <van-dialog v-model="showDanger" show-cancel-button confirmButtonColor="#267EF0" confirmButtonText="确定"
            @confirm="handleRemark">
            <div class="tips">
                确定将此客户标注为异常吗？标注后管理员可将本客户信息取消。
            </div>
        </van-dialog>
    </div>
</template>
<script>
    import { notAddRecord, updateAbnormal, edit } from "@/api/contactBatchAdd";
    import { navigateToAddCustomer, setClipboardData } from "@/utils/wxCodeAuth";
    export default {
        data() {
            return {
                addRecord: {},
                showDanger: false,
                editId: '',
                showEdit: false,
                phoneNum: '',
                remark: '',
            };
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                notAddRecord().then((res) => {
                    if (res.code == 200) {
                        this.addRecord = res.data
                    }
                })
            },
            //异常按钮
            abnormalBtn(item) {
                this.showDanger = true;
                this.editId = item.id
            },
            //标注为异常
            handleRemark() {
                updateAbnormal({ id: this.editId }).then(res => {
                    this.$toast('操作成功')
                    this.getData();
                })
            },
            // 复制按钮
            async copyBtn(item) {
                this.$toast("复制成功");
                setTimeout(() => {
                    this.navigateToAddContact();
                }, 500);
                await setClipboardData(item.phone);
            },
            async navigateToAddContact(data) {
                await navigateToAddCustomer();
            },
            editInfo(item) {
                this.showEdit = true
                this.editId = item.id
            },
            //确认修改
            confirmEdit() {
                edit({ id: this.editId, phone: this.phoneNum, remark: this.remark }).then(res => {
                    this.$toast('修改成功')
                    this.getData();
                })
            },

        },
    };
</script>
<style lang="less" scoped>
    /deep/ .van-dialog__content{
        padding: 20px 10px;
    }
    .page {
        padding: 12px;

        .herad {
            width: 100%;
            padding: 14px;
            background: #FEF7EF;
            border-radius: 5px;
            font-weight: 400;
            font-size: 14px;
            color: #CBAC8B;
        }

        .title {
            margin-top: 25px;
            font-weight: bold;
            font-size: 15px;
            color: #333333;
        }

        .tableBox {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            /* 表格样式 */
            .table {
                border-collapse: collapse;
                width: 100%;
            }

            /* 表格边框样式 */
            .table td,
            .table th {
                padding: 8px;
                font-size: 14px;
                font-weight: 400;
                color: #4A4A4A;
                text-align: center;
                background-color: #FFFFFF;
                width: calc(100%/3);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            /* 表头样式 */
            .table th {
                background-color: #FFFFFF;
                text-align: center;
                font-weight: bold;
                font-size: 15px;
                color: #333333;
            }

            .editIcon {
                width: 10px;
                height: 10px;
            }

            .btnBox {
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                .copy {
                    min-width: 54px;
                    height: 24px;
                    line-height: 24px;
                    background: #7CADFE;
                    border-radius: 12px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #FFFFFF;
                }

                .abnormal {
                    min-width: 54px;
                    height: 24px;
                    line-height: 24px;
                    background: #D81E06;
                    border-radius: 12px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #FFFFFF;
                }
            }

        }
    }
</style>