<template>
    <div class="page">
        <van-popup v-model="show" round  position="bottom" :style="{ height: '50%' }">
            <van-datetime-picker
                v-model="currentDate"
                type="datetime"
                @cancel="show = false"
                @confirm="choseDate"
            />
          </van-popup>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        show: false,
        minDate: new Date("Jul 12 2011"),
        startT: "",
        endT: "",
        currentDate: new Date(),
        nextDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        type:''
      };
    },
    methods: {
      $show(type) {
        this.type=type
        this.show = true;
        // this.startT = this.formatter(this.currentDate);
        // this.endT = this.formatter(this.nextDate);
      },
    formatter(date) {
        const year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let hour = date.getHours();
                let minutes = date.getMinutes();
                if (month < 10) {
                    month = "0" + month;
                }
                if (day < 10) {
                    day = "0" + day;
                }
                if (hour < 10) {
                    hour = "0" + hour;
                }
                if (minutes < 10) {
                    minutes = "0" + minutes;
                }
                let second = '00'
                this.endTime = `${year}-${month}-${day} 23:59:59`
                return `${year}-${month}-${day} ${hour}:${minutes}:${second}`;
    },
    choseDate(e) {
        let time=this.formatter(this.currentDate);
        this.show = false
        let params={
            time:time,
            type:this.type
        }
        this.$emit('change',params)
    }
    },
  };
  </script>
  <style lang="less" scoped>
  /deep/ .van-calendar__bottom-info {
    font-size: 0;
  }
  /deep/ .van-calendar__footer {
    padding: 10px 3%;
  }
  /deep/ .van-calendar__day--start,
  /deep/ .van-calendar__day--end {
    border-radius: 50%;
  }
  /deep/ .van-calendar__day {
    height: 40px;
    width: 40px;
  }
  .page{
    /* width: 100%;
    height: 500px;
    position: relative;
    z-index: 999; */
  }
  .footer {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .m16 {
      font-size: 14px;
      color: #267ef0;
    }
    .m17 {
      font-size: 12px;
      padding: 0 10px;
      color: #595a5e;
    }
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;

      .reset {
        text-align: center;
        width: 55px;
        height: 31px;
        line-height: 31px;
        background: #f1f2f6;
        border-radius: 5px;
        border: 1px solid #d7d7d9;
        font-size: 14px;
        font-weight: 500;
        color: #595a5e;
        margin-right: 10px;
      }
      .confirm {
        text-align: center;
        width: 55px;
        height: 31px;
        line-height: 31px;
        background: #3974c6;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  </style>