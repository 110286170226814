<template>
  <div class="all">
    <div class="comment">
      <div class="m16">财经点评</div>
      <van-field
        v-model="message"
        rows="6"
        autosize
        type="textarea"
        maxlength="500"
        placeholder="点评内容"
        show-word-limit
      />
    </div>
    <div class="recommend">
      <div class="m16">
        推荐产品（最多5个）
        <img
          src="../../assets/images/electronicCard/plus.png"
          alt=""
          style="width: 17px; height: 17px"
          @click="add"
        />
      </div>
      <!-- <div class="rec" v-for="(item, index) in fliterList" :key="index"> -->
      <div class="left">
        <van-dropdown-menu
          active-color="#3974C6"
          style="width: 100%"
          v-show="num > 1"
        >
          <van-dropdown-item
            v-model="value2"
            :options="option1"
            @change="selectClassify1"
          />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="margin: 10px 0; width: 100%"
          v-show="num > 1"
        >
          <van-dropdown-item v-model="value3" :options="value3_1" />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="width: 100%"
          v-show="num > 2"
        >
          <van-dropdown-item
            v-model="value4"
            :options="option1"
            @change="selectClassify2"
          />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="margin: 10px 0; width: 100%"
          v-show="num > 2"
        >
          <van-dropdown-item v-model="value5" :options="value5_1" />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="width: 100%"
          v-show="num > 3"
        >
          <van-dropdown-item
            v-model="value6"
            :options="option1"
            @change="selectClassify3"
          />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="margin: 10px 0; width: 100%"
          v-show="num > 3"
        >
          <van-dropdown-item v-model="value7" :options="value7_1" />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="width: 100%"
          v-show="num > 4"
        >
          <van-dropdown-item
            v-model="value8"
            :options="option1"
            @change="selectClassify4"
          />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="margin: 10px 0; width: 100%"
          v-show="num > 4"
        >
          <van-dropdown-item v-model="value9" :options="value9_1" />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="width: 100%"
          v-show="num > 5"
        >
          <van-dropdown-item
            v-model="value10"
            :options="option1"
            @change="selectClassify5"
          />
        </van-dropdown-menu>
        <van-dropdown-menu
          active-color="#3974C6"
          style="margin: 10px 0; width: 100%"
          v-show="num > 5"
        >
          <van-dropdown-item v-model="value11" :options="value11_1" />
        </van-dropdown-menu>
      </div>
      <div class="right" v-show="num > 1" style="text-align: right">
        <img
          src="../../assets/images/electronicCard/jian.png"
          alt=""
          style="width: 17px; height: 17px"
          @click="del(index)"
        />
      </div>
      <!-- </div> -->
    </div>
    <div style="width: 100%; height: 46px"></div>
    <div class="last" @click="confirm">确定</div>
  </div>
</template>
    <script>
import {
  jingRongClassify,
  productList,
  setComment,
  getComment,
} from "@/api/electronicCard";

export default {
  data() {
    return {
      message: "",
      option1: [],
      option2: [],
      value1: 0,
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      value3_1: [],
      value5_1: [],
      value7_1: [],
      value9_1: [],
      value11_1: [],
      productId: "",
      num: 1,
    };
  },
  created() {
    this.productId = this.$route.query.id;
    this.getClassify();
    // this.getData();
    this.getmyComment();
  },
  methods: {
    //获取点评
    getmyComment() {
      getComment({ id: this.productId }).then((res) => {
        console.log("dianping", res);
        this.message = res.data.comment;
        // const {data} =res
        // switch (data.recommended_product.length){
        //   case 1: this.value2 = data.recommended_product[0].categoryId
        //   this.value3 =data.recommended_product[0].id
        //   this.num=2
        //   break;
        //   case 2: this.value2 = data.recommended_product[0].categoryId
        //   this.value3 =data.recommended_product[0].id
        //   this.value4 = data.recommended_product[1].categoryId
        //   this.value5 =data.recommended_product[1].id
        //   this.num=3
        //   break;
        // }
      });
    },
    selectClassify1(val) {
      console.log(val);
      this.value1 = val;
      this.getData(1);
      this.xiabiao += 1;
    },
    selectClassify2(val) {
      console.log(val);
      this.value1 = val;
      this.getData(2);
    },
    selectClassify3(val) {
      console.log(val);
      this.value1 = val;
      this.getData(3);
      this.xiabiao += 1;
    },
    selectClassify4(val) {
      console.log(val);
      this.value1 = val;
      this.getData(4);
    },
    selectClassify5(val) {
      console.log(val);
      this.value1 = val;
      this.getData(5);
    },
    //get二级分类
    getData(index) {
      let arr = [];
      productList({ categoryId: this.value1 }).then((res) => {
        console.log("产品列表", res);
        arr = res.data.list.map((res) => {
          return {
            text: res.name,
            value: res.id,
          };
        });
        if (index == 1) {
          this.value3_1 = arr;
        }
        if (index == 2) {
          this.value5_1 = arr;
        }
        if (index == 3) {
          this.value7_1 = arr;
        }
        if (index == 4) {
          this.value9_1 = arr;
        }
        if (index == 5) {
          this.value11_1 = arr;
        }
      });
    },
    //get一级分类
    getClassify() {
      jingRongClassify().then((res) => {
        console.log("fenlei", res.data);
        res.data.unshift({ name: "全部产品", id: 0 });
        this.option1 = res.data.map((res) => {
          return {
            text: res.name,
            value: res.id,
          };
        });
        console.log("this option", this.option1);
      });
    },
    add() {
      this.num++;
    },
    del() {
      this.num--;
    },
    confirm() {
      let brr = [];
      console.log("this.num", this.num, this.value2,this.value4);
      switch (this.num) {
        case 2:
           brr.push(this.value3);
          break;
          case 3:
           brr.push(this.value3,this.value5);
          break;
          case 4:
           brr.push(this.value3,this.value5,this.value7);
          break;
          case 5:
           brr.push(this.value3,this.value5,this.value7,this.value9);
          break;
          case 6:
           brr.push(this.value3,this.value5,this.value7,this.value9,this.value11);
          break;
      }

      console.log("brr", brr);
      setComment({
        id: this.productId,
        comment: this.message,
        productIds: brr,
      }).then((res) => {
        if (res.code == 200) {
          this.$toast("设置成功");
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>
    <style lang="less" scoped>
/deep/ .van-dropdown-item {
  top: 0 !important;
}
/deep/ .van-popup--top {
  top: 0 !important;
}
/deep/ .van-dropdown-item__content{
  top: 0 !important;
}
/deep/ .van-popup{
  top: 0 !important;
}
.all {
  height: 100%;
  background-color: #f2f3f7;
  overflow: auto;
}
.comment,
.recommend,
.last {
  width: 94%;
  margin: 0 auto;
}
.m16 {
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  margin: 12px 0;
}
.recommend {
}
.last {
  position: fixed;
  bottom: 10px;
  left: 3%;
  width: 94%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #3974c6;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}
.rec {
  display: flex;
  justify-content: space-between;
  position: relative;
  .left {
    width: 90%;
  }
  .right {
    position: absolute;
    right: 0;
    top: 36px;
    width: 10%;
  }
}
</style>