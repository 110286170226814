<template>
  <div class="all">
    <div class="banxin">
      <div class="comment">
        <div class="top">
          <div class="m16">早报点评:</div>
          <div class="clear" @click="clear">清空点评</div>
        </div>
        <div class="content">
          <van-field
            v-model="message"
            rows="8"
            autosize
            type="textarea"
            placeholder="请输入点评"
            show-word-limit
          />
        </div>
      </div>
      <div class="poster">
        <div class="m16" style="margin-bottom: 10px">背景海报:</div>
        <div>
          <van-uploader
            :after-read="afterRead"
            v-model="poster"
            multiple
            :max-count="1"
          />
        </div>
      </div>
      <van-cell center title="早报模版消息推送：">
        <template #right-icon>
          <van-switch v-model="isPush" size="24" />
        </template>
      </van-cell>
    </div>
    <div style="padding: 50px 0; width: 100%"></div>
    <div class="lastBtn">
      <div class="last" @click="save">保存设置</div>
    </div>
  </div>
</template>
    <script>
import {
  morningPaperCommentSet,
  morningPaperComment,
} from "@/api/morningPaper";
import { upload } from "../../api/contactWelcome";

export default {
  data() {
    return {
      message: "",
      poster: [],
      isPush: false,
    };
  },
  created() {
    this.getCommentData();
  },
  methods: {
    // 上传文件
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      var formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        file.status = "done";
        this.poster[0] = {
          url: res.data.fullPath,
          path: res.data.path,
        };
      });
    },
    clear() {
      this.message = "";
    },
    save() {
      console.log(111);
      //  if(this.poster[0]==undefined){
      //   this.$toast('请上传海报')
      //  }
      morningPaperCommentSet({
        poster: this.poster[0] == undefined ? "" : this.poster[0].path,
        morningComment: this.message,
        morningPushMessage: this.isPush == true ? 1 : 0,
      }).then((res) => {
        if (res.code == 200) {
          this.$router.go(-1);
          this.$toast("设置成功");
        }
      });
    },
    getCommentData() {
      morningPaperComment().then((res) => {
        this.message = res.data.morning_comment;
        this.isPush = res.data.morning_push_message == 0 ? false : true;
        this.poster[0] = {};
        this.poster[0].path = res.data.poster;
        this.poster[0].url = res.data.fullPoster;
      });
    },
  },
};
</script>
    <style lang="less" scoped>
/deep/ .van-switch {
  width: 50px;
  height: 26px;
}
/deep/ .van-cell__title {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  margin-left: -3px;
}
.all {
  height: 100%;
  background-color: #f2f3f7;
}
.banxin {
  margin: 0 auto;
  margin-top: 11px;
  /* height: 461px; */
  width: 94%;
  background-color: #fff;
}
.m16 {
  font-size: 14px;
  font-weight: bold;
  color: #999999;
}
.comment {
  overflow: hidden;
  .top {
    width: 94%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .clear {
    font-size: 13px;
    color: #3974c7;
  }
  /deep/ .van-field__body {
    background-color: #f5f5f5;
  }
  /deep/ .van-cell {
    padding: 10px;
  }
  /deep/ .van-field__control {
    text-indent: 6px;
  }
}
.poster {
  width: 94%;
  margin: 0 auto;

  img {
    margin-top: 10px;
    width: 100%;
    height: 133px;
    background: rgba(0, 0, 0, 0.32);
    border-radius: 6px;
  }
}
.lastBtn {
  width: 100%;
  position: fixed;
  height: 7%;
  bottom: 0px;
  left: 0;
  padding: 10px 10px;
  background-color: #ffffff;
  .last {
    margin: 0 auto;
    width: 94%;
    height: 42px;
    line-height: 42px;
    text-align: center;
    background: #3974c6;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>