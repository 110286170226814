import request from "../plugins/axios";

//获取内容
export function getDetail(params) {
    return request({
        url: '/helpCenterCategory/getDetail',
        method: 'GET',
        params
    })
}
//获取分类
export function getCategory(params) {
    return request({
        url: '/helpCenterCategory/getCategory',
        method: 'GET',
        params
    })
}