<template>
  <div class="component">
    <van-popup v-model="show" round position="bottom" :style="{ height: '50%' }">
      <!-- <van-datetime-picker
          v-model="currentDate"
          type="time"
          :title="showTime"
          :min-minute="minute"
          :min-hour="hour"
          @cancel="show = false"
          @confirm="choseDate"
      /> -->
      <van-datetime-picker v-model="currentDate" type="date" :title="showTime" @cancel="show = false"
        @confirm="choseDate" />
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        //   date: "",
        currentDate: new Date(),
        index: "",
        // showtime
        showTime: "自定义上线日期",
        minute: null,
        hour: null,
        begin: "",
        currenTime: '',
      };
    },
    watch: {},
    methods: {
      $show(type) {
        this.currenTime = type;
        this.show = true;
        // this.index = i;
        // this.name = e;
        // this.minute = null;
        // this.hour = null;
        this.showTime = "自定义上线日期";
      },
      choseDate(e) {
        // if (typeof this.minute != "number") {
        //   this.begin = e;
        //   this.showTime = "自定义下线日期";
        // } else {
        const dateTime = {
          type: this.currenTime,
          time: this.timeFormat(this.currentDate)
        };
        this.show = false;
        this.$emit("change", dateTime);
        // }
      },
      timeFormat(time) { // 时间格式化 2019-09-08
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let day = time.getDate();
        return year + '-' + month + '-' + day

      },

    },
  };
</script>

<style lang="less" scoped>
  /deep/.van-action-sheet__header {
    font-weight: bolder;
  }

  .component {}
</style>