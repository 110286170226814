<template>
  <div class="qaRecord">
    <div class="top">
      <div class="m16">
        <div class="topLeft" @click="show = true">
          <div v-if="showStartTime == false" class="startTime">
            提问开始时间
          </div>
          <div v-else class="startTime">{{ startTime }}</div>
          <img class="" src="../../assets/images/xiaoban/xiala.png" alt="" />
        </div>
        <div class="topRight" @click="show2 = true">
          <div v-if="showEndTime == false" class="endTime">提问结束时间</div>
          <div v-else class="endTime">{{ endTime }}</div>
          <img src="../../assets/images/xiaoban/xiala.png" alt="" />
        </div>
      </div>
      <van-popup v-model="show" position="bottom" :style="{ height: '30%' }"
        ><van-datetime-picker
          v-model="currentDate"
          type="date"
          @cancel="show = false"
          @confirm="handleConfirm"
        />
      </van-popup>
      <van-popup v-model="show2" position="bottom" :style="{ height: '30%' }"
        ><van-datetime-picker
          v-model="currentDate2"
          @cancel="show2 = false"
          type="date"
          @confirm="handleConfirm2"
        />
      </van-popup>
    </div>
    <div class="main">
      <div v-if="qaList.length > 0">
        <van-list
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
>
        <div class="content" v-for="(item, index) in qaList" :key="index">
          <div class="time">{{ item.createdAt }}</div>
          <div class="q">
            <div class="qleft">Q :</div>
            <div class="qright">
              {{ item.question }}
            </div>
          </div>
          <div class="a">
            <div class="aleft">A :</div>
            <div class="aright">
              {{ item.answer }}
            </div>
          </div>
          <div class="copy" @click="copy(item.answer)">复制</div>
        </div>
        </van-list>
      </div>
      <div v-else>
        <van-empty description="暂无数据" />
      </div>
    </div>
  </div>
</template>
<script>
import { qaRecord } from "@/api/xiaoban";

export default {
  data() {
    return {
      show: false,
      currentDate: new Date(),
      show2: false,
      currentDate2: new Date(),
      showStartTime: false,
      showEndTime: false,
      qaList: [],
      startTime: "",
      endTime: "",
      loading: false,
      finished: false,
      page:1
    };
  },
  created() {
    this.getQaRecord();
  },
  methods: {
    getQaRecord() {
      qaRecord({ startTime: this.startTime, endTime: this.endTime,page:this.page,perPage:5 }).then(
        (res) => {        
          this.qaList.push(...res.data.list)
          this.loading= false
          if(this.qaList.length>=res.data.page.total){
            this.finished = true
          }
        }
      );
    },
    onLoad(){
        this.page+=1
        this.getQaRecord()
    },
    handleConfirm(val) {
      this.startTime = this.timeFormat(val);
      this.showStartTime = true;
      this.show = false;
      if (this.startTime != "" && this.endTime != "") {
        this.qaList =[]
        this.page = 1
        this.getQaRecord();
      }
    },
    handleConfirm2(val) {
      this.endTime = this.timeFormat(val);
      this.showEndTime = true;
      this.show2 = false;
      if (this.startTime != "" && this.endTime != "") {
        this.qaList =[]
        this.page = 1
        this.getQaRecord();
      }
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "-" + month + "-" + day;
    },
    copy(content) {
      let oInput = document.createElement('textarea')
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success("复制成功");
      oInput.remove();
    },
  },
};
</script>
<style lang="less" scoped>
.qaRecord {
  height: 100%;
  width: 100%;
  background: #f2f3f7;
}
.top {
  width: 100%;
  height: 58px;
  background: #ffffff;
  .m16 {
    width: 92%;
    height: 58px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .topLeft,
  .topRight {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 155px;
    height: 28px;
    line-height: 28px;
    background: #f1f2f6;
    border-radius: 14px;
    border: 1px solid #d7d7d9;
    font-size: 14px;
    color: #000000;
    img {
      width: 6px;
      height: 6px;
    }
  }
}
.main {
  width: 94%;
  height: 90%;
  overflow: auto;
  margin: 0 auto;
  margin-top: 12px;
  background: #ffffff;
  border-radius: 6px;
  .content {
    width: 94%;
    min-height: 100px;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #e7f3ff;
    border-radius: 5px;
    overflow: hidden;
    .time {
      width: 100%;
      text-align: center;
      margin: 13px 0;
      font-size: 12px;
      color: #999999;
    }
    .q {
      width: 96%;
      margin: 0 auto;
      margin-bottom: 13px;
      display: flex;
      justify-content: space-between;
      .qleft,
      .qright {
        font-size: 14px;
        font-weight: 500;
        color: #3975c6;
        line-height: 20px;
      }
      .qleft {
        text-align: center;
        width: 26px;
      }
      .qright {
        width: 96%;
      }
    }
    .a {
      width: 96%;
      margin: 0 auto;
      border-top: 1px dashed #bbb;
      display: flex;
      justify-content: space-between;
      .aleft,
      .aright {
        margin-top: 8px;
        white-space: pre-wrap;
        font-size: 12px;
        font-weight: 500;
        color: #888888;
        line-height: 18px;
      }
      .aleft {
        text-align: center;
        width: 26px;
      }
      .aright {
        width: 96%;
      }
    }
    .copy {
      width: 96%;
      margin-bottom: 10px;
      font-size: 12px;
      color: #3975c6;
      text-align: right;
    }
  }
}
</style>