<template>
    <div class="page">
        <div class="benner"></div>
        <van-loading size="24px" type="spinner" color="#1989fa" vertical v-if="pageLoad">加载数据中...</van-loading>
        <div v-else>
            <van-empty description="暂无数据" v-if="styleData.length==0" />
            <div class="contentBox" v-else>
                <div class="content_title">选择样式</div>
                <div class="scollBox">
                    <div class="itemBox" v-for="(item,index) of styleData" :key="index"
                        :class="styleAtive==index?'active':''" @click="selectImg(item,index)">
                        <div class="name">{{item.styleName}}</div>
                        <div class="box">
                            <img :src="item.styleImg" alt="">
                        </div>
                    </div>
                </div>
                <div class="content_title">选择文案风格</div>
                <div class="tagBox" v-if="styleData.length!=0">
                    <div class="tag" v-for="(item,index) of styleData[styleAtive].list" :key="index"
                        :class="chelkAtive==index?'tagCheck':''" @click="tagBtn(index)">{{item.name}}</div>
                </div>
                <div id="shareNode" ref="shareImg">
                    <div class="setBox"
                        :style="{'background-image': 'url(' + testSrc + ')','background-repeat':'no-repeat','background-size':'100% 100%' }">
                        <div class="textA" :style="setStyle()">{{loaction.text}}</div>
                    </div>
                    <div class="manageBox" v-if="posterCreate">
                        <div class="infoBox">
                            <div class="infoLeft">
                                <img :src="userInfo.avatar" alt="">
                                <div class="infoDetail">
                                    <div class="title">{{ userInfo.user_name }}</div>
                                    <div class="minfo">{{ userInfo.bank_name }}</div>
                                    <div class="minfo">{{ userInfo.department_name }}</div>
                                    <div class="minfo">{{ userInfo.mobile }}</div>
                                </div>
                            </div>
                            <div class="inforight">
                                <img class="qrcode" :src="userInfo.QRcode" alt="">
                            </div>
                        </div>
                        <div class="infoTips">添加我的企业微信，为您提供专业的服务。</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 编辑弹窗 -->
        <van-overlay :show="showEdit">
            <div class="btn">
                <div class="cencal" @click="showEdit=false">取消</div>
                <div class="submit" @click="save">确定</div>
            </div>
            <div class="overBox">
                <div class="inputBox">
                    <van-field v-model="text" rows="1" autosize type="textarea" placeholder="请输入内容" />
                </div>
                <div class="tips">已输入{{text.length}}个字</div>
            </div>
        </van-overlay>
        <!-- 下载海报弹窗 -->
        <van-overlay :show="showDown">
            <!-- <div v-if="posterImg==''" class="downBox">
                <van-loading type="spinner" color="#1989fa">海报生成中...</van-loading>
            </div> -->
            <div class="downBox">
                <img :src="posterImg" alt="" class="poster">
                <div class="tips1">长按图片保存到相册</div>
                <!-- <div class="tips2">（如果分享文案将自动复制，可长按粘贴）</div> -->
                <!-- <div class="shareTitle">
                    <img src="../../../assets/images/festival/tips.png" alt="">
                    <span>分享小提示</span>
                </div>
                <div class="shareTips">1、可长按海报直接分享至微信/企业微信聊天框</div>
                <div class="shareTips">2、保存海报至手机相册，配合文案进行微信朋友圈营销</div> -->
            </div>
            <div class="imgBox">
                <img src="../../../assets/images/festival/close.png" alt="" @click="showDownClose">
            </div>
        </van-overlay>
        <!-- 分享底部弹出 -->
        <van-popup v-model="matePop" position="bottom" round closeable :style="{ height: '25%'}">
            <div class="shareBox">
                <div class="popTitle">请选择分享方式</div>
                <div class="popTips">为了方便您的分享行为数据统计，建议您使用以下的分享方式。</div>
                <div class="btnIconBox">
                    <div class="itemList" v-for="(item,index) of iconData" :key="index" @click="shareFun(item)">
                        <div class="iconBox">
                            <img :src="item.img" alt="" :style="{width:item.width,height:item.height}">
                        </div>
                        <div class="iconTitle">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </van-popup>
        <!-- 海报生成弹出层 -->
        <van-popup v-model="posterCreate" :close-on-click-overlay="false" class="createBox">
            <div class="createLoad">
                <van-loading size="24px" type="spinner" color="#1989fa" vertical>海报生成中...</van-loading>
            </div>
        </van-popup>
        <!-- 底部操作按钮 -->
        <div class="operate" v-if="!pageLoad&&styleData.length!=0&&!saveBtnShow">
            <div class="editBtn" @click="editSubmit">
                <img src="../../../assets/images/festival/edit.png" alt="">
                <span>编辑</span>
            </div>
            <div class="shareBtn" v-if="showType" style="width: 100%;" @click="sharePerson">分享给{{showName}}</div>
            <div class="shareBtn" @click="matePop=true" v-if="!showType">分享给客户</div>
            <div class="downBtn" @click="dowmPoster" v-if="!showType">下载海报</div>
        </div>
        <div class="operate" v-if="saveBtnShow">
            <div class="cancelBtn" @click="cancelBtn">取消</div>
            <div class="saveBtn" @click="saveSubmit">立即使用</div>
        </div>
    </div>
</template>
<script>
    import '@/assets/font/font.css'
    import { posterDetail, addLog } from "@/api/festival";
    import { shareToExternalContact, shareToExternalMoments, forward, shareMyData, selectExternalContact, shareAppMessage } from "@/utils/wxCodeAuth";
    import { posterApi } from "@/api/poster";
    import { vcardIndex } from "@/api/electronicCard";
    import html2canvas from 'html2canvas';
    export default {
        components: {
            html2canvas
        },
        data() {
            return {
                styleAtive: 0,
                chelkAtive: 0,
                iconData: [
                    {
                        name: '转发',
                        img: require('../../../assets/images/festival/qiwei.png'),
                        width: '27px',
                        height: '22px'
                    },
                    {
                        name: '企微朋友圈',
                        img: require('../../../assets/images/festival/pengyouquan.png'),
                        width: '22px',
                        height: '22px'
                    },
                    {
                        name: '群发客户',
                        img: require('../../../assets/images/festival/qunfa.png'),
                        width: '22px',
                        height: '21px'
                    }
                ],
                text: '',
                imgWidth: 0,
                imgheight: 0,
                showEdit: false,
                showDown: false,
                matePop: false,
                pageLoad: false,
                posterImg: '',
                testSrc: '',
                loaction: {
                    text: '',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    width: 200,
                    height: 200,
                },
                setStyle() {
                    let styleStr = {'width': this.loaction.width + '%','height': this.loaction.height + 'px'}
                    this.loaction.top != 0 ? styleStr.top = this.loaction.top + '% !important' : '';
                    this.loaction.right != 0 ? styleStr.right = this.loaction.right + '% !important' : '';
                    this.loaction.bottom != 0 ? styleStr.bottom = this.loaction.bottom + '% !important' : '';
                    this.loaction.left != 0 ? styleStr.left = this.loaction.left + '% !important' : '';
                    return styleStr
                },
                styleData: [],
                saveBtnShow: false,
                showType: false,
                showName: '',
                posterCreate: false,
                userInfo: {}
            };
        },
        created() {
            this.showType = this.$route.query.type ? true : false
            this.showName = this.$route.query.name ? this.$route.query.name : ''
            this.getData()
            this.getUserInfo()
        },
        methods: {
            //获取用户信息
            getUserInfo() {
                posterApi.posterInfo().then((res) => {
                    this.userInfo = res.data;
                });
            },
            getData() {
                this.pageLoad = true
                let params = {
                    nodeCareCategoryId: this.$route.query.id ? this.$route.query.id : undefined,
                    type: this.$route.query.type ? this.$route.query.type : undefined
                }
                posterDetail(params).then((res) => {
                    if (res.code == 200 && res.data.length != 0) {
                        this.styleData = res.data
                        this.setDefault(res.data[0])
                    }
                    setTimeout(() => {
                        this.pageLoad = false
                    }, 1000)
                }).catch((error) => {
                    this.pageLoad = false
                })
            },
            //设置默认信息
            setDefault(data) {
                this.testSrc = data.avatar
                this.loaction.top = data.top?data.top:'';
                this.loaction.right = data.right?data.right:'';
                this.loaction.bottom = data.bottom?data.bottom:'';
                this.loaction.left = data.left?data.left:'';
                this.loaction.width = data.width
                this.loaction.height = data.height
                this.loaction.text = data.list[0].text
            },
            //样式选择
            selectImg(row, index) {
                this.chelkAtive=0
                this.styleAtive = index
                this.setDefault(row)
                this.saveBtnShow&&this.cancelBtn()//创建使用开启状态切换样式后关闭
            },
            //点击
            tagBtn(index) {
                this.loaction.text = this.styleData[this.styleAtive].list[index].text
                this.chelkAtive = index
            },
            //编辑弹窗确定
            save() {
                this.saveBtnShow = true
                this.showEdit = false
                this.loaction.text = this.text
            },
            //编辑
            editSubmit() {
                this.showEdit = true
                this.text = this.loaction.text
            },
            //编辑弹窗取消
             cancelBtn(){
                this.loaction.text = this.styleData[this.styleAtive].list[this.chelkAtive].text
                this.saveBtnShow=false
            },
            //下载海报
            dowmPoster() {
                this.createPoster()
            },
            //创建海报
            createPoster(row) {
                // 将滚动条位置设置为顶部
                this.posterCreate = true
                setTimeout(() => {
                    let oldScrollTop = document.documentElement.scrollTop
                    document.documentElement.scrollTop = 0;
                    document.body.scrollTop = 0;
                    var that = this;
                    var node = document.getElementById("shareNode");
                    this.imgWidth = this.$refs.shareImg.clientWidth
                    this.imgheight = this.$refs.shareImg.clientHeight
                    html2canvas(node, {
                        dpi: window.devicePixelRatio * 2,
                        allowTaint: true,
                        useCORS: true,
                        logging: true,
                        taintTest: false,
                        scale: 2,
                        async: true,
                        // backgroundColor: "transparent",
                    }).then(function (canvas) {
                        that.posterImg = canvas.toDataURL("image/png")
                        // 截图完成后将滚动条位置设置回原来的位置
                        document.documentElement.scrollTop = oldScrollTop;
                        document.body.scrollTop = oldScrollTop;
                        if (row) {
                            that.shareFunNext(row)
                        } else {
                            that.showDown = true
                            that.posterCreate = false
                        }
                    }).catch(function (error) {
                        console.log(error)
                        that.$message.error('生成海报失败');
                    });
                }, 1000)
            },
            showDownClose() {
                this.showDown = false
                this.posterImg = ''
            },
            //保存
            saveSubmit() {
                //生日类型列表nodeCareCategoryTwoId  其他则取query.id 
                addLog({ nodeCareCategoryTwoId: this.showType == 1?this.styleData[this.styleAtive].nodeCareCategoryTwoId:this.$route.query.id }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success("操作成功");
                    }
                    this.saveBtnShow=false
                })
            },
            //分享事件
            shareFun(row) {
                if (this.posterCreate) return
                this.createPoster(row)//创建海报
            },
            //生日分享给个人
            sharePerson() {
                if (this.posterCreate) return
                let row = { name: '转发' }
                this.createPoster(row)//创建海报
            },
            shareFunNext(row) {
                //将base64上传
                posterApi.createMediaId({ base64ImageContent: this.posterImg }).then((res) => {
                    this.posterCreate = false
                    if (row.name == '转发') {
                        let shareData = {
                            description: this.showType == 1 ? `祝您快乐 不止生日` : this.styleData[this.styleAtive].list[this.chelkAtive].text,//描述
                            url: res.data.shareUrl,//链接
                            title: this.showType == 1 ? `岁岁年年，生生不息。${res.data.name}祝您生日快乐` : this.styleData[this.styleAtive].name,//标题
                            imgUrl: this.styleData[this.styleAtive].styleImg//分享图标
                        };
                        shareAppMessage(shareData);
                    } else if (row.name == '企微朋友圈') {
                        let shareData = {};
                        shareData.type = 2;
                        shareData.mediaid = res.data.mediaId;
                        shareToExternalMoments(this.styleData[this.styleAtive].name, shareData).then((item) => {
                            this.$message.success("发送成功");
                        });
                    } else if (row.name == '群发客户') {
                        const data = {
                            title: this.styleData[this.styleAtive].name,
                            description: this.styleData[this.styleAtive].name,
                            type: 2,
                            mediaid: res.data.mediaId,
                            content: {
                                imageFullPath: ''
                            }
                        };
                        shareToExternalContact(data);
                    }
                });
            }
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .van-cell {
        background: none;
    }
    /deep/ .van-overlay {
        z-index: 20
    }
    /deep/ .van-field__control {
        font-size: 15px;
        color: #FFFFFF;
    }
    /deep/ .van-loading {
        margin: 0 auto;
        margin-top: 50%;
        display: flex;
        justify-content: center;
    }
    /* /deep/.van-popup{
        left: 50% !important;
    } */
    .page {
        width: 100%;
        height: 100%;
        background: url("../../../assets/images/festival/back.png") no-repeat;
        background-size: 100% 100%;
        font-size: 39px !important;
        .benner {
            width: 100%;
            height: 185px;
            background: url("../../../assets/images/festival/banTips.png") no-repeat;
            background-size: 100% 100%;
        }
        .contentBox {
            width: 100%;
            padding: 20px 12px;
            background: #F2F3F7;
            border-radius: 26px 26px 4px 4px;
            .content_title {
                font-size: 17px;
                font-weight: bold;
                color: #262626;
            }
            .scollBox {
                margin-top: 16px;
                margin-bottom: 23px;
                width: 100%;
                display: flex;
                flex-direction: row;
                white-space: nowrap;
                overflow: hidden;
                overflow-x: scroll;
                .active {
                    border: 1px solid #ED7B38;
                }
                .itemBox {
                    width: 111px;
                    height: 106px;
                    background: #FFFFFF;
                    border-radius: 3px;
                    padding: 8px;
                    &:nth-child(n+2) {
                        margin-left: 16px;
                    }
                    .box {
                        margin-top: 8px;
                        width: 94px;
                        height: 63px;
                        background: #D8D8D8;
                        border-radius: 4px;
                        img {
                            width: 94px;
                            height: 63px;
                        }
                    }
                    .name {
                        text-align: left;
                        font-size: 12px;
                        font-weight: 400;
                        color: #262626;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
            .tagBox {
                display: inline-flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                .tag {
                    min-width: 99px;
                    padding: 0 17px;
                    text-align: center;
                    height: 31px;
                    line-height: 31px;
                    background: #FFFFFF;
                    border-radius: 2px;
                    font-size: 15px;
                    margin-top: 10px;
                    font-weight: 400;
                    color: #515151;
                    margin-right: 14px;
                }
                .tagCheck {
                    background: #ED7B38;
                    color: #FFFFFF;
                }
            }
            .setBox {
                width: 100%;
                height: 648px;
                position: relative;
                .setImg {
                    width: 100%;
                    height: 100%;
                }
                .textA {
                    position: absolute;
                    z-index: 18;
                    font-size: 16px;
                    word-break: break-all;
                    white-space: pre-wrap;
                    font-family: 'xingshu';
                }
            }
            .manageBox {
                padding: 23px 18px;
                background: #FFFFFF;
                .infoBox {
                    display: flex;
                    justify-content: space-between;
                    .infoLeft {
                        display: flex;
                        flex-direction: row;
                        img {
                            width: 85px;
                            height: 85px;
                            border-radius: 4px;
                        }
                        .infoDetail {
                            margin-left: 10px;
                            .title {
                                font-size: 19px;
                                font-weight: bold;
                                color: #303030;
                            }
                            .minfo {
                                font-size: 11px;
                                font-weight: 400;
                                color: #717174;
                            }
                        }
                    }
                    .inforight {
                        display: flex;
                        align-items: center;
                        .qrcode {
                            width: 68px;
                            height: 68px;
                        }
                    }
                }
                .infoTips {
                    margin-top: 18px;
                    font-size: 11px;
                    font-weight: 400;
                    color: #4A4A4A;
                }
            }
        }
        .operate {
            width: 100%;
            height: 70px;
            background: #FFFFFF;
            padding: 12px 17px;
            display: inline-flex;
            justify-content: space-around;
            .editBtn {
                width: 97px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 5px;
                border: 1px solid #3974C6;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 15px;
                    height: 15px;
                }
                span {
                    margin-left: 5px;
                    font-size: 17px;
                    font-weight: 500;
                    color: #3974C6;
                }
            }
            .shareBtn {
                width: 136px;
                height: 48px;
                background: #3974C6;
                border-radius: 5px;
                font-size: 17px;
                font-weight: 500;
                color: #FFFFFF;
                margin-left: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .downBtn {
                width: 97px;
                height: 48px;
                background: #3974C6;
                border-radius: 5px;
                font-size: 17px;
                font-weight: 500;
                color: #FFFFFF;
                margin-left: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .saveBtn {
                width: 50%;
                height: 48px;
                background: #3974C6;
                border-radius: 5px;
                font-size: 17px;
                font-weight: 500;
                color: #FFFFFF;
                /* margin-left: 16px; */
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .cancelBtn {
                width: 50%;
                height: 48px;
                margin-right: 16px;
                border-radius: 5px;
                background: #FFFFFF;
                color: #3974C6;
                font-size: 17px;
                border-radius: 5px;
                border: 1px solid #3974C6;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .btn {
        padding: 30px 45px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .cencal {
            width: 63px;
            height: 32px;
            border-radius: 5px;
            border: 1px solid #FFFFFF;
            font-size: 15px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 32px;
        }
        .submit {
            width: 63px;
            height: 32px;
            background: #3974C6;
            border-radius: 5px;
            font-size: 15px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 32px;
        }
    }
    .overBox {
        width: 90%;
        height: 474px;
        background: rgba(0, 0, 0, 0.52);
        border-radius: 5px;
        border: 1px solid #FFFFFF;
        margin: 0 auto;
        margin-top: 20px;
        position: relative;
        .inputBox {
            padding: 17px 27px;
        }
        .tips {
            position: absolute;
            bottom: 5px;
            right: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .downBox {
        width: 90%;
        min-height: 450px;
        background: #FFFFFF;
        border-radius: 4px;
        margin: 0 auto;
        margin-top: 100px;
        padding: 15px 18px;
        display: flex;
        flex-direction: column;
        .poster {
            width: 171px;
            height: 374px;
            margin: 0 auto;
            box-shadow: 0px 0px 8px 0px rgba(214, 214, 214, 0.5);
        }
        .tips1 {
            margin-top: 13px;
            font-size: 16px;
            font-weight: bold;
            color: #3974C6;
            width: 100%;
            text-align: center;
        }
        .tips2 {
            font-size: 11px;
            font-weight: 400;
            color: #3974C6;
            width: 100%;
            text-align: center;
        }
        .shareTitle {
            img {
                width: 14px;
                height: 14px;
            }
            span {
                margin-left: 5px;
                font-size: 14px;
                font-weight: bold;
                color: #515151;
            }
        }
        .shareTips {
            font-size: 11px;
            font-weight: 400;
            color: #A5A5A5;
        }
    }
    .imgBox {
        width: 100%;
        margin-top: 25px;
        display: flex;
        justify-content: center;
        img {
            width: 33px;
            height: 33px;
        }
    }
    .shareBox {
        padding: 22px 24px;
        .popTitle {
            font-size: 17px;
            font-weight: bold;
            color: #000000;
        }
        .popTips {
            margin-top: 7px;
            font-size: 12px;
            font-weight: 400;
            color: #A5A5A5;
        }
        .btnIconBox {
            margin-top: 22px;
            display: flex;
            justify-content: flex-start;
            .itemList {
                display: flex;
                justify-content: center;
                flex-direction: column;
                &:nth-child(n+2) {
                    margin-left: 35px;
                }
                .iconBox {
                    width: 49px;
                    height: 49px;
                    background: #F2F8FF;
                    border-radius: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .iconTitle {
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                    text-align: center;
                }
            }
        }
    }
    .createBox {
        left: 50% !important;
        .createLoad {
            padding: 0 20px 20px 20px;
            border-radius: 20px;
        }
    }
</style>