<template>
  <div>
    <a-modal
      title="选择标签"
      :visible="visible"
      okText="确定"
      cancelText="取消"
      @cancel="visible = !visible"
      @ok="ok"
      :zIndex="3000"
    >
      <van-tabs color="#1989fa">
        <van-tab title="企业标签" name="a">
          <div class="list">
            <div class="item" v-for="(group, i) in tags" :key="i">
              <div class="group-title">
                {{ group.groupName }}
              </div>
              <div class="tags">
                <div class="tag" v-for="(tag, i2) in group.tags" :key="i2">
                  <div
                    v-if="!active"
                    :class="{ 'tag-box': true, active: tag.select }"
                    @click="switchActive(tag)"
                  >
                    {{ tag.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="分行标签" name="b">
          <div class="list">
            <div class="item" v-for="(group, i) in businessTagData" :key="i">
              <div class="group-title">
                {{ group.groupName }}
              </div>
              <div class="tags">
                <div class="tag" v-for="(tag, i2) in group.tags" :key="i2">
                  <div
                    v-if="!active"
                    :class="{ 'tag-box': true, active: tag.select }"
                    @click="switchActive(tag)"
                  >
                    {{ tag.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </a-modal>
  </div>
</template>

<script>
import { getTagList } from "../../../api/common";
import { ContactApi } from "@/api/contact";

export default {
  data() {
    return {
      visible: false,
      allTags: [],
      tags: [],
      businessTagData: [],
      active: false,
    };
  },
  methods: {
    switchActive(data) {
      this.active = true;
      data.select = !data.select;
      this.active = false;
    },
    // 获取支行标签列表
    getBusinessTag() {
      ContactApi.businessTag().then((res) => {
        console.log("业务标签", res);
        this.businessTagData = res.data.list;
      });
    },
    ok() {
      const selectTag = [];
      this.allTags = [].concat(this.businessTagData, this.tags);
      this.allTags.forEach((group) => {
        group.tags.forEach((tag) => {
          if (tag.select) selectTag.push(tag);
        });
      });

      this.$emit("change", JSON.parse(JSON.stringify(selectTag)));
      this.visible = false;
    },

    show(tags = []) {
      this.getData(tags);
      this.getBusinessTag();
      this.visible = true;
    },

    getData(tags) {
      getTagList().then((res) => {
        this.tags = res.data;

        this.tags.forEach((group) => {
          group.tags.forEach((tag2) => {
            tag2.select = false;
          });
        });

        tags.forEach((tag) => {
          this.tags.forEach((group) => {
            group.tags.forEach((tag2) => {
              if (tag.tagId === tag2.id) {
                console.log(tag);
                tag2.select = true;
              }
            });
          });
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  height: 450px;
  overflow: auto;
}

/deep/ .ant-tag-checkable {
  padding: 3px 17px;
  background: #f4f4f4;
  color: #727272;
  cursor: pointer;
}

/deep/ .ant-tag-checkable-checked {
  background: #158fff1a;
  color: #1890ff;
  border: 1px solid #1890ff;
}

.group-title {
  margin-bottom: 4px;
  font-size: 13px;
}

.item {
  margin-bottom: 16px;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tag {
    margin-bottom: 6px;
  }
}

.tag-box {
  padding: 4px 17px;
  background: #f4f4f4;
  color: #727272;
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 3px;

  &:hover {
    color: #4f4f4f;
  }
}

.active {
  background: #158fff1a;
  color: #1890ff;
  border: 1px solid #1890ff;
}
</style>
