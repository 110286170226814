<template>
    <div class="pageIndex">
        <div class="title" v-permission="'system:X65Ib'">从素材库选择</div>
        <div class="cell" :class="{'flex_box': flexStart }" v-permission="'system:X65Ib'">
            <div class="tab_box" v-for="(item,index) of modelList" :key='index' @click="goPage(item)">
                <div class="item_box">
                    <img :src="item.imageUrl" alt="" :style="{width:item.width,height:item.height}">
                </div>
                <span>{{item.title}}</span>
            </div>
        </div>
        <div class="title m13">从本地上传</div>
        <div class="cell flex_box">
            <div class="tab_box " v-for="(item,index) of localList" :key='index'>
                <!-- 上传图片 -->
                <div v-if="item.id==1" class="cenbox">
                    <van-uploader :after-read="afterReadImg" :max-size="2000 * 1024" @oversize="onOversize"
                        :max-count="1">
                        <div class="item_box">
                            <img :src="item.imageUrl" alt="" :style="{width:item.width,height:item.height}">
                        </div>
                        <span>{{item.title}}</span>
                    </van-uploader>
                </div>
                <!-- 上传视频 -->
                <div v-else-if="item.id==2" class="cenbox">
                    <van-uploader :after-read="afterReadVideo" :max-count="1">
                        <div class="item_box">
                            <img :src="item.imageUrl" alt="" :style="{width:item.width,height:item.height}">
                        </div>
                        <span>{{item.title}}</span>
                    </van-uploader>
                </div>
                <!-- 自定义事件 -->
                <div v-else @click="btnClick(item)">
                    <div class="item_box">
                        <img :src="item.imageUrl" alt="" :style="{width:item.width,height:item.height}">
                    </div>
                    <span>{{item.title}}</span>
                </div>

            </div>

        </div>

    </div>
</template>
<script>
    import { store, upload } from "@/api/contactWelcome";
    export default {
        data() {
            return {
                modelList: [
                    {
                        id: 1,
                        imageUrl: require("@/assets/images/mateSelect/wenbenwendang-txt@2x.png"),
                        title: "文本",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '25px'
                    },
                    {
                        id: 2,
                        imageUrl: require("@/assets/images/mateSelect/tupian-2@2x.png"),
                        title: "图片",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '20px'
                    },
                    {
                        id: 3,
                        imageUrl: require("@/assets/images/mateSelect/tupian-jpg_png@2x.png"),
                        title: "图文",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '24px'
                    },
                    {
                        id: 4,
                        imageUrl: require("@/assets/images/mateSelect/yinpin@2x.png"),
                        title: "音频",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '24px'
                    },
                    {
                        id: 5,
                        imageUrl: require("@/assets/images/mateSelect/shipin-4@2x.png"),
                        title: "视频",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '22px'
                    },
                    {
                        id: 6,
                        imageUrl: require("@/assets/images/mateSelect/xiaochengxuyonghu@2x.png"),
                        title: "小程序",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '22px'
                    },
                    {
                        id: 7,
                        imageUrl: require("@/assets/images/mateSelect/wenjianjia-2@2x.png"),
                        title: "文件",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '20px'
                    },
                    // {
                    //     id: 8,
                    //     imageUrl: require("@/assets/images/mateSelect/leida@2x.png"),
                    //     title: "雷达",
                    //     url: "/channelCode/index",
                    //     width: '22px',
                    //     height: '24px'
                    // }
                ],
                localList: [
                    {
                        id: 1,
                        imageUrl: require("@/assets/images/mateSelect/tupian-2@2x.png"),
                        title: "图片",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '20px'
                    },
                    {
                        id: 2,
                        imageUrl: require("@/assets/images/mateSelect/shipin-4@2x.png"),
                        title: "视频",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '22px'
                    },
                    {
                        id: 3,
                        imageUrl: require("@/assets/images/mateSelect/tupian-2@2x.png"),
                        title: "图片",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '20px'
                    },
                    {
                        id: 4,
                        imageUrl: require("@/assets/images/mateSelect/link.png"),
                        title: "链接",
                        url: "/channelCode/index",
                        width: '28px',
                        height: '22px'
                    },
                    {
                        id: 5,
                        imageUrl: require("@/assets/images/mateSelect/choujiang.png"),
                        title: "活动",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '22px'
                    },
                    {
                        id: 6,
                        imageUrl: require("@/assets/images/mateSelect/xiaochengxuyonghu@2x.png"),
                        title: "小程序",
                        url: "/channelCode/index",
                        width: '22px',
                        height: '22px'
                    },
                ],
                flexStart: false,
                fileData: {
                    name: '',
                    fileList: []
                }
            }
        },
        methods: {
            goPage(item) {
                this.$emit('popBtn', item)
            },
            // 文件大小
            onOversize(file) {
                this.$message.warning("文件大小不能超过 2MB");
            },
            // 上传图片文件
            afterReadImg(file) {
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                console.log("file", file)
                this.$emit('imagesData', file.content)
                upload(formData).then((res) => {
                    file.status = "done";
                    this.fileData.name = res.data.name;
                    this.fileData.fileList[0] = {
                        url: res.data.fullPath,
                        path: res.data.path,
                    };
                    //将数据传回父组件
                    this.$emit('imagesData2', this.fileData.fileList[0])
                });
            },
            afterReadVideo(file) {
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    this.fileData.name = res.data.name;
                    this.fileData.fileList[0] = {
                        url: res.data.fullPath,
                        path: res.data.path,
                    };
                    console.log("res", res)
                    //将数据传回父组件
                    // this.$emit('imagesData', this.fileData)
                });
            },
            //隐藏素材库部分选项
            deltefun(idArray) {
                // this.$nextTick(() => {this.$refs.matebpx.deltefun([4])}) //父组件调用方式   4为对应隐藏id
                idArray.forEach((id) => {
                    if (this.modelList.findIndex(item => item.id === id) != -1) {
                        this.modelList.splice(this.modelList.findIndex(item => item.id === id), 1)
                    }
                })
            },
            //隐藏本地选择部分选项
            deltelocalfun(idArray) {
                // this.$nextTick(() => {this.$refs.matebpx.deltelocalfun([1,2])}) //父组件调用方式   2为对应隐藏id
                idArray.forEach((id) => {
                    if (this.localList.findIndex(item => item.id === id) != -1) {
                        this.localList.splice(this.localList.findIndex(item => item.id === id), 1)
                    }
                })
            },
            btnClick(row) {
                if (row.title == '图片') {
                    //  this.$emit('choseImg', false)
                    this.$parent.$parent.choseImg(false)
                } else if (row.title == '链接') {
                    //  this.$emit('choseUrl', false)
                    this.$parent.$parent.choseUrl(false)
                } else if (row.title == '活动') {
                    // this.$emit('choseLuckDraw', false)
                    this.$parent.$parent.choseLuckDraw(false)
                } else if (row.title == '小程序') {
                    // this.$emit('choseApplet', false)
                    this.$parent.$parent.choseApplet(false)
                }

            }
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-uploader__wrapper {
        display: flex;
        justify-content: center;
    }

    .pageIndex {
        width: 100%;
        /* height: 100vh; */
        padding: 20px 25px;
        background: #FFFFFF;

        .title {
            font-size: 17px;
            font-weight: bold;
            color: #000000;
        }

        .m13 {
            margin-top: 40px;
        }

        .cell {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .tab_box {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: calc((100% - 20px) / 4);
                margin-top: 22px;

                .cenbox {
                    display: flex;
                    justify-content: center;
                }

                .item_box {
                    width: 49px;
                    height: 49px;
                    background: #F2F3F7;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin: 0 auto;

                    img {
                        position: relative;
                        top: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                        justify-content: center;
                        width: 22px;
                        height: 25px;
                    }
                }


                span {
                    text-align: center;
                    margin-top: 11px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: block;
                }
            }
        }

        .flex_box {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }
</style>