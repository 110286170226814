<template>
    <div class="page">
        <!-- v-for="(item,index) in 10" :key="index" -->
        <van-empty description="暂无数据" v-if="!contact.name" />
        <div class="itemBox" v-else>
            <div class="title">{{date}}</div>
            <div class="infoBox">
                <div class="leftBox">
                    <img :src="contact.avatar" alt="">
                    <div class="content">
                        <div class="name">给{{contact.name}}送个生日祝福吧</div>
                        <div class="tips">
                            {{isThreeDays==0?`今日是客户${contact.name}的生日`:`${isThreeDays}天后就是客户${contact.name}的生日了` }}
                        </div>
                    </div>
                </div>
                <div class="rightBox" @click="goPage()">
                    <span>生日祝福</span>
                    <van-icon name="arrow" class="icon" size="7" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { birthdayRemind } from "@/api/festival";
    export default {
        data() {
            return {
                contact: {},
                date: '',
                isThreeDays: ''
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                birthdayRemind({ contactId: this.$route.query.contactId }).then((res) => {
                    if (res.code == 200 && res.data.contact) {
                        this.contact = res.data.contact
                        this.date = res.data.date
                        this.isThreeDays = res.data.isThreeDays
                    }
                })
            },
            goPage(row) {
                this.$router.push({ path: "/festival/festivalUse?type=1&name=" + this.contact.name });//固定类型  1生日祝福
            },
        }
    }
</script>
<style lang="less" scoped>
    .page {
        width: 100%;
        height: 100vh;
        padding: 0 12px;
        background: #F2F3F7;
        .itemBox {
            .title {
                padding: 14px 0px;
                font-size: 11px;
                font-weight: 400;
                color: #888888;
            }
            .infoBox {
                width: 100%;
                padding: 12px 18px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                justify-content: space-between;
                .leftBox {
                    display: flex;
                    img {
                        width: 50px;
                        height: 50px;
                        background: #D8D8D8;
                        border-radius: 8px;
                    }
                    .content {
                        margin-left: 20px;
                        .name {
                            font-size: 12px;
                            font-weight: 500;
                            color: #000000;
                        }
                        .tips {
                            margin-top: 8px;
                            font-size: 11px;
                            font-weight: 400;
                            color: #717174;
                        }
                    }
                }
                .rightBox {
                    span {
                        font-size: 9px;
                        font-weight: 400;
                        color: #9B9B9B;
                    }
                    .icon {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
</style>