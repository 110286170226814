import { getUserLocation } from "@/utils/wxCodeAuth";
export const loacationMixins = {
    data() {
        return {
            center: { lng: 116.404, lat: 39.915 },
            currentTime: '',
            initialAddress: '',
            timer: null,
            locationAddress:''
        }
    },
    mounted() {
        this.updateTime(); // 首次加载时立即更新时间
        this.timer = setInterval(this.updateTime, 1000);// 每秒更新一次时间
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        // 选址确认
        onConfirm(value, index) {
            this.pickerIndex = index
            this.params.locationAddress = this.initialAddress + value
            this.addressShow = false
        },
        // 选址取消
        onCancel() {
            this.addressShow = false
        },
        async handler({ BMap, map }) {
            //获取用户位置
            let isMobile = await this.isMobileDevice()//判断是否手机端
            if (!isMobile) return
            const loctionInfo = await getUserLocation();
            // const loctionInfo = { longitude: 114.0303726196289, latitude: 22.66407012939453 }
            this.center = await this.qqMapTransBdMap(loctionInfo.longitude, loctionInfo.latitude)
            var that = this;
            //解析经纬度地址
            var myGeo = new BMap.Geocoder();
            var pt = new BMap.Point(this.center.lng, this.center.lat);
            myGeo.getLocation(pt, function (result) {
                if (result) {
                    let newAddress = result.surroundingPois != 0 ? result.surroundingPois[0].title : ''
                    that.params.locationAddress = result.address + newAddress
                    that.initialAddress = result.address
                    that.columns = []
                    result.surroundingPois.forEach((item, index) => {
                        that.columns.push(item.title)
                    })
                }
            });
        },
        /**
        * @description 高德转百度
        * @description  GCJ-02 转 BD-09
        * @param{*}list [lng,lat]
        */
        qqMapTransBdMap(lng, lat) {
            let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
            let x = lng;
            let y = lat;
            let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
            let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
            let lngs = z * Math.cos(theta) + 0.0065;
            let lats = z * Math.sin(theta) + 0.006;
            return { lng: lngs, lat: lats };
        },
        // 判断是否为移动端
        isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        updateTime() {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            this.currentTime = `${hours}:${minutes}:${seconds}`;
        },
    }
}
