<template>
  <div class="index">
    <div class="header">
      <div class="content" v-for="(item,index) in noticeData" :key="index">
        <div class="top">
          <div class="topLeft">系统消息</div>
          <div class="topRight"></div>
        </div>
        <div class="bottom" @click="skipDetail(item.id)">
          <div class="title">{{ item.title }}</div>
          <div class="message">
            {{ item.summary }}
          </div>
          <div style="height:10px"></div> 
        </div>
        <div style="height:10px;background-color: #f7f7f7;width:108%;margin-left:-4%"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { noticeList } from '@/api/electronicCard'
export default {
  data() {
    return {
      noticeData:[]
    };
  },
  created(){
    this.getData()
  },
  methods:{
    getData(){
      noticeList().then(res=>{
        this.noticeData = res.data.list
      })
    },
    skipDetail(id){
      console.log('id',id)
      this.$router.push({
        path: "/electronicCard/notice/detail",
        query: {
          id
        },
      });
    }
  }
};
</script>
<style lang="less" scoped>
.index {
  height: 100%;
  overflow: auto;
  background-color: #f7f7f7;
}
.header {
  width: 94%;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 108px;
  background: #ffffff;
  border-radius: 3px;
  .content {
    width: 94%;
    margin: 0 auto;
    .top {
      height: 37px;
      width: 100%;
      border-bottom: 1px solid #efefef;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .topLeft {
        height: 23px;
        font-size: 13px;
        color: #333333;
      }
      .topRight {
        height: 6px;
        font-size: 12px;
        color: #888888;
        line-height: 6px;
      }
    }
    .bottom {
      .title {
        height: 40px;
        font-size: 16px;
        color: #333333;
        line-height: 40px;
      }
      .message {
        font-size: 13px;
        color: #4a4a4a;
        line-height: 17px;
        overflow: hidden; //多出的隐藏
        text-overflow: ellipsis; //多出部分用...代替
        display: -webkit-box; //定义为盒子模型显示
        -webkit-line-clamp: 4; //用来限制在一个块元素显示的文本的行数
        -webkit-box-orient: vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
      }
    }
  }
}
</style>
  