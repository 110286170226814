<template>
  <div class="index">
    <div class="header">
      <div class="title">{{detail.title}}</div>
      <div class="content" v-html="detail.content">
      </div>
    </div>
  </div>
</template>
<script>
import { noticeDetail } from '@/api/electronicCard'
export default {
  data() {
    return {
      detail:''
    };
  },
  created(){
    this.getData()
  },
  methods:{
    getData(){
      const id = this.$route.query.id
      noticeDetail({id:id}).then(res=>{
        this.detail = res.data
      })
    },
  }
};
</script>
<style lang="less" scoped>
.header {
  width: 94%;
  margin: 0 auto;
  overflow: auto;
  .title {
    text-align: center;
    width: 100%;
    height: 35px;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    margin-top: 20px;
  }
  .content {
    font-size: 14px;
    color: #4a4a4a;
    line-height: 20px;
  }
}
/deep/ img{
  max-width: 100% !important;
}
</style>
  