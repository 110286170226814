import request from "@/utils/request";

/**
 * 对公业务
 */
export class publicBusinessApi {
  // 绑定公司列表及剩余次数
  static bindCropList(params) {
    return request({
      url: "/publicBusinessEmployeeContact/getBind",
      method: "get",
      params: params,
    });
  }
  //搜索公司
  static searchCorp(params) {
    return request({
      url: "/publicBusinessEmployeeContact/search",
      method: "get",
      params: params,
    });
  }
  //绑定公司
  static bindCorp(params) {
    return request({
      url: "/publicBusinessEmployeeContact/bind",
      method: "post",
      data: params,
    });
  }
  //删除绑定公司
  static unbindCorp(params) {
    return request({
      url: "/publicBusinessEmployeeContact/unBind",
      method: "post",
      data: params,
    });
  }
  //绑定公司详情
  static bindCorpDetail(params) {
    return request({
      url: "/publicBusinessEmployeeContact/corpDetail",
      method: "get",
      params: params,
    });
  }
  //更新公司信息
  static updateCorp(params) {
    return request({
      url: "/publicBusinessEmployeeContact/updateCorp",
      method: "post",
      data: params,
    });
  }
  //创建订单
  static createOrder(params) {
    return request({
      url: "/publicBusinessOrder/create",
      method: "post",
      data: params,
    });
  }
  //查询订单状态
  static queryStatus(params) {
    return request({
      url: "/publicBusinessOrder/getStatus",
      method: "get",
      params: params,
    });
  }
  //再次付款
  static repay(params) {
    return request({
      url: "/publicBusinessOrder/repayment",
      method: "get",
      params: params,
    });
  }
}