<template>
  <div class="all">
    <van-tabs
      v-model="activeName3"
      @click="tabHandler"
      title-inactive-color="#4A4A4A"
      background="#fff"
      title-active-color="#3974C6"
    >
      <van-tab title="今日排行"  title-style="font-size:14px;">
        <div class="main">
          <div class="myData">
            <div class="myDataTop">
              <div class="left">
                <img
                  :src="myAvatar"
                  alt=""
                  style="
                    float: left;
                    margin-top: 8px;
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                  "
                />
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    float: left;
                    margin-left: 10px;
                  "
                >
                  <p>我的数据</p>
                  <span>{{ today }}</span>
                </div>
              </div>
            </div>
            <div class="myDataBottom">
              <p>预约处理</p>
              <div class="custom">
                <div class="m16">
                  <span class="m12">{{ todayhandle }}</span>
                  <i>今日处理</i>
                </div>
                <div class="m16">
                  <span class="m12">{{ handleTotal }}</span>
                  <i>本月累计</i>
                </div>
                <div class="m16">
                  <span class="m12">{{ handleRank }}</span>
                  <i style="border: none">本月累计排行</i>
                </div>
              </div>
            </div>
            <div class="myDataBottom">
              <p>新增客户</p>
              <div class="custom">
                <div class="m16">
                  <span class="m13">{{ todayAddNum }}</span>
                  <i>今日新增客户</i>
                </div>
                <div class="m16">
                  <span class="m13">{{ monthAddNum }}</span>
                  <i>本月累计</i>
                </div>
                <div class="m16">
                  <span class="m13">{{ rank }}</span>
                  <i style="border: none">本月累计排行</i>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              width: 94%;
              margin: 0 auto;
              background-color: #fff;
              border-radius: 10px 10px 0 0;
            "
          >
            <div class="rankTop">
              <span
                :class="[active == true ? 'span1' : 'span2']"
                @click="handleClick"
                >新增客户排名</span
              >
              <span
                :class="[active2 == true ? 'span1' : 'span2']"
                @click="handleClick2"
                >处理预约排名</span
              >
            </div>
          </div>
          <div class="rank">
            <div class="rankList">
              <div v-if="myRankList != ''">
                <div
                  class="rL16"
                  style="height: 60px; border-bottom: 5px solid #eee"
                >
                  <i>{{ myRankList.rank }}</i>
                  <img
                    :src="myRankList.avatar"
                    alt=""
                    style="
                      width: 38px;
                      height: 38px;
                      border-radius: 50%;
                      margin: 0 10px 0 8px;
                    "
                  />
                  <span>{{ myRankList.name }}</span>
                </div>
                <div class="rr16" style="border-bottom: 5px solid #eee">
                  <span>{{ myRankList.addNum }}</span>
                </div>
              </div>
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                v-if="rankList.length > 0"
              >
                <div v-for="(item, index) in rankList" :key="index">
                  <div
                    class="rL16"
                    style="height: 60px; border-bottom: 1px solid #eee"
                  >
                    <i v-if="index + 1 == 1"
                      ><img
                        style="width: 24px; height: 24px"
                        src="../../assets/one.png"
                        alt=""
                      />
                    </i>
                    <i v-else-if="index + 1 == 2"
                      ><img
                        style="width: 24px; height: 24px"
                        src="../../assets/two.png"
                        alt=""
                      />
                    </i>
                    <i v-else-if="index + 1 == 3"
                      ><img
                        style="width: 24px; height: 24px"
                        src="../../assets/three.png"
                        alt=""
                      />
                    </i>
                    <i v-else>{{ index + 1 }}</i>
                    <img
                      :src="item.avatar"
                      alt=""
                      style="
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;
                        margin: 0 10px 0 8px;
                      "
                    />
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="rr16" style="border-bottom: 1px solid #eee">
                    <span style="display: inline-block">{{ item.addNum }}</span>
                  </div>
                </div>
                <!-- <div v-else>
                  <van-empty description="暂无数据" />
                </div> -->
              </van-list>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="工作小结"  title-style="font-size:14px;">
        <div class="time">
          <div class="sum">
            <div
              :class="[spanIndex == index ? 'btnActive' : 'btn']"
              v-for="(item, index) in dayTime"
              :key="index"
              @click="changeDay(index)"
            >
              {{ item.day }}
            </div>
          </div>
        </div>
        <div>
          <div class="mainSummary">
            <div class="box">
              <div class="customData">
                <div
                  class="Top"
                  @click="
                    () => {
                      this.$router.push('/contactStatistic/index?activeName=1');
                    }
                  "
                >
                  <div>
                    <img class="icon" src="./img/编组.png" alt="" />
                    <p>客户统计</p>
                  </div>
                  <img
                    src="./img/jiantou.png"
                    alt=""
                    style="width: 7px; height: 11px"
                  />
                </div>
                <div class="custom">
                  <div class="m16">
                    <span class="m14">{{ summary.newApplyCnt }}</span>
                    <i>发起申请数</i>
                  </div>
                  <div class="m16">
                    <span class="m14">{{ summary.todayAddNum }}</span>
                    <i>新增客户数</i>
                  </div>
                  <div class="m16">
                    <span style="border: none" class="m14">{{
                      summary.todayLossNum
                    }}</span>
                    <i style="border: none">流失客户数</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="customData">
                <div
                  class="Top"
                  @click="
                    () => {
                      this.$router.push('/contactStatistic/index?activeName=2');
                    }
                  "
                >
                  <div>
                    <img class="icon" src="./img/编组2.png" alt="" />
                    <p>客户群统计</p>
                  </div>
                  <img
                    src="./img/jiantou.png"
                    alt=""
                    style="width: 7px; height: 11px"
                  />
                </div>
                <div class="custom">
                  <div class="m16">
                    <span class="m14">{{ summary.todayAddGroupNum }}</span>
                    <i>新增客户群</i>
                  </div>
                  <div class="m16">
                    <span class="m14">{{ summary.todayGroupAddNum }}</span>
                    <i>新增客户数</i>
                  </div>
                  <div class="m16">
                    <span style="border: none" class="m14">{{
                      summary.todayGroupLossNum
                    }}</span>
                    <i style="border: none">流失客户数</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="customData">
                <div
                  class="Top"
                  @click="
                    () => {
                      this.$router.push('/index/index');
                    }
                  "
                >
                  <div>
                    <img class="icon" src="./img/daiban.png" alt="" />
                    <p>待办统计</p>
                  </div>
                  <img
                    src="./img/jiantou.png"
                    alt=""
                    style="width: 7px; height: 11px"
                  />
                </div>
                <div class="custom">
                  <div class="m17">
                    <span class="m14">{{ summary.todoNum }}</span>
                    <i>待办任务</i>
                  </div>
                  <div class="m17">
                    <span style="border: none" class="m14">{{
                      summary.completeTodoNum
                    }}</span>
                    <i style="border: none">已完成待办</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="customData">
                <div
                  class="Top"
                  @click="
                    () => {
                      this.$router.push('/appointment/index');
                    }
                  "
                >
                  <div>
                    <img class="icon" src="./img/yuyue.png" alt="" />
                    <p>预约处理</p>
                  </div>
                  <img
                    src="./img/jiantou.png"
                    alt=""
                    style="width: 7px; height: 11px"
                  />
                </div>
                <div class="custom">
                  <div class="m17">
                    <span class="m14">{{ summary.waitBankServiceNum }}</span>
                    <i>待处理服务</i>
                  </div>
                  <div class="m17">
                    <span style="border: none" class="m14">{{
                      summary.completeBankServiceNum
                    }}</span>
                    <i style="border: none">已完成服务</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-overlay :show="showWait">
      <van-loading type="spinner" color="#1989fa">加载中...</van-loading>
    </van-overlay>
  </div>
</template>
<script>
import { MyApi } from "@/api/my";
import { userCenter } from "../../api/homePage";
import {  getCookie,setCookie } from "@/plugins/utils";

export default {
  data() {
    return {
      filterVal: 1,
      spanIndex: 0,
      dayTime: [
        { day: "昨日", value: 1 },
        { day: "本周", value: 2 },
        { day: "本月", value: 3 },
      ],
      userDatalt: {
        name: "",
      },
      showWait: false,
      myAvatar: "",
      rankList: [],
      myRankList: "",
      activeName3: Number(getCookie("activeIdx"))
        ? Number(getCookie("activeIdx"))
        : 0,
      todayAddNum: 0,
      monthAddNum: 0,
      rank: 0,
      loading: false,
      finished: false,
      // 今日排行预约处理
      todayhandle: 0,
      handleTotal: 0,
      handleRank: 0,
      // 今日排行新增客户
      newApplyCnt: 0,
      todayAddNum: 0,
      todayLossNum: 0,
      // 每日小结
      summary: {
        newApplyCnt: 0,
        todayAddNum: 0,
        todayLossNum: 0,
        todayAddGroupNum: 0,
        todayGroupAddNum: 0,
        todayGroupLossNum: 0,
        todoNum: 0,
        completeTodoNum: 0,
        waitBankServiceNum: 0,
        completeBankServiceNum: 0,
      },
      today: "",
      active: true,
      active2: false,
    };
  },
  mounted() {
    this.getTodayRank();
    this.getDaySummary();
    this.userDetails();
    // window.addEventListener(
    //   "popstate",
    //   function (e) {
    //     console.log("后退");
    //     localStorage.setItem("activeIdx", 1);
    //     console.log("ddd", that.activeName3);
    //   },
    //   false
    // );
  },
  // beforeDestroy(){
  //   console.log('销毁')
  //   if(localStorage.removeItem("activeIdx")){
  //     localStorage.removeItem("activeIdx")
  //   }
  // },
  methods: {
    // tab点击事件
    tabHandler(idx) {
      console.log('1111',idx)
      this.activeName3 = idx;
      setCookie("activeIdx", idx)
      // localStorage.setItem("activeIdx", idx); // 将选中的tab缓存起来
    },
    handleClick() {
      this.active = true;
      this.active2 = false;
      this.getTodayRank();
    },
    handleClick2() {
      this.active = false;
      this.active2 = true;
      this.getTodayRank();
    },
    changeDay(index) {
      this.spanIndex = index;
      this.filterVal = this.dayTime[index].value;
      this.showWait = true;
      this.getDaySummary();
    },
    // 获取自己的昵称
    userDetails() {
      userCenter().then((res) => {
        console.log("我的名字", res);
        this.userDatalt = res.data.baseInfo;
      });
    },
    //获取今日排行数据
    getTodayRank() {
      MyApi.todayRank().then((res) => {
        console.log("今日排行todayRank", res);
        const { data } = res;
        this.today = data.date;
        this.todayAddNum = data.addContact.todayAddNum;
        this.monthAddNum = data.addContact.addNum;
        this.rank = data.addContact.rank;
        this.todayhandle = data.bankService.todayAddNum;
        this.handleTotal = data.bankService.addNum;
        this.handleRank = data.bankService.rank;
        this.myAvatar = data.avatar;
        this.rankList =
          this.active == true
            ? data.addContactRankList.list
            : data.bankServiceRankList.list;
        this.myRankList =
          this.active == true
            ? data.addContactRankList.myself
            : data.bankServiceRankList.myself;
        console.log("this.rankList", this.rankList);
      });
    },
    //获取每日小结
    getDaySummary() {
      MyApi.daySummary({ queryType: this.filterVal }).then((res) => {
        console.log("每日小结todayRank", res);
        const { data } = res;
        this.summary.newApplyCnt = data.newApplyCnt;
        this.summary.todayAddNum = data.todayAddNum;
        this.summary.todayLossNum = data.todayLossNum;
        this.summary.todayAddGroupNum = data.todayAddGroupNum;
        this.summary.todayGroupAddNum = data.todayGroupAddNum;
        this.summary.todayGroupLossNum = data.todayGroupLossNum;
        this.summary.todoNum = data.todoNum;
        this.summary.completeTodoNum = data.completeTodoNum;
        this.summary.waitBankServiceNum = data.waitBankServiceNum;
        this.summary.completeBankServiceNum = data.completeBankServiceNum;
        this.showWait = false;
      });
    },
    onLoad() {
      console.log("this.list", this.rankList);
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-loading {
  width: 140px;
  text-align: center;
  height: 40px;
  margin: 0 auto;
  margin-top: 80%;
}
/deep/ .van-tabs__nav--line {
  margin-top: 10px;
}
/deep/ .van-tabs__line {
  background-color: #3975c6 !important;
  bottom: 10px;
}
/deep/ .van-tabs__wrap {
  background: #fff;
}
.custom {
  display: flex;
  text-align: center;
  justify-content: space-between;
  height: 80px;
  margin-top: 14px;
  .m17 {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .m16 {
    width: 33.3%;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  i {
    border-right: 1px solid #eeeeee;
    font-style: normal;
    font-size: 12px;
    color: #9b9b9b;
  }
}
.all {
  height: 100%;
  overflow: auto;
  background-color: #f2f3f7;

  .main {
    width: 100%;
    height: 100%;
    background-color: #f2f3f7;
    padding-top: 10px;
  }
  .myData {
    width: 94%;
    height: 299px;
    margin: 0 auto;
    border-radius: 10px;
    // background-color: #ffffff;
    background: linear-gradient(#f5f8ff 0%, #ffffff 100%);
    .myDataTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      margin: 0 auto;
      height: 75px;
      width: 90%;

      .img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
      p {
        margin: 0;
        font-size: 16px;
        color: #4a4a4a;
        font-weight: bold;
      }
      span {
        font-size: 14px;
        color: #9b9b9b;
      }
    }
    .myDataBottom {
      margin: 0 auto;
      height: 110px;
      width: 90%;
      p {
        margin: 0;
        font-size: 14px;
        color: #4a4a4a;
        font-weight: bold;
        margin-top: 10px;
      }
      .m12 {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
      }
      .m13 {
        font-size: 16px;
        font-weight: bold;
        color: #3974c7;
      }
    }
  }
  .rankTop {
    margin: 0 auto;
    margin-top: 10px;
    width: 94%;
    display: flex;
    justify-content: space-between;
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #f1f1f1;
    .span1 {
      font-size: 14px;
      font-weight: bold;
      color: #3974c6;
    }
    .span2 {
      font-size: 14px;
      font-weight: bold;
      color: #4a4a4a;
    }
  }
  .rank {
    margin: 0 auto;
    width: 94%;
    background-color: #fff;
    min-height: 200px;
  }
  .rankList {
    margin: 0 auto;
    width: 94%;
    margin-bottom: 10px;
    overflow-x: hidden;
    i {
      display: inline-block;
      width: 61px;
      text-align: center;
      font-style: normal;
      color: #9cc4fd;
      font-size: 14px;
    }
    span {
      font-size: 14px;
      color: #2d3047;
      font-weight: 500;
      height: 19px;
      width: 50%;
      line-height: 21px;
      display: inline-flex;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      text-overflow: ellipsis;
    }
    .em {
      // float: left;
      font-style: normal;
      color: #9b9b9b;
      font-size: 12px;
      height: 18px;
      margin-left: 5px;
    }
    .rr16 {
      float: left;
      width: 15%;
      height: 62px;
      margin-top: 14px;
      text-align: left;
    }
  }

  .box {
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    width: 92%;
    margin: 0 auto;
  }
  .customData {
    width: 90%;
    height: 136px;
    margin: 0 auto;
    margin-bottom: 10px;
    .Top {
      height: 54px;
      width: 100%;
      line-height: 54px;
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        display: inline-block;
        font-size: 14px;
        color: #4a4a4a;
        font-weight: bold;
        margin: 0 190px 0 10px;
      }
    }
  }
}
.rL16 {
  width: 85%;
  float: left;
  margin-top: 14px;
}
.time {
  width: 100%;
  background: #fff;

  .sum {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 94%;
    margin: 0 auto;
    height: 56px;
    border-top: 1px solid #e8e8e8;
  }

  .btn {
    width: 103px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #f1f2f6;
    border-radius: 14px;
    font-size: 12px;
    color: #000000;
  }
  .btnActive {
    width: 103px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #ecf0fc;
    border-radius: 14px;
    font-size: 12px;
    color: #3974c6;
  }
}
.mainSummary {
  padding-top: 10px;
  background-color: #f2f3f7;
  height: 100vh;
  .icon {
    width: 22px;
    height: 22px;
  }
  .m14 {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    padding-bottom: 3px;
    border-right: 1px solid #eeeeee;
  }
}
</style>