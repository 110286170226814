import request from "../plugins/axios";

// 获取电子名片
export function getVcardData(params) {
  return request({
    url: '/workVcard/get',
    method: 'get',
    params: params
  })
}
//二维码转base64
export function getbase64(params) {
  return request({
    url: '/workVcard/qrcodeBase64',
    method: 'get',
    params: params
  })
}
// 编辑电子名片
export function editVcard(params) {
  return request({
    url: '/workVcard/edit',
    method: 'post',
    data: params
  })
}
// 电子名片首页
export function vcardIndex(params) {
  return request({
    url: '/workVcard/index',
    method: 'get',
    params: params
  })
}
// 通知
// 通知列表
export function noticeList(params) {
  return request({
    url: '/workVcardNotice/index',
    method: 'get',
    params: params
  })
}
// 电子名片通知详情
export function noticeDetail(params) {
  return request({
    url: '/workVcardNotice/detail',
    method: 'get',
    params: params
  })
}
// 活动列表
export function activityList(params) {
  return request({
    url: '/workHotActivity/index',
    method: 'get',
    params: params
  })
}
//活动详情
export function activityDetail(params) {
  return request({
    url: '/workHotActivity/detail',
    method: 'get',
    params: params
  })
}
//分类列表
export function jingRongClassify(params) {
  return request({
    url: '/financialProduct/category',
    method: 'get',
    params: params
  })
}
//产品列表
export function  productList(params) {
  return request({
    url: '/financialProduct/index',
    method: 'get',
    params: params
  })
}
//产品详情
export function productDetail(params) {
  return request({
    url: '/financialProduct/detail',
    method: 'get',
    params: params
  })
}

// 财经咨讯列表
export function newsList(params) {
  return request({
    url: '/industryInformation/index',
    method: 'get',
    params: params
  })
}
//财经资讯详情
export function newsDetail(params) {
  return request({
    url: '/industryInformation/detail',
    method: 'get',
    params: params
  })
}
//财经资讯分类
export function newsCategory(params) {
  return request({
    url: '/industryInformation/category',
    method: 'get',
    params: params
  })
}
//获取点评
export function getComment(params) {
  return request({
    url: '/industryInformation/getComment',
    method: 'get',
    params: params
  })
}
//设置点评
export function setComment(params) {
  return request({
    url: '/industryInformation/comment',
    method: 'post',
    data: params
  })
}
//是否显示电子名片入口
export function isShowVcard(params) {
  return request({
    url: '/workVcard/show',
    method: 'get',
    params: params
  })
}
//功能权限
export function getPermissionsList(params) {
  return request({
    url: '/corp/permission',
    method: 'get',
    params: params
  })
}