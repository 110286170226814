<template>
  <div class="statistics">
    <div class="top">
      <div class="topContent" @click="skipDetail">
        <div class="left" style="float: left; width: 97%; margin-top: 4px">
          <div class="m16">
            <span class="status" v-if="sendStatus == 1">进行中</span>
            <span class="status" v-else>已结束</span>
            <span class="title">{{ employeeData.batchTitle }}</span>
          </div>
          <div class="con">
            {{ createdTime }} 由 {{ employeeData.creator }} 创建
          </div>
        </div>
        <div class="right" style="float: left; margin-top: 20px">
          <img
            style="width: 6px; height: 10px"
            src="../../assets/images/qunfa/rightIcon.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="main">
      <van-tabs
        color="#3974C6"
        title-active-color="#3974C6"
        v-model="active"
        @change="tabChange"
      >
        <van-tab title="员工统计">
          <div class="taskDetail">
            <div class="taskTop">任务概括</div>
            <div class="chart-container" id="myChart" ref="myChart"></div>
            <div class="rate">
              <div class="rate2">{{ rate * 100 }}%</div>
              <div class="rota">完成率</div>
            </div>
            <div class="text" style="margin-top: 33px">
              <div class="m17" style="border-bottom: 1px solid #e8e8e8">
                <div class="custom">预计执行员工</div>
                <div class="num">
                  {{ employeeData.notSendTotal + employeeData.sendTotal }}
                </div>
              </div>
              <div class="m17">
                <div class="custom">未执行员工</div>
                <div class="num">{{ employeeData.notSendTotal }}</div>
              </div>
              <div class="m17">
                <div class="custom">已执行员工</div>
                <div class="num">{{ employeeData.sendTotal }}</div>
              </div>
            </div>
            <div class="btn" @click="remind">提醒员工执行</div>
          </div>
          <div class="dataDetail">
            <div class="taskTop">数据详情</div>
            <!-- <van-search
              v-model="value"
              shape="round"
              placeholder="搜索员工名称"
            /> -->
            <div class="status">
              <div
                class="m19"
                :class="[spanIndex == 0 ? 'active' : '']"
                @click="selectStatus(0)"
              >
                未执行
              </div>
              <div
                class="m19"
                :class="[spanIndex == 1 ? 'active' : '']"
                @click="selectStatus(1)"
              >
                已执行
              </div>
              <div
                class="m19"
                :class="[spanIndex == 2 ? 'active' : '']"
                @click="selectStatus(2)"
              >
                发送失败
              </div>
            </div>
          </div>
          <div class="members">
            <div class="m20">执行员工</div>
            <div class="m20">预计送达客户群</div>
          </div>
          <div class="membersData">
            <div style="width: 94%; margin: 0 auto">
              <div
                class="membersDetail"
                v-for="(item, index) in carryOutList"
                :key="index"
              >
                <div class="left" @click="skipToSendDetail(item)">
                  <img :src="item.employeeAvatar" alt="" />
                  <span> {{ item.employeeName }}</span>
                </div>
                <div class="right" @click="skipToSendDetail(item)">{{ item.sendContactTotal }} ></div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="客户群统计">
          <div class="taskDetail">
            <div class="taskTop">任务概括</div>
            <div class="chart-container" id="myChart2" ref="myChart2"></div>
            <div class="rate">
              <div class="rate2">{{ rate * 100 }}%</div>
              <div class="rota">送达率</div>
            </div>
            <div class="text" style="margin-top: 40px">
              <div class="m17" style="border-bottom: 1px solid #e8e8e8">
                <div class="custom">预计送达客户群</div>
                <div class="num">{{ employeeData.sendRoomTotal }}</div>
              </div>
              <div class="m17">
                <div class="custom">未送达客户群</div>
                <div class="num">{{ employeeData.notReceivedTotal }}</div>
              </div>
              <div class="m17">
                <div class="custom">已送达客户群</div>
                <div class="num">{{ employeeData.receivedTotal }}</div>
              </div>
              <!-- <div class="m17">
                <div class="custom">送达失败客户群</div>
                <div class="num">
                  {{
                    employeeData.notFriendTotal + employeeData.receiveLimitTotal
                  }}
                </div>
              </div> -->
              <!-- <div class="m17">
                <div class="custom">超额送达客户</div>
                <div class="num">13</div>
              </div> -->
            </div>
          </div>
          <div class="dataDetail">
            <div class="taskTop">数据详情</div>
            <!-- <van-search
              v-model="value"
              shape="round"
              placeholder="搜索员工名称"
            /> -->
            <div class="status">
              <div
                class="m19"
                :class="[spanIndex2 == 0 ? 'active' : '']"
                @click="selectStatus2(0)"
              >
                未送达
              </div>
              <div
                class="m19"
                :class="[spanIndex2 == 1 ? 'active' : '']"
                @click="selectStatus2(1)"
              >
                已送达
              </div>
              <!-- <div
                class="m19"
                style="width: 81px"
                :class="[spanIndex == 2 ? 'active' : '']"
                @click="selectStatus(2)"
              >
                送达失败
              </div> -->
              <div
                class="m19"
                :class="[spanIndex2 == 2 ? 'active' : '']"
                @click="selectStatus2(2)"
              >
                送达失败
              </div>
            </div>
          </div>
          <div class="members">
            <div class="m20">群名称</div>
            <div class="m20">群聊成员数量</div>
          </div>
          <div class="membersData">
            <div style="width: 94%; margin: 0 auto">
              <div
                class="membersDetail"
                v-for="(item, index) in roomList"
                :key="index"
              >
                <div class="left" @click="skipToSendDetail(item)">
                  <!-- <img :src="item.contactAvatar" alt="" /> -->
                  <span> {{ item.roomName }}</span>
                </div>
                <div class="right" @click="skipToSendDetail(item)">{{ item.roomEmployeeNum }} ></div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import { roomDataStatistics, receiveDetail, sendDetail,remindRoomEmployee } from "@/api/qunfa";

export default {
  data() {
    return {
      showChart: false,
      spanIndex: 0,
      spanIndex2: 0,
      dom: "myChart",
      showInteraction: false,
      rate: 0,
      employeeData: "",
      batchid: "",
      createdTime: "",
      carryOutList: [],
      roomList: [],
    };
  },
  watch: {
    spanIndex: {
      handler(val, oldVal) {
        this.spanIndex = val;
        this.getMembersDetail();
      },
    },
    spanIndex2: {
      handler(val, oldVal) {
        this.spanIndex2 = val;
        this.getroomList();
      },
    },
  },
  mounted() {
    this.batchid = this.$route.query.id;
    this.getData();
    this.getMembersDetail();
    this.getroomList();
  },
  methods: {
    skipToSendDetail(item){
      this.$router.push({path:'/qunfa/sendDetail',query:{id:this.batchid,employeeId:item.employeeId,type:'tokehuqun'}})
    },
    //提醒员工执行
    remind() {
      remindRoomEmployee({ batchId: this.batchid }).then((res) => {
        this.$toast("提醒成功");
      });
    },
    //群主发送详情
    getMembersDetail() {
      sendDetail({ batchId: this.batchid, sendStatus: this.spanIndex }).then(
        (res) => {
          this.carryOutList = res.data.list;
        }
      );
    },
    //群主接收详情
    getroomList() {
      receiveDetail({
        batchId: this.batchid,
        sendStatus: this.spanIndex2,
      }).then((res) => {
        this.roomList = res.data.list;
      });
    },
    getData() {
      roomDataStatistics({ batchId: this.batchid }).then((res) => {
        this.employeeData = res.data;
        this.createdTime = res.data.createdAt.slice(0, 10);
        this.rate = (
          res.data.sendTotal /
          (res.data.sendTotal + res.data.notSendTotal)
        ).toFixed(2);
        this.getEchart();
      });
    },
    tabChange() {
      if (this.active == 0) {
        this.spanIndex = 0;
        this.dom = "myChart";
        this.getEchart();
      } else if (this.active == 1) {
        this.spanIndex = 0;
        this.dom = "myChart2";
        this.getroomList();
        this.getEchart();
      } else {
        this.spanIndex = 0;
        this.dom = "myChart3";
        this.getEchart();
      }
    },
    skipDetail() {
      this.$router.push({
        path: "/qunfa/qunfaDetail",
        query: { id: this.batchid, selectWay: "tokehuqun" },
      });
    },
    getEchart() {
      this.$nextTick(() => {
        const chart = echarts.init(document.getElementById(this.dom));
        console.log("ccc", chart);
        const option = {
          tooltip: {
            show: false,
          },
          series: [
            {
              name: "饼图",
              type: "pie",
              //内圆和外圆的比例
              radius: ["70%", "90%"],
              avoidLabelOverlap: false,
              // emphasis：设置鼠标放到哪一块扇形上面的时候，扇形样式、阴影
              emphasis: {
                label: {
                  show: false,
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: this.rate * 100,
                  itemStyle: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#3975C6", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#64CFAE", // 100% 处的颜色
                        },
                      ],
                      global: false, // 缺省为 false
                    },
                  },
                },
                {
                  value: 100 - this.rate * 100,
                  itemStyle: {
                    color: "#E2E9FD",
                  },
                },
              ],
              animationDuration: 2000,
              animationEasing: "easeOutBounce",
              animationDelay: function (idx) {
                return idx * 100;
              },
              animationDurationUpdate: 2000,
              animationEasingUpdate: "easeOutBounce",
            },
          ],
        };
        chart.setOption(option, true);
        let progress = 0;
        var that = this;
        // setInterval(function () {
        //   if (progress <= that.rate) {
        //     progress += 10;
        //     console.log('progress',progress)
        //     option.series[0].data[0].value = progress;
        //     chart.setOption(option, true);
        //   }
        // }, 500);
      });
    },
    selectStatus(index) {
      this.spanIndex = index;
    },
    selectStatus2(index) {
      this.spanIndex2 = index;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-tabs__nav--line {
  padding-bottom: 0;
}
/deep/ .van-tabs__line {
  bottom: 0;
}
/deep/ .van-tabs__nav {
  background-color: transparent;
}
.chart-container {
  position: relative;
  display: inline-block;
  margin: 21px 31px 6px 14px;
  width: 150px;
  height: 150px;
}
.rate {
  position: absolute;
  text-align: center;
  width: 145px;
  top: 174px;
  left: 30px;
  font-size: 22px;
  font-weight: bold;
  color: "#000";
}
.rate2 {
  font-size: 22px;
  font-weight: bold;
  color: "#000";
}
.rota {
  font-size: 12px;
  color: #9b9b9b;
}
.text {
  display: inline-block;
  vertical-align: top;
  margin-top: 43px;
  width: 143px;
}
.btn {
  width: 320px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  margin: 0 auto;
  background: #3974c6;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.m17 {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .custom {
    font-size: 12px;
    color: #9b9b9b;
  }
  .num {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
}
.statistics {
  height: 100vh;
  background-color: #f2f3f7;
  overflow: auto;
}
.top {
  width: 94%;
  height: 83px;
  margin: 0 auto;
  margin-top: 13px;
  background: #ffffff;
  border-radius: 5px;
  .topContent {
    width: 94%;
    margin: 0 auto;
    overflow: hidden;
    .m16 {
      display: flex;
      align-items: center;
      height: 16px;
      line-height: 16px;
      margin: 16px 0 12px 0;
    }
    .status {
      padding: 3px;
      background: #ebeffb;
      border-radius: 4px;
      font-size: 10px;
      color: #3974c6;
      margin-right: 8px;
    }
    .title {
      height: 18px;
      line-height: 18px;
      width: 80%;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .con {
      font-size: 11px;
      color: #9b9b9b;
      -webkit-line-clamp: 2; //固定三行省略好
      display: -webkit-box; // 旧版弹性盒子
      -webkit-box-orient: vertical; // 主轴垂直
      overflow: hidden; // 隐藏
    }
  }
}
.main {
  margin-top: 10px;
  .taskDetail {
    width: 94%;
    margin: 0 auto;
    min-height: 200px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 9px;
    padding-bottom: 20px;
  }
  .taskTop {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    padding-left: 13px;
    border: 1px solid #e8e8e8;
  }
}
.dataDetail {
  margin: 0 auto;
  margin-top: 12px;
  width: 94%;
  height: 98px;
  background: #ffffff;
  border-radius: 5px;
  .status {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;
    margin-top: 10px;
    .m19 {
      text-align: center;
      width: 30%;
      height: 28px;
      line-height: 28px;
      background: #f1f2f6;
      border-radius: 14px;
      font-size: 12px;
      color: #000;
    }
    .active {
      background: #ecf0fc;
      color: #3974c6;
    }
  }
}
.members {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  width: 90%;
  .m20 {
    font-size: 12px;
    color: #515151;
  }
}
.membersData {
  width: 94%;
  margin: 0 auto;
  min-height: 144px;
  background: #ffffff;
  border-radius: 5px;
  .membersDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 68px;
    border-bottom: 1px solid #f5f5f5;
    .left {
      img {
        width: 32px;
        height: 32px;
      }
      span {
        font-size: 14px;
        color: #303030;
      }
    }
    .right {
      font-size: 14px;
      color: #303030;
    }
  }
}
</style>