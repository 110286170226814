<template>
    <div class="page">
        <van-dialog v-model="dialogShow" :showConfirmButton="false" :showCancelButton="false" class="dialogBox">
            <van-loading size="32px" type="spinner"></van-loading>
        </van-dialog>
        <div class="conBox">
            <div class="con">
                <div class="t1">
                    <div class="t1-left">商户编号</div>
                    <div class="t1-right">{{merchantData.serial}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">营业执照名称</div>
                    <div class="t1-right bor">
                        <!-- {{merchantData.businessLicenseName}} -->
                        <van-field :readonly="isEditFir" v-model="merchantData.businessLicenseName" class="labelInput"
                            placeholder="请输入内容" />
                    </div>
                </div>
                <div class="t1">
                    <div class="t1-left">商户类型</div>
                    <div class="t1-right">{{merchantData.type}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">营业执照号</div>
                    <div class="t1-right">{{merchantData.businessLicenseNumber}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">法人姓名</div>
                    <div class="t1-right bor">
                        <van-field :readonly="isEditFir" v-model="merchantData.legalName" class="labelInput"
                            placeholder="请输入内容" />
                        <!-- {{merchantData.legalName}} -->
                    </div>
                </div>
                <div class="t1">
                    <div class="t1-left">联系电话</div>
                    <div class="t1-right bor">
                        <van-field :readonly="isEditFir" v-model="merchantData.contactPhone" class="labelInput"
                            placeholder="请输入内容" />
                        <!-- {{merchantData.contactPhone}} -->
                    </div>
                </div>
                <div class="t1">
                    <div class="t1-left">地址</div>
                    <div class="t1-right bor" @click.stop="handleClick(merchantData)">
                        <van-field :readonly="isEditFir" v-model="merchantData.address" class="labelInput"
                            placeholder="请输入内容">
                            <template #right-icon>
                                <img class="t1-right-img" src="../../../assets/images/ins/loca.png" />
                            </template>
                        </van-field>
                    </div>
                </div>
                <div class="t1">
                    <div class="t1-left">经营状态</div>
                    <div class="t1-right">{{merchantData.businessStatus}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">经办支行</div>
                    <div class="t1-right">{{merchantData.handlingBranch}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户经理</div>
                    <div class="t1-right">{{merchantData.accountManagerName}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">开户日期</div>
                    <div class="t1-right">{{merchantData.accountOpeningDate}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">POS终端台数</div>
                    <div class="t1-right">{{merchantData.posNum}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">终端序列号</div>
                    <div class="t1-right">{{merchantData.terminalSerialNumber}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">巡检人</div>
                    <div class="t1-right">{{merchantData.inspectionName}}</div>
                </div>
                <div class="t1-btn" v-if="isEditFir" @click="isEditFirFun">
                    编辑商户
                </div>
                <div class="t1-btnin" v-if="isEdit">
                    <div class="cancel" @click="isEditFirFun">取消</div>
                    <div class="sub" @click="merChantEditFun">确定</div>
                </div>
            </div>
            <div class="nextCon m10">
                <div class="title">历史巡检内容记录</div>
                <van-steps direction="vertical" :active="0">
                    <van-step v-for="(item,index) in historyData" :key="index">
                        <div class="stepBox">
                            <div class="heard">
                                <div class="time">{{item.createdAt}}</div>
                                <div class="status" :style="setColor(item.reviewStatus)">
                                    {{item.reviewStatus}}
                                </div>
                            </div>
                            <div class="content" v-if="item.launch">
                                <div class="text" v-if="item.createTime">巡检上传时间：{{item.createTime}}</div>
                                <div class="text">巡检人：{{item.inspectionPeople}}</div>
                                <div class="text statusBtn">
                                    <span>状态：{{item.reviewStatus}}</span>
                                    <span v-if="item.reviewStatus=='未巡检'" class="btn" @click="goInspe(item,'insSubmit')">巡检</span>
                                    <span v-if="item.reviewStatus=='审核驳回'" class="btn" @click="goInspe(item,'taskDetail')">巡检</span>
                                </div>
                                <div class="text">巡检资料：{{(item.reviewStatus=='未巡检'||item.reviewStatus=='巡检过期')?'无':''}}
                                </div>
                                <div v-if="item.reviewStatus!='未巡检'&&item.reviewStatus!='巡检过期'">
                                    <div class="con p10 noPadd">
                                        <div class="title">巡检照片</div>
                                        <div class="line"></div>
                                        <div class="li">
                                            <span class="li-title">商户门头照</span>
                                            <img class="showimg" @click="preview(item.merchantImgPhoto)"
                                                :src="item.merchantImgPhoto" alt="">
                                        </div>
                                        <div class="li">
                                            <span class="li-title">收银台</span>
                                            <img class="showimg" @click="preview(item.merchantImgCashier)"
                                                :src="item.merchantImgCashier" alt="">
                                        </div>
                                        <div class="li">
                                            <span class="li-title">经营场所照</span>
                                            <img class="showimg" @click="preview(item.merchantImgPremisesPhoto)"
                                                :src="item.merchantImgPremisesPhoto" alt="">
                                        </div>
                                        <div class="li">
                                            <span class="li-title">机具(收款码）</span>
                                            <img class="showimg" @click="preview(item.merchantImgPaymentCode)"
                                                :src="item.merchantImgPaymentCode" alt="">
                                        </div>
                                        <div class="li">
                                            <span class="li-title">工商信息截图</span>
                                            <img class="showimg" @click="preview(item.merchantImgInformation)"
                                                :src="item.merchantImgInformation" alt="">
                                        </div>
                                        <div class="li">
                                            <span class="li-title">其他</span>
                                            <img class="showimg" @click="preview(item.merchantImgOther)"
                                                :src="item.merchantImgOther" alt="">
                                        </div>
                                    </div>
                                    <div class="con m10 p10 noPadd">
                                        <div class="title">巡检信息</div>
                                        <div class="line"></div>
                                        <div class="quBox">
                                            <div class="quBox-title">1.商户机具是否移机使用？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationOne"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">2.商户机具是否被改造/加装其它设备？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationTwo"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">3.商户机具是否完整？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationThr"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">4.商户机具是否处于正常工作状态？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationFou"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">5.商户交易凭证保存是否符合规定？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationFiv"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">6.商户机具所有权是否变动？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationSix"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">7.商户是否实质性经营，是否与营业执照经营业务相符？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationSev"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">8.商户处是否有其它收单机构POS设备？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationEig"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">9.商户交易资金结算是否正常？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationNie"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">10.商户工商注册状态是否正常？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationTen"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">11.商户受益所有人是否发生变更？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationEle"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">12.支付终端附近是否有可以获取付款人密码等敏感信息的录像设施、营业场所内是否出现其他可疑收款条码？
                                            </div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationTwe"
                                                    direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">13.商户是否有语音播报器？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationFour" direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">14.语音播报器是否正常？</div>
                                            <div class="radioBox">
                                                <van-radio-group icon-size="15px" v-model="item.informationFif" direction="horizontal">
                                                    <van-radio :name="1">是</van-radio>
                                                    <van-radio :name="2">否</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="quBox">
                                            <div class="quBox-title">15.其它</div>
                                            <div class="radioBox">
                                                <input type="text" v-model="item.informationThi" placeholder="请输入内容"
                                                    class="text_input" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="con m10 p10 noPadd">
                                        <div class="title">培训信息 <span class="tips">（至少选一项）</span></div>
                                        <div class="line"></div>
                                        <div>
                                            <van-checkbox-group v-model="currntTrainingContent" class="check-group"
                                                direction="horizontal">
                                                <van-checkbox shape="square" v-for="(item, index) in options"
                                                    :key="index" :name="item.key" icon-size="15px" class="check-item">
                                                    <span class="check-text">{{ item.value }}</span>
                                                </van-checkbox>
                                            </van-checkbox-group>
                                        </div>
                                    </div>
                                    <div class="con m10 p10 noPadd">
                                        <div class="title">异常情况 <span class="tips">（选填）</span></div>
                                        <div class="line"></div>
                                        <div class="abnormal">
                                            <div class="title">异常</div>
                                            <div class="right">
                                                <van-radio-group icon-size="15px"  v-model="item.abnormalStatus"
                                                    direction="horizontal" class="row-checkbox">
                                                    <van-radio shape="square" :name="1"
                                                        class="row-square radio">无</van-radio>
                                                    <van-radio shape="square" :name="2"
                                                        class="row-square radio">异常</van-radio>
                                                </van-radio-group>
                                            </div>
                                        </div>
                                        <div class="abnormal">
                                            <div class="title">业务类型</div>
                                            <div class="right">
                                                <van-checkbox-group class="row-checkbox" v-model="currentAbnormalType"
                                                    :disabled="item.abnormalStatus==1">
                                                    <van-checkbox shape="square" icon-size="15px" :name="1"
                                                        class="row-square"><span class="text">条码</span></van-checkbox>
                                                    <van-checkbox shape="square" icon-size="15px" :name="2"
                                                        class="row-square"><span class="text">pos</span></van-checkbox>
                                                </van-checkbox-group>
                                            </div>
                                        </div>
                                        <div class="abnormal">
                                            <div class="title">异常情况</div>
                                            <div class="right">
                                                <van-checkbox-group class="row-checkbox more"
                                                    v-model="abnormalSituation" :disabled="item.abnormalStatus==1">
                                                    <van-checkbox shape="square" icon-size="15px" :name="item.key"
                                                        class="row-square" v-for="(item,index) in options3"
                                                        :key="index"><span
                                                            class="text">{{item.value}}</span></van-checkbox>
                                                </van-checkbox-group>
                                            </div>
                                        </div>
                                        <div class="abnormal" v-if="item.abnormalStatus==2">
                                            <div class="title">备注</div>
                                        </div>
                                        <div class="abnormal" v-if="item.abnormalStatus==2">
                                            <div class="column">
                                                <input type="text" v-model="item.abnormalRemark" placeholder="请输入内容" class="text_input" />
                                                <img class="showimg" v-if="item.abnormalImg!=''" @click="preview(item.abnormalImg)"
                                                :src="item.abnormalImg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btnImg">
                                <img v-if="!item.launch" src="../../../assets/images/ins/down.png" alt=""
                                    @click="toggleExpand(item,index)">
                                <img v-else src="../../../assets/images/ins/up.png" alt=""
                                    @click="toggleExpand(item,index)">
                            </div>
                        </div>
                    </van-step>
                </van-steps>
            </div>
        </div>
        <!--  员工  -->
        <employeeIndex ref="employee" @change="userChange" />
        <baidu-map style="display:none;" ak="uSDQM0SXJgOKGoiDmMxh5a1BR0zhoKVJ" @ready="handler"></baidu-map>
    </div>
</template>

<script>
    import { store, upload } from "@/api/contactWelcome";
    import employeeIndex from "./employeeIndex";
    import { openUserLocation } from "@/utils/wxCodeAuth";
    import { inspDetail, merchantDetail, transfer, inspectionSubmit, woHistory, merchantEdit, setLocation } from "@/api/Inspection";
    import { ImagePreview } from 'vant';
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    export default {
        components: {
            employeeIndex,
            BaiduMap
        },
        data() {
            return {
                detailJson: {
                    merchantImgPhoto: '',
                    merchantImgCashier: '',
                    merchantImgPremisesPhoto: '',
                    merchantImgPaymentCode: '',
                    merchantImgInformation: '',
                    merchantImgOther: '',
                    informationOne: undefined,
                    informationTwo: undefined,
                    informationThr: undefined,
                    informationFou: undefined,
                    informationFiv: undefined,
                    informationSix: undefined,
                    informationSev: undefined,
                    informationEig: undefined,
                    informationNie: undefined,
                    informationTen: undefined,
                    informationEle: undefined,
                    informationTwe: undefined,
                    informationFour: undefined,
                    informationFif: undefined,
                    informationThi: '',
                    trainingContent: [],
                    abnormalStatus: 1,
                    abnormalType: undefined,
                },
                merchantData: {},
                serviceList: [],// 请选择成员
                gc: '',
                params: {},
                imgData: {
                    merchantImgPhoto: [],
                    merchantImgCashier: [],
                    merchantImgPremisesPhoto: [],
                    merchantImgPaymentCode: [],
                    merchantImgInformation: [],
                    merchantImgOther: [],
                },
                options: [
                    {
                        key: 1,
                        value: "POS及二维码交易的操作流程和要求"
                    },
                    {
                        key: 2,
                        value: "银行卡的识别、伪卡辨别、没收卡的处理及受理操作 流程"
                    },
                    {
                        key: 4,
                        value: "刷卡交易完成后，需核对持卡人签名与卡背面预留签 名是否一致"
                    },
                    {
                        key: 8,
                        value: "银行卡交易资金结算、对账数据获取方式及要求、调 账、差错处理流程"
                    },
                    {
                        key: 16,
                        value: "明确消费撤销、退货、消费调整等高风险交易的授权管理"
                    },
                    {
                        key: 32,
                        value: "POS机受理IC卡及非接触式IC卡的操作方法及注意事项"
                    },
                    {
                        key: 64,
                        value: "交易单据的保管方式与保管时限要求"
                    },
                    {
                        key: 128,
                        value: "机具及二维码的正确使用方法和简单故障排查"
                    },
                    {
                        key: 256,
                        value: "常见欺诈行为的识别及应用技巧"
                    },
                    {
                        key: 512,
                        value: "商户风险责任及风险防范知识"
                    },
                    {
                        key: 1024,
                        value: "违规行为及救济措施"
                    },
                    {
                        key: 2048,
                        value: "调单的处理要求"
                    },
                    {
                        key: 4096,
                        value: "财务处理流程、投诉、差错、争议处理要求"
                    }
                ],
                options2: [
                    {
                        key: 1,
                        value: "条码"
                    }, {
                        key: 2,
                        value: "POS"
                    }],
                options3: [
                    {
                        key: 1,
                        value: "终端丢失/损坏"
                    }, {
                        key: 2,
                        value: "商户失联"
                    }, {
                        key: 4,
                        value: "停业"
                    }, {
                        key: 8,
                        value: "迁址"
                    }, {
                        key: 16,
                        value: "其他"
                    }],
                options4: [
                    {
                        key: 1,
                        value: "无"
                    }, {
                        key: 2,
                        value: "异常"
                    }],
                historyData: {},
                currntTrainingContent: [],
                currntAbnormalStatus: [],
                currentAbnormalType: [],
                abnormalSituation: [],
                isEditFir: true,
                isEdit: false,
                dialogShow: false,
            }
        },
        watch: {},
        created() {
            this.getMerData()
            this.getHistory()
        },
        methods: {
            getMerData() {
                merchantDetail({ id: this.$route.query.id }).then((res) => {
                    if (res.code == 200) {
                        this.merchantData = res.data
                    }
                })
            },
            getHistory() {
                woHistory({ merchantId: this.$route.query.id }).then((res) => {
                    if (res.code == 200) {
                        this.historyData = res.data.map((item) => {
                            item.launch = false;//添加展开选项
                            return item
                        })
                    }
                })
            },
            parseTrainingContent(trainingContent, type) {
                const dataSources = {
                    trainingContent: this.options,
                    abnormalType: this.options2,
                    abnormalSituation: this.options3,
                };
                let currentData = dataSources[type] || [];
                let selectedKeys = [];
                // 遍历options数组，检查每个option的key是否在trainingContent中被设置
                currentData.forEach((option) => {
                    if ((trainingContent & option.key) === option.key) {
                        selectedKeys.push(option.key);
                    }
                });
                return selectedKeys

            },
            initImageFields() {
                // 遍历 params 中的图片字段
                for (const field in this.detailJson) {
                    if (this.detailJson.hasOwnProperty(field) && this.detailJson[field]) {
                        // 构建图片数据对象
                        const imageData = {
                            url: this.detailJson[field], // 假设 params 中的值是图片的 Base64 或 URL
                            // name: field, // 用于标识图片来自哪个字段
                        };
                        // 将图片数据添加到对应的 fileList 数组中
                        this.imgData[field] = [imageData];
                    }
                }
            },
            //签字
            goAutogra() {
                this.$router.push({
                    path: '/Inspection/autograph',
                    query: {
                        id: this.$route.query.id,
                    },

                });
            },
            getBack() {
                this.$router.back();
            },
            setColor(type) {
                const colorMap = {
                    '未巡检': 'background: #FFDAD8; color: #D2261D',
                    '待审核': 'background: #EBEFFB; color: #3974C6', // 假设的颜色，根据实际情况调整
                    '审核通过': 'background: #D7F3D4; color: #39A52E', // 假设的颜色，根据实际情况调整
                    '审核驳回': 'background: #F2F3F7; color: #9B9B9B', // 假设的颜色，根据实际情况调整
                    '巡检过期': 'background: #F2F3F7; color: #9B9B9B', // 假设的颜色，根据实际情况调整
                };
                return colorMap[type] || ''; // 返回对应的状态颜色，如果状态不存在则返回空字符串
            },
            merchantTypeFun(type) {
                const colorMap = {
                    1: '周期巡检',
                    2: '风险巡检',
                    3: '临时巡检',
                };
                return colorMap[type] || ''; // 返回对应的状态颜色，如果状态不存在则返回空字符串
            },
            // 选择员工
            userChange(data, info) {
                let employeeId = data.map(i => i.id)[0]
                //走转接流程
                let params = {
                    id: this.$route.query.id,
                    employeeId: employeeId
                }
                transfer(params).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('已提交转接')
                    }
                })
            },
            // 文件大小
            onOversize(file) {
                this.$message.warning("文件大小不能超过 10MB");
            },
            // 上传图片文件
            afterReadImg(file, type) {
                let currentType = ''
                if (this.imgData.merchantImgPhoto.includes(file)) {
                    currentType = 'merchantImgPhoto'
                }
                if (this.imgData.merchantImgCashier.includes(file)) {
                    currentType = 'merchantImgCashier'
                }
                if (this.imgData.merchantImgPremisesPhoto.includes(file)) {
                    currentType = 'merchantImgPremisesPhoto'
                }
                if (this.imgData.merchantImgPaymentCode.includes(file)) {
                    currentType = 'merchantImgPaymentCode'
                }
                if (this.imgData.merchantImgInformation.includes(file)) {
                    currentType = 'merchantImgInformation'
                }
                if (this.imgData.merchantImgOther.includes(file)) {
                    currentType = 'merchantImgOther'
                }
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    this.detailJson[currentType] = res.data.fullPath;

                });
            },
            submitBtn() {
                let params = {
                    ...this.detailJson,
                    id: this.$route.query.id
                }
                inspectionSubmit(params).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('提交成功')
                        this.$router.back();
                    }
                })
            },
            //是否展开全文
            toggleExpand(item, index) {
                if (!this.historyData[index].launch) {
                    this.currntTrainingContent = this.parseTrainingContent(item.trainingContent, 'trainingContent');//培训信息
                    this.currentAbnormalType = this.parseTrainingContent(item.abnormalType, 'abnormalType');//业务类型
                    this.abnormalSituation = this.parseTrainingContent(item.abnormalSituation, 'abnormalSituation');//异常类型
                }
                this.historyData[index].launch = !this.historyData[index].launch
            },
            //预览图片
            preview(img) {
                ImagePreview([img]);
            },
            isEditFirFun() {
                this.isEdit = !this.isEdit
                this.isEditFir = !this.isEditFir
            },
            //商户编辑
            merChantEditFun() {
                if (this.merchantData.businessLicenseName == '' || this.merchantData.legalName == '' || this.merchantData.contactPhone == '' || this.merchantData.address == '') {
                    return this.$message.warning("内容为空，请检查！");
                }
                let params = {
                    id: this.$route.query.id,
                    businessLicenseName: this.merchantData.businessLicenseName,
                    legalName: this.merchantData.legalName,
                    contactPhone: this.merchantData.contactPhone,
                    address: this.merchantData.address,
                }
                merchantEdit(params).then((res) => {
                    if (res.code == 200) {
                        this.getMerData()
                        this.$message.success('编辑成功')
                        this.isEditFirFun()

                    }
                })
            },
            goInspe(row,type) {
                if(type=='insSubmit'){
                    this.$router.push({
                    path: '/Inspection/insSubmit',
                    query: {
                        id: row.id,
                    },
                    });
                }else {
                    this.$router.push({
                    path: '/Inspection/taskDetail',
                    query: {
                        id: row.id,
                        merchantId: this.$route.query.id
                    },
                    });
                }
            },
            async handler({ BMap, map }) {
                //创建地址解析器实例
                this.gc = new BMap.Geocoder();
            },
            // 包装后的点击事件处理函数
            handleClick(res) {
                if (!this.isEditFir) return//当处于编辑情况无法前往地址
                this.immediateDebounce(() => this.showMap(res), 1000); // 3000毫秒内多次点击只执行一次
            },
            // 立即执行的防抖函数
            immediateDebounce(func, delay) {
                if (!this.debounceTimer) {
                    // 如果没有设置过计时器，则立即执行func，并设置计时器
                    func();
                    this.debounceTimer = setTimeout(() => {
                        this.debounceTimer = null; // 3秒后重置计时器
                    }, delay);
                } else {
                    // 如果计时器已设置（即在延迟时间内再次点击），则清除现有计时器并重新开始计时
                    clearTimeout(this.debounceTimer);
                    this.debounceTimer = setTimeout(() => {
                        this.debounceTimer = null;
                    }, delay);
                }
            },
            //地图显示
            async showMap(row) {
                this.dialogShow = true
                if (row.latitude == '') {//需要调用接口获取经纬度
                    try{
                    var geocoder = new TMap.service.Geocoder(); // 新建一个正逆地址解析类
                    geocoder.getLocation({ address: row.address })
                        .then((point) => {
                        if (point) {
                            let params = {
                                id: row.id,
                                longitude: point.result.location.lng,
                                latitude: point.result.location.lat,
                            }
                            this.setlngInfo(params)
                            let openParams = {
                                address: row.address,
                                lng: point.result.location.lng,
                                lat: point.result.location.lat,
                            }
                            setTimeout(() => {
                                this.dialogShow = false
                                openUserLocation(openParams)
                            }, 1000)
                        } else {
                            this.$toast("地址解析失败~");
                            this.dialogShow = false
                        }
                    }).catch((err) => {
                            this.dialogShow = false
                            this.$toast("地址解析失败~");
                        });
                    }catch (e) {
                        this.dialogShow = false
                        this.$toast("地址解析失败~");
                    }
                } else {
                    let openParams = {
                        address: row.address,
                        lng: row.longitude,
                        lat: row.latitude,
                    }
                    setTimeout(() => {
                        this.dialogShow = false
                        openUserLocation(openParams)
                    }, 1000)
                }
            },
            /**
            * @description 百度转腾讯
            * @description BD-09 转  GCJ-02
            * @param{*}list [lng,lat]
            */
            bd09ToGcj02(bdLng, bdLat) {
                const x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                let x = bdLng - 0.0065;
                let y = bdLat - 0.006;
                let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
                let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
                let ggLng = z * Math.cos(theta);
                let ggLat = z * Math.sin(theta);

                return { lng: ggLng, lat: ggLat };
            },
            //设置经纬度
            setlngInfo(params) {
                setLocation(params).then(() => {
                    this.getMerData();
                })
            }

        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-radio__label {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
    }

    /deep/.van-checkbox__label {
        display: flex;
        align-items: center;
    }

    .page {
        width: 100%;
        padding: 10px 0px;
        background: #F2F3F7;
        min-height: 100vh;

        .conBox {
            width: 100%;
            padding: 0px 13px;
        }

        .con {
            padding: 0px 16px;
            padding-bottom: 10px;
            padding-top: 1px;
            background: #FFFFFF;
            border-radius: 5px;

            .title {
                font-weight: bold;
                font-size: 17px;
                color: #000000;

                .tips {
                    font-weight: 400;
                    font-size: 14px;
                    color: #9B9B9B;
                }
            }

            .line {
                margin-top: 13px;
                width: 100%;
                border-top: 1px solid #E8E8E8;
            }

            .li {
                display: flex;
                flex-direction: column;

                .li-title {
                    font-weight: 400;
                    font-size: 15px;
                    color: #000000;
                    margin: 15px 0px;
                }

                .showimg {
                    width: 100px;
                    height: 100px;
                }
            }

            .quBox {
                margin-top: 25px;

                .quBox-title {
                    font-weight: 400;
                    font-size: 14px;
                    color: #000000;
                }

                .radioBox {
                    margin-top: 20px;

                    .text_input {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #BBBBBB;
                        padding: 5px;
                    }
                }
            }

            .check-group {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                .check-item {
                    margin: 10px 0;

                    .check-text {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }
                }
            }

            .abnormal {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 9px 0;

                .title {
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                }

                .right {
                    width: 70%;

                    .row-checkbox {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .row-square {
                            flex-basis: calc(50% - 5px);
                            /* 减去间距的一半，假设两边各留2.5px间距 */
                            box-sizing: border-box;

                            /* 确保内边距和边框被包含在宽度内 */
                            .text {
                                font-weight: 400;
                                font-size: 14px;
                                color: #000000;
                            }
                        }

                        .row-square:nth-child(2n+3) {
                            margin-top: 10px;
                            /* 为奇数位置的元素添加底部外边距 */
                        }

                        .radio {
                            margin-right: 0 !important;
                        }

                    }
                }
                .column{
                    width: 100%;

                    .text_input {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #BBBBBB;
                        padding: 5px;
                        margin-bottom: 15px;
                    }
                    .showimg {
                    width: 100px;
                    height: 100px;
                }
                }
            }

            .t1 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px 0;

                .t1-left {
                    font-weight: 400;
                    font-size: 15px;
                    color: #9B9B9B;
                }

                .t1-right {
                    font-weight: 400;
                    font-size: 15px;
                    color: #515151;
                    max-width: 70%;

                    .t1-right-img {
                        margin-left: 5px;
                        width: 11px;
                        height: 11px;
                    }
                }

                .bor {
                    width: 100%;
                    border-bottom: 1px solid #E8E8E8;
                }
            }

            .t1-btn {
                margin: 20px 0;
                width: 100%;
                background: #3974C6;
                border-radius: 5px;
                padding: 13px;
                font-weight: bold;
                font-size: 17px;
                color: #FFFFFF;
                text-align: center;

            }

            .t1-btnin {
                margin: 20px 0;
                width: 100%;
                display: flex;
                flex-direction: row;

                .cancel {
                    width: 30%;
                    background: #F1F2F6;
                    border-radius: 5px;
                    padding: 13px;
                    font-weight: bold;
                    font-size: 17px;
                    color: #595A5E;
                    text-align: center;
                }

                .sub {
                    width: 70%;
                    background: #3974C6;
                    border-radius: 5px;
                    padding: 13px;
                    font-weight: bold;
                    font-size: 17px;
                    color: #FFFFFF;
                    text-align: center;
                }
            }
        }

        .noPadd {
            padding: 0px;
        }

        .m10 {
            margin-top: 10px;
        }

        .p10 {
            padding-top: 15px;
        }


        .nextCon {
            background: #FFFFFF;
            border-radius: 5px;
            width: 100%;
            padding: 16px 12px;

            .title {
                font-weight: bold;
                font-size: 17px;
                color: #000000;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .tips {
                    font-weight: 400;
                    font-size: 12px;
                    height: 21px;
                    line-height: 21px;
                    padding: 0px 4px;
                    border-radius: 3px;
                }
            }

            .line {
                margin-top: 13px;
                width: 100%;
                border-top: 1px solid #E8E8E8;
            }

            .infoText {
                font-weight: 400;
                font-size: 15px;
                color: #9B9B9B;
                margin-top: 10px;
            }
        }

        .footer {
            position: relative;
            width: 100%;
            padding: 15px;
            background: #FFFFFF;
            box-shadow: 0px 1 3px 0px rgba(0, 0, 0, 0.26);

            .box {
                background: #FFFFFF;

                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                .btn1 {
                    padding: 6px 20px;
                    background: #F2F3F7;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #9B9B9B;
                    text-align: center;
                }

                .btn2 {
                    text-align: center;
                    padding: 6px 20px;
                    background: #3974C6;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .stepBox {
        background: #F8F8F8;
        border-radius: 4px;
        padding: 18px 15px;

        .heard {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
                padding: 6px 12px;
                border-radius: 50%;
                background: #FFFFFF;
                border-radius: 15px;
                color: #4A4A4A;
            }

            .status {
                font-weight: 400;
                font-size: 12px;
                height: 21px;
                line-height: 21px;
                padding: 0px 4px;
                border-radius: 3px;
            }
        }

        .content {
            margin-top: 10px;
            padding: 10px;
            background: #FFFFFF;
            border-radius: 4px;

            .text {
                font-weight: 400;
                font-size: 13px;
                color: #000000;
                margin: 5px 0px;

            }

            .statusBtn {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .btn {
                    width: 50px;
                    height: 24px;
                    background: #3974C6;
                    border-radius: 12px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #FFFFFF;
                    line-height: 24px;
                    text-align: center;
                }
            }
        }

        .btnImg {
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                width: 10px;
                height: 6px;
            }
        }
    }

    /deep/ .van-cell {
        padding: 0px;
    }

    /deep/ .van-field__control {
        text-align: right;
    }

    .labelInput {
        width: 100%;
        border: 0px solid #BBBBBB;
    }

    .dialogBox {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
    }
</style>