<template>
    <div>
        <van-action-sheet v-model="showPopup" title="筛选条件">
            <div class="border"></div>
            <div class="content">
                <div class="top">
                    <div class="m16">种植种类</div>
                    <div class="wayBox">
                        <div class="way" v-for="(item, index) in typeData" :key="item" @click="toggleSelection(item, index)"
                        :class="[selectAll && item === '全部' ? 'Active' : filterParam.reviewStatus.includes(item) ? 'Active' : 'noActive']">
                        {{ item }}
                        </div>
                    </div>
                </div>
                <div class="top">
                    <div class="m16">养殖种类</div>
                    <div class="wayBox">
                        <!-- <div class="way" :class="[filterParam.merchantType.includes(item) ? 'Active' : 'noActive']"
                            v-for="(item, index) in inspectionType" :key="item" @click="selectType(item)">
                            {{ item }}
                        </div> -->
                        <div class="way" v-for="(item, index) in inspectionType" :key="item" @click="selectType(item, index)"
                        :class="[selectAll2 && item === '全部' ? 'Active' : filterParam.merchantType.includes(item) ? 'Active' : 'noActive']">
                        {{ item }}
                        </div>
                    </div>
                </div>
                <!-- <van-cell-group class="creatT">
                    <van-field readonly v-model="filterParam.startTime" input-align="right" right-icon="arrow"
                        label="开始时间" placeholder="请选择" @click="$refs.calendar.$show('start')" />
                </van-cell-group>
                <van-cell-group class="creatT">
                    <van-field readonly v-model="filterParam.endTime" input-align="right" right-icon="arrow"
                        label="结束时间" placeholder="请选择" @click="$refs.calendar.$show('end')" />
                </van-cell-group> -->
                <van-cell-group class="creatT">
                    <van-field readonly v-model="value2" input-align="right" right-icon="arrow" label="添加时间"
                        placeholder="请选择" @click="$refs.calendar.$show()" />
                </van-cell-group>
            </div>
            <div class="bottom">
                <div class="del" @click="reset">重置</div>
                <div class="save" @click="confirm">确定</div>
            </div>
        </van-action-sheet>
        <Calendar ref="calendar" @changeTime="changeT" />
    </div>
</template>
<script>
    import Calendar from "../../../components/calendar.vue";
    export default {
        components: { Calendar },
        data() {
            return {
                wayIndex: -1,
                typeIndex: -1,
                statusIndex: 0,
                showPopup: false,
                filterParam: {
                    // merchantName: '',
                    reviewStatus: [],
                    merchantType: [],
                    startTime: '',
                    endTime: ''
                },
                way: [
                    { name: "员工一键发送", value: 1 },
                    { name: "发给我的客户", value: 2 },
                ],
                status: [
                    { name: "未开始", value: 0, statusAct: true },
                    { name: "进行中", value: 1, statusAct: false },
                    { name: "已结束", value: 2, statusAct: false },
                ],
                value1: "",
                value2: "",
                employeeIds: [],
                operateData: ['存续', '注销', '吊销', '撤销', '迁出', '设立中', '清算中', '停业', '其他', '歇业', '责令关闭', '已取缔非法社会组织', '涉嫌非法社会组织'],//经营状态
                typeData: ['全部','棉花', '小麦', '玉米', '大豆', '荞麦', '水果', '辣椒', '其他'],
                inspectionType: [ '全部','牛', '羊', '骆驼', '马', '其他'],
                selectedIndexes: [], // 存储选中的项的索引或值
                selectAll: false, // 用于跟踪“全部”是否被选中
                selectAll2: false, // 用于跟踪“全部”是否被选中
            };
        },
        methods: {
            $show() {
                this.showPopup = true;
            },
            // 获取员工Id
            getCount() {
                this.filterParam.userId = this.$refs.groups.get().map((v) => {
                    return v.id;
                });
            },
            selectType(item) {
                // const isAlreadySelected = this.filterParam.merchantType.includes(item);
                // if (isAlreadySelected) {
                //     // 移除索引
                //     this.filterParam.merchantType.splice(this.filterParam.merchantType.indexOf(item), 1);
                // } else {
                //     // 添加索引
                //     this.filterParam.merchantType.push(item);
                // }
                if (item === '全部') {
                    // 如果点击的是“全部”，则反转selectAll状态
                    this.selectAll2 = !this.selectAll2;
                    if (this.selectAll2) {
                        // 如果选中“全部”，则将typeData中的所有项（除了“全部”）添加到reviewStatus
                        this.filterParam.merchantType = this.inspectionType.filter(i => i !== '全部');
                    } else {
                        // 如果取消“全部”，则清空reviewStatus
                        this.filterParam.merchantType = [];
                    }
                } else {
                    // 如果点击的不是“全部”，则只更新特定的项
                    const isAlreadySelected = this.filterParam.merchantType.includes(item);
                    if (isAlreadySelected) {
                        this.filterParam.merchantType.splice(this.filterParam.merchantType.indexOf(item), 1);
                    } else {
                        this.filterParam.merchantType.push(item);
                    }
                    // 如果此时reviewStatus中有超过一种类型的项目，则取消selectAll
                    if (this.filterParam.merchantType.length > 1 && this.selectAll2) {
                        this.selectAll2 = false;
                    }
                }
            },
            selectStatus(index) {
                this.status[index].statusAct = !this.status[index].statusAct;
            },
            changeT(s, e) {
                this.filterParam.startTime = s
                this.filterParam.endTime = e
                this.value2 = `${s} 至 ${e}`;
            },
            reset() {
                this.filterParam = {
                    reviewStatus: [],
                    merchantType: [],
                    startTime: '',
                    endTime: ''
                }
                this.selectAll=false;
                this.selectAll2=false
                this.$emit('filterData', this.filterParam)
                this.showPopup = false
            },
            confirm() {
                this.$emit('filterData', this.filterParam)
                this.showPopup = false
            },
            toggleSelection(item, index) {
                if (item === '全部') {
                    // 如果点击的是“全部”，则反转selectAll状态
                    this.selectAll = !this.selectAll;
                    if (this.selectAll) {
                        // 如果选中“全部”，则将typeData中的所有项（除了“全部”）添加到reviewStatus
                        this.filterParam.reviewStatus = this.typeData.filter(i => i !== '全部');
                    } else {
                        // 如果取消“全部”，则清空reviewStatus
                        this.filterParam.reviewStatus = [];
                    }
                } else {
                    // 如果点击的不是“全部”，则只更新特定的项
                    console.log("item2",this.filterParam.reviewStatus.includes(item))
                    const isAlreadySelected = this.filterParam.reviewStatus.includes(item);
                    if (isAlreadySelected) {
                        this.filterParam.reviewStatus.splice(this.filterParam.reviewStatus.indexOf(item), 1);
                    } else {
                        this.filterParam.reviewStatus.push(item);
                    }
                    // 如果此时reviewStatus中有超过一种类型的项目，则取消selectAll
                    if (this.filterParam.reviewStatus.length > 1 && this.selectAll) {
                        this.selectAll = false;
                    }
                }
            }
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .van-action-sheet {
        /* height: 450px; */
    }

    /deep/ .van-action-sheet__header {
        font-weight: bold;
    }

    /deep/ .van-field__label {
        color: #000;
    }

    /deep/ .van-hairline--top-bottom::after {
        border: none;
        border-bottom: 1px solid #ebedf0;
    }

    /deep/ .van-cell {
        padding-left: 0;
        padding-right: 0;
    }

    /deep/ .van-action-sheet__content {
        margin-bottom: 70px;
    }

    /deep/ .van-cell-group {
        margin-top: 10px;
    }

    .border {
        width: 100%;
        height: 1px;
        border: 1px solid #e8e8e8;
    }

    .content {
        width: 90%;
        height: 100%;
        margin: 0 auto;
    }

    .m16 {
        height: 54px;
        line-height: 54px;
        font-size: 14px;
        color: #000000;
    }

    .filed {
        width: 100%;
        min-height: 83px;
        background-color: #fff;
        font-size: 14px;

        .top_filed {
            width: 100%;
            margin: 0 auto;
            padding-top: 10px;
            margin-bottom: 12px;
            height: 20px;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
        }

        .input {
            display: flex;
            width: 100%;
            margin: 0 auto;
            margin-top: 30px;
        }
    }


    .wayBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        line-height: 1.5;
    }

    .way {
        text-align: center;
        font-size: 12px;
        /* padding: 6px 28px; */
        width: 22%;
        height: 29px;
        line-height: 29px;
        background: #ECF0FC;
        border-radius: 15px;
        border: 1px solid #E2E5FA;
        margin: 5px;
    }

    .lastTwo {
        width: 40%;
    }

    .status {
        width: 103px;
        height: 28px;
        line-height: 28px;
        display: inline-block;
        text-align: center;
        background: #ecf0fc;
        border-radius: 14px;
        border: 1px solid #e2e5fa;
        margin-right: 15px;
        font-size: 12px;
    }

    .status:nth-child(3n + 1) {
        margin-right: 0;
    }

    .Active {
        background: #ecf0fc;
        color: #3974c6;
    }

    .noActive {
        background: #f1f2f6;
        color: #4a4a4a;
    }

    .creattime {
        margin-bottom: 70px;
    }

    .bottom {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 60px;
        background: #ffffff;
        position: absolute;
        bottom: 10px;
        margin-right: 10px;

        .del {
            text-align: center;
            line-height: 46px;
            width: 83px;
            height: 46px;
            background: #f1f2f6;
            border-radius: 5px;
            border: 1px solid #d7d7d9;
            font-size: 16px;
            font-weight: 500;
            color: #595a5e;
        }

        .save {
            text-align: center;
            width: 230px;
            height: 46px;
            line-height: 46px;
            background: #3974c6;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
        }
    }
</style>