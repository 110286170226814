<template>
    <div class="page">
        <div class="pageBox">
            <div class="userInfo">
                <div class="infoBox">
                    <div class="info_left fontText">{{sopInfo.name}}</div>
                    <!-- <div class="info_right">进行中</div> -->
                </div>
                <div class="infoBox">
                    <div class="info_left">创建人</div>
                    <div class="info_right">{{sopInfo.creatorName}}</div>
                </div>
                <div class="infoBox">
                    <div class="info_left">创建时间</div>
                    <div class="info_right">{{sopInfo.createTime}}</div>
                </div>
                <div class="infoBox">
                    <div class="info_left">发送方式</div>
                    <div class="info_right">{{sopInfo|sendReslut}}</div>
                </div>
            </div>
            <div class="content" v-for="(item,index) of sopInfo.setting" :key="index">
                <div class="ruleName">内容名称：{{item.name}}</div>
                <div class="ruleName infoBox">
                    <div class="info_left">
                        <span>提醒时间：</span>
                        <div class="info_right">
                            <!-- 群发type:4 -->
                            <div v-if="item.push_type == 1&&item.time.type==4">
                                <div class="title m12">设置标签:</div>
                                <div class="tags">
                                    <div class="tag" v-for="tag in item.tags">
                                        <a-tag>{{ tag.name }}</a-tag>
                                    </div>
                                </div>
                                <div class="title m12">
                                    在{{item.time.data.first}}天{{item.time.data.last}}点后，提醒发送</div>
                            </div>
                            <!-- 群发type:5 -->
                            <div v-else-if="item.push_type == 1&&item.time.type==5">
                                <div class="title m12">设置包含标签:</div>
                                <div class="tags">
                                    <div class="tag" v-for="tag in item.tags5">
                                        <a-tag>{{ tag.name }}</a-tag>
                                    </div>
                                </div>
                                <div class="title m12">且不包含标签:</div>
                                <div class="tags">
                                    <div class="tag" v-for="tag in item.notTags">
                                        <a-tag>{{ tag.name }}</a-tag>
                                    </div>
                                </div>
                                <div class="title m12">
                                    在{{sopInfo.setting[0].time.data.first}}天{{sopInfo.setting[0].time.data.last}}点后提醒发送
                                </div>
                            </div>
                            <!-- type:6 -->
                            <div v-else-if="item.push_type == 1&&item.time.type==6">
                                <div class="title m12">在设置标签</div>
                                <div class="tags">
                                    <div class="tag" v-for="tag in item.tags">
                                        <a-tag>{{ tag.name }}</a-tag>
                                    </div>
                                </div>
                                <div class="title m12">
                                    <span v-if="item.time.data.cycle==1">每日{{item.time.data.last }}</span>
                                    <span
                                        v-if="item.time.data.cycle==2">每周{{item.time.data.date|weekFilter}}{{item.time.data.last
                                        }}</span>
                                    <span v-if="item.time.data.cycle==3">每月{{item.time.data.date}}日{{item.time.data.last
                                        }}</span>
                                    <span
                                        v-if="item.time.data.cycle==4">每年{{item.time.data.month}}月{{item.time.data.date}}日{{item.time.data.last
                                        }}</span>
                                    提醒发送
                                </div>
                            </div>
                            <!-- type:7 -->
                            <div v-else-if="item.push_type == 1&&item.time.type==7">
                                <div class="title m12">sop创建成功开启{{sopInfo.setting[0].time.data.first}}天 {{item.fullTags&&'，设置' }}
                                        <div class="tags" v-if="item.fullTags">
                                            <div class="tag" v-for="tag in item.tags">
                                                <a-tag>{{ tag.name }}</a-tag>
                                            </div>
                                        </div>
                                    后，当日{{sopInfo.setting[0].time.data.last}}提醒发送
                                </div>
                            </div>
                            <!-- type 0 1 3走过滤器 -->
                            <div v-else>{{item|tipsReslut}}</div>
                        </div>
                    </div>

                </div>
                <div class="title">发送内容</div>
                <div class="con_list" v-for="(v,i) of item.content" :key="i">
                    <div v-if="v.type=='text'" class="palist">
                        <div class="item_box">
                            <div class="item_left "></div>
                            <div class="item_right">
                                <div class="itemTitle">{{v.value}}</div>
                                <div class="tips">
                                    文字
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="v.type=='sucai'" v-for="(m,l) of v.childMediumData" :key="l" class="palist">
                        <div class="item_box">
                            <div class="item_left">
                                <img v-if="m.initialType=='图片'" :src="m.pic_url" />
                                <img v-if="m.initialType=='图文'" :src="m.url" />
                                <img v-if="m.initialType=='视频'" src="@/assets/images/vidoeType.png" />
                                <img v-if="m.initialType=='音频'" src="@/assets/images/yinpin.png" />
                                <img v-if="m.initialType=='小程序'" src="@/assets/images/chengxu.png" />
                                <img v-if="m.initialType=='文件'" src="@/assets/images/wenjian.png" />
                            </div>
                            <div class="item_right">
                                <div class="itemTitle">{{ m.title?m.title:m.content.title}}</div>
                                <div class="tips">
                                    {{m.initialType?m.initialType:m.type}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="v.type=='image'" class="palist">
                        <div class="item_box">
                            <div class="item_left ">
                                <img :src="v.value" />
                            </div>
                            <div class="item_right">
                                <div class="itemTitle">图片</div>
                                <div class="tips">
                                    本地上传
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="btnBox">
            <div class="cancel" @click="deleteSop(sopInfo)">删除</div>
            <div class="submit" @click="editPage(sopInfo)">编辑</div>
            <div class="cancel" @click="editStutas(sopInfo)">{{currentStatus==0?'开启':'关闭'}}</div>
        </div>
    </div>
</template>
<script>
    import { sopDetail, sopDelete, sopStatus } from "@/api/sopList";
    import { Dialog } from 'vant';
    export default {
        components: {
            Dialog
        },
        data() {
            return {
                sopInfo: {},
                currentStatus: undefined,
                createType: '',//创建类型
            }
        },
        filters: {
            //处理发送方式
            sendReslut(value) {
                return value.setting ? value.setting[0].push_type == 0 ? '复制单聊推送' : '系统群发提醒' : ''
            },
            //处理文件类型渲染前格式
            contentReslut(value) {
                const typeData = {
                    'text': '文本',
                    'images': '图片',
                    'link': '图文',
                    'miniprogram': '小程序',
                    "video": '音频',
                    'file': '文件'
                }
                return typeData[value]
            },
            weekFilter(val) {
                let weekData = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
                return weekData[val - 1]
            },
            //处理提醒方式
            tipsReslut(value) {
                if (!value) return
                //value[0].push_type ==0 单聊   value[0].day.first  0 1
                //value [0].time.push_type ==1 群发    value[0].day.first  3 4 5
                if (value.push_type == 0) {
                    if (value.time.type == 0) {
                        return '客户添加企业客服' + value.time.data.first + '小时后' + value.time.data.last + '分钟后提醒发送'
                    } else if (value.time.type == 1) {
                        return '客户添加企业客服' + value.time.data.first + '天后，当日' + value.time.data.last + '提醒发送'
                    }
                } else if (value.push_type == 1) {
                    // if (value.time.type == 3) {
                    //     return '客户添加企业客服' + value.time.data.first + '日后， 提醒发送'
                    // }else 
                    if (value.time.type == 3) {
                        return '客户添加企业客服' + value.time.data.first + '天后，当日' + value.time.data.last + '提醒发送'
                    }else if (value.time.type == 0) {
                        return '客户添加企业客服' + value.time.data.first + '小时后' + value.time.data.last + '分钟后提醒发送'
                    }
                }
            }

        },
        created() {
            this.createType = this.$route.query.type
            this.currentStatus = this.$route.query.state
            this.getData(this.$route.query.id)
        },
        methods: {
            //获取详情
            getData(id) {
                sopDetail({ id: id }).then((res) => {
                    if (res.code == 200) {
                        res.data.setting.forEach(item => {
                            if (item.push_type == 1 && item.time.type == 4) {
                                item.tags = item.fullTags
                                item.tags5 = []
                                item.notTags = []
                            } else if (item.push_type == 1 && item.time.type == 5) {
                                item.tags = []
                                item.tags5 = item.fullTags
                                item.notTags = item.notFullTags
                            } else if (item.push_type == 1 && item.time.type == 6) {
                                item.tags = item.fullTags
                                item.tags5 = []
                                item.notTags = []
                            }else if (item.push_type == 1 && item.time.type == 7) {
                                item.tags = item.fullTags
                                item.tags5 = []
                                item.notTags = []
                            }
                        });
                        this.sopInfo = res.data
                    }
                })
            },
            //编辑
            editPage(row) {
                if(this.currentStatus==1){
                    this.$message.warning("状态已开启，暂时无法编辑");
                    return
                }
                let path = row.setting[0].push_type == 0 ? '/sopList/chatPage' : '/sopList/hairPage'
                this.$router.push({ path: path, query: { data: JSON.stringify(this.sopInfo), type: this.createType } })
            },
            //删除SOP
            deleteSop(row) {
                let that = this
                Dialog.confirm({
                    title: '提示',
                    message: '是否删除当前sop信息',
                }).then(() => {
                    sopDelete({ id: row.id }).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功')
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 1000)
                        }
                    })
                }).catch(() => { });
            },
            //修改状态
            editStutas(row) {
                Dialog.confirm({
                    title: '提示',
                    message: `是否将当前状态设置为${this.currentStatus == 1 ? '关闭' : '开启'}`,
                }).then(() => {
                    sopStatus({ id: row.id, state: this.currentStatus == 1 ? 0 : 1 }).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('修改成功')
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 1000)
                        }
                    })
                }).catch(() => { });

            }
        }
    }
</script>
<style lang="less" scoped>
    .page {
        width: 100%;
        height: 100vh;
        background: #F2F3F7;

        .pageBox {
            padding: 12px 12px;
            padding-bottom: 130px;

            .userInfo {
                width: 100%;
                padding: 5px 17px;
                background: #FFFFFF;
                border-radius: 5px;

                .infoBox {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 10px 0;

                    .info_left {
                        font-size: 15px;
                        font-weight: 400;
                        color: #9B9B9B;
                        /* min-width: 70px; */
                    }

                    .info_right {
                        font-size: 15px;
                        font-weight: 400;
                        color: #515151;
                    }

                    .fontText {
                        font-weight: bold;
                        color: #000000;
                    }
                }

            }

            .content {
                margin-top: 10px;
                width: 100%;
                padding: 12px 17px;
                background: #FFFFFF;
                border-radius: 5px;

                .ruleName {
                    font-size: 12px;
                    font-weight: 400;
                    color: #BBBBBB;
                }

                .infoBox {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 10px 0;

                    .info_left {
                        width: 100%;

                        span {
                            font-size: 12px;
                            font-weight: 400;
                            color: #BBBBBB;
                            float: left;
                        }

                        /* min-width: 70px; */
                    }

                    .info_right {
                        float: left;
                        font-size: 12px;
                        font-weight: 400;
                        color: #BBBBBB;
                    }

                    .fontText {
                        font-weight: bold;
                        color: #000000;
                    }
                }

                .title {
                    font-size: 15px;
                    font-weight: 400;
                    color: #9B9B9B;
                }

                .m12 {
                    font-size: 12px;
                }

                .con_list {
                    padding: 7px;
                    margin-top: 15px;
                    width: 100%;
                    background: #F2F3F7;
                    border-radius: 4px;

                    .palist:nth-of-type(n+2) {
                        margin-top: 10px;
                    }

                    .item_box {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .item_left {
                            width: 36px;
                            height: 36px;
                            background: #E1E7F7;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 19px;
                                height: 19px;
                            }
                        }

                        .item_right {
                            display: flex;
                            flex-direction: column;
                            margin-left: 6px;

                            .itemTitle {
                                font-size: 11px;
                                font-weight: 500;
                                color: #4A4A4A;
                                display: flex;
                                justify-content: flex-start;
                            }

                            .tips {
                                font-size: 9px;
                                font-weight: 500;
                                color: #9B9B9B;
                                display: flex;
                                justify-content: flex-start;

                            }

                        }

                    }
                }
            }
        }
    }

    .tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 8px;
        margin-bottom: 8px;

        .tag {
            margin-bottom: 6px;
        }
    }

    .btnBox {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 106px;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 10px 12px;

        .cancel {
            width: 30%;
            height: 48px;
            padding: 12px 0;
            text-align: center;
            background: #F1F2F6;
            border-radius: 5px;
            border: 1px solid #D7D7D9;
            font-size: 17px;
            font-weight: 500;
            color: #595A5E;
        }

        .submit {
            width: 65%;
            height: 48px;
            text-align: center;
            padding: 12px 0;
            background: #3974C6;
            border-radius: 5px;
            font-size: 17px;
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 16px;
        }
    }
</style>