<template>
  <div>
    <div class="box">
      <a-button @click="$refs.popup.show(room)">
        选择成员
      </a-button>
      <div class="tags">
        <div class="tag" v-for="(v,i) in list">
          <a-tag>{{ v.name }} <a-icon @click="closeTagChange(v,i)" type="close" /></a-tag>
        </div>
      </div>
    </div>

    <popup ref="popup" @change="e => list = e" />
  </div>
</template>

<script>
  import popup from "./popup";

  export default {
    data() {
      return {
        list: []
      }
    },
    props: {
      room: {
        default: false,
        type: Boolean
      }
    },
    methods: {
      get() {
        return this.list.filter(v => {
          return !v.closable;
        })
      },
      //回显数据
      returnBack(row) {
        this.list = row
      },
      closeTagChange(e, i) {
        this.list.splice(i, 1)
      }
    },
    components: { popup },
    watch: {
      list: {
        handler(newValue, oldValue) {
          this.$emit('change')
        },
        deep: true
      }
    }
  }
</script>

<style lang="less" scoped>
  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 8px;

    .tag {
      margin-bottom: 6px;
    }
  }
</style>