<template>
    <div class="page">
        <div class="conBox">
            <div class="con">
                <div class="t1">
                    <div class="t1-left">商户编号</div>
                    <div class="t1-right">{{merchantData.serial}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">营业执照名称</div>
                    <div class="t1-right">{{merchantData.businessLicenseName}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">商户类型</div>
                    <div class="t1-right">{{merchantData.type}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">营业执照号</div>
                    <div class="t1-right">{{merchantData.businessLicenseNumber}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">法人姓名</div>
                    <div class="t1-right">{{merchantData.legalName}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">联系电话</div>
                    <div class="t1-right">{{maskPhone(merchantData.contactPhone)}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">地址</div>
                    <div class="t1-right">{{merchantData.address}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">经营状态</div>
                    <div class="t1-right">{{merchantData.businessStatus}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">经办支行</div>
                    <div class="t1-right">{{merchantData.handlingBranch}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户经理</div>
                    <div class="t1-right">{{merchantData.accountManagerName}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">开户日期</div>
                    <div class="t1-right">{{merchantData.accountOpeningDate}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">POS终端台数</div>
                    <div class="t1-right">{{merchantData.posNum}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">终端序列号</div>
                    <div class="t1-right">{{merchantData.terminalSerialNumber}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">巡检人</div>
                    <div class="t1-right">{{merchantData.inspectionName}}</div>
                </div>
            </div>
            <div class="nextCon m10">
                <div class="title">巡检信息 <span class="tips"
                        :style="setColor(detailJson.reviewStatusText)">{{detailJson.reviewStatusText}}</span>
                </div>
                <div class="line"></div>
                <div class="infoText">创建时间：{{detailJson.createdAt}}</div>
                <div class="infoText">巡检上传时间：{{detailJson.updatedAt}}</div>
                <div class="infoText">巡检审核时间：{{detailJson.reviewTime}}</div>
                <div class="infoText">审核员：{{detailJson.reviewPeopleText}}</div>
                <div class="infoText">巡检类型：{{merchantTypeFun(detailJson.merchantType)}}</div>
            </div>
            <div class="con m10 p10">
                <div class="title">巡检照片</div>
                <div class="line"></div>
                <div class="li">
                    <span class="li-title">商户门头照</span>
                    <van-uploader v-model="imgData.merchantImgPhoto" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">收银台</span>
                    <van-uploader v-model="imgData.merchantImgCashier" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">经营场所照</span>
                    <van-uploader v-model="imgData.merchantImgPremisesPhoto" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">机具(收款码）</span>
                    <van-uploader v-model="imgData.merchantImgPaymentCode" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">工商信息截图</span>
                    <van-uploader v-model="imgData.merchantImgInformation" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">其他</span>
                    <van-uploader v-model="imgData.merchantImgOther" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
            </div>
            <div class="con m10 p10">
                <div class="title">巡检信息</div>
                <div class="line"></div>
                <div class="quBox">
                    <div class="quBox-title">1.商户机具是否移机使用？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationOne" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">2.商户机具是否被改造/加装其它设备？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationTwo" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">3.商户机具是否完整？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationThr" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">4.商户机具是否处于正常工作状态？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationFou" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">5.商户交易凭证保存是否符合规定？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationFiv" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">6.商户机具所有权是否变动？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationSix" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">7.商户是否实质性经营，是否与营业执照经营业务相符？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationSev" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">8.商户处是否有其它收单机构POS设备？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationEig" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">9.商户交易资金结算是否正常？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationNie" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">10.商户工商注册状态是否正常？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationTen" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">11.商户受益所有人是否发生变更？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationEle" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">12.支付终端附近是否有可以获取付款人密码等敏感信息的录像设施、营业场所内是否出现其他可疑收款条码？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationTwe" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">13.商户是否有语音播报器？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationFour" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">14.语音播报器是否正常？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="detailJson.informationFif" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">15.其它</div>
                    <div class="radioBox">
                        <input type="text" v-model="detailJson.informationThi" placeholder="请输入内容" class="text_input" />
                    </div>
                </div>
            </div>
            <div class="con m10 p10">
                <div class="title">培训信息 <span class="tips">（至少选一项）</span></div>
                <div class="line"></div>
                <div>
                    <van-checkbox-group v-model="detailJson.trainingContent" class="check-group" direction="horizontal">
                        <van-checkbox shape="square" v-for="(item, index) in options" :key="index" :name="item.key"
                            icon-size="15px" class="check-item">
                            <span class="check-text">{{ item.value }}</span>
                        </van-checkbox>
                    </van-checkbox-group>
                </div>
            </div>
            <div class="con m10 p10">
                <div class="title">异常情况 <span class="tips">（选填）</span></div>
                <div class="line"></div>
                <div class="abnormal">
                    <div class="title">异常</div>
                    <div class="right">
                        <van-radio-group icon-size="15px" @change="abnormalChange" v-model="detailJson.abnormalStatus"
                            direction="horizontal" class="row-checkbox">
                            <van-radio shape="square" :name="1" class="row-square radio">无</van-radio>
                            <van-radio shape="square" :name="2" class="row-square radio">异常</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="abnormal">
                    <div class="title">业务类型</div>
                    <div class="right">
                        <van-checkbox-group class="row-checkbox" v-model="detailJson.abnormalType"
                            :disabled="detailJson.abnormalStatus==1">
                            <van-checkbox shape="square" icon-size="15px" :name="1" class="row-square"><span
                                    class="text">条码</span></van-checkbox>
                            <van-checkbox shape="square" icon-size="15px" :name="2" class="row-square"><span
                                    class="text">pos</span></van-checkbox>
                        </van-checkbox-group>
                    </div>
                </div>
                <div class="abnormal">
                    <div class="title">异常情况</div>
                    <div class="right">
                        <van-checkbox-group class="row-checkbox more" v-model="detailJson.abnormalSituation"
                            :disabled="detailJson.abnormalStatus==1">
                            <van-checkbox shape="square" icon-size="15px" :name="item.key" class="row-square"
                                v-for="(item,index) in options3" :key="index"><span
                                    class="text">{{item.value}}</span></van-checkbox>
                        </van-checkbox-group>
                    </div>
                </div>
                <div class="abnormal" v-if="detailJson.abnormalStatus==2">
                    <div class="title">备注</div>
                </div>
                <div class="abnormal" v-if="detailJson.abnormalStatus==2">
                    <div class="column">
                        <input type="text" v-model="detailJson.abnormalRemark" placeholder="请输入内容" class="text_input" />
                        <van-uploader v-model="imgData.abnormalImg" :after-read="abnormalImgFun"
                            :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                        </van-uploader>
                    </div>
                </div>
            </div>
            <!-- 位置 -->
            <div class="con m10 movablesBox paddBox">
                <div class="nameBox">
                    <div class="left" style="min-width: 80px;">
                        <span class="title">定位地址</span>
                    </div>
                    <div class="right" @click="addressShow=true">
                        <span>{{params.locationAddress==''?'请选择':params.locationAddress}}</span>
                        <img src="../../../assets/credit/jiantou.png" alt="">
                    </div>
                </div>
                <div class="clockBox">
                    <div class="circle" @click="submitBtn">
                        <div class="info">
                            <div class="clockTime">{{ currentTime }}</div>
                            <div class="clockName">提交</div>
                        </div>
                    </div>
                </div>
                <div class="clockTips"></div>
            </div>
            <!-- 占位 -->
            <div style="width: 100%;height: 80px;"></div>
        </div>
        <!-- 底部按钮 -->
        <div class="footer m10">
            <div class="box">
                <div class="btn1" @click="getBack()">返回</div>
                <div class="btn2" @click="$refs.employee.$show(serviceList, 'max')">转接</div>
                <div class="btn2" @click="goAutogra()">商户签字</div>
                <!-- <div class="btn2" @click="submitBtn">提交</div> -->
            </div>
        </div>
        <!--  员工  -->
        <employeeIndex ref="employee" @change="userChange" />
        <!-- 签名弹出层 -->
        <van-popup v-model="showEsign" round position="bottom" :style="{ height: '90%',background:'#F2F3F7' }">
            <div class="imgBox">
                <img src="../../../assets/images/festival/close.png" alt="" @click="showEsign=false">
            </div>
            <esignPage @submit="esignChange"></esignPage>
        </van-popup>
        <!-- 地址 -->
        <van-popup v-model="addressShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"
                :default-index="pickerIndex" />
        </van-popup>
        <!-- 更新提示 -->
        <van-dialog v-model="showPopup" @confirm="confirmPopup" show-cancel-button @cancel="showPopup=false" confirmButtonText="确定"
            cancelButtonText="取消" confirmButtonColor="#3974C6" >
            <div style="width: 100%;text-align: center;margin: 40px 0px;">确认更新本次提交的巡检单内容吗？</div>
        </van-dialog>
        <baidu-map style="display:none;" ak="uSDQM0SXJgOKGoiDmMxh5a1BR0zhoKVJ" @ready="handler"></baidu-map>
    </div>
</template>

<script>
    import { store, upload } from "@/api/contactWelcome";
    import employeeIndex from "./employeeIndex";
    import { inspDetail, merchantDetail, transfer, inspectionSubmit } from "@/api/Inspection";
    import esignPage from './autograph.vue'
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import { loacationMixins } from "./mixins.js";
    import { Dialog } from "vant";
    export default {
        mixins: [loacationMixins],
        components: {
            employeeIndex,
            esignPage,
            BaiduMap
        },
        data() {
            return {
                showEsign: false,
                detailJson: {
                    merchantImgPhoto: '',
                    merchantImgCashier: '',
                    merchantImgPremisesPhoto: '',
                    merchantImgPaymentCode: '',
                    merchantImgInformation: '',
                    merchantImgOther: '',
                    informationOne: 2,
                    informationTwo: 2,
                    informationThr: 1,
                    informationFou: 1,
                    informationFiv: 1,
                    informationSix: 2,
                    informationSev: 1,
                    informationEig: 2,
                    informationNie: 1,
                    informationTen: 1,
                    informationEle: 2,
                    informationTwe: 2,
                    informationFour: 1,
                    informationFif: 1,
                    informationThi: '',
                    trainingContent: [],
                    abnormalStatus: 1,
                    abnormalType: undefined,
                    abnormalRemark: '',
                    abnormalImg: '',
                    locationAddress: '',
                },
                merchantData: {},
                serviceList: [],// 请选择成员
                params: {},
                imgData: {
                    merchantImgPhoto: [],
                    merchantImgCashier: [],
                    merchantImgPremisesPhoto: [],
                    merchantImgPaymentCode: [],
                    merchantImgInformation: [],
                    merchantImgOther: [],
                    abnormalImg: []
                },
                options: [
                    {
                        key: 1,
                        value: "POS及二维码交易的操作流程和要求"
                    },
                    {
                        key: 2,
                        value: "银行卡的识别、伪卡辨别、没收卡的处理及受理操作 流程"
                    },
                    {
                        key: 4,
                        value: "刷卡交易完成后，需核对持卡人签名与卡背面预留签 名是否一致"
                    },
                    {
                        key: 8,
                        value: "银行卡交易资金结算、对账数据获取方式及要求、调 账、差错处理流程"
                    },
                    {
                        key: 16,
                        value: "明确消费撤销、退货、消费调整等高风险交易的授权管理"
                    },
                    {
                        key: 32,
                        value: "POS机受理IC卡及非接触式IC卡的操作方法及注意事项"
                    },
                    {
                        key: 64,
                        value: "交易单据的保管方式与保管时限要求"
                    },
                    {
                        key: 128,
                        value: "机具及二维码的正确使用方法和简单故障排查"
                    },
                    {
                        key: 256,
                        value: "常见欺诈行为的识别及应用技巧"
                    },
                    {
                        key: 512,
                        value: "商户风险责任及风险防范知识"
                    },
                    {
                        key: 1024,
                        value: "违规行为及救济措施"
                    },
                    {
                        key: 2048,
                        value: "调单的处理要求"
                    },
                    {
                        key: 4096,
                        value: "财务处理流程、投诉、差错、争议处理要求"
                    }
                ],
                options2: [
                    {
                        key: 1,
                        value: "条码"
                    }, {
                        key: 2,
                        value: "POS"
                    }],
                options3: [
                    {
                        key: 1,
                        value: "终端丢失/损坏"
                    }, {
                        key: 2,
                        value: "商户失联"
                    }, {
                        key: 4,
                        value: "停业"
                    }, {
                        key: 8,
                        value: "迁址"
                    }, {
                        key: 16,
                        value: "其他"
                    }],
                merchantImgSig: '',
                addressShow: false,
                columns: [],
                pickerIndex: 0,
                showPopup: false
            }
        },
        watch: {},
        created() {
            this.getData()
            this.getMerchantInfo()
        },
        methods: {
            getData() {
                inspDetail({ id: this.$route.query.id }).then(async (res) => {
                    if (res.code == 200) {
                        const parsedTrainingContent = await this.parseTrainingContent(res.data.trainingContent, 'trainingContent');
                        const abnormalType = await this.parseTrainingContent(res.data.abnormalType, 'abnormalType');
                        const abnormalSituation = await this.parseTrainingContent(res.data.abnormalSituation, 'abnormalSituation');
                        res.data.trainingContent = parsedTrainingContent;
                        res.data.abnormalType = abnormalType;
                        res.data.abnormalSituation = abnormalSituation;
                        this.detailJson = res.data
                        this.initImageFields()//其他图片回显
                        if (res.data.abnormalImgText && res.data.abnormalImgText != '') {//备注图片回显
                            const imageData = {
                                url: res.data.abnormalImgText,
                            };
                            this.imgData.abnormalImg = [imageData];
                        }
                    }
                })
            },
            getMerchantInfo() {
                merchantDetail({ id: this.$route.query.merchantId }).then((res) => {
                    if (res.code == 200) {
                        this.merchantData = res.data
                    }
                })
            },
            parseTrainingContent(trainingContent, type) {
                const dataSources = {
                    trainingContent: this.options,
                    abnormalType: this.options2,
                    abnormalSituation: this.options3,
                };
                let currentData = dataSources[type] || [];
                let selectedKeys = [];
                // 遍历options数组，检查每个option的key是否在trainingContent中被设置
                currentData.forEach((option) => {
                    if ((trainingContent & option.key) === option.key) {
                        selectedKeys.push(option.key);
                    }
                });
                return selectedKeys

            },
            initImageFields() {
                // 遍历 params 中的图片字段
                for (const field in this.detailJson) {
                    if (this.detailJson.hasOwnProperty(field) && this.detailJson[field]) {
                        // 构建图片数据对象
                        const imageData = {
                            url: this.detailJson[field], // 假设 params 中的值是图片的 Base64 或 URL
                            // name: field, // 用于标识图片来自哪个字段
                        };
                        // 将图片数据添加到对应的 fileList 数组中
                        this.imgData[field] = [imageData];
                    }
                }
            },
            //签字
            goAutogra() {
                // this.$router.push({
                //     path: '/Inspection/autograph',
                //     query: {
                //         id: this.$route.query.id,
                //     },
                // });
                this.showEsign = true
            },
            getBack() {
                this.$router.back();
            },
            setColor(type) {
                const colorMap = {
                    '未巡检': 'background: #FFDAD8; color: #D2261D',
                    '待审核': 'background: #EBEFFB; color: #3974C6', // 假设的颜色，根据实际情况调整
                    '审核通过': 'background: #D7F3D4; color: #39A52E', // 假设的颜色，根据实际情况调整
                    '审核驳回': 'background: #F2F3F7; color: #9B9B9B', // 假设的颜色，根据实际情况调整
                };
                return colorMap[type] || ''; // 返回对应的状态颜色，如果状态不存在则返回空字符串
            },
            merchantTypeFun(type) {
                const colorMap = {
                    1: '周期巡检',
                    2: '风险巡检',
                    3: '临时巡检',
                };
                return colorMap[type] || ''; // 返回对应的状态颜色，如果状态不存在则返回空字符串
            },
            // 选择员工
            userChange(data, info) {
                let employeeId = data.map(i => i.id)[0]
                //走转接流程
                let params = {
                    id: this.$route.query.id,
                    employeeId: employeeId
                }
                transfer(params).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('已提交转接')
                    }
                })
            },
            esignChange(row) {
                this.detailJson.merchantImgSig = row
                this.showEsign = false
            },
            // 文件大小
            onOversize(file) {
                this.$message.warning("文件大小不能超过 10MB");
            },
            // 上传图片文件
            afterReadImg(file, type) {
                let currentType = ''
                if (this.imgData.merchantImgPhoto.includes(file)) {
                    currentType = 'merchantImgPhoto'
                }
                if (this.imgData.merchantImgCashier.includes(file)) {
                    currentType = 'merchantImgCashier'
                }
                if (this.imgData.merchantImgPremisesPhoto.includes(file)) {
                    currentType = 'merchantImgPremisesPhoto'
                }
                if (this.imgData.merchantImgPaymentCode.includes(file)) {
                    currentType = 'merchantImgPaymentCode'
                }
                if (this.imgData.merchantImgInformation.includes(file)) {
                    currentType = 'merchantImgInformation'
                }
                if (this.imgData.merchantImgOther.includes(file)) {
                    currentType = 'merchantImgOther'
                }
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    this.detailJson[currentType] = res.data.fullPath;
                });
            },
            //异常图片
            abnormalImgFun(file, type) {
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    this.detailJson.abnormalImg = res.data.path;
                });
            },
            submitBtn() {
                // if (this.detailJson.reviewStatusText == '待审核' || this.detailJson.reviewStatusText == '审核通过') {
                //     this.$message.warning("当前状态不可编辑！");
                //     return
                // }
                //巡检照片检验
                // const hasEmptyArray = Object.entries(this.imgData)
                // .filter(([key, value]) => key !== 'merchantImgOther' && Array.isArray(value) && value.length === 0)
                // .length > 0;
                // if (hasEmptyArray) {
                //     this.$message.warning("请上传巡检照片！");
                //     return
                // }
                if (this.detailJson.abnormalStatus != 2) {
                    if (this.imgData.merchantImgPhoto.length == 0) {
                        this.$message.warning("请上传商户门头照！");
                        return
                    }
                    //培训内容
                    if (this.detailJson.trainingContent.length == 0) {
                        this.$message.warning("请选择培训信息！");
                        return
                    }
                    // 商户签字
                    if (this.detailJson.merchantImgSig == '') {
                        this.$message.warning("请签名确认！");
                        return
                    }
                }
                if (this.detailJson.reviewStatusText == '审核通过') {
                    this.showPopup = true
                    return
                }
                this.confirmPopup()
            },
            confirmPopup() {
                let params = {
                    ...this.detailJson,
                    id: this.$route.query.id
                }
                inspectionSubmit(params).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('提交成功')
                        this.$router.back();
                    }
                })
            },
            //异常选择
            abnormalChange(e) {
                if (e == 1) {//状态变更为无，需要将异常情况重置
                    this.detailJson.abnormalType = undefined
                    this.detailJson.abnormalSituation = undefined
                }
            },
            maskPhone(contactPhone) {
                if (contactPhone) {
                    // 假设我们想要替换中间4位数字为星号
                    const firstPart = contactPhone.slice(0, 3);
                    const lastPart = contactPhone.slice(-4);
                    const maskedMiddle = '*'.repeat(4); // 4个星号
                    return `${firstPart}${maskedMiddle}${lastPart}`;
                }
            }

        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-radio__label {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
    }

    /deep/.van-checkbox__label {
        display: flex;
        align-items: center;
    }

    .page {
        width: 100%;
        padding: 10px 0px;
        background: #F2F3F7;
        height: 100%;
        /* min-height: 100vh; */

        .conBox {
            width: 100%;
            padding: 0px 13px;
        }

        .con {
            padding: 0px 16px;
            padding-bottom: 10px;
            padding-top: 1px;
            background: #FFFFFF;
            border-radius: 5px;

            .title {
                font-weight: bold;
                font-size: 17px;
                color: #000000;

                .tips {
                    font-weight: 400;
                    font-size: 14px;
                    color: #9B9B9B;
                }
            }

            .line {
                margin-top: 13px;
                width: 100%;
                border-top: 1px solid #E8E8E8;
            }

            .li {
                display: flex;
                flex-direction: column;

                .li-title {
                    font-weight: 400;
                    font-size: 15px;
                    color: #000000;
                    margin: 15px 0px;
                }
            }

            .quBox {
                margin-top: 25px;

                .quBox-title {
                    font-weight: 400;
                    font-size: 14px;
                    color: #000000;
                }

                .radioBox {
                    margin-top: 20px;

                    .text_input {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #BBBBBB;
                        padding: 5px;
                    }
                }
            }

            .check-group {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                .check-item {
                    margin: 10px 0;

                    .check-text {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }
                }
            }

            .abnormal {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 9px 0;

                .title {
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                }

                .right {
                    width: 70%;

                    .row-checkbox {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .row-square {
                            flex-basis: calc(50% - 5px);
                            /* 减去间距的一半，假设两边各留2.5px间距 */
                            box-sizing: border-box;

                            /* 确保内边距和边框被包含在宽度内 */
                            .text {
                                font-weight: 400;
                                font-size: 14px;
                                color: #000000;
                            }
                        }

                        .row-square:nth-child(2n+3) {
                            margin-top: 10px;
                            /* 为奇数位置的元素添加底部外边距 */
                        }

                        .radio {
                            margin-right: 0 !important;
                        }

                    }

                }

                .column {
                    width: 100%;

                    .text_input {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #BBBBBB;
                        padding: 5px;
                        margin-bottom: 15px;
                    }
                }
            }

            .t1 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px 0;

                .t1-left {
                    font-weight: 400;
                    font-size: 15px;
                    color: #9B9B9B;
                }

                .t1-right {
                    font-weight: 400;
                    font-size: 15px;
                    color: #515151;
                    max-width: 70%;
                }
            }
        }

        .m10 {
            margin-top: 10px;
        }

        .p10 {
            padding-top: 15px;
        }

        .nextCon {
            background: #FFFFFF;
            border-radius: 5px;
            width: 100%;
            padding: 16px 12px;

            .title {
                font-weight: bold;
                font-size: 17px;
                color: #000000;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .tips {
                    font-weight: 400;
                    font-size: 12px;
                    height: 21px;
                    line-height: 21px;
                    padding: 0px 4px;
                    border-radius: 3px;
                }
            }

            .line {
                margin-top: 13px;
                width: 100%;
                border-top: 1px solid #E8E8E8;
            }

            .infoText {
                font-weight: 400;
                font-size: 15px;
                color: #9B9B9B;
                margin-top: 10px;
            }
        }

        .movablesBox {
            padding: 0px 20px;
            background: #FFFFFF;
            border-radius: 5px;

            .line {
                border-top: 1px solid #F5F5F5;
            }

            .line-bom {
                border-bottom: 1px solid #F5F5F5;
            }

            .nameBox {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 12px 0;

                .left {
                    .xing {
                        font-weight: bold;
                        font-size: 17px;
                        color: #CD0005;
                        margin-right: 5px;
                    }

                    .title {
                        font-weight: bold;
                        font-size: 17px;
                        color: #000000;
                    }

                    .remakeInput {
                        border: none;
                        width: 100%;
                        font-weight: 400;
                        font-size: 14px;
                        color: #9B9B9B;
                        text-align: right;
                    }

                    .tips {
                        font-weight: 400;
                        font-size: 14px;
                        color: #9B9B9B;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;

                    span {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }

                    img {
                        width: 6px;
                        height: 10px;
                        margin-left: 6px;
                    }
                }

            }

            .clockBox {
                margin-top: 24px;
                width: 100%;
                display: flex;
                justify-content: center;

                .circle {
                    width: 118px;
                    height: 118px;
                    position: relative;
                    background: #3975C6;
                    border: 8px solid #CDE3FF;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .info {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 2;

                        .clockTime {
                            font-size: 19px;
                            font-weight: bold;
                            color: #FFFFFF;
                        }

                        .clockName {
                            font-size: 16px;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: -8px;
                        left: -8px;
                        width: calc(100% + 16px);
                        height: calc(100% + 16px);
                        border: 8px solid #CDE3FF;
                        border-radius: 50%;
                        animation: wave 2s linear infinite;
                        z-index: 1;
                    }
                }
            }

            .clockTips {
                width: 100%;
                text-align: center;
                font-weight: 400;
                font-size: 11px;
                color: #9B9B9B;
                margin-top: 25px;
            }

            @keyframes wave {
                0% {
                    box-shadow: 0 0 0 0 #CDE3FF;
                }

                50% {
                    box-shadow: 0 0 0 8px #CDE3FF;
                }

                100% {
                    box-shadow: 0 0 0 0 #CDE3FF;
                }
            }
        }

        .paddBox {
            margin-top: 10px;
            padding-bottom: 20px;
        }

        .footer {
            position: fixed;
            z-index: 999;
            left: 0;
            bottom: 0px;
            width: 100%;
            padding: 15px;
            padding-bottom: 25px;
            background: #FFFFFF;
            box-shadow: 0px 1 3px 0px rgba(0, 0, 0, 0.26);

            .box {
                background: #FFFFFF;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                .btn1 {
                    padding: 6px 20px;
                    background: #F2F3F7;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #9B9B9B;
                    text-align: center;
                }

                .btn2 {
                    text-align: center;
                    padding: 6px 20px;
                    background: #3974C6;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .imgBox {
        width: 100%;
        margin-top: 25px;
        display: flex;
        justify-content: center;

        img {
            width: 33px;
            height: 33px;
        }
    }
</style>