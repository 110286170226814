<template>
  <div class="index">
    <div class="userInfo">
      <div class="content">
        <div class="contentLeft">
          <img :src="employeeData.fullImage" alt="" />
          <div style="margin-left: 8px">
            <div class="item marquee" style="width: 144px">
              <div class="marquee-wrap">
                <div class="marquee-content name">
                  {{ employeeData.name }}
                </div>
              </div>
            </div>
            <div class="position">
              {{ employeeData.departmentName }}｜{{ employeeData.position }}
            </div>
          </div>
        </div>
        <div class="contentRight">
          <div class="phone" style="margin-right: 20px">
            <img
              class="pic"
              style="width: 15px; height: 14px"
              @click=""
              src="../../../assets/images/electronicCard/home.png"
              alt=""
              @click="goPage"
            />
          </div>
          <div class="phone" style="margin-right: 20px">
            <img
              class="pic"
              style="width: 14px; height: 14px"
              src="../../../assets/images/electronicCard/phone.png"
              alt=""
              @click="daPhone"
            />
          </div>
          <div class="phone">
            <img
              class="pic"
              style="width: 17px; height: 14px"
              src="../../../assets/images/electronicCard/wechat.png"
              alt=""
              @click="showWechat = true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="banxin">
      <h5>{{ detail.title }}</h5>
      <div class="top">
        <div class="left">
          来自：{{ detail.author_name }} &nbsp;&nbsp;阅读 {{ detail.view_num }}
        </div>
        <div class="right">
          {{ detail.date }}
        </div>
      </div>
      <div
        class="comment"
        v-if="detail.comment.comment != ''"
        @click.stop="showDetail = !showDetail"
      >
        查看{{ employeeData.name }}的点评
        <a-icon type="up" v-if="showDetail == true" /><a-icon
          type="down"
          v-else
        />
      </div>
      <div class="myComment" v-if="showDetail == true">
        {{ detail.comment.comment }}
        <div @click.stop="showDetail = false" class="shouqi">收起</div>
      </div>
      <div class="rich" style="" v-html="detail.content"></div>
    </div>
    <div class="recommend" v-if="detail.comment.recommended_product.length > 0">
      <div class="top">推荐产品</div>
      <div
        class="content"
        v-for="(res, i) in detail.comment.recommended_product"
        :key="i"
        @click="skipDetail(res.id)"
      >
        <div class="left">
          <div class="title">{{ res.name }}</div>
          <div class="abstruct">
            {{ res.summary }}
          </div>
        </div>
        <div class="right">
          <img :src="res.fullImage" alt="" />
        </div>
      </div>
    </div>
    <!-- 版权声明 -->
    <div class="copyright">
      <div class="box_title">免责声明：</div>
      <div class="box_cen">
        以上内容仅供参考，引用之目的在于共享资讯，不代表对#{{
          detail.author_name
        }}#内容的真实性、准确性负责。以上内容不构成对任何人的投资建议，敬请您独立决策并承担风险。如信息内容侵犯知识产权，请提交相关链接至邮箱：service@qsjykj.com.cn，我们将及时处理。市场有风险，投资须谨慎
      </div>
    </div>
    <div style="height: 68px"></div>
    <div class="last">
      <div class="cont">
        <div class="left" @click="skipSet">
          <img
            class="setting"
            src="../../../assets/images/electronicCard/set.png"
            alt=""
          />
          <div class="m17">设置</div>
        </div>
        <div class="right" @click="sendCircle">分享</div>
      </div>
    </div>
    <!-- 微信二维码弹窗 -->
    <van-popup
      v-model="showWechat"
      closeable
      style="width: 260px; height: 286px; text-align: center; margin-left: 50%"
    >
      <img
        style="
          margin-top: 30px;
          width: 200px;
          height: 190px;
          text-align: center;
        "
        :src="employeeData.qrCode"
        alt=""
      />
      <p style="font-size: 16px; color: #515151">长按扫一扫<br />即可联系我</p>
    </van-popup>
  </div>
</template>
<script>
import { newsDetail, vcardIndex } from "@/api/electronicCard";
import { shareToExternalMoments, shareMyData } from "@/utils/wxCodeAuth";

export default {
  data() {
    return {
      detail: "",
      id: "",
      showDetail: false,
      showWechat: false,
      shareUrl: "",
      shareTitle: "",
      employeeData: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getUserInfo() {
      vcardIndex().then((res) => {
        const data = res.data;
        this.employeeData = data.employee ? data.employee : "";
        const shareData = {
          title: "[资讯]" + this.detail.title,
          // description: data.summary,
          description: this.detail.content1,
          imgUrl: data.employee.fullImage,
          url: this.shareUrl,
        };
        console.log("fenxiang", shareData);
        shareMyData(shareData);
      });
    },
    sendCircle() {
      let time = new Date();
      let shareData = {};
      let text = "";
      shareData.type = 3;
      shareData.content = {};
      shareData.content.title = "[资讯]" + this.detail.title.slice(0, 26);
      shareData.content.imageLink = this.detail.shareUrl;
      shareData.content.imageFullPath = this.employeeData.fullImage;
      console.log("shareData.content", shareData);
      shareToExternalMoments(text, shareData).then((res) => {
        console.log("res", res);
        this.$message.success("发送成功");
      });
    },
    getData() {
      this.id = this.$route.query.id;
      newsDetail({ id: this.id }).then((res) => {
        if (res.data.content_type == 2) {
          window.location.href = res.data.url;
          return;
        } else {
          this.detail = res.data;
          this.shareUrl = res.data.shareUrl;
          this.shareTitle = res.data.title;
          this.detail.content1 = this.detail.content.replace(/<[^>]+>/g, "");
          console.log("this.detail.content1", this.detail.content1);
          this.getUserInfo();
        }
      });
    },
    skipSet() {
      this.$router.push({
        path: "/electronicCard/morningPaper/setting",
        query: { id: this.id },
      });
    },
    skipDetail(id) {
      this.$router.push({
        path: "/electronicCard/financialinfo/detail",
        query: {
          id,
        },
      });
    },
    daPhone() {
      window.location.href = `tel:${this.employeeData.mobile}`;
    },
    goPage() {
      let routeHistory = history.length - 1;
      this.$router.go(-routeHistory);
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  height: 100%;
  background: #fff;
  overflow: auto;
}

.userInfo {
  width: 100%;
  height: 79px;
  background: #ffffff;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.05);
  /* background: url(../../../assets/images/electronicCard/pinkBg.png); */
}

.content {
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .contentLeft {
    display: flex;
    margin-left: 14px;
    margin-top: 10px;

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    .name {
      margin-top: 6px;
      height: 21px;
      font-size: 18px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 21px;
    }

    .position {
      margin-top: 10px;
      height: 15px;
      font-size: 12px;
      color: #9b9b9b;
      line-height: 15px;
    }
  }

  .contentRight {
    display: flex;
    justify-content: space-around;
    // .phone {
    //   width: 31px;
    //   height: 31px;
    //   background-color: #fff;
    //   border-radius: 50%;
    //   text-align: center;
    //   line-height: 22px;
    // }

    .pic {
      width: 14px;
      height: 14px;
    }
  }
}

.banxin {
  width: 94%;
  margin: 0 auto;
}

h5 {
  margin: 16px 0 10px 0;
  min-height: 32px;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
  line-height: 24px;
  text-align: center;
}

.top {
  display: flex;
  justify-content: space-between;
  height: 19px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #717174;
  line-height: 19px;
  padding: 0 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.left {
  width: 56%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/deep/ img {
  max-width: 100% !important;
}

.last {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: #ffffff;

  .cont {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 10%;

      .setting {
        width: 22px;
        height: 22px;
      }

      .m17 {
        font-size: 10px;
        color: #c1c9d0;
        line-height: 14px;
      }
    }

    .right {
      margin-top: 8px;
      width: 80%;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #3974c6;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}

.comment {
  margin-top: 10px;
  width: 100%;
  height: 29px;
  background: #f2f3f7;
  font-size: 13px;
  color: #3975c6;
  line-height: 29px;
  text-indent: 6px;
}

.myComment {
  text-indent: 6px;
  padding: 10px 0;
  background: #f2f3f7;
  font-size: 13px;
  color: #4a4a4a;
  line-height: 20px;
}

.shouqi {
  display: inline-block;
  width: 44px;
  height: 18px;
  font-size: 13px;
  color: #3975c6;
  line-height: 19px;
}

.recommend {
  background: #f2f3f7;
  margin-top: 14px;
  overflow: hidden;

  .top {
    margin: 14px 0;
    width: 94%;
    margin-left: 3%;
    // margin: 0 auto;
    width: 105px;
    height: 21px;
    font-size: 15px;
    text-indent: 8px;
    font-weight: bold;
    color: #262626;
    border-left: 2px solid #3974c6;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;
    height: 107px;
    background: #ffffff;
    margin-bottom: 10px;
  }

  .left {
    width: 60%;

    .title {
      margin: 0px 0 10px 5px;
      height: 21px;
      font-size: 15px;
      font-weight: 500;
      color: #333333;
    }

    .abstruct {
      font-size: 13px;
      color: #959595;
      margin-left: 5px;
      line-height: 22px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }
  }

  .right {
    width: 30%;
    margin-right: 5px;

    img {
      width: 107px;
      height: 81px;
    }
  }
}

.rich {
  margin-top: 8px;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  text-align: justify;
  text-justify: distribute-all-lines;
  /* text-align-last: justify; */
}

.copyright {
  width: 94%;
  padding: 10px;
  background: #f8f9fb;
  margin: 10px;
  margin: 0 auto;
  border-radius: 6px;
  margin-top: 12px;
  margin-bottom: 70px;

  .box_title {
    color: #acadb1;
    font-size: 12px;
  }

  .box_cen {
    margin-top: 5px;
    color: #acadb1;
    font-size: 12px;
    line-height: 20px;
  }
}
.marquee {
  overflow: hidden;
}

.marquee .marquee-wrap {
  width: 100%;
  animation: marquee-wrap 10s infinite linear;
}

.marquee .marquee-content {
  float: left;
  white-space: nowrap;
  min-width: 100%;
  animation: marquee-content 10s infinite linear;
}

@keyframes marquee-wrap {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-content {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(-100%);
  }
}
</style>