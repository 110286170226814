<template>
  <div class="depositDetail">
    <div class="main">
      <div class="content">
        <div class="banxin">
          <div class="title">平安理财新启航第181期五个月</div>
          <div class="describe">
            <showMore :length="28" :longStr="longStr" />
          </div>
          <div class="infoMiddle">
            <div class="lilv">新品热销</div>
            <div class="shen">5个月</div>
          </div>
          <div class="infoMiddle">
            <div class="time">新启航系列</div>
            <div class="money">投资期限</div>
          </div>
          <div class="text">
            本产品将不超过49%资金投资于非标准化债权类资产，优先股、权益类、混合类资产管理产品不高于20%，剩余资金投资于标准化债权类资产根据资产配置比例及收益测算水平，扣除柜费成本后得出业绩比较基准。业绩比较基准
          </div>
          <div class="tag">
            <div class="tagList">非保本</div>
          </div>
        </div>
      </div>
    </div>
    <div class="share" draggable="true">
        <img class="shareImg" src="../../assets/images/manageMoney/share.png" alt="">
    </div>
  </div>
</template>
<script>
import showMore from "../manageMoney/components/showMore.vue";
export default {
  components: {  showMore },
  data() {
    return {
      longStr: "QXFGO5M181代销 | 由平安理财有限责任公司有限有限责…",
    };
  },
  created(){
    console.log(process.env.BASE_URL);
  },
  methods:{
  }
};
</script>
<style lang="less" scoped>
.main {
  overflow: auto;
  height: 365px;
  background: linear-gradient(180deg, #e7c488 0%, #fff0d3 56%, #ffffff 100%);
  .content {
    margin: 0 auto;
    margin-top: 20px;
    width: 340px;
    min-height: 329px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #ffffff;
    .banxin {
      width: 94%;
      margin: 0 auto;
    }
    .title {
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
    }
  }
}
.describe {
  font-size: 12px;
  color: #515151;
  line-height: 17px;
}
.lilv {
  margin-left: 15%;
  width: 40%;
  font-weight: 600;
  font-size: 16px;
  color: #d81e06;
}
.shen {
  width: 40%;
  font-weight: 600;
  font-size: 16px;
}
.time {
  margin-left: 15%;
  width: 40%;
  font-size: 14px;
  color: #515151;
}
.money {
  width: 40%;
  font-size: 12px;
  color: #515151;
}
.infoMiddle {
  display: flex;
  min-height: 26px;
  align-items: center;
  margin: 12px 0;
}
.text {
  margin-top: 10px;
  min-height: 80px;
  background: #f7f9ff;
  border-radius: 2px;
  font-size: 12px;
  color: #242d44;
  line-height: 19px;
  padding: 8px 6px;
}
.tag {
  .tagList {
    display: inline-block;
    border-radius: 2px;
    border: 1px solid #bfbebe;
    font-size: 10px;
    color: #9b9b9b;
    padding: 2px 4px;
  }
}
.share{
  position: fixed;
  right: 10px;
  top: 74%;
  .shareImg{
  width: 48px;
  height: 54px;
}
}

</style>