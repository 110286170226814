<template>
  <div class="index">
    <van-loading v-if="isGet == false" style="text-align: center" size="24px"
      >加载中...</van-loading
    >
    <div v-else>
      <div class="mapBox">
        <baidu-map
          class="bm-view"
          :center="center"
          :zoom="zoom"
          :dragging="dragging"
          ak="uSDQM0SXJgOKGoiDmMxh5a1BR0zhoKVJ"
        >
          <!-- <bm-circle
          :center="center"
          :radius="300"
          strokeColor="#EFA59B"
          :strokeOpacity="0.2"
          :fillOpacity="0.6"
          fillColor="#EFA59B"
        ></bm-circle> -->
          <bm-marker
            :position="center"
            :dragging="false"
            animation="BMAP_ANIMATION_BOUNCE"
          >
          </bm-marker>
        </baidu-map>
      </div>
      <div class="detailInfo">
        <div class="address">
          <div class="common" style="font-size: 16px; width: 86%">
            {{ sData.name }}
          </div>
          <!-- <div class="shen">待审批</div> -->
          <div class="shen" v-if="sData.status == 1">待抢单</div>
          <div class="shen" style="color: #9b9b9b" v-if="sData.status == 2">
            待审批
          </div>
          <div class="shen" style="color: #9b9b9b" v-if="sData.status == 3">
            已完成
          </div>
          <div class="shen" style="color: #9b9b9b" v-if="sData.status == 4">
            审批驳回
          </div>
        </div>
        <div class="address">
          <div class="common">经营地址</div>
          <div class="m16">{{ sData.addr }}</div>
        </div>
        <div class="address">
          <div class="common">客户名称</div>
          <div class="m17">{{ sData.contactName }}</div>
        </div>
        <div class="address">
          <div class="common">联系方式</div>
          <div class="m16">{{ sData.contactPhone }}</div>
        </div>
        <div class="address">
          <div class="common">图片</div>
          <div class="m17">
            <img v-if="sData.image!= ''" style="width: 48px; height: 48px" :src="sData.image" alt="" />
          </div>
        </div>
        <div class="address">
          <div class="common">发起时间</div>
          <div class="m17">{{ sData.createdAt }}</div>
        </div>
        <div class="address">
          <div class="common">发起人</div>
          <div class="m17">{{ sData.create_employee_name }}</div>
        </div>
        <div class="line"></div>
        <div class="address">
          <div class="common">接单人</div>
          <div class="m17">{{ sData.employee_name }}</div>
        </div>
        <div class="address">
          <div class="common">接单时间</div>
          <div class="m17">{{ sData.receiveTime }}</div>
        </div>
        <div class="address">
          <div class="common">接单方式</div>
          <div class="m17" v-if="sData.receiveType == 1">发起人分配</div>
          <div class="m17" v-if="sData.receiveType == 2">工单池抢单</div>
          <div class="m17" v-else>管理员分配</div>
        </div>
        <div class="line"></div>
        <div class="address">
          <div class="common">审批时间</div>
          <div class="m17">{{ sData.verifyTime }}</div>
        </div>
        <div class="address">
          <div class="common">审批状态</div>
          <div class="m17" v-if="sData.verifyStatus == 0">待审批</div>
          <div class="m17" v-if="sData.verifyStatus == 1">完成</div>
          <div class="m17" v-if="sData.verifyStatus == 2">驳回工单池</div>
          <div class="m17" v-if="sData.verifyStatus == 3">审批驳回</div>
        </div>
        <div class="address">
          <div class="common">审批图片</div>
          <div class="m17">
            <img
              v-if="sData.verifyImage != ''"
              style="width: 48px; height: 48px"
              :src="sData.verifyImage"
              alt=""
            />
          </div>
        </div>
        <div class="address">
          <div class="common">备注</div>
          <div class="m17">{{ sData.verifyRemark }}</div>
        </div>
        <div style="height: 10px"></div>
      </div>
      <div class="btnBox" v-if="sData.status == 2">
        <div class="cancel" @click="$router.go(-1)">返回</div>
        <div class="submit" @click="show = true">审批</div>
      </div>
      <div class="btnBox" v-if="sData.status == 1">
        <div class="cancel" @click="$router.go(-1)">返回</div>
        <div class="submit" @click="handleRob">抢单</div>
      </div>
      <van-action-sheet v-model="show" title="审批">
        <div class="content">
          <div class="status">
            <div class="title">审批状态</div>
            <div>
              <van-radio-group v-model="radio" direction="horizontal">
                <van-radio name="1">完成</van-radio>
                <van-radio name="2">驳回到工单池</van-radio>
                <van-radio name="3">拒绝</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="status">
            <van-field name="uploader" label="审批图片">
              <template #input>
                <van-uploader
                  multiple
                  v-model="fileList"
                  @delete="imgChange($event, item)"
                  :after-read="afterRead"
                  :before-read="beforeRead"
                  result-type="file"
                  :max-count="1"
                >
                </van-uploader>
              </template>
            </van-field>
          </div>
          <div class="status">
            <van-field v-model="remark" label="备注" placeholder="请输入备注" />
          </div>
          <div class="btn" @click="handleVerify">确认</div>
        </div>
      </van-action-sheet>
      <van-dialog
        v-model="showPopup"
        @confirm="$router.push('/workOrder/index')"
        @cancel="$router.push('/workOrder/workOrderPool')"
        confirmButtonText="我的工单"
        cancelButtonText="继续抢单"
        show-cancel-button
        confirmButtonColor="#3974C6"
      >
      <div @click="showPopup=false,getData()" style="text-align:right;width:95%;padding-top:6px"><img class="closeBtn" src="../../assets/workOrder/close.png" alt=""></div>
        <div class="popupContent">抢单成功！</div>
      </van-dialog>
    </div>
  </div>
</template>
<script>
import { verifyOrder, orderDetail, robOrder } from "@/api/workOrder";
import { ContactApi } from "@/api/contact";
import { getVcardData } from "@/api/electronicCard";

import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import bmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";
import BmCircle from "vue-baidu-map/components/overlays/Circle.vue";
import BmGeolocation from "vue-baidu-map/components/controls/Geolocation.vue";
export default {
  components: {
    BaiduMap,
    BmMarker,
    bmInfoWindow,
    BmLocalSearch,
    BmCircle,
    BmGeolocation,
  },
  data() {
    return {
      orderId: "",
      isGet: false,
      sData: {},
      show: false,
      showPopup: false,
      radio: "1",
      //BMap类
      BMap: null,
      //地图对象
      map: null,
      center: { lng: 116.404, lat: 39.915 },
      zoom: 16,
      // 地图坐标解析器
      gc: "",
      address: "北京市东城区中华路甲10号",
      location: "深圳",
      dragging: true,
      addressShow: false,
      pic: "",
      fileList: [],
      remark: "",
      employeeId: '',
      reveiceEmployeeId: '',//接单人Id
    };
  },
  created() {
    this.orderId = this.$route.query.id;
    this.getData();
    this.getEmplyeeId()
  },
  methods: {
    getEmplyeeId(){
      getVcardData().then(res=>{
        this.employeeId = res.data.id
      })
    },
    getData() {
      orderDetail({
        id: this.orderId,
      }).then((res) => {
        this.isGet = true;
        this.sData = res.data;
        this.center.lng = res.data.lng;
        this.center.lat = res.data.lat;
        this.reveiceEmployeeId = res.data.employeeId
      });
    },
    handleVerify() {
      verifyOrder({
        id: this.orderId,
        verify_image: this.pic,
        verify_remark: this.remark,
        verify_status: this.radio,
      }).then((res) => {
        this.$toast("审核成功！");
        this.$router.push("/workOrder/index");
      });
    },
    handleRob() {
      robOrder({ id: this.orderId }).then((res) => {
        this.showPopup = true;
        // this.getList();
      });
    },
    beforeRead(data) {
      if (data instanceof Array) {
        this.$toast({ position: "top", message: "最多选择一张图片" });
        return false;
      } else {
        return true;
      }
    },
    afterRead(data) {
      const params = new FormData();
      params.append("file", data.file);
      ContactApi.upload(params).then((res) => {
        this.fileList = [{ url: res.data.fullPath }];
        this.pic = res.data.fullPath;
      });
    },
    imgChange(data, item) {
      item.value = "";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-radio__icon {
  font-size: 14px;
}
/deep/ .van-radio-group--horizontal {
  padding: 12px;
}

.index {
  height: 100%;
  background-color: #fff;
  overflow: auto;
}
.mapBox {
  width: 100%;
  height: 260px;

  .bm-view {
    width: 100%;
    height: 100%;
  }
}
.detailInfo {
  width: 93%;
  margin: 0 auto;
  margin-top: 10px;
}
.address {
  display: flex;
  align-items: center;
  min-height: 28px;
}
.common {
  width: 20%;
  display: inline-block;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.shen {
  font-size: 12px;
  font-weight: 500;
  color: #3975c6;
}
.m16,
.m17 {
  font-size: 14px;
}
.m17 {
  color: #333333;
}
.m16 {
  color: #3975c6;
}
.line {
  margin: 10px 0;
  width: 100%;
  border: 0.1px solid #f1f1f1;
}
.btnBox {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 12px 10px 12px;

  .cancel {
    width: 30%;
    padding: 12px 0;
    text-align: center;
    background: #f1f2f6;
    border-radius: 5px;
    border: 1px solid #d7d7d9;
    font-size: 17px;
    font-weight: 500;
    color: #595a5e;
  }

  .submit {
    width: 65%;
    text-align: center;
    padding: 12px 0;
    background: #3974c6;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
  }
}
.content {
  width: 94%;
  margin: 0 auto;
  .status {
    font-size: 14px;
    color: #515151;
  }
  .title {
    padding: 0 12px;
  }
  .btn {
    height: 46px;
    line-height: 46px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin: 10px 0;
  }
}
.popupContent {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin: 10px 0 20px 0;
}
.closeBtn{
  width: 13px;
  height: 13px;
}
</style>