<template>
  <div class="page">
    <div class="item">
      <div class="title">规则名称：</div>
      <van-field v-model="ruleName" placeholder="请输入规则名称" />
    </div>
    <div class="item filed">
      <div class="top_filed title">客服成员：</div>
      <div class="input">
        <selectEmployee @change="getCount" ref="groups" />
      </div>
    </div>
    <div class="tips">*以下客服成员的新客户触发了规则后，将在侧边栏快 捷回复提醒该规则的内容。</div>
    <div class="borderBox" v-for="(rItem,rindex) in ruleList" :key="rindex">
      <div class="item">
        <div class="title">设置内容名称：
          <div class="iconBtn" @click="delRule(rindex)" v-if="rindex!=0">
            <img src="@/assets/images/sop/delete.png" alt="">
          </div>
        </div>
        <van-field class="vantName" v-model="rItem.name" placeholder="请输入名称" />
      </div>
      <div class="checkBox m15">
        <div class="title">设置发送时间：</div>
        <van-radio-group v-model="rItem.time.type">
          <div v-if="createType==1">
            <!-- <div class="groupBox">
              <div class="groupLeft mline">
                客户添加企业客服<input v-model="rItem.hour.first" placeholder=""
                  :style="{width:textWidth(rItem.hour.first)}" />小时
                <input v-model="rItem.hour.last" placeholder="" :style="{width:textWidth(rItem.hour.last)}" />分钟后提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'0'"></van-radio>
              </div>
            </div> -->
            <div class="groupBox">
              <div class="groupLeft mline">
                客户添加企业客服后<input v-model="rItem.day.first" placeholder=""
                  :style="{width:textWidth(rItem.day.first)}" />天后，当日
                <input readonly @click="timepopFun(rindex,1)" v-model="rItem.day.last" placeholder="选择时间"
                  style="width: 85px;" />提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'1'"></van-radio>
              </div>
            </div>
          </div>
          <!-- 原群发 -->
          <div v-if="createType==2">
            <div class="groupBox">
              <div class="groupLeft">
                客户添加企业客服<input v-model="rItem.time.data.typeFirst3" placeholder=""
                  :style="{width:textWidth(rItem.time.data.typeFirst3)}" />日后， 提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'3'"></van-radio>
              </div>
            </div>
          </div>
          <div v-if="createType==3">
            <div class="groupBox">
              <div class="groupLeft mline">
                sop创建成功开启<input v-model="rItem.day.first" placeholder=""
                  :style="{width:textWidth(rItem.day.first)}" />天后，当日
                <input readonly @click="timepopFun(rindex,1)" v-model="rItem.day.last" placeholder="选择时间"
                  style="width: 85px;" />提醒发送
              </div>
              <div class="groupRight">
                <van-radio :name="'7'"></van-radio>
              </div>
            </div>
          </div>
        </van-radio-group>
        <div v-if="rItem.tagIds&&rItem.tagIds.length!=0">
          <div class="title">{{rItem.time.type==5?'包含':'选中'}}标签:</div>
          <div class="tags">
            <div class="tag" v-for="tag in rItem.tagIds">
              <a-tag @close="closeTagChange(rindex,'contain',tag)" closable>{{ tag.name }}</a-tag>
            </div>
          </div>
        </div>
        <div v-if="rItem.tagIdName&&rItem.tagIdName.length!=0">
          <div class="title">不包含标签:</div>
          <div class="tags">
            <div class="tag" v-for="tag in rItem.tagIdName">
              <a-tag @close="closeTagChange(rindex,'notContain',tag)" closable>{{ tag.name }}</a-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="title">设置发送内容：
        </div>
        <van-field v-model="rItem.content[0].value" type="textarea" maxlength="50" placeholder="请输入留言" show-word-limit
          class="textareaBox" />
        <div class="title" v-if="rItem.content[2]&&rItem.content[2].value!=''">图片: </div>
        <div class="imgBox" v-if="rItem.content[2]&&rItem.content[2].value!=''">
          <img :src="rItem.content[2].value" alt="">
        </div>
        <div v-if="rItem.content[1]&&rItem.content[1].childMediumData&&rItem.content[1].childMediumData.length!=0">
          <div class="title">附件：</div>
          <div class="filesitem" v-for="(v,index) in rItem.content[1].childMediumData" :key="index">
            <div class="filesleft">
              【{{v.initialType?v.initialType:v.type}}】：{{v.content?(v.content.title?v.content.title:v.title):v.title}}
            </div>
            <div class="filesright fz16">
              <a-icon type="delete" class="pointer" @click="deleteMaterial(rindex,v,index)" />
            </div>
          </div>
        </div>
        <div class="item_flex" @click="selectMate(rindex)">
          <div class="addbox">
            <img src="@/assets/images/sop/add.png" alt="">
          </div>
          <div class="name">选择素材</div>
        </div>
      </div>
    </div>
    <div class="item_flex ruleBox" @click="addRuleBtn">
      <div class="addrule">
        <img src="@/assets/images/sop/add.png" alt="">
      </div>
      <div class="name">添加规则</div>
    </div>
    <div class="btnBox">
      <div class="cancel" @click="cellback()">取消</div>
      <div class="submit" @click="btnClick()">确定</div>
    </div>
    <!--  员工  -->
    <employeeIndex ref="employee" @change="userChange" />
    <!--  标签  -->
    <popup ref="popup" @change="choseTags" />
    <!-- 选择时间 -->
    <van-popup v-model="timeShow" round position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker v-model="dataTime" type="time" title="选择时间" @confirm="confirmTime" @cancel="cancelTime" />
    </van-popup>
    <!-- 素材库 -->
    <van-popup v-model="matePop" position="bottom" :style="{ height: '100%',background:'#F2F3F7' }">
      <matePopup ref="mateChild" @closePop="closePop" @popSubmit="popSubmit" :key="datekey"></matePopup>
    </van-popup>
    <van-popup v-model="mateSele" round position="bottom" closeable :style="{ height: '50%',background:'#FFFFFF' }">
      <popPage ref="matebpx" @popBtn="popBtn" @imagesData="imagesData"></popPage>
    </van-popup>
  </div>
</template>
<script>
  import employeeIndex from "../../channelCode/components/employeeIndex";
  import { storeContactSop, sopUpdate } from "@/api/sopList";
  import popup from "@/components/select/tag/popup";
  import matePopup from "@/components/select/material/index";
  import popPage from "@/components/select/material/popPage";
  import { Dialog } from 'vant';
  import selectEmployee from "@/components/select/employee/select";
  export default {
    components: {
      employeeIndex,
      popup,
      matePopup,
      Dialog,
      popPage,
      selectEmployee
    },
    data() {
      return {
        radio: '1',
        message: '',
        // 请选择成员
        serviceList: [],
        upperStepper: [],
        upperSteppert: [],
        // 备用员工
        StandbyStaffList: [],
        // 自动上下线
        autoSxs: [
          {
            user: [],
            workWeek: [],
            workWeekName: "",
            goTime: "请选择",
          },
        ],
        ruleList: [
          {
            name: '',
            push_type: 0,
            hour: {
              first: 0,
              last: 0,
            },
            day: {
              first: 0,
              last: null,
            },
            time: {
              type: '1',
              data: {
                first: 0,
                last: null,
                typeFirst3: 0,
                typeFirst4: 0,
                typeLast4: null,
                typeFirst5: 0,
                typeLast5: null,
              },
            },
            content: [{ type: 'text', value: '' }, { type: 'sucai', value: '' }, { type: 'image', value: '' }],
          }
        ],
        dataTime: null,
        tags: [],
        timeShow: false,
        tagsValueEdit: false,
        submitLoad: false,//提交状态
        ruleName: '',//规则名称,
        showEmployee: '',//成员
        tagsType: '',//选择标签类型
        containTags: [],//包含标签
        employeeIds: [],
        NotContainTags: [],//不包含标签
        timePopType: undefined,
        matePop: false,//素材库弹窗
        selectMetedata: [],//已选素材库
        currentGroup: 'text',//当前选择
        selectMateIndex: 0,//当前内容选项
        currentrItem: 0,//当前规则下标
        datekey: Date.now(),
        currentTimerItem: 0,//时间选择规则列表下标
        currentTagsrItem: 0,//标签选择规则列表下标
        mateSele: false,
        editStatus: false,//是否为编辑 false新增数据 true编辑修改
        editId: undefined,//编辑ID
        createType: '',//创建类型
        weekShow: false,
        mouthShow: false,
        yearShow: false,
        yearColumns: {
          minDate: new Date(2020, 0, 1),
          maxDate: new Date(2025, 10, 1),
          currentDate: new Date(),
        }
      };
    },
    computed: {
      textWidth() {
        return function (value) {
          if (value == '' || value == 0 || String(value).length <= 2) {
            return '40px'
          } else {
            return String(value).length * 0.42 + 'rem'
          }
        }
      }
    },
    created() {
      //获取创建类型
      if (this.$route.query.type) {
        this.createType = this.$route.query.type
      }
      //存在编辑信息 将数据回显
      if (this.$route.query.data) {
        this.$nextTick(() => {
          this.editStatus = true
          let editInfo = JSON.parse(this.$route.query.data)
          this.editId = editInfo.id
          this.ruleName = editInfo.name
          this.showEmployee = editInfo.creatorName
          this.serviceList = editInfo.employees
          this.$refs.groups.returnBack(editInfo.employees)
          //处理发送方式
          editInfo.setting.forEach(item => {
            if (item.push_type == 0 && item.time.type == 0) {
              item.hour = item.time.data
              item.day = {
                first: 0,
                last: null,
              }
            } else if (item.push_type == 0 && item.time.type == 1) {
              item.day = item.time.data
              item.hour = {
                first: 0,
                last: 0,
              }
            } else if (item.push_type == 1 && item.time.type == 0) {
              item.hour = item.time.data
              item.day = {
                first: 0,
                last: null,
              }
            } else if (item.push_type == 1 && item.time.type == 1) {
              item.day = item.time.data
              item.hour = {
                first: 0,
                last: 0,
              }
            }
          })
          this.ruleList = editInfo.setting
        })
      }
    },
    methods: {
      //返回
      cellback() {
        this.$router.back();
      },
      // 选择员工
      userChange(data, info) {
        this.showEmployee = data[0].name
        if (info.name == "serviceList") {
          this.serviceList = data;
          const value = [...data];
          data.forEach((item) => {
            item.stepper = 1;
          });
          this.upperStepper = value;
        } else if (info.name == "StandbyStaffList") {
          this.StandbyStaffList = data;
        } else if (typeof info.name == "number") {
          this.autoSxs[info.name].user = data;
          const value = [...data];
          data.forEach((item) => {
            item.stepper = 1;
          });
          this.upperSteppert.push(...value);
        }
      },
      // 时间组件弹窗
      timepopFun(rindex, type) {
        //type选择的组
        this.currentTimerItem = rindex;
        this.timePopType = type
        this.timeShow = true
      },
      // 时间选择确认事件
      confirmTime(e) {
        this.dataTime = e
        //将数据回显到对应内容上
        this.ruleList[this.currentTimerItem].day.last = e;
        this.timeShow = false
      },
      cancelTime() {
        this.timeShow = false
      },
      //添加数据
      async btnClick() {
        if (this.submitLoad) return;
        if (this.ruleName == '') {
          this.$message.error('请输入规则名称')
          return false
        }
        if (this.employeeIds.length == 0) {
          this.$message.error('请选择客服成员')
          return false
        }
        //循环判断必填条件
        let judgeData = await this.judgeFun(this.ruleList)
        if (!judgeData) return
        //移除非必要数据
        let newData = JSON.parse(JSON.stringify(this.ruleList))
        let resNonData = await this.resNonFun(newData)
        //处理文件格式
        let resFileData = await this.resFileFun(resNonData)
        let employeeId = this.employeeIds
        let params = {
          name: this.ruleName,//规则名称
          employees: JSON.stringify(employeeId),//员工数据
          setting: JSON.stringify(resFileData),//设置参数
        }
        this.submitLoad = true
        if (this.editStatus) {
          params.id = this.editId
          sopUpdate(params).then((res) => {
            if (res.code == 200) {
              this.$message.success("编辑成功");
              setTimeout(() => {
                this.$router.go(-2);
              }, 1500)
            }
            this.submitLoad = false
          })
        } else {
          params.type = this.createType// 新增方式类型
          storeContactSop(params).then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");
              setTimeout(() => {
                this.$router.go(-2);
              }, 1500)
            }
            this.submitLoad = false
          })
        }

      },
      //循环判断必填条件
      judgeFun(data) {
        let status = data.every(item => {
          if (item.name == '') {
            this.$message.error('请输入内容名称')
            return false
          } else if (item.time.type == 0 && item.hour.first < 1) {
            this.$message.error('提醒天数不能小于1')
            return false
          } else if (item.time.type == 0 && item.hour.last == 0) {
            this.$message.error('请填写提醒时间')
            return false
          } else if (item.time.type == 1 && item.day.first < 1) {
            this.$message.error('提醒天数不能小于1')
            return false
          } else if (item.time.type == 1 && item.day.last == null) {
            this.$message.error('请填写提醒时间')
            return false
          } else if (item.content[0].type == 'text' && item.content[0].value == '') {
            this.$message.error('请输入发送内容')
            return false
          } else {
            return true
          }
        })
        return status
      },
      //移除非必要数据
      resNonFun(data) {
        return new Promise(resolve => {
          let resNonData = data.map((item) => {
            if (item.time.type == 0) {
              item.time.data = item.hour
            } else if (item.time.type == 1) {
              item.time.data = item.day
            }
            delete item['hour']
            delete item['day']
            // 删除空内容 防止提交空数据
            var arr = item.content;
            var i = arr.length;
            while (i--) {
              if (arr[i].type == 'text' && arr[i].value == '') {
                arr.splice(i, 1);
              } else if (arr[i].type == 'sucai' && arr[i].childMediumData == undefined) {
                arr.splice(i, 1);
              } else if (arr[i].type == 'image' && arr[i].value == '') {
                arr.splice(i, 1);
              }
            }
            return item
          })
          resolve(resNonData);
        });
      },
      //处理文件格式
      resFileFun(data) {
        return new Promise(resolve => {
          data.forEach((row) => {
            row.content.forEach((item) => {
              if (!item.childMediumData) return
              item.childMediumData = item.childMediumData.map((item) => {
                // XX||XX 编辑||新增
                if (item.type == '文本' || item.type == 'text') {
                  return item = {
                    content: item.content.content ? item.content.content : item.content,
                    type: 'text',
                    title: item.title || item.content.title,//回显需要
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '图文' || item.type == 'link') {
                  return item = {
                    title: item.title || item.content.title,
                    picurl: item.picurl || item.content.imageFullPath,
                    desc: item.desc || item.content.description,
                    url: item.url || item.content.imageLink,
                    type: 'link',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '图片' || item.type == 'images') {
                  return item = {
                    title: item.title || item.content.title,//回显需要
                    media_id: item.media_id || item.mediaId,
                    id: item.id,
                    pic_url: item.pic_url || item.content.imageFullPath,
                    value: item.value || item.content.imageFullPath,
                    imagePath: item.imagePath || item.content.imagePath,
                    type: 'images',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '音频' || item.type == 'voice') {
                  return item = {
                    title: item.title || item.content.title,
                    media_id: item.media_id || item.mediaId,
                    id: item.id,
                    pic_url: item.pic_url || item.content.voiceFullPath,
                    type: 'voice',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '视频' || item.type == 'video') {
                  return item = {
                    title: item.title || item.content.title,
                    media_id: item.media_id || item.mediaId,
                    id: item.id,
                    pic_url: item.pic_url || item.content.videoFullPath,
                    type: 'video',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '文件' || item.type == 'file') {
                  return item = {
                    title: item.title || item.content.title,
                    pic_url: item.pic_url || item.content.fileFullPath,
                    media_id: item.media_id || item.mediaId,
                    id: item.id,
                    type: 'file',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.type == '小程序' || item.type == 'miniprogram') {
                  return item = {
                    title: item.title || item.content.title,
                    pic_media_id: item.pic_media_id || item.content.imageFullPath,
                    id: item.id,
                    appid: item.appid || item.content.appid,
                    page: item.page || item.content.page,
                    type: 'miniprogram',
                    initialType: item.initialType || item.type,//回显需要
                  }
                } else if (item.typeName) {//雷达素材
                  return item = {
                    title: item.title || item.link_title,
                    picurl: item.picurl || item.link_cover,
                    desc: item.desc || item.link_description,
                    url: item.url || item.linkUrl,
                    type: 'link',
                    initialType: item.initialType || item.type,//回显需要
                  }
                }
              })
            })
          })
          resolve(data);
        });

      },
      //选择标签
      selectTag(rindex, type) {
        this.currentTagsrItem = rindex;
        this.tagsType = type;
        this.$refs.popup.show()
      },
      // 企业标签
      choseTags(data) {
        if (this.tagsType == 'contain') {//包含标签
          this.$set(this.ruleList[this.currentTagsrItem], 'tagIds', data)
        } else if (this.tagsType == 'notContain') {//不包含标签
          this.$set(this.ruleList[this.currentTagsrItem], 'tagIdName', data)
        } else {//设置标签
          this.$set(this.ruleList[this.currentTagsrItem], 'tagIds', data)
        }
      },
      closeTagChange(rindex, type, e) {
        if (type == 'contain') {
          for (const k in this.ruleList[rindex].tagIds) {
            if (this.ruleList[rindex].tagIds[k].id === e.id) {
              this.ruleList[rindex].tagIds[k].closable = true;
              return false;
            }
          }
        } else {
          for (const k in this.ruleList[rindex].tagIdName) {
            if (this.ruleList[rindex].tagIdName[k].id === e.id) {
              this.ruleList[rindex].tagIdName[k].closable = true;
              return false;
            }
          }
        }
      },
      //选择素材库
      selectMate(rIndex) {//, i
        this.currentrItem = rIndex;//规则下标
        // this.selectMateIndex = i;//内容下标
        this.mateSele = true;
        this.$nextTick(() => { this.$refs.matebpx.deltelocalfun([2, 3, 4, 5, 6]) })
      },
      //关闭弹窗
      closePop() {
        this.matePop = false
      },
      //s素材库选择确认
      popSubmit(data) {
        this.matePop = false
        let arr = this.ruleList[this.currentrItem].content[1]
        arr.childMediumData == undefined ? arr.childMediumData = data : arr.childMediumData = [...arr.childMediumData, ...data]
      },
      //素材删除
      deleteMaterial(rindex, v, index) {//i,
        //rindex规则下标 内容父下标  index子下标
        this.ruleList[rindex].content[1].childMediumData.splice(index, 1)
        let newData = this.ruleList[rindex].content[1].childMediumData
        this.$delete(this.ruleList[rindex].content[1], 'childMediumData')
        this.$set(this.ruleList[rindex].content[1], 'childMediumData', newData)
      },
      chengeGroup(rindex, index, type) {
        this.currentGroup = type
        type == 'text' ? this.$delete(this.ruleList[rindex].content[index], 'selectMetedata') : this.$delete(this.ruleList[rindex].content[index], 'value')
      },
      //添加内容
      addContent(rItem) {
        this.ruleList[rItem].content.push({ type: 'text', value: '' })
      },
      delContent(rindex, i) {
        let that = this
        Dialog.confirm({
          title: '提示',
          message: '是否删除当前内容',
        })
          .then(() => {
            if (that.ruleList[rindex].content.length === 1) {
              that.$message.error('无法删除最后一个')
              return false
            }
            that.ruleList[rindex].content.splice(i, 1)

          })
          .catch(() => {
          });
      },
      //添加规则
      addRuleBtn() {
        let json = {
          name: '',
          push_type: 0,
          hour: {
            first: 0,
            last: 0,
          },
          day: {
            first: 0,
            last: null,
          },
          time: {
            type: this.createType == 1 ? '1' : (this.createType == 3 ? '7' : '3'),
            data: {
              first: 0,
              last: null,
            },
          },
          content: [{ type: 'text', value: '' }, { type: 'sucai', value: '' }, { type: 'image', value: '' }],
        }
        this.ruleList.push(json)
      },
      //删除规则
      delRule(i) {
        let that = this
        Dialog.confirm({
          title: '提示',
          message: '是否删除当前规则内容？',
        })
          .then(() => {
            that.ruleList.splice(i, 1)
          })
          .catch(() => {
          });

      },
      //素材库选项
      popBtn(row) {
        this.matePop = true
        this.mateSele = false;
        this.datekey = Date.now();//刷新子组件
        this.$nextTick(() => {
          this.$refs.mateChild.selectPop = true //修改子组件data
          this.$refs.mateChild.currentID = row.id  //修改子组件变量
          this.$refs.mateChild.activeName = row.id   //修改子组件选中tab
          this.$refs.mateChild.oldSelectData = this.ruleList[this.currentrItem].content[1].childMediumData || []   //传递已选择数据
        })
      },
      //子组件图片数据
      imagesData(data) {
        if (this.ruleList[this.currentrItem].content[2] != undefined) {
          this.$delete(this.ruleList[this.currentrItem].content[2], 'value')
          this.$set(this.ruleList[this.currentrItem].content[2], 'value', data)
        } else {
          this.ruleList[this.currentrItem].content[2] = { type: 'image', value: data }
        }
        this.mateSele = false;
      },
      // 获取员工Id
      getCount() {
        this.employeeIds = this.$refs.groups.get().map((v) => {
          return v.id;
        });
      },
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .van-popup__close-icon {
    top: 22px !important;
  }

  /deep/.van-cell::after {
    border-bottom: none
  }

  /deep/[class*=van-hairline]::after {
    border: none
  }

  /deep/.van-cell__title {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }

  /deep/.van-field--min-height .van-field__control {
    min-height: 100px;
  }


  .page {
    width: 100%;
    background: #FFFFFF;
    padding: 5px 0;
    font-size: 16px;

    .borderBox {
      width: 95%;
      border: 1px dashed #C0C0C0;
      /* padding: 0px 10px; */
      margin: 0 auto;
    }

    .item {
      margin-top: 17px;
      border-bottom: 1px solid #F5F5F5;
      padding: 0 12px 10px 12px;

      .vantName {
        padding: 10px 0px;
        margin-top: 5px;
        font-size: 15px;
      }

      .title {
        font-size: 15px;
        font-weight: 400;
        color: #515151;
        display: inline-flex;

        .iconBtn {
          float: right;
          position: absolute;
          right: 22px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .conBox {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        img {
          width: 6px;
          height: 10px;
          margin-right: 10px;
        }
      }

      .textareaBox {
        margin: 20px 0;
        padding: 15px 20px;
        background: #F2F3F7;
        min-height: 100px;
        border-radius: 12px;
      }

      .imgBox {
        margin: 10px 0;

        img {
          width: 70px;
          height: 70px;
        }
      }

      .addbox {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #F2F3F7;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }

      }

      .name {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        margin-left: 12px;
      }
    }

    .item_flex {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .tips {
      margin-top: 12px;
      font-size: 15px;
      font-weight: 400;
      color: #A5A5A5;
      padding: 0 12px 10px 12px;
    }

    .checkBox {
      margin-top: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid #F5F5F5;
      padding: 0 12px 10px 12px;

      .title {
        font-size: 15px;
        font-weight: 400;
        color: #515151;
        margin-bottom: 5px;
      }

      .groupBox {
        display: flex;
        // justify-content: space-between;
        margin: 24px 0;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        position: relative;

        .groupLeft {
          width: 90%;
          word-break: break-all;
          overflow: hidden;
          font-size: 15px;

          input {
            background: #F2F3F7;
            border-radius: 2px;
            height: 25px;
            border: none;
            text-align: center;
            padding: 0 10px;
            width: 40px;

          }
        }

        .mline {
          /* line-height: 2.5; */
        }

        .groupRight {
          width: 10%;
          position: absolute;
          top: 5px;
          right: -15px;
        }
      }
    }

    .m15 {
      margin-top: 17px;
    }

    .btnBox {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 12px 10px 12px;

      .cancel {
        width: 30%;
        padding: 12px 0;
        text-align: center;
        background: #F1F2F6;
        border-radius: 5px;
        border: 1px solid #D7D7D9;
        font-size: 17px;
        font-weight: 500;
        color: #595A5E;
      }

      .submit {
        width: 65%;
        text-align: center;
        padding: 12px 0;
        background: #3974C6;
        border-radius: 5px;
        font-size: 17px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }

  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 8px;

    .tag {
      margin-bottom: 6px;
    }
  }

  .filesitem {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filesleft {
      color: #565656;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .ruleBox {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;

    .name {
      font-size: 15px;
      color: #3974C6;
      margin-left: 5px
    }
  }

  .addrule {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    border: 1px dashed #F2F3F7;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
    }

  }

  .space {
    width: 100%;
    height: 10px;
  }
</style>