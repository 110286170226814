import request from "../plugins/axios";

export function add(params) {
  return request({
    url: '/contactMessageBatchSend/store',
    method: 'POST',
    data: params
  })
}

export function getPower(params) {
  return request({
    url: '/contactMessageBatchSend/employeeInfo',
    method: 'GET',
    params
  })
}

export function getSendUserCount(params) {
  return request({
    url: '/contactMessageBatchSend/filterContact',
    method: 'PUT',
    data: params
  })
}
