<template>
    <div class="popup">
      <a-modal
        title="选择成员"
        :visible="visible"
        okText="确定"
        cancelText="取消"
        @cancel="cancel"
        @ok="ok"
        width="280px"
        :zIndex="1999"
      >
        <template slot="footer">
          <!-- <a-checkbox
            v-show="tranferMessage != 1"
            class="checkBox"
            :checked="checked"
            @change="onChange"
            >全选
          </a-checkbox> -->
          <a-button key="back" @click="cancel"> 取消 </a-button>
          <a-button key="submit" type="primary" @click="ok"> 确定 </a-button>
        </template>
        <div class="search">
          <a-input-search placeholder="请输入昵称" @search="onSearch" />
        </div>
        <div class="list">
          <div class="item" v-for="v in list">
            <div class="info">
              <div class="avatar">
                <img :src="v.avatar" />
              </div>
              <div class="nickname">
                {{ v.name }}
              </div>
            </div>
            <div class="input">
              <a-checkbox v-model="v.select" />
            </div>
          </div>
        </div>
      </a-modal>
    </div>
  </template>
  
  <script>
  import { allEmployeeList } from "@/api/workOrder";
  
  export default {
    // 是否限制单选标识
    inject: ["tranferMessage"],
    data() {
      return {
        visible: false,
        list: [],
        oldList: [],
        checked: false,
        radio: "",
      };
    },
    methods: {
      onSearch(e) {
        if (!e) this.list = this.oldList;
  
        this.list = this.oldList.filter((v) => {
          const rep = new RegExp(e);
  
          return rep.test(v.name);
        });
      },
      cancel() {
        this.checked = false;
        this.visible = !this.visible;
      },
      ok() {
        const data = this.list.filter((v) => {
          return v.select;
        });
  
        if (data.length > 1 && this.tranferMessage == 1) {
          this.$toast({
            message: "最多分配一人",
          });
          return;
        } else {
          this.checked = false;
          this.visible = false;
          this.$emit("change", JSON.parse(JSON.stringify(data)));
        }
      },
      show(room = false) {
        this.getData();
  
        this.visible = true;
      },
      getData() {
        allEmployeeList().then((res) => {
          res.data.forEach((v) => (v.select = false));
  
          this.list = res.data;
          this.oldList = res.data;
        });
      },
      //全选
      onChange(e) {
        this.checked = e.target.checked;
        this.list.forEach((v) => (v.select = e.target.checked));
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  /deep/ .ant-modal-body {
    height: 300px;
    overflow: auto;
  }
  
  /deep/ .ant-btn-primary {
    background: #3974c6;
  }
  .search {
    width: 100%;
    margin-bottom: 20px;
    /deep/ .ant-input-search {
      width: 100% !important;
    }
  }
  
  .list {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 13px;
  
      .info {
        display: flex;
        align-items: center;
      }
  
      .avatar {
        img {
          width: 35px;
          height: 35px;
          border-radius: 3px;
          margin-right: 6px;
        }
      }
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .checkBox {
    float: left;
    margin-top: 5px;
  }
  </style>