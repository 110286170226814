<template>
  <div class="all">
    <a-spin :spinning="loading" tip="绑定中..."> 
    <div class="top">
      <van-search
        class="input"
        v-model="inputSearch"
        shape="round"
        placeholder="请输入企业名称或关键词搜索"
        @search="onSearch"
        autofocus="true"
      />
    </div>
    <p class="grayBackground"></p>
    <div class="result" v-for="(item, index) in cropInfo.corpData" :key="index">
      <div class="left">{{ item.name }}</div>
      <div class="right" @click="bind(item.id, item.name, item.creditNo)">
        绑定
      </div>
    </div>
    <van-dialog
      v-model="show"
      show-cancel-button
      @cancel="quexiao"
      @confirm="confirm"
    >
      <p class="bind1">确认绑定吗?</p>
    </van-dialog>
    <van-dialog v-model="showRecharge" show-cancel-button @cancel="quexiao" @confirm="payOrder">
      <p class="bind2">对公业务搜索次数已用完，可自行扫码充值或公司统一采购</p>
      <van-cell-group>
        <van-field
          v-model="chargeNum"
          label="采购次数"
          placeholder="请输入充值次数"
        />
      </van-cell-group>
    </van-dialog>
    <van-dialog
      v-model="showQrcode"
      title="请扫码支付"
      show-cancel-button
      @confirm="queding"
      @cancel="quexiao"
    >
      <div class="qrcode" ref="qrCodeDiv"></div>
    </van-dialog>
    <van-dialog
      v-model="showSucced"
      @confirm="goBind"
      @cancel="fanhui"
      show-cancel-button
      cancelButtonText="返回"
      confirmButtonText="继续绑定"
    >
      <div class="bind2">付款成功，对公业务更新次数已更新。</div>
    </van-dialog>
    <van-dialog
      v-model="showFail"
      @confirm="againPay"
      @cancel="fanhui"
      show-cancel-button
      cancelButtonText="返回"
      confirmButtonText="继续绑定"
    >
      <div class="bind2">付款失败，请重新提交支付。</div>
    </van-dialog>
  </a-spin>
  </div>
</template>
<script>
import { publicBusinessApi } from "../../api/publicBusiness";
import { judge } from "../../utils/judge.js";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      contactId: "",
      inputSearch: "",
      showQrcode: false,
      showSucced: false,
      cropInfo: [],
      show: false,
      corpId: "",
      corpName: "",
      corpCreditNo: "",
      detailCreditNo: "",
      //搜索流水号
      serialNum: "",
      showRecharge: false,
      chargeNum: "",
      times: "",
      device: "",
      timer: "",
      order: "",
      loading:false
    };
  },
  created() {
    this.times = this.$route.query.times;
    this.contactId = this.$route.query.contactId;
    this.device = judge();
    console.log("this.device", this.device);
    if (this.times <= 0) {
      this.showRecharge = true;
    }
  },
  methods: {

    quexiao() {
      clearInterval(this.timer);
      this.$router.push({
        path: "/contact/selectBinding",
        query: {
          contactId: this.contactId,
        },
      });
    },
    queding() {
      clearInterval(this.timer);
    },
    fanhui() {
      this.$router.push({
        path: "/contact/selectBinding",
        query: {
          contactId: this.contactId,
        },
      });
    },
    goBind() {
      this.$router.push("/contact/search");
    },
    handlePay(res) {
      if (res.code == 200) {
        if (this.device == "m") {
          let orderId = this.order
          let contactid = this.contactId
          let url = encodeURIComponent(
              `${
                location.protocol + "//" + location.host
              }/payPopu/index?id=${orderId}&contactid=${contactid}`
            );
            location.href = res.data.wechctUrl + `&redirect_url=${url}`;
        } else {
          this.showQrcode = true;
          this.$nextTick(() => {
            new QRCode(this.$refs.qrCodeDiv, {
              text: res.data.wechctUrl, //url地址  文本等需要转换为二维码的内容
              width: 80,
              height: 80,
              colorDark: "#333333", //二维码颜色
              colorLight: "#ffffff", //二维码背景色
              correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
            });
          });
          var that = this;
          this.timer = setInterval(function () {
            console.log("order", that.order);
            publicBusinessApi
              .queryStatus({ orderNo: that.order })
              .then((res) => {
                console.log("res", res);
                if (res.data.status == 2) {
                  that.showSucced = true;
                  that.showQrcode = false;
                  clearInterval(that.timer);
                }
              });
          }, 1000);
        }
      } else {
        this.$toast(res.sMsg);
      }
    },

    payOrder() {
      publicBusinessApi
        .createOrder({ rechargeTimes: this.chargeNum, device: this.device })
        .then((res) => {
          console.log("下单", res);
          this.order = res.data.orderNo;
          console.log("this.order", this.order);
          this.handlePay(res);
        });
    },
    onSearch() {
      publicBusinessApi.searchCorp({ name: this.inputSearch }).then((res) => {
        console.log("搜索", res);
        this.cropInfo = res.data;
        this.serialNum = res.data.serialNumber;
      });
    },
    bind(id, name, creditNo) {
      this.corpId = id;
      this.corpName = name;
      this.corpCreditNo = creditNo;
      this.show = true;
    },
    confirm() {
      this.loading=true
      this.contactId = this.$route.query.contactId;
      publicBusinessApi
        .bindCorp({
          id: this.corpId,
          name: this.corpName,
          creditNo: this.corpCreditNo,
          contactId: this.contactId,
          serialNumber: this.serialNum,
        })
        .then((res) => {
          this.loading=false
          console.log("绑定成功");
          if (res.code == 200) {
            this.$toast("绑定成功");
            this.$router.push({
              path: "/contact/selectBinding",
              query: {
                contactId: this.contactId,
              },
            });
          } else {
            this.$toast(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.all {
  overflow: hidden;
}

/deep/ .van-cell {
  font-size: 16px;
  color: #4a4a4a;
}
.grayBackground {
  width: 100%;
  height: 11px;
  background: #f2f3f7;
}
.qrcode {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  margin-top: 16px;
}
.result {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%;
  height: 40px;
  .left {
    font-size: 13px;
    color: #4a4a4a;
  }
  .right {
    font-size: 13px;
    color: #3974c6;
  }
}
.bind1 {
  margin-top: 20px;
  text-align: center;
  margin-left: 6%;
  font-size: 16px;
  color: #4a4a4a;
}
.bind2 {
  margin-top: 20px;
  width: 88%;
  margin-left: 6%;
  font-size: 16px;
  color: #4a4a4a;
}
</style>