-<template>
    <div>
        <div class="heard">
            <div class="top">
                <div class="topContent">
                    <div class="topLeft">
                        <span class="team">我的</span>
                        <img class="change" src="../../assets/qiehuan.png" alt="" />
                    </div>
                    <div class="topRight">
                        <van-search shape="round" v-model="searchVal" @search="handleSearch" @clear="handleSearch"
                            clearable placeholder="搜索" />
                    </div>
                </div>
            </div>
            <div class="filter">
                <div class="filterLeft">共 {{ length }} 个结果</div>
            </div>
        </div>
        <div class="conBox">
            <div v-if="customerData.length > 0">
            <van-list  v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div style="width: 94%; margin: 0 auto">
                    <div class="mainTop"></div>
                    <div class="mainList" v-for="(res, ind) in customerData" :key="ind" @click="goDetail(res)">
                        <div class="content" style="width: 94%; margin: 0 auto">
                            <div class="listTop">
                                <div class="name">
                                    {{ res.name }}
                                </div>
                                <div class="status" :style="setColor(res.status)">
                                    {{res.status}}
                                </div>
                            </div>
                            <div class="address" @click.stop="handleClick(res)">
                                <span>{{res.address}}</span>
                                <img src="../../assets/images/ins/loca.png" alt="">
                            </div>


                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <div v-else class="conBox">
            <van-empty description="暂无数据" />
        </div>
        </div>

    </div>
</template>
<script>
    import { getCustomlist } from "@/api/gridManage";
    import { openUserLocation } from "@/utils/wxCodeAuth";
    export default {
        data() {
            return {
                time: false,
                loading: false,
                finished: false,
                page: 1,
                searchVal: "",
                showMy: false,
                active: 0,
                showAction: false,
                length: 0,
                customerData: [],
                type: 0,
                filterParam: {},
                page: 1,
                hasLoadedOnce: false, // 新增一个标志
            };
        },
        created() {
            this.getList();
        },
        methods: {
            onLoad() {
            this.page++;
            this.getList();

            },
            getList() {
                this.loading = true;
                getCustomlist({
                    name: this.searchVal,
                    page: this.page,
                    perPage: 10
                }).then((res) => {
                    this.customerData.push(...res.data.list);
                    this.length = res.data.page.total;
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if (this.customerData.length >= res.data.page.total) {
                        this.finished = true;
                    } else {
                        this.finished = false;
                    }
                });

            },
            handleTabChange() {

            },
            //搜索功能
            handleSearch() {
                this.customerData = [];
                this.finished = false;
                this.page = 1;
                this.getList()
            },
            setColor(type) {
                const colorMap = {
                    '正常': 'background: #D7F3D4; color: #39A52E', // 假设的颜色，根据实际情况调整
                };
                return colorMap[type] || 'background: #FFDAD8; color: #D2261D'; // 返回对应的状态颜色，如果状态不存在则返回空字符串
            },
            // 包装后的点击事件处理函数
            handleClick(res) {
                this.immediateDebounce(() => this.showMap(res), 1000); // 3000毫秒内多次点击只执行一次
            },
            // 立即执行的防抖函数
            immediateDebounce(func, delay) {
                if (!this.debounceTimer) {
                    // 如果没有设置过计时器，则立即执行func，并设置计时器
                    func();
                    this.debounceTimer = setTimeout(() => {
                        this.debounceTimer = null; // 3秒后重置计时器
                    }, delay);
                } else {
                    // 如果计时器已设置（即在延迟时间内再次点击），则清除现有计时器并重新开始计时
                    clearTimeout(this.debounceTimer);
                    this.debounceTimer = setTimeout(() => {
                        this.debounceTimer = null;
                    }, delay);
                }
            },
            //地图显示
            async showMap(row) {
                let newPoint = this.bd09ToGcj02(row.longitude, row.latitude)
                let openParams = {
                    address: row.address,
                    lng: newPoint.lng,
                    lat: newPoint.lat,
                }
                setTimeout(() => {
                    openUserLocation(openParams)
                }, 1000)

            },
            /**
            * @description 百度转腾讯
            * @description BD-09 转  GCJ-02
            * @param{*}list [lng,lat]
            */
            bd09ToGcj02(bdLng, bdLat) {
                const x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                let x = bdLng - 0.0065;
                let y = bdLat - 0.006;
                let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
                let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
                let ggLng = z * Math.cos(theta);
                let ggLat = z * Math.sin(theta);

                return { lng: ggLng, lat: ggLat };
            },
            goDetail(row) {
                this.$router.push({
                    path: '/gridManage/infoDetail',
                    query: {
                        id: row.id
                    }
                })

            },
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .van-search {
        width: 100%;
        padding: 16px 0 14px 0;
        background-color: #3975c6;
        margin-right: 16px;
    }

    /deep/ .van-search__content {
        background-color: #fff;
        opacity: 0.51;
        color: #fff;
    }

    /deep/ .van-tabs__nav--line {
        line-height: 48px;
        padding-bottom: 0;
    }

    /deep/ .van-tabs__line {
        bottom: 0;
    }

    .index {
        height: 100%;
        background: #f2f3f7;
    }

    .heard {
        width: 100%;
        height: 105px;
        /* position: fixed;
        top: 0px; */
    }

    .filter {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border-top: 1px solid #e8e8e8;

        .filterLeft {
            margin-left: 4%;
            font-size: 12px;
            color: #a5a5a5;
        }

        .filterRight {
            margin-right: 4%;
            font-size: 12px;
            color: #515151;

            .icon {
                width: 11px;
                height: 11px;
                margin-right: 6px;
                margin-top: -2px;
            }
        }
    }

    .top {
        width: 100%;
        height: 65px;
        background: #3975c6;

        .topContent {
            width: 92%;
            height: 64px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .topLeft {
            height: 64px;
            line-height: 64px;
            display: flex;
            align-items: center;

            .team {
                font-size: 15px;
                font-weight: bold;
                color: #ffffff;
                margin-right: 6px;
            }

            .change {
                width: 14px;
                height: 11px;
            }
        }

        .topRight {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
        }

        .add {
            width: 25px;
            height: 25px;
        }
    }

    .conBox {
        height: 100vh;
        background: #f2f3f7;
        /* margin-top: 100px; */
        /* height: calc(100vh - 105px); */
    }

    .tab {
        height: 48px;
        line-height: 48px;
    }

    .main {
        width: 100%;
        /* height: calc(100vh - 105px); */
    }

    .mainTop {
        height: 17px;
        font-size: 12px;
        color: #9b9b9b;
    }

    .mainList {
        width: 100%;
        /* height: 129px; */
        background: #ffffff;
        border-radius: 5px;
        margin-bottom: 10px;

        .content {
            padding-bottom: 18px;
        }
    }

    .listTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        /* border-bottom: 1px solid #f1f1f1; */

        .name {
            height: 56px;
            line-height: 56px;
            width: 80%;
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .status {
            font-size: 12px;
            font-weight: 500;
            color: #3975c6;
            height: 21px;
            line-height: 21px;
            padding: 0px 8px;
            border-radius: 3px;
        }

        .completed {
            font-size: 12px;
            font-weight: 500;
            color: #a5a5a5;
        }
    }

    .address {

        line-height: 1.5;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 90%;

        span {
            font-weight: 400;
            font-size: 12px;
            color: #000000;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        img {
            margin-left: 5px;
            width: 11px;
            height: 11px;
        }
    }

    .m16 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 0 0;
        border-top: 1px solid #f1f1f1;
        margin-top: 15px;

        .m16-left {
            font-weight: 400;
            font-size: 12px;
            color: #9B9B9B;
        }

        .m16-right {
            width: 50px;
            height: 24px;
            background: #3974C6;
            border-radius: 12px;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            text-align: center;
            line-height: 24px;
        }
    }
</style>