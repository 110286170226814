import request from "../plugins/axios";

// 获取充值剩余次数
export function remianTimes(params) {
    return request({
        url: '/chatgptEmployeeQuestion/homepage',
        method: 'get',
        params: params
    })
}
// 发起提问
export function initiateQuestions(params) {
    return request({
        url: '/chatgptEmployeeQuestion/initiateQuestions',
        method: 'post',
        data: params
    })
}
// 创建新的会话
export function createNewSession(params) {
    return request({
        url: '/chatgptEmployeeQuestion/createChat',
        method: 'post',
        data: params
    })
}
// 创建订单
export function createOrder(params) {
    return request({
        url: '/chatgptOrder/create',
        method: 'post',
        data: params
    })
}
// 查询订单状态
export function queryStatus(params) {
    return request({
        url: '/chatgptOrder/getStatus',
        method: 'get',
        params: params
    })
}
// 再次发起付款
export function repay(params) {
    return request({
        url: '/chatgptOrder/repayment',
        method: 'get',
        params: params
    })
}
//提问历史记录
export function qaRecord(params) {
    return request({
        url: '/chatgptEmployeeQuestion/record',
        method: 'get',
        params: params
    })
}