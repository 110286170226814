<template>
  <div class="index">
    <div class="radarData">
      <div class="banxin">
        <div class="dataTop">
          <div class="left">雷达数据总览</div>
          <div class="right">
            <span
              v-for="(item, index) in dayList"
              :key="index"
              class="day"
              @click="changeDay1(index)"
              :class="{ activeSpan: spanIndex1 == index }"
              >{{ item.value }}</span
            >
          </div>
        </div>
        <div class="dataContent">
          <div class="flex_box">
            <div class="num">{{ userNum }}</div>
            <div class="m16">访客总人数</div>
          </div>
          <div class="flex_box">
            <div class="num">{{ userTimes }}</div>
            <div class="m16">访客次数</div>
          </div>
        </div>
        <div class="dataBot" @click="$router.push('/contactRadar/index')">访客详情 ></div>
      </div>
    </div>
    <div class="cardData">
      <div class="banxin">
        <div class="dataTop">
          <div class="left">名片数据总览</div>
          <div class="right">
            <span
              v-for="(item, index) in dayList"
              :key="index"
              class="day"
              @click="changeDay2(index)"
              :class="{ activeSpan: spanIndex2 == index }"
              >{{ item.value }}</span
            >
          </div>
        </div>
        <div class="dataContent">
          <div class="flex_box">
            <div class="num">{{ allInfo.clickPeopleNum }}</div>
            <div class="m16">访客总人数</div>
          </div>
          <div class="flex_box">
            <div class="num">{{ allInfo.clickTotalNum }}</div>
            <div class="m16">访客次数</div>
          </div>
        </div>
        <div class="seven">
          <div class="card">
            <div class="cardTop">电子名片</div>
            <div class="cardBot">{{ allInfo["1-1"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">早报</div>
            <div class="cardBot">{{ allInfo["2-1"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">晚报</div>
            <div class="cardBot">{{ allInfo["3-1"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">早晚报资讯</div>
            <div class="cardBot">{{ allInfo["4-1"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">每日专题</div>
            <div class="cardBot">{{ allInfo["5-1"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">精选活动</div>
            <div class="cardBot">{{ allInfo["6-1"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">热销产品</div>
            <div class="cardBot">{{ allInfo["7-1"] }}人</div>
          </div>
        </div>
        <div class="clickChart" id="clickChart"></div>
        <div class="tip">
          <div class="oneTip">
            <span class="icon" style="background-color: #e5e5e5"></span>
            <span class="title">电子名片</span>
            <span class="icon" style="background-color: #314a91"></span>
            <span class="title">早报</span>
            <span class="icon" style="background-color: #e44a45"></span>
            <span class="title">晚报</span>
            <span class="icon" style="background-color: #5fad0b"></span>
            <span class="title">早晚报资讯</span>
          </div>
          <div class="oneTip">
            <span class="icon" style="background-color: #fd8927"></span>
            <span class="title">每日专题</span>
            <span class="icon" style="background-color: #59abee"></span>
            <span class="title">精选活动</span>
            <span class="icon" style="background-color: #f8dc3e"></span>
            <span class="title">热销产品</span>
          </div>
        </div>
        <div class="dataContent" style="margin-top: 12px">
          <div class="flex_box">
            <div class="num">{{ allInfo.sharePeopleNum }}</div>
            <div class="m16">访客分享总人数</div>
          </div>
          <div class="flex_box">
            <div class="num">{{ allInfo.shareTotalNum }}</div>
            <div class="m16">访客分享总次数</div>
          </div>
        </div>

        <div class="seven">
          <div class="card">
            <div class="cardTop">电子名片</div>
            <div class="cardBot">{{ allInfo["1-2"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">早报</div>
            <div class="cardBot">{{ allInfo["2-2"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">晚报</div>
            <div class="cardBot">{{ allInfo["3-2"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">早晚报资讯</div>
            <div class="cardBot">{{ allInfo["4-2"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">每日专题</div>
            <div class="cardBot">{{ allInfo["5-2"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">精选活动</div>
            <div class="cardBot">{{ allInfo["6-2"] }}人</div>
          </div>
          <div class="card">
            <div class="cardTop">热销产品</div>
            <div class="cardBot">{{ allInfo["7-2"] }}人</div>
          </div>
        </div>
        <div class="shareChart" id="shareChart"></div>
        <div class="tip">
          <div class="oneTip">
            <span class="icon" style="background-color: #e5e5e5"></span>
            <span class="title">电子名片</span>
            <span class="icon" style="background-color: #314a91"></span>
            <span class="title">早报</span>
            <span class="icon" style="background-color: #e44a45"></span>
            <span class="title">晚报</span>
            <span class="icon" style="background-color: #5fad0b"></span>
            <span class="title">早晚报资讯</span>
          </div>
          <div class="oneTip">
            <span class="icon" style="background-color: #fd8927"></span>
            <span class="title">每日专题</span>
            <span class="icon" style="background-color: #59abee"></span>
            <span class="title">精选活动</span>
            <span class="icon" style="background-color: #f8dc3e"></span>
            <span class="title">热销产品</span>
          </div>
        </div>
      </div>
    </div>
    <div class="circleData">
      <div class="banxin">
        <div class="dataTop">
          <div class="left">朋友圈数据总览</div>
          <div class="right">
            <span
              v-for="(item, index) in dayList"
              :key="index"
              class="day"
              @click="changeDay3(index)"
              :class="{ activeSpan: spanIndex3 == index }"
              >{{ item.value }}</span
            >
          </div>
        </div>
        <div class="dataContent" style="height: 154px">
          <div class="flex_box" style="margin-top: 24px">
            <div class="num">{{ circleInfo.clickPeopleNum }}</div>
            <div class="m16">访客总人数</div>
          </div>
          <div class="flex_box" style="margin-top: 24px">
            <div class="num">{{ circleInfo.clickTotalNum }}</div>
            <div class="m16">访客次数</div>
          </div>
          <div class="flex_box" style="margin-top: 17px">
            <div class="num">{{ circleInfo.sharePeopleNum }}</div>
            <div class="m16">访客分享总人数</div>
          </div>
          <div class="flex_box" style="margin-top: 17px">
            <div class="num">{{ circleInfo.shareTotalNum }}</div>
            <div class="m16">访客分享总次数</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { circleTotalData, dataOverview } from "@/api/dataBurialPoint";
import { getRadarList } from "@/api/contact";

export default {
  data() {
    return {
      spanIndex1: 0,
      spanIndex2: 1,
      spanIndex3: 0,
      dayList: [
        { id: 1, value: "今日" },
        { id: 2, value: "近7日" },
        { id: 3, value: "近30天" },
      ],
      circleInfo: {},
      allInfo: {},
      xAxis: [],
      cardX1: [],
      morningPaperX1: [],
      eveningPaperX1: [],
      infoX1: [],
      specialTopicX1: [],
      activityX1: [],
      productX1: [],
      cardX2: [],
      morningPaperX2: [],
      eveningPaperX2: [],
      infoX2: [],
      specialTopicX2: [],
      activityX2: [],
      productX2: [],
      userNum: 0,
      userTimes: 0,
      page: 1,
      userNum :0,
      userTimes:0,
      day:0,
    };
  },
  created() {
    this.getCircleData();
    this.getAll();
    this.getRadarData()
  },
  methods: {
    changeDay1(index) {
      this.spanIndex1 = index;
      if(index==0){
        this.day = 0
      }else if(index==1){
        this.day = 7
      }else{
        this.day = 30
      }
      this.getRadarData()
    },
    changeDay2(index) {
      this.spanIndex2 = index;
      this.getAll();
    },
    changeDay3(index) {
      this.spanIndex3 = index;
      this.getCircleData();
    },
    //获取雷达数据
    getRadarData() {
      getRadarList({ day: this.day, page: this.page, perPage: 10 }).then(
        (res) => {
          this.userNum = res.data.count_user;
          this.userTimes = res.data.count_num;
        }
      );
    },
    getCircleData() {
      circleTotalData({ searchType: this.spanIndex3 + 1 }).then((res) => {
        this.circleInfo = res.data;
      });
    },
    getAll() {
      dataOverview({ searchType: this.spanIndex2 + 1 }).then((res) => {
        this.allInfo = res.data;
        this.xAxis = res.data.xAxis.map((res) => {
          return res.slice(5, 10);
        });
        this.cardX1 = res.data.lineChart.click["1-1"].map((res) => {
          return res.total;
        });
        this.morningPaperX1 = res.data.lineChart.click["2-1"].map((res) => {
          return res.total;
        });
        this.eveningPaperX1 = res.data.lineChart.click["3-1"].map((res) => {
          return res.total;
        });
        this.infoX1 = res.data.lineChart.click["4-1"].map((res) => {
          return res.total;
        });
        this.specialTopicX1 = res.data.lineChart.click["5-1"].map((res) => {
          return res.total;
        });
        this.acticityX1 = res.data.lineChart.click["6-1"].map((res) => {
          return res.total;
        });
        this.productX1 = res.data.lineChart.click["7-1"].map((res) => {
          return res.total;
        });
        this.cardX2 = res.data.lineChart.share["1-2"].map((res) => {
          return res.total;
        });
        this.morningPaperX2 = res.data.lineChart.share["2-2"].map((res) => {
          return res.total;
        });
        this.eveningPaperX2 = res.data.lineChart.share["3-2"].map((res) => {
          return res.total;
        });
        this.infoX2 = res.data.lineChart.share["4-2"].map((res) => {
          return res.total;
        });
        this.specialTopicX2 = res.data.lineChart.share["5-2"].map((res) => {
          return res.total;
        });
        this.acticityX2 = res.data.lineChart.share["6-2"].map((res) => {
          return res.total;
        });
        this.productX2 = res.data.lineChart.share["7-2"].map((res) => {
          return res.total;
        });
        this.showCharts(
          "clickChart",
          this.cardX1,
          this.morningPaperX1,
          this.eveningPaperX1,
          this.infoX1,
          this.specialTopicX1,
          this.acticityX1,
          this.productX1
        );
        this.showCharts(
          "shareChart",
          this.cardX2,
          this.morningPaperX2,
          this.eveningPaperX2,
          this.infoX2,
          this.specialTopicX2,
          this.acticityX2,
          this.productX2
        );
      });
    },
    showCharts(dom, data1, data2, data3, data4, data5, data6, data7) {
      var chartDom = document.getElementById(dom);
      var myChart = this.$echarts.init(chartDom);
      var option = {
        grid: {
          left: "0",
          right: "20",
          bottom: "0",
          top:"24",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          axisLine: {
            lineStyle: {
              type: "dotted",
            },
          },
          axisTick: {
            show: false, //隐藏x轴刻度
          },
          type: "category",
          boundaryGap: false,
          data: this.xAxis,
        },
        yAxis: {
          axisTick: {
            show: false, //隐藏x轴刻度
          },
          axisLine: {
            lineStyle: {
              type: "dotted",
            },
          },
          type: "value",
        },
        series: [
          {
            name:'电子名片',
            type: "line",
            smooth: true,
            data: data1,
            itemStyle:{ color: "#E5E5E5"},
            lineStyle: {
              color: "#E5E5E5", //改变折线颜色
            },
          },
          {
            name:'早报',
            type: "line",
            data: data2,
            smooth: true,
            itemStyle:{ color: "#314A91"},
            lineStyle: {
              color: "#314A91", //改变折线颜色
            },
          },
          {
            name:'晚报',
            type: "line",
            data: data3,
            smooth: true,
            itemStyle:{ color: "#E44A45"},
            lineStyle: {
              color: "#E44A45", //改变折线颜色
            },
          },
          {
            name:'早晚报资讯',
            type: "line",
            data: data4,
            smooth: true,
            itemStyle:{ color: "#5FAD0B"},
            lineStyle: {
              color: "#5FAD0B", //改变折线颜色
            },
          },
          {
            name:'每日专题',
            type: "line",
            data: data5,
            smooth: true,
            itemStyle:{ color: "#FD8927"},
            lineStyle: {
              color: "#FD8927", //改变折线颜色
            },
          },
          {
            name:'精选活动',
            type: "line",
            data: data6,
            smooth: true,
            itemStyle:{ color: "#59ABEE"},
            lineStyle: {
              color: "#59ABEE", //改变折线颜色
            },
          },
          {
            name:'热销产品',
            type: "line",
            data: data7,
            smooth: true,
            itemStyle:{ color: "#F8DC3E"},
            lineStyle: {
              color: "#F8DC3E", //改变折线颜色
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  height: 100%;
  background-color: #f2f3f7;
  padding-top: 8px;
  padding-bottom: 50px;
}
.radarData {
  height: 215px;
  background: #fff;
}
.banxin {
  width: 93%;
  height: 100%;
  margin: 0 auto;
}
.dataTop {
  display: flex;
  justify-content: space-between;
  height: 66px;
  line-height: 66px;
  .left {
    font-family: PingFangSC-Medium, PingFang SC;
    height: 21px;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }
  .right {
    font-size: 12px;
    color: #9b9b9b;
  }
  .day {
    padding-left: 12px;
  }
}
.dataContent {
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 2px 0px rgba(171, 171, 171, 0.3);
  border-radius: 14px;
  .flex_box {
    display: inline-block;
    width: 50%;
    text-align: center;
    margin-top: 27px;
    .num {
      height: 24px;
      font-size: 21px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 24px;
    }
    .m16 {
      height: 24px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #4a4a4a;
      line-height: 24px;
    }
  }
}
.dataBot {
  margin-top: 12px;
  height: 24px;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #9b9b9b;
  line-height: 24px;
}
.activeSpan {
  font-size: 12px;
  color: #000000;
}
.cardData {
  margin-top: 8px;
  min-height: 898px;
  background: #fff;
}
.circleData {
  margin-top: 8px;
  height: 247px;
  background: #fff;
}
.seven {
  margin-top: 15px;
  height: 75px;
  background: #f2f3f7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .card {
    text-align: center;
    .cardTop {
      height: 15px;
      font-size: 11px;
      color: #9b9b9b;
      margin-bottom: 8px;
    }
    .cardBot {
      font-size: 11px;
      font-weight: bold;
      color: #333333;
    }
  }
}
.clickChart,
.shareChart {
  height: 220px;
  width: 100%;
}
.tip {
  padding-bottom: 10px;
  .oneTip {
    text-align: center;
    height: 24px;
    line-height: 24px;

    .icon {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-left: 13px;
      margin-right: 4px;
      vertical-align: middle;
    }
    .title {
      font-size: 11px;
    }
  }
}
</style>