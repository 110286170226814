<template>
  <div class="index">
    <div class="middle">
      <van-field
        class="myfiled"
        v-model="message"
        rows="5"
        autosize
        label="发送内容"
        type="textarea"
        maxlength="1000"
        placeholder="可发送产品信息、节日关怀、优惠活动等内容"
        show-word-limit
      />
      <div @click="selectMaterial">
        <div class="select">
          <img class="icon" src="@/assets/images/qunfa/add2.png" alt="" />
          <span class="medium">选择素材</span>
        </div>
        <div v-if="qunfaType == 'toCircle'" class="medium2">
          (最多支持9个图片类型，或者1个视频，或者1个链接。)
        </div>
      </div>
      <div v-if="selectMetedata && selectMetedata.length != 0">
        <div
          class="filesitem"
          v-for="(v, index) in selectMetedata"
          :key="index"
        >
          <div class="filesleft">
            【{{ v.typeName ? v.typeName : v.type }}】：{{
              v.content ? v.content.title : v.title
            }}
          </div>
          <div class="filesright fz16">
            <a-icon
              type="delete"
              class="pointer"
              @click="deleteMaterial(v, index)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="last">
      <div class="imme" @click="goSend">前往发送</div>
    </div>
    <!-- 素材库 -->
    <van-popup v-model="matePop" position="bottom" :style="{ height: '100%' }">
      <matePopup
        ref="mateChild"
        @closePop="closePop"
        @popSubmit="popSubmit"
        :key="datekey"
      ></matePopup>
    </van-popup>
    <van-popup
      v-model="mateSele"
      round
      position="bottom"
      closeable
      :style="{ height: '50%', background: '#FFFFFF' }"
    >
      <popPage
        ref="matebpx"
        @popBtn="popBtn"
        @imagesData2="imagesData2"
      ></popPage>
    </van-popup>
  </div>
</template>
<script>
import matePopup from "@/components/select/material/index";
import popPage from "@/components/select/material/popPage";
import { userCenter } from "@/api/homePage";

import {
  shareToQunfa,
  shareTokehuqun,
  shareToCircle,
} from "@/utils/wxCodeAuth";
export default {
  components: { matePopup, popPage },
  data() {
    return {
      message: "",
      selectMetedata: [], //已选素材库
      datekey: Date.now(),
      mateSele: false,
      matePop: false,
      myId: [],
      currenttime: "",
      qunfaType: "",
    };
  },
  created() {
    this.userDetails();
    this.qunfaType = this.$route.query.type;
  },
  methods: {
    formatter(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return `${year}-${month}-${day} ${hour}:${minutes}`;
    },
    //获取用户信息
    userDetails() {
      userCenter().then((res) => {
        this.myId.push(res.data.baseInfo.id);
      });
    },
    //素材库选项
    popBtn(row) {
      this.matePop = true;
      this.mateSele = false;
      this.datekey = Date.now(); //刷新子组件
      this.$nextTick(() => {
        this.$refs.mateChild.selectPop = true; //修改子组件data
        this.$refs.mateChild.currentID = row.id; //修改子组件变量
        this.$refs.mateChild.activeName = row.id; //修改子组件选中tab
        //朋友圈只能发下面几种类型
        if(this.$route.query.type=='toCircle'){
          this.$refs.mateChild.typeOption = [
          { name: "所有", id: "" },
          { name: "文本", id: 1 },
          { name: "图片", id: 2 },
          { name: "图文", id: 3 },
          { name: "视频", id: 5 },
        ]
        }else{
          this.$refs.mateChild.typeOption = [
          { name: "所有", id: "" },
          { name: "文本", id: 1 },
          { name: "图片", id: 2 },
          { name: "图文", id: 3 },
          { name: "视频", id: 5 },
          { name: "小程序", id: 6 },
          { name: "文件", id: 7 },
        ]
        }
          (this.$refs.mateChild.oldSelectData =
            this.ruleList[this.currentrItem].content[1].childMediumData || []); //传递已选择数据
      });
    },
    //子组件图片数据
    imagesData2(data) {
      console.log("dataaaa", data);
      this.selectMetedata.push({
        content: {
          title: "本地图片",
          imageFullPath: data.url,
          imagePath: data.path,
        },
        type: "图片",
      });
      this.mateSele = false;
    },
    goSend() {
      this.currenttime = this.formatter(new Date());
      let text2 = "";
      console.log("this.selectMetedata", this.selectMetedata);
      const data = this.selectMetedata.map((row, i) => {
        switch (row.type) {
          case "文本" || "":
            text2 = text2 + row.content.content;
          case "图片":
            return {
              type: 2,
              mediaid: row.mediaId,
              content: row.content,
            };
          case "图文":
            return {
              type: 7,
              title: row.content.title,
              share_link: row.content.imageFullPath,
            };
          case "音频":
            return {
              type: 5,
              content: row.mediaId,
            };
          case "视频":
            return {
              type: 5,
              content: row.mediaId,
            };
          case "小程序":
            return {
              type: 4,
              content: row.content,
            };
          case "文件":
            return {
              type: 6,
              mediaId: row.mediaId,
            };
          case "链接":
            return {
              title: row.link_title,
              description: row.link_description,
              cover: row.link_cover,
              share_link: row.linkUrl,
              type: 3,
            };
          case "PDF":
            return {
              title: row.title,
              share_link: row.linkUrl,
              type: 7,
            };
          case "文章":
            return {
              title: row.title,
              share_link: row.linkUrl,
              type: 7,
            };
        }
      });
      console.log("332213", data);
      if (this.$route.query.type == "toCustom") {
        shareToQunfa(
          data,
          this.message + text2,
          this.myId,
          this.selectMetedata,
          this.currenttime
        );
      } else if (this.$route.query.type == "tokehuqun") {
        shareTokehuqun(
          data,
          this.message + text2,
          this.myId,
          this.selectMetedata,
          this.currenttime
        );
      } else {
        shareToCircle(
          data,
          this.message + text2,
          this.myId,
          this.selectMetedata,
          this.currenttime
        );
      }
    },
    //选择素材
    selectMaterial() {
      this.mateSele = true;
      this.$nextTick(() => {
        this.$refs.matebpx.deltelocalfun([2,3,4,5,6]);
      });
      this.$nextTick(() => {
        this.$refs.matebpx.deltefun([4]);
      });
      if(this.$route.query.type == "toCircle"){
        this.$nextTick(() => {
        this.$refs.matebpx.deltefun([6,7]);
      });
      }
    },
    //关闭弹窗
    closePop() {
      this.matePop = false;
    },
    //素材库选择确认
    popSubmit(data) {
      this.matePop = false;
      if (data.length > 9) {
        this.$toast("最多添加9个素材!");
        return;
      }
      let a = [];
      a = data.filter((item) => {
        return (
          item.type === "视频" ||
          item.type === 1 ||
          item.type === 2 ||
          item.type === 3
        );
      });
      console.log("a", a);
      if (a.length > 1) {
        this.$toast("最多可选1个视频或1个雷达");
        return;
      }
      this.selectMetedata = data;
    },
    //素材删除
    deleteMaterial(v, index) {
      // index子下标
      this.selectMetedata.splice(index, 1);
      let newData = this.selectMetedata[index];
      this.$delete(this.selectMetedata[index], "selectMetedata");
      this.$set(this.selectMetedata[index], "selectMetedata", newData);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .myfiled .van-field__value {
  height: 163px !important;
  background: #f2f3f7 !important;
  border-radius: 5px;
  padding: 10px !important;
}
/deep/ .van-cell__title {
  height: 40px !important;
}
/deep/ .van-cell {
  display: block;
}
.index {
  height: 100%;
  width: 100%;
  background-color: #f2f3f7;
}
.middle {
  width: 96%;
  margin: 0 auto;
  margin-top: 13px;
  min-height: 273px;
  background: #ffffff;
  border-radius: 5px;
}
.select {
  margin-top: 14px;
  margin-left: 16px;
  height: 20px;
  display: flex;
  align-items: center;
  .icon {
    width: 12px;
    height: 12px;
    margin-top: 1px;
  }
  .medium {
    margin-left: 10px;
    font-size: 15px;
    color: #515151;
  }
}
.medium2 {
  margin-left: 10px;
  font-size: 15px;
  color: #515151;
  padding-bottom: 6px;
  padding-top: 6px;
}
.filesitem {
  width: 94%;
  margin: 0 auto;
  font-size: 14px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filesleft {
    color: #565656;
  }

  &:last-child {
    padding-bottom: 12px;
  }
}
.last {
  position: fixed;
  bottom: 0;
  margin-top: 13px;
  height: 46px;
  width: 100%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
  text-align: center;
  background: #3974c6;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 46px;
}
</style>