<template>
  <div class="index">
    <div class="banxin">
      <div class="top">请选择发送方式</div>
      <div class="tips">
        可根据具体场景选择不同的发送方式，不同员工向同一个客户每天
        都可群发1条消息
      </div>
      <div class="m16" @click="skipCreate(1)" v-if="type==1">
        <div class="content">
          <div class="left">
            <img src="@/assets/images/sop/chat.png" alt="" />
          </div>
          <div class="right">
            <div class="rigth_top">复制单聊推送</div>
            <div class="rigth_bottom">
              当客户添加客服成员时，系统将在侧边栏快捷回复中智能 提醒该规则内容
            </div>
          </div>
        </div>
      </div>
      <div class="m16" @click="skipCreate(2)">
        <div class="content">
          <div class="left">
            <img src="@/assets/images/sop/hair.png" alt="" />
          </div>
          <div class="right">
            <div class="rigth_top">系统群发提醒发送</div>
            <div class="rigth_bottom">
              当客户添加客服成员时，系统将在侧边栏快捷回复中智能 提醒该规则内容
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      type:''
    };
  },
  created(){
    this.type = this.$route.query.type
  },
  methods:{
    skipCreate(type){
       let path=type==1?'/sopList/chatPage?type='+this.type:'/sopList/hairPage?type='+this.type
       this.$router.push({path:path})
      }
    }
  }
</script>
<style lang="less" scoped>
.index {
  width: 100%;
  height: 100%;
  background: #f2f3f7;
}
.banxin {
  width: 94%;
  height: 89px;
  margin: 0 auto;
  border-radius: 5px;
}
.top {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  padding-top: 45px;
  padding-bottom: 4px;
}
.tips {
  width: 319px;
  line-height: 18px;
  height: 32px;
  font-size: 11px;
  color: #9b9b9b;
  margin-bottom: 20px;
}
.m16 {
  height: 89px;
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  .content {
    width: 94%;
    height: 61px;
    margin: 0 auto;
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    margin-right: 10px;
    img {
      width: 61px;
      height: 61px;
    }
  }
  .right {
    .rigth_top {
      height: 17px;
      font-size: 12px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 2px;
    }
    .rigth_bottom {
      height: 32px;
      font-size: 12px;
      color: #9b9b9b;
      line-height: 16px;
    }
  }
}
</style>