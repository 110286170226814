<template>
  <div class="index">
    <div class="banxin">
      <div class="top">请选择发送方式</div>
      <div class="tips">
        可根据具体场景选择不同的发送方式，不同员工向同一个客户每天
        都可群发1条消息
      </div>
      <div class="m16" @click="skipCreate(1)">
        <div class="content">
          <div class="left">
            <img src="../../../assets/images/qunfa/send.png" alt="" />
          </div>
          <div class="right">
            <div class="rigth_top">员工一键发送</div>
            <div class="rigth_bottom" v-if="type=='toCustom'">
              适用于精细化运营，可设置员工按要求发送指定内容给指定的客户
            </div>
            <div class="rigth_bottom" v-else-if="type=='tokehuqun'">
              适用于精细化运营，可设置员工按要求发送指定内容给指定的客户群
            </div>
            <div class="rigth_bottom" v-else>
              适用于精细化运营，可设置员工按要求发送指定内容给指定的客户
            </div>
          </div>
        </div>
      </div>
      <div class="m16" @click="skipCreate(2)">
        <div class="content">
          <div class="left">
            <img src="../../../assets/images/qunfa/send2.png" alt="" />
          </div>
          <div class="right">
            <div class="rigth_top">发送给我的客户</div>
            <div class="rigth_bottom" v-if="type=='toCustom'">
              可一键发送给我所有的客户，支持查看客户访问素材情况
            </div>
            <div class="rigth_bottom" v-else-if="type=='tokehuqun'">
              可一键发送给我所有的客户群，支持查看客户访问素材情况
            </div>
            <div class="rigth_bottom" v-else>
              可一键发送给我所有的客户的朋友圈，支持查看客户访问素材情况
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      <script>
export default {
  data() {
    return {
      type: "",
    };
  },
  created() {
    //群发类型
    this.type = this.$route.query.selectWay;
  },
  methods: {
    skipCreate(way) {
      if (way == 1) {
        this.$router.push({
          path: "/qunfa/create",
          query: { type: this.type },
        });
      } else {
        this.$router.push({
          path: "/qunfa/directSend",
          query: { type: this.type },
        });
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.index {
  width: 100%;
  height: 100%;
  background: #f2f3f7;
}
.banxin {
  width: 94%;
  height: 89px;
  margin: 0 auto;
  border-radius: 5px;
}
.top {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  padding-top: 45px;
  padding-bottom: 4px;
}
.tips {
  width: 319px;
  line-height: 18px;
  height: 32px;
  font-size: 12px;
  color: #9b9b9b;
  margin-bottom: 20px;
}
.m16 {
  height: 89px;
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  .content {
    width: 94%;
    height: 61px;
    margin: 0 auto;
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    margin-right: 10px;
    img {
      width: 61px;
      height: 61px;
    }
  }
  .right {
    .rigth_top {
      height: 17px;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 2px;
    }
    .rigth_bottom {
      height: 32px;
      font-size: 12px;
      color: #9b9b9b;
      line-height: 16px;
    }
  }
}
</style>