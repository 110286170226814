<template>
  <div class="index">
    <div class="content">
      <div v-if="activityData.length > 0">
        <div>
          <div
            class="boxContent"
            v-for="(item, index) in activityData"
            :key="index"
            @click="skipDetail(item.id, item.url)"
          >
            <div class="top">
              <img :src="item.fullImage" alt="" />
              <div class="right">
                <div class="rightTop">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty description="暂无活动~" style="padding: 0" />
      </div>
    </div>
  </div>
</template>
<script>
import { activityList, vcardIndex } from "@/api/electronicCard";
import {shareMyData} from "@/utils/wxCodeAuth";

export default {
  data() {
    return {
      activityData: [],
      shareUrl: "",
    };
  },
  created() {
    this.getData();
  },

  methods: {
    getUserInfo() {
      vcardIndex().then((res) => {
        console.log("res,", res);
        const data = res.data;
        const shareData = {
          title: `${data.employee.departmentName}金融管家：${data.employee.name}为您服务！`,
          description: data.summary,
          imgUrl: data.employee.fullImage,
          url: this.shareUrl,
        };
        console.log("fenxiang", shareData);
        shareMyData(shareData);
        // forward(shareData);
        // onMenuShareTimeline(shareData);
      });
    },
    getData() {
      activityList().then((res) => {
        this.activityData = res.data.list;
        this.shareUrl = res.data.shareUrl;
        this.getUserInfo();
      });
    },
    skipDetail(id, url) {
        this.$router.push({
          path: "/electronicCard/activity/detail",
          query: {
            id,
          },
        });
      // } else {
      //   window.location.href = url;
      // }
    },
  },
};
</script>
  <style lang="less" scoped>
.index {
  overflow: auto;
}
.boxContent {
  width: 94%;
  min-height: 180px;
  margin: 0 auto;
  margin-top: 14px;
  border-radius: 10px;
  overflow: auto;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.06);

  .box {
    min-height: 180px;
    width: 100%;
    margin-bottom: 10px;
  }
  .top {
    img {
      width: 100%;
      height: 130px;
      border-radius: 10px;
    }
    .rightTop {
      margin: 10px;
      width: 96%;
      min-height: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
}
</style>
  