<template>
    <div class="page">
        <div class="search">
            <van-search v-model="keyword" shape="round" placeholder="搜索" @clear="clear" />
        </div>
        <div class="map_box">
            <!-- @click="clickMap":dragging="false"  @touchend="mapTouchend"@touchmove="mapTouchmove"  -->
            <baidu-map class='bm-view' :center="center" :zoom="zoom" @ready="handler" :dragging="dragging"
                @touchstart="mapmove" @touchend="mapTouchend" @touchmove="mapTouchmove"
                ak="uSDQM0SXJgOKGoiDmMxh5a1BR0zhoKVJ">
                <!-- :location="location" -->
                <bm-local-search :keyword="keyword" :auto-viewport="true" :panel="false"></bm-local-search>
                <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                    <!-- <bm-info-window :show="true" >{{address}}</bm-info-window> -->
                </bm-marker>
            </baidu-map>
        </div>
        <div class="info">
            <div class="tips">提示：点击地图获取区域地址</div>
            <div class="title">地址：{{address}}</div>
            <div class="btnBox">
                <van-button round type="default" class="btn" @click="goback()"
                    style="margin-right: 20px;">取消</van-button>
                <van-button round type="info" class="btn" @click="goSave()">确定</van-button>
            </div>
        </div>
    </div>
</template>
<script>
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
    import bmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue"
    import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
    export default {
        components: {
            BaiduMap,
            BmMarker,
            bmInfoWindow,
            BmLocalSearch
        },
        data() {
            return {
                //BMap类
                BMap: null,
                //地图对象
                map: null,
                center: { lng: 116.404, lat: 39.915 },
                zoom: 16,
                // 地图坐标解析器
                gc: '',
                address: '北京市东城区中华路甲10号',
                location: '深圳',
                keyword: '',
                dragging: true
            }
        },
        created() {
            //安卓设备需要 设置为false 否则点击事情无效
            var userAgent = navigator.userAgent;
            var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
            if (isAndroid) {
                this.dragging = false
            }
        },
        mounted() {
        },
        methods: {
            async handler({ BMap, map }) {
                this.center.lng = 116.404
                this.center.lat = 39.915
                this.zoom = 14
                //创建地址解析器实例
                this.gc = new BMap.Geocoder();
                this.map = map
                let that = this;
                this.map.addEventListener('click', function (e) {
                    // console.log('点击了地图，点击位置经纬度为：' + e.point.lng + ',' + e.point.lat);
                    // 点击后需要触发的事件，例-点击添加marker
                    that.getPointInfo(e.point)
                });
            },
            // 点击地图--更新点坐标，获取点信息，添加标注
            clickMap(event) {
                console.log(event.point)
                this.getPointInfo(event.point)
            },
            // 获取点信息
            getPointInfo(point) {
                this.center.lng = point.lng
                this.center.lat = point.lat
                // // 解析坐标点--获取坐标点所在的区域名称（城市）
                var that = this
                this.gc.getLocation(point, function (res) {
                    that.address = res.address
                })
            },
            //返回上一页
            goSave() {
                // let json={
                //     address:this.address,
                //     lng:this.center.lng,
                //     lat:this.center.lat
                // }
                this.$emit('mapSave', this.address);
            },
            goback() {
                this.$emit('closeMap');
            },
            clear() {
                this.keyword = ''
            },
            mapmove(e) {
                this.dragging = true
            },
            //触摸移动时触发此事件
            mapTouchmove(e) {
                var userAgent = navigator.userAgent;
                var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
                if (isAndroid) {
                    this.dragging = true
                } else {
                    this.dragging = false //开启禁止拖拽
                }
            },
            //触摸结束时触发此事件
            mapTouchend(e) {
                //安卓设备结束后需要 设置为false 否则点击事情无效
                var userAgent = navigator.userAgent;
                var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
                if (isAndroid) {
                    this.dragging = false
                } else {
                    this.dragging = true
                }
            }
        }
    }
</script>
<style lang="less" scoped>
    .page {
        width: 100%;
        height: 100%;

        .search {
            position: fixed;
            top: 0;
            z-index: 999;
            width: 100%;
        }

        .map_box {
            width: 100%;
            height: 100%;
            padding-bottom: 130px;
            background: #D8D8D8;

            .bm-view {
                width: 100%;
                height: 100%;
            }
        }

        .info {
            position: fixed;
            bottom: 0;
            width: 100%;
            /* height: 130px; */
            background: #FFFFFF;
            padding: 10px 15px;

            .tips {
                color: #999;
                font-size: 12px;
            }

            .title {
                margin-top: 10px;
                font-size: 13px;
            }

            .btnBox {
                display: flex;
                justify-content: space-between;

                .btn {
                    margin-top: 15px;
                    width: 100%;
                }
            }

        }
    }
</style>