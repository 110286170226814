<template>
  <div class="all">
    <div class="userInfo">
      <div class="content">
        <div class="contentLeft">
          <img :src="employee.fullImage" alt="" />

          <div style="margin-left: 8px">
            <div class="item marquee" style="width: 144px;">
              <div class="marquee-wrap">
                <div class="marquee-content name">
                  {{ employee.name }}
                </div>
              </div>
            </div>
            <div class="position">
              {{ employee.departmentName }}｜{{ employee.position }}
            </div>
          </div>
        </div>
        <div class="contentRight">
          <div class="phone" style="margin-right: 20px">
            <img
              class="pic"
              src="../../assets/images/electronicCard/phone.png"
              alt=""
              @click="daPhone"
            />
          </div>
          <div class="phone" style="margin-right: 20px">
            <img
              class="pic"
              src="../../assets/images/electronicCard/wechat.png"
              alt=""
              @click="showWechat = true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="asideTop">
      <div class="Box">
        <div class="gray">上证指数</div>
        <div :class="stockMarketData.szzz_index.increPer.indexOf('-')==-1? 'm16':'m18'">{{ stockMarketData.szzz_index.nowpri }}</div>
        <div style="height:18px;line-height: 13px;">
          <span :class="stockMarketData.szzz_index.increPer.indexOf('-')==-1? 'm17':'m19'">{{ stockMarketData.szzz_index.increPer }}</span>
          <span :class="stockMarketData.szzz_index.increase.indexOf('-')==-1? 'm17':'m19'"> {{ stockMarketData.szzz_index.increase }}</span>
        </div>
      </div>
      <div class="Box">
        <div class="gray">深证成指</div>
        <div :class="stockMarketData.szcz_index.increPer.indexOf('-')==-1? 'm16':'m18'">
          {{ stockMarketData.szcz_index.nowpri }}
        </div>
        <div style="height:18px;line-height: 13px;">
          <span :class="stockMarketData.szcz_index.increPer.indexOf('-')==-1? 'm17':'m19'">{{ stockMarketData.szcz_index.increPer }}</span>
          <span :class="stockMarketData.szcz_index.increase.indexOf('-')==-1? 'm17':'m19'"> {{ stockMarketData.szcz_index.increase }}</span>
        </div>
      </div>
      <div class="Box">
        <div class="gray">恒生指数</div>
        <div :class="stockMarketData.hszs_index.increPer.indexOf('-')==-1? 'm16':'m18'">{{ stockMarketData.hszs_index.nowpri }}</div>
        <div style="height:18px;line-height: 13px;">
          <span :class="stockMarketData.hszs_index.increPer.indexOf('-')==-1? 'm17':'m19'">{{ stockMarketData.hszs_index.increPer }}</span>
          <span :class="stockMarketData.hszs_index.increase.indexOf('-')==-1? 'm17':'m19'"> {{ stockMarketData.hszs_index.increase }}</span>
        </div>
      </div>
    </div>
    <div class="comment">
      <div class="banxin">
        <div class="title">晚安心语</div>
        <div class="editContent">
          <div class="m16" v-if="eveningData.night_comment != ''">
            {{ eveningData.night_comment }}
          </div>
          <div class="m16" v-else>暂无点评</div>
        </div>
      </div>
    </div>
    <div class="daodu">
      <div class="banxin">
        <div class="top">
          <div class="title">要闻盘点</div>
          <div class="selectBtn" @click="selectDaodu">选择导读榜单</div>
        </div>
        <div
          class="editContent"
          v-if="eveningData.night_guided_reading.length > 0"
        >
          <div
            class="m16"
            v-for="(res, i) in eveningData.night_guided_reading"
            :key="i"
            @click="skipDaoduDetail(res.id)"
          >       
          <img v-show="i==0" style="width:10px;height:12px;margin-top: -2px;margin-right: 2px;" src='../../assets/images/electronicCard/1.png' alt="">
          <img v-show="i==1" style="width:10px;height:12px;margin-top: -2px;margin-right: 2px;" src='../../assets/images/electronicCard/2.png' alt="">
          <img v-show="i==2" style="width:10px;height:12px;margin-top: -2px;margin-right: 2px;" src='../../assets/images/electronicCard/3.png' alt="">
          <img v-show="i==3" style="width:10px;height:12px;margin-top: -2px;margin-right: 2px;" src='../../assets/images/electronicCard/4.png' alt="">
          <img v-show="i==4" style="width:10px;height:12px;margin-top: -2px;margin-right: 2px;" src='../../assets/images/electronicCard/5.png' alt="">
           {{ res.title }}
          </div>
          <div class="more" @click="viewAll">查看全部 ></div>
        </div>
        <div
          style="font-size: 14px; color: #000000; margin-bottom: 10px"
          v-else
        >
          暂无数据
        </div>
      </div>
    </div>
    <div class="daodu touzi">
      <div class="banxin">
        <div class="top">
          <div class="title">投资话题</div>
          <div class="selectBtn"></div>
        </div>
        <div
          class="m16"
          style="font-size: 14px; color: #000000; margin-bottom: 10px"
        >
          {{ eveningData.investment_topic_question }}
        </div>
        <div class="touZiSelect" v-for="(res,i) in eveningData.investment_topic_option" :key="i">
          <div class="left">{{res.option}}</div>
          <div class="right">{{res.num}}/{{ res.rate }}</div>
        </div>
      </div>
    </div>
      <!-- 版权声明 -->
      <div class="copyright">
        <div class="box_title">免责声明：</div>
        <div class="box_cen">
          以上内容仅供参考，引用之目的在于共享资讯，不代表对信息来源内容的真实性、准确性负责。以上内容不构成对任何人的投资建议，敬请您独立决策并承担风险。如信息内容侵犯知识产权，请提交相关链接至邮箱：service@qsjykj.com.cn，我们将及时处理。市场有风险，投资须谨慎</div>
      </div>
    <div class="last">
      <div class="cont">
        <div class="left"  @click="skipSet">
          <img
            class="setting"
            src="../../assets/images/electronicCard/set.png"
            alt=""
          />
          <div class="m17">设置</div>
        </div>
        <div class="right" @click="sendCircle">分享</div>
      </div>
    </div>
    <!-- 微信二维码弹窗 -->
    <van-popup
      v-model="showWechat"
      closeable
      style="width: 260px; height: 286px; text-align: center; margin-left: 45%"
    >
      <img
        style="
          margin-top: 30px;
          width: 200px;
          height: 190px;
          text-align: center;
        "
        :src="employee.qrCode"
        alt=""
      />
      <p style="font-size: 16px; color: #515151">长按扫一扫<br />即可联系我</p>
    </van-popup>
  </div>
</template>
  <script>
import { eveningPaperIndex,shareCallBack } from "@/api/morningPaper";
import { shareToExternalMoments,shareMyData } from "@/utils/wxCodeAuth";

export default {
  data() {
    return {
      employee: "",
      eveningData: "",
      stockMarketData: "",
      phoneNum: "",
      showWechat: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    sendCircle(){
      let time = new Date()
      let shareData = {};
      let text = "";
      shareData.type = 3;
      shareData.content = {};
      let title=
        time.toLocaleDateString() +
        this.employee.name +
        "的晚报点评：" +
        this.eveningData.night_comment;
      shareData.content.title=title.slice(0,35)
      shareData.content.imageFullPath = this.employee.fullImage;
      shareData.content.imageLink = this.shareUrl;
      console.log("shareData.content", shareData);
      shareToExternalMoments(text, shareData).then((res) => {
        console.log("res", res);
        shareCallBack({type:2}).then(()=>{
          console.log('回调')
        })
        this.$message.success("发送成功");
      });
    },
    getData() {
      eveningPaperIndex().then((res) => {
        const { data } = res;
        this.employee = data.employee;
        this.phoneNum = data.employee.mobile;
        console.log("this.employeeData", this.employeeData);
        this.eveningData = data.night;
        this.shareUrl= data.shareUrl
        this.stockMarketData = data.stockMarketIndex;
        const shareData = {
          title: `${data.employee.departmentName}金融管家：${data.employee.name}为您服务！`,
          description: data.summary,
          imgUrl: data.employee.fullImage,
          url: data.shareUrl,
        };
        console.log("fenxiang", shareData);
        shareMyData(shareData);
        // forward(shareData);
        // onMenuShareTimeline(shareData);
      });
    },
    daPhone() {
      window.location.href = `tel:${this.phoneNum}`;
    },
    selectDaodu() {
      this.$router.push({
        path: "/electronicCard/morningPaper/selectDaodu",
        query: { type: "night_guided_reading" },
      });
    },
    skipDaoduDetail(id) {
      this.$router.push({
        path: "/electronicCard/caijing/detail",
        query: {
          id,
        },
      });
    },
    viewAll() {
      this.$router.push({ path: "/electronicCard/caijing" });
    },
    skipSet(){
      this.$router.push({ path: "/electronicCard/eveningPaper/setting" });
    }
  },
};
</script>
  <style lang="less" scoped>
.all {
  height: 100%;
  overflow: auto;
  background-color: #f2f3f7;
}
.userInfo {
  width: 100%;
  height: 79px;
  background: #fff;
}
.banxin {
  width: 94%;
  margin: 0 auto;
}
.content {
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .contentLeft {
    display: flex;
    margin-left: 14px;
    margin-top: 10px;
    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }
    .name {
      margin-top: 6px;
      height: 21px;
      font-size: 18px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 21px;
    }
    .position {
      margin-top: 10px;
      height: 15px;
      font-size: 12px;
      color: #9b9b9b;
      line-height: 15px;
    }
  }
  .contentRight {
    display: flex;
    justify-content: space-around;
    // .phone {
    //   width: 31px;
    //   height: 31px;
    //   background-color: #fff;
    //   border-radius: 50%;
    //   text-align: center;
    //   line-height: 22px;
    // }

    .pic {
      width: 14px;
      height: 14px;
    }
  }
}
.asideTop {
  width: 94%;
  margin: 0 auto;
  background: #f6faff;
  height: 76px;
  margin-top: 12px;
  border-radius: 6px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 12px;
  .Box {
    float: left;
    margin-top: 14px;
    width: 33.3%;
    border-right: 1px solid #ccc;

    // height: 54%;
    .gray {
      height: 18px;
      font-size: 10px;
      color: #4a4a4a;
      line-height: 14px;
      font-weight: bold;
    }
    .m16 {
      height: 20px;
      font-size: 15px;
      color: #d90000;
      line-height: 21px;
    }
    .m18 {
      height: 20px;
      font-size: 15px;
      color: #249900;
      line-height: 21px;
    }
    .m17 {
      height: 14px;
      font-size: 8px;
      color: #d90000;
    }
    .m19 {
      height: 14px;
      font-size: 8px;
      color: #249900;
    }
  }
  .Box:nth-child(3) {
    border-right: none;
  }
}
.comment {
  margin: 0 auto;
  margin-top: 12px;
  width: 94%;
  min-height: 74px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  .title {
    margin: 12px 0;
    height: 21px;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    line-height: 21px;
  }
  .editContent {
    width: 100%;
    min-height: 31px;
    margin-bottom: 12px;
    .m16 {
      font-size: 14px;
      color: #f49013;
    }
  }
}
.daodu {
  margin: 0 auto;
  margin-top: 12px;
  width: 94%;
  max-height: 207px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  .top {
    display: flex;
    justify-content: space-between;
    margin: 12px 6px 6px 0;
    .title {
      height: 21px;
      font-size: 15px;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
    .selectBtn {
      text-align: right;
      width: 96px;
      height: 28px;
      font-size: 14px;
      font-weight: 500;
      color: #3974c6;
      line-height: 23px;
    }
  }

  .editContent {
    width: 100%;
    min-height: 54px;
    .m16 {
      font-size: 14px;
      color: #000000;
      line-height: 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .more {
      margin-top: 6px;
      width: 71px;
      height: 28px;
      font-size: 13px;
      color: #3974c6;
    }
  }
}
.last {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: #ffffff;
  .cont {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .setting {
        width: 22px;
        height: 22px;
      }
      .m17 {
        font-size: 10px;
        color: #c1c9d0;
        line-height: 14px;
      }
    }
    .right {
      margin-top: 8px;
      width: 278px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #3974c6;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.touZiSelect {
  height: 26px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .left {
    font-size: 14px;
    color: #000000;
  }
  .right{
    font-size: 13px;
    color: #3974C6;
  }
}
.copyright {
    width: 94%;
    padding: 10px;
    background: #F8F9FB;
    margin: 10px;
    margin: 0 auto;
    border-radius: 6px;
    margin-top: 12px;
    margin-bottom: 70px;
    .box_title {
      color: #ACADB1;
      font-size: 12px;
    }

    .box_cen {
      margin-top: 5px;
      color: #ACADB1;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .marquee {
  overflow: hidden;
}

.marquee .marquee-wrap {
  width: 100%;
  animation: marquee-wrap 10s infinite linear;
}

.marquee .marquee-content {
  float: left;
  white-space: nowrap;
  min-width: 100%;
  animation: marquee-content 10s infinite linear;
}

@keyframes marquee-wrap {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-content {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(-100%);
  }
}
</style>