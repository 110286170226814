<template>
  <div class="index">
    <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="back"
    />
    <div v-if="!first">
      <div class="header-tool">
        <h3 class="title">共{{count}}个客户</h3>
        <span @click="toggleAll" v-if="result.length == customerList.length" class="clickable select-all">取消</span>
        <span v-else @click="checkAll" class="clickable select-all">全选</span>
      </div>
      <div class="content" style="padding-bottom: 60px">
        <van-checkbox-group icon-size="15px" v-model="result" ref="checkboxGroup">
          <!--        <van-list-->
          <!--            v-model="loading"-->
          <!--            :finished="finished"-->
          <!--            finished-text="没有更多了"-->
          <!--            @load="onLoad"-->
          <!--        >-->
          <div class="user-list" style=""
               v-for="(item,index) in customerList" :key="index">
            <p class="time">{{ item.createTime }}</p>
            <div class="user-item" :class="{ 'border-color' : result.indexOf(item) >= 0 }">
              <div class="top-wrapper">
                <img class="avatar clickable" :src="item.avatar"> 
                <div>
                  <span class="name">{{ item.name }}</span>
                <div class="bottom">
                <div :class="{unset: item.zk}" class="bottom-list">
                  <div v-for="(i,is) in item.tags" :key="is">{{i}}</div>
                </div>
                <div class="button" v-if="item.aa.join('').length > 18" style="padding: 0 12px;">
                  <div v-if="!item.zk" @click="item.zk = true">
                    展开
                    <van-icon name="arrow-down"/>
                  </div>
                  <div v-else @click="item.zk = false">
                    收起
                    <van-icon name="arrow-up"/>
                  </div>
                </div>
              </div>
                </div>
                <van-checkbox @click="ac" style="position: absolute;right: 15px;top: 15px;"
                              :name="item">

                </van-checkbox>
              </div>
              
            </div>
          </div>
          <!--        </van-list>-->
        </van-checkbox-group>
      </div>

      <van-button :disabled="result.length < 1" class="nexts" @click="nextF" block type="info">下一步</van-button>
    </div>
    <div v-else>
      <div class="header-tool">
        <h3 class="title">批量设置群聊名称</h3>
      </div>
      <van-cell-group>
        <van-field v-model="groupName" label="群名称" placeholder="请输入群名称" />
        <van-cell title="群序号" value="内容" label="群聊名称将按照序号依次创建">
          <van-stepper v-model="groupNum" />
        </van-cell>

        <van-button class="nexts" :loading="loading" @click="pullRoomBtn" block type="info">开始拉群</van-button>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import {filterContact} from '../../api/roomTemplate'
import {openEnterpriseChat} from '../../utils/wxCodeAuth'
import {userCenter} from "../../api/homePage";

export default {
  data() {
    return {
      // 條件數據
      customerList: [],
      result: [],
      allChose: false,
      borderColor: {
        border: '1px solid #1890ff;'
      },
      serchData: null,
      count: 0,
      first: false,
      groupName: '',
      groupNum: 0,
      loading: false
    }
  },
  watch: {},
  methods: {
    // 客戶選擇
    ac() {
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
    },
    async pullRoomBtn () {
      if(!this.groupName){
        this.$message.error('请输入群名称')
        return
      }
      let externalUserIds = this.result.map(i=>{
        return i.wxExternalUserid
      }).join(';')
      userCenter().then(async (res) => {
        let baseInfo = res.data.baseInfo
        // this.loading = true
        await openEnterpriseChat(baseInfo.wxUserId, externalUserIds, this.groupName, '')
        // this.loading = false
        this.$router.push('/tool/index')
      })
    },
    nextF(){
      this.first = true
    },
    back(){
      this.$router.back()
    },

    getData(){
      filterContact(this.serchData).then(res=>{
        this.count =  res.data.count
        res.data.list.forEach(i=>{
          i.zk = false
          i.aa = i.tags
        })
        this.customerList = res.data.list
      })
    },
  },
  created() {
    if(this.$route.query.tags){
      this.serchData= this.$route.query
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-field__label{
  color: #323233;
}
/deep/ .van-checkbox{
  top: calc(50% - 10px)  !important;
}
/deep/ .van-stepper__minus{
  border-radius: 50%;
  height: 26px;
  width: 26px;
}
/deep/ .van-stepper__plus{
  border-radius: 50%;
  height: 26px;
  width: 26px;
}
/deep/ .van-cell__label{
  font-size: 14px;
}
/deep/.van-nav-bar__content{
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon{
  color: #fff;
}
/deep/.van-nav-bar__text{
  color: #fff;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}
/deep/ .van-cell{
  flex-direction: column;
}
/deep/ .tabs .van-cell__title {
  width: 56px !important;
  flex: none !important;
  white-space: nowrap;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f2f4f8;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 12px;
  }

  .fs14 {
    font-size: 14px;
  }

  .header-tool {
    padding: 0 18.2347px;
    height: 40px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background-color: #f2f3f7;
    .clickable{
      font-size: 14px;
    }
    .title {
      font-size: 14px;
      margin: 0 !important;
    }

    .select-all {
      font-size: 14px;
      color: #1890ff;
    }
  }

  .bottom {
    .bottom-list {
      background: #fff;
      font-size: 12px;
      max-height: 30px;
      overflow: hidden;
      padding-bottom: 10px;

      div {
        background: #f7f7f7;
        border: 1px solid #e9e9e9;
        display: inline-block;
        padding: 2px 3px;
        border-radius: 6px;
        margin: 0px 7px 10px 0;
      }
    }

    .button {
      padding: 0px 12px;
      background: #fff;
      padding-bottom: 5px !important;

      div {
        font-size: 13px;
        color: #8db1db;
      }
    }

    .unset {
      max-height: unset;
    }
  }

  .border-color {
    border: 1px solid #1890ff !important;
  }

  .nexts{
    position: fixed;
    left: 0;
    bottom: 10px;
    width: 90%;
    /* width: auto; */
    /* margin: 0 auto; */
    left: 50%;
    transform: translate(-50%);
  }

  .content {
    background: #f2f4f8;
    min-height: 92%;
    //padding-top: 5px;
    padding: 16.668px 16.668px 0;

    .hint {
      margin-top: 12px !important;
      padding: 12.5px;
      background: #f7fbff;
      border: 1px solid #cce9ff;
      font-size: 12.5px;
      color: #333;
    }

    .button-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* margin-top: 50px; */
      position: fixed;
      bottom: 10px;
      width: 100%;
      left: 0;
      padding: 0 10px;
    }

    img {
      width: 22px;
      height: 22px;
    }

    .time {
      margin-bottom: 9.676px !important;
    }

    .user-item {
      background: #fff;
      border-radius: 6px;
      border: 1px solid transparent;
      position: relative;
      padding: 18.2347px 0px 0;
      margin-bottom: 13.676px;
      position: relative;
      padding-bottom: 7px;

      .top-wrapper {
        padding: 0 18.2347px;
        padding-bottom: 10px;
        display: flex;
        -webkit-box-align: center;
        // align-items: center;

        .avatar {
          width: 66.1007px;
          height: 66.1007px;
          border-radius: 4.5587px;
          margin-right: 13.676px;
          cursor: pointer;
        }

        .name {
          display: inline-block;
          color: rgba(0, 0, 0, .85);
          font-weight: 600;
          align-self: flex-start;
          padding-bottom: 22px;
        }
      }
    }
  }
}
</style>
