<template>
  <div class="component">
    <van-action-sheet v-model="show" title="选择标签">
      <div class="content" style="color: #818181">
        <!--        <div style="padding: 0 16px;">-->
        <!--          创建标签-->
        <!--        </div>-->
        <!--        <van-search style="padding: 5px 16px" v-model="serchValue" placeholder="回车创建" @search="search">-->
        <!--          <template #left-icon>-->
        <!--            <div></div>-->
        <!--          </template>-->
        <!--        </van-search>-->
        <van-tabs color="#1989fa">
          <van-tab title="企业标签" name="a">
            <div class="user-list">
              <van-checkbox-group
                @change="fullRadio"
                v-if="user.length > 0"
                icon-size="15px"
                v-model="radio"
              >
                <div>
                  <div
                    style="padding-left: 10px"
                    v-for="(items, indexs) in user"
                    :key="indexs"
                  >
                    <div
                      class="group_name"
                      style="font-weight: bolder; font-size: 15px"
                    >
                      {{ items.groupName }}
                    </div>

                    <div
                      v-for="(item, index) in items.tags"
                      :key="index"
                      :class="{ 'active-background': radio == item.id }"
                      class="user"
                      style="padding: 10px 16px"
                    >
                      <div>
                        {{ item.name }}
                      </div>
                      <van-checkbox
                        ref="choseRadio"
                        shape="square"
                        :name="item"
                      ></van-checkbox>
                    </div>
                  </div>
                </div>
              </van-checkbox-group>
              <van-empty
                v-else
                class="custom-image"
                :image="require('../../../assets/images/mobiletodo.png')"
                description="分组"
              />

              <!--          <van-radio-group v-if="user.length > 0" icon-size="15px" v-model="radio">-->
              <!--          <van-collapse v-model="user[0].tags">-->
              <!--            <van-collapse-item v-for="(items,indexs) in user" :key="indexs" :title="items.groupName" :name="items.tags">-->
              <!--              <div v-for="(item,index) in items.tags" :key="index" :class="{'active-background':  radio == item.id }" class="user" style="padding: 10px 16px;">-->
              <!--                <div>-->
              <!--                  {{ item.name }}-->
              <!--                </div>-->
              <!--                <van-radio  shape="square" :name="item"></van-radio>-->

              <!--              </div>-->
              <!--            </van-collapse-item>-->
              <!--          </van-collapse>-->
              <!--          </van-radio-group>-->
              <!--          <van-empty-->
              <!--              v-else-->
              <!--              class="custom-image"-->
              <!--              image=""-->
              <!--              description="分组"-->
              <!--          />-->
            </div>
          </van-tab>
          <van-tab title="分行标签" name="b">
            <div class="user-list">
              <van-checkbox-group
                @change="fullRadio"
                v-if="businessTagData.length > 0"
                icon-size="15px"
                v-model="radio"
              >
                <div>
                  <div
                    style="padding-left: 10px"
                    v-for="(items, indexs) in businessTagData"
                    :key="indexs"
                  >
                    <div
                      class="group_name"
                      style="font-weight: bolder; font-size: 15px"
                    >
                      {{ items.groupName }}
                    </div>

                    <div
                      v-for="(item, index) in items.tags"
                      :key="index"
                      :class="{ 'active-background': radio == item.id }"
                      class="user"
                      style="padding: 10px 16px"
                    >
                      <div>
                        {{ item.name }}
                      </div>
                      <van-checkbox
                        ref="choseRadio1"
                        shape="square"
                        :name="item"
                      ></van-checkbox>
                    </div>
                  </div>
                </div>
              </van-checkbox-group>
              <van-empty
                v-else
                class="custom-image"
                :image="require('../../../assets/images/mobiletodo.png')"
                description="分组"
              />
            </div>
          </van-tab>
        </van-tabs>
        <van-button
          @click="determine"
          block
          style="
            width: 90%;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
          "
          type="info"
          >确定
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { employeeIndex, contactTagList, store } from "../../../api/channelCode";
import { ContactApi } from "@/api/contact";

export default {
  data() {
    return {
      show: false,
      radio: [],
      // 搜索名称
      serchValue: "",
      user: [],
      businessTagData: [],
      activeNames: [],
    };
  },
  watch: {},
  methods: {
    $show(data) {
      this.show = true;
      console.log('data',data)
      // this.radio = data
      if (data.length <= 0 || (this.user.length <= 0 && data.length > 0)) {
        this.getBusinessTag();
        if (data.length > 0) {
          this.editCode(data);
        }
      }

      if (data.length <= 0 || (this.user.length <= 0 && data.length > 0)) {
        contactTagList().then((res) => {
          res.data.forEach((item) => {
            item.show = false;
          });
          const that = this;
          this.user = res.data.map((item) => {
            return {
              groupName: item.groupName,
              id: item.id,
              show: item.show,
              wxGroupId: item.wxGroupId,
              tags: item.tags.map((it) => {
                return {
                  id: it.id,
                  name: it.name,
                };
              }),
            };
          });
          if (data.length > 0) {
            this.editCode(data);
          }
        });
      } else {
        this.editCode(data);
      }
    },
    // 获取支行标签列表
    getBusinessTag() {
      ContactApi.businessTag().then((res) => {
        console.log("业务标签", res);
        this.businessTagData = res.data.list;
      });
    },
    determine(e) {
      if (!this.radio) {
        Toast.fail("请选择客户");
      }
      const data = this.radio;
      this.$emit("change", data);
      this.show = false;
      this.radio = [];
    },
    fullRadio() {},
    editCode(data) {
      this.$nextTick(() => {
        data.forEach((item, index) => {
          this.user.forEach((is, ins) => {
            is.tags.forEach((its, ind) => {
              if (item.id == its.id) {
                this.$refs.choseRadio[ind].toggle(true);
              }
            });
          });
        });
        data.forEach((item, index) => {
          this.businessTagData.forEach((is, ins) => {
            is.tags.forEach((its, ind) => {
              if (item.id == its.id) {
                this.$refs.choseRadio1[ind].toggle(true);
              }
            });
          });
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
  .content {
    padding: 0;

    .user-list {
      height: 275px;
      overflow: auto;
      .group_name {
        color: #747474;
        margin: 25px 0 15px 0;
        border-left: 8px solid #1989fa;
        padding-left: 8px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
      }
      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }
    }
  }
}
</style>
