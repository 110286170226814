import request from "../plugins/axios";

// 获取早报首页
export function morningPaperIndex(params) {
    return request({
        url: '/morningPost/homepage',
        method: 'get',
        params: params
    })
}

// 设置导读
export function setDaodu(params) {
    return request({
        url: '/workVcardPostComments/guidedReading',
        method: 'post',
        data: params
    })
}
// 晚报首页
export function eveningPaperIndex(params) {
    return request({
        url: '/nightPost/homepage',
        method: 'get',
        data: params
    })
}
// 获取早报点评
export function morningPaperComment(params) {
    return request({
        url: '/workVcardPostComments/getMorningComment',
        method: 'get',
        data: params
    })
}
// 设置早报点评
export function morningPaperCommentSet(params) {
    return request({
        url: '/workVcardPostComments/setMorningComment',
        method: 'post',
        data: params
    })
}
// 获取晚报点评
export function eveningPaperComment(params) {
    return request({
        url: '/workVcardPostComments/getNightComment',
        method: 'get',
        data: params
    })
}
// 设置晚报点评
export function eveningPaperCommentSet(params) {
    return request({
        url: '/workVcardPostComments/setNightComment',
        method: 'post',
        data: params
    })
}
//分享回调
export function shareCallBack(params) {
    return request({
        url: '/workVcardPostComments/setShare',
        method: 'post',
        data: params
    })
}