<template>
  <div class="index">
    <div class="top">
      <div class="topContent">
        <div class="topLeft" @click="changeTab('personal')" v-if="showMy == false">
          <span class="team">团队</span>
          <img class="change" src="../../assets/qiehuan.png" alt="" />
        </div>
        <div class="topLeft" v-else @click="changeTab('team')">
          <span class="team">我的</span>
          <img class="change" src="../../assets/qiehuan.png" alt="" />
        </div>
        <div style="width: 85%;">
          <div class="topRight">
            <van-search shape="round" v-model="searchVal" @search="handleSearch" placeholder="搜索" />
            <img class="add" @click="goPage()" src="../../assets/add.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="tab">
      <van-tabs v-model="active" background="#FFFFFF" title-active-color="#3974C6" color="#3974C6" @click="tabChange">
        <van-tab title="客户添加后触发"></van-tab>
        <van-tab title="新增标签后触发"></van-tab>
        <van-tab title="规则创建后触发"></van-tab>
      </van-tabs>
      <div class="filter">
        <div class="filterLeft">共{{listInfo.total}}个结果</div>
      </div>
      <div class="main">
        <div style="width: 94%; margin: 0 auto">
          <div class="mainTop">{{!showMy?'团队':'我所在管理组'}}创建的群发任务</div>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="mainList" v-for="(item,index) of list" :key="index" @click="goDetail(item)">
              <div class="content" style="width: 94%; margin: 0 auto">
                <div class="listTop">
                  <div class="name">
                    {{item.name}}
                  </div>
                  <div class="status" v-if="item.state==1">已开启</div>
                  <div class="completed" v-else>已关闭</div>
                </div>
                <div class="m16">
                  共 <span style="color: #000000">{{item.employees.length}}</span> 名员工需发送
                </div>
                <div class="m16">{{item.createTime}} 由 {{item.creatorName}} 创建</div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="showAction" :actions="actions" cancel-text="取消" close-on-click-action
      @select="onSelect" />
  </div>
</template>
<script>
  import { contactSopList } from "@/api/sopList";
  export default {
    data() {
      return {
        searchVal: "",
        showMy: false,
        active: 0,
        showAction: false,
        actions: [{ name: '客户添加后触发', id: 1 }, { name: '新增标签后触发', id: 2 }, { name: '规则创建后触发', id: 3 }],
        list: [],
        listParams: {
          page: 0,
          sopType: 1,
          perPage: 10
        },
        listInfo: {},
        finished: false,
        loading: false,
        refreshing: false,
      };
    },
    methods: {
      onLoad() {
        this.listParams.page++;
        let params = {
          type: 1,//团队
          ...this.listParams
        }
        this.getlist(params)
      },
      //获取列表数据
      getlist(params) {
        contactSopList(params).then((res) => {
          if (res.code == 200) {
            this.listInfo = res.data.page;
            this.list.push(...res.data.list);
            // 加载状态结束
            this.loading = false;
            // 数据全部加载完成
            if (this.list.length >= res.data.page.total) {
              this.finished = true;
            } else {
              this.finished = false;
            }
          }
        })
      },
      //搜索功能
      handleSearch() {
        let params = {
          type: !this.showMy ? 1 : undefined,//团队
          searchStr: this.searchVal,
          ...this.listParams
        }
        this.list = []
        this.getlist(params)
      },
      goPage() {
        this.showAction = true
      },
      //数据切换
      changeTab(type) {
        this.showMy = !this.showMy
        this.list = [];
        this.listParams = {
          page: 1,
          sopType: 1,
          perPage: 10
        },
          this.searchVal = ''
        if (type == 'team') {
          let params = {
            type: 1,//团队
            ...this.listParams
          }
          this.getlist(params)
        } else {
          let params = this.listParams
          this.getlist(params)
        }
      },
      //前往详情
      goDetail(row) {
        this.$router.push({ path: '/sopList/sopDetail', query: { id: row.id, state: row.state, type: this.listParams.sopType } })
      },
      onSelect(item) {
        this.$router.push({ path: '/sopList/selectWay', query: { type: item.id } })
      },
      //tab切换
      tabChange(e) {
        this.searchVal = ''
        this.active = e;
        this.list = [];
        this.listParams = {
          page: 1,
          sopType: e + 1,
          perPage: 10
        }
        let params = {
          type: !this.showMy ? 1 : undefined,//团队
          ...this.listParams
        }
        this.getlist(params)
      }
    },
  };
</script>
<style lang="less" scoped>
  /deep/ .van-search {
    width: 100%;
    padding: 16px 16px 14px 16px;
    background-color: #3975c6;
  }

  /deep/ .van-search__content {
    background-color: #fff;
    opacity: 0.51;
  }

  /deep/ .van-tabs__nav--line {
    line-height: 48px;
    padding-bottom: 0;
  }

  /deep/ .van-tabs__line {
    bottom: 0;
  }

  .index {
    height: 100%;
  }

  .top {
    width: 100%;
    height: 64px;
    background: #3975c6;

    .topContent {
      width: 94%;
      height: 64px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .topLeft {
      display: flex;
      align-items: center;

      .team {
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        margin-right: 6px;
      }

      .change {
        width: 14px;
        height: 11px;
      }
    }

    .topRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .add {
      width: 25px;
      height: 25px;
    }
  }

  .tab {
    height: 48px;
    line-height: 48px;
  }

  .filter {
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-top: 1px solid #e8e8e8;

    .filterLeft {
      margin-left: 4%;
      font-size: 12px;
      color: #a5a5a5;
    }

    .filterRight {
      margin-right: 4%;
      font-size: 12px;
      color: #515151;

      .icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
        margin-top: -2px;
      }
    }
  }

  .main {
    height: 100vh;
    background: #f2f3f7;

    .mainTop {
      font-size: 12px;
      color: #9b9b9b;
    }

    .mainList {
      width: 100%;
      height: 129px;
      background: #ffffff;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .listTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      border-bottom: 1px solid #f1f1f1;

      .name {
        height: 56px;
        line-height: 56px;
        width: 88%;
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .status {
        font-size: 12px;
        font-weight: 500;
        color: #3975c6;
      }

      .completed {
        font-size: 12px;
        font-weight: 500;
        color: #a5a5a5;
      }
    }

    .m16 {
      height: 18px;
      font-size: 12px;
      color: #9b9b9b;
      margin-bottom: 5px;
    }
  }
</style>