import request from "@/utils/request";
export class posterApi {
    // 海报
    static posterInfo(params) {
      return request({
        url: "/poster/getPoster",
        method: "get",
        params: params,
      });
    }
    // 生成mediaId
    static createMediaId(params) {
      return request({
        url: "/friendsCircleInformation/getMediaId",
        method: "post",
        data: params,
      });
    }
}