<template>
  <div class="index">
    <div class="main-content">
      <div style="padding-bottom: 13px; display: none" class="tab-bar fs14">
        <div
          class="tab-item active fs16"
          style="font-size: 16px; font-weight: bold"
        >
          我的待办(0)
        </div>
        <div class="check-more">
          <span class="fs12">更多</span>
          <a-icon type="right" />
        </div>
      </div>
      <div class="top">
        <van-search
          shape="round"
          v-model="searchVal"
          @search="searchDraw"
          placeholder="请输入搜索关键词"
        />
        <div class="right" @click="routerPush('/index/create')">
          <img src="../../assets/add.png" alt="" />
        </div>
      </div>
      <div class="filter">
        <div class="filterLeft">{{ filterName }} (<span style="display:inline-block;padding:0 2px">{{ total }}</span>)</div>
        <div class="filterRight" @click="show = true">
          <img src="../../assets/filter.png" class="icon" alt="" /><span
            >筛选</span
          >
        </div>
      </div>
    </div>

    <div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-if="todo.length > 0" class="data-list">
          <div class="list-time" style="font-size: 13px">
            {{ listDate }}
          </div>
          <van-radio-group v-model="radio" icon-size="14">
            <div v-for="(item, index) in todo" :key="index">
              <div class="title">
                <!--<div>
                  <img src="../../assets/images/date.png" />
                  任务
                </div>-->
              </div>
              <div class="content">
                <div>
                  <div
                    @click="details(item.id)"
                    v-if="item.status != 3"
                    
                  >
                    <div class="tips" v-if="item.status_text == '已逾期'">
                      逾期
                    </div>
                    <!-- <div class="right" style="display: flex"> -->
                      {{ item.content }}
                    <!-- </div> -->
                  </div>
                  <div @click="details(item.id)" v-if="item.status == 3">
                    <div class="tips" style="background: #2a0cf0">已完成</div>
                    {{ item.content }}
                  </div>
                </div>
                <div class="content-user">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      margin-top: 7px;
                      font-size: 14px;
                    "
                  >
                    <!-- <img
                        style="margin-right: 8px"
                        src="../../assets/images/clock.png"
                      /> -->
                    <div v-if="item.status == 3">
                      {{ item.day }} {{ item.time }}
                    </div>
                    <div
                      v-else
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 6px;
                        width: 100%;
                      "
                    >
                      {{ item.day }} {{ item.time }}
                      <van-radio :name="item.id" @click="overDate(item.id)" />
                    </div>
                  </div>
                  <div v-if="item.overdueDays">
                    已逾期<span style="color: #ee5855">{{
                      item.overdueDays
                    }}</span
                    >天
                  </div>
                  <div v-if="item.overdueHours">
                    已逾期<span style="color: #ee5855">{{
                      item.overdueHours
                    }}</span
                    >小时
                  </div>
                </div>
                <div @click="details(item.id)" class="content-name">
                  <div class="name-user">
                    <img :src="item.contactAvatar" />
                    {{ item.contactName }}
                  </div>
                  <div class="name-button">
                    跟进
                    <!-- <a-icon type="right" /> -->
                  </div>
                </div>
              </div>
            </div>
          </van-radio-group>
        </div>
        <div v-else class="data-list" style="background: none">
          <div class="list-time" style="font-size: 13px; margin-bottom: 5px">
            {{ listDate }}
          </div>
          <div style="background: #fff">
            <van-empty
              :image="require('../../assets/images/mobiletodo.png')"
              description="没有待开始的待办~"
            />
          </div>
        </div>
      </van-list>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import {
  workEmployee,
  workEmployeeNew,
  updateStatus,
  userCenter,
} from "../../api/homePage";
import { Toast } from "vant";
export default {
  data() {
    return {
      searchVal: "",
      loading: false, // 是否处在加载状态
      finished: false, // 是否已加载完成
      error: false, // 是否加载失败
      page: 1, // 分页
      page_size: 10, // 每页条数
      total: 0, // 数据总条数
      schedule: "have-matter-wait",
      // 默认日期
      value1: "",
      show: false,
      filterName: "全部",
      actions: [
        { name: "全部", value: "" },
        { name: "未提醒", value: 0 },
        { name: "已提醒", value: 1 },
        { name: "周期提醒进行中", value: 2 },
        { name: "已完成", value: 3 },
      ],
      week: "",
      date: "",
      // 列表展示日期
      listDate: "",
      // 数据类别加载动画
      isLoading: true,
      // tab 切换
      active: 0,
      // 单选
      radio: true,
      // 下拉分类
      isSelect: false,
      optionChose: "",
      switching: true,
      // 顶部预览数据
      baseInfo: "",
      todo: [],
      userDatalt: {
        name: "",
        good: "",
      },
    };
  },
  watch: {},
  created() {
    // 获取日期
    this.getWeekDate();
    // 获取数据
    // this.getNowTime();
    // console.log('created_getList');
    // user信息
    this.userDetails();
  },
  methods: {
    //搜索功能
    searchDraw() {
      this.page = 1;
      this.todo = [];
      this.getList();
    },
    // 获取列表数据方法
    async getList() {
      let { data: res } = await workEmployeeNew({
        status: this.value1,
        page: this.page,
        perPage: this.page_size,
        content: this.searchVal,
      });
      if (res.data.length === 0) {
        // 判断获取数据条数若等于0
        this.todo = []; // 清空数组
        this.finished = true; // 停止加载
      }
      // 若数据条数不等于0
      this.total = res.total; // 给数据条数赋值
      console.log("this.total", this.total);
      this.todo.push(...res.data); // 将数据放入list中
      this.loading = false; // 加载状态结束
      console.log("this.listdddd", this.todo,this.todo.length);
      // 如果list长度大于等于总数据条数,数据全部加载完成
      if (this.todo.length >= res.total) {
        this.finished = true; // 结束加载状态
      }else{
        this.finished = false
      }
    },
    // 被 @load调用的方法
    onLoad() {
      console.log('onload')
      // 若加载条到了底部     
      this.getList();// 调用上面方法,请求数据
      this.page++; // 分页数加一
      
    },
    onSelect(e) {
      console.log("model", e);
      this.filterName = e.name;
      this.value1 = e.value;
      this.show = false;
      this.page = 1;
      this.todo = [];
      this.getList();
    },
    activeTab(e) {
      this.active = e;
    },
    openSelect() {
      this.isSelect = !this.isSelect;
    },
    selectType(e) {
      this.isSelect = false;
      this.optionChose = e;
      switch (e) {
        case "已逾期":
          this.schedule = "have-matter-over";
          break;
        case "已完成":
          this.schedule = "have-matter-finsh";
          break;
        case "待开始":
          this.schedule = "have-matter-wait";
          break;
      }

      // this.$refs.content.formatter.haveMatter()
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e);
    },
    // 获取数据
    getNowTime(e) {
      console.log("e", e);
      this.isLoading = true;
      workEmployee({ day: e }).then((res) => {
        this.baseInfo = res.data.baseInfo;
        // this.todo = res.data.todo;
        this.isLoading = false;
      });
    },

    // 获取当前日期
    getWeekDate() {
      const now = new Date();
      const day = now.getDay();
      const weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      const year = new Date().getFullYear();
      const mm = new Date().getMonth() + 1;
      const dd = new Date().getDate();
      this.week = weeks[day];
      this.date = mm + "月" + dd + "日";
      this.dateDetail = {
        year: year,
        month: mm,
        day: dd,
      };
      this.listDate = "今天 · " + mm + "月" + dd + "日" + "  " + weeks[day];
    },
    // 根据日期切换
    dataSelect(e) {
      const year = e.getFullYear();
      const month =
        e.getMonth() + 1 < 10 ? "0" + (e.getMonth() + 1) : e.getMonth() + 1; //js从0开始取
      const date1 = e.getDate() < 10 ? "0" + e.getDate() : e.getDate();
      const day = e.getDay();
      const weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      if (date1 == this.dateDetail.day) {
        this.listDate =
          "今天 · " + month + "月" + date1 + "日" + "   " + weeks[day];
      } else if (year == this.dateDetail.year) {
        this.listDate = month + "月" + date1 + "日" + "   " + weeks[day];
      } else {
        this.listDate =
          year + "年" + month + "月" + date1 + "日" + "   " + weeks[day];
      }
      this.overTextDate = year + "-" + month + "-" + date1;
      this.getNowTime(year + "-" + month + "-" + date1);
    },
    // 日期格式化
    formatter(day, css) {
      const year = day.date.getFullYear();
      const month = day.date.getMonth() + 1;
      const date = day.date.getDate();
      ("日期格式化");
      const details = this.dateDetail;

      if (year === details.year && month === details.month) {
        if (date === 1) {
          day.className = this.schedule;
        } else if (date === 4) {
          day.className = this.schedule;
        } else if (date === details.day) {
          day.className = "nowDate";
        }
      }

      return day;
    },
    // 完成任务
    // overDate(id) {
    //   updateStatus({ id: id }).then((res) => {
    //     this.getNowTime(this.overTextDate);
    //     Toast.success("待办已完成");
    //   });
    // },
    overDate(id) {
      updateStatus({ id: id }).then((res) => {
        console.log("overDate_getList");
        Toast.success("待办已完成");
        this.todo = [];
        this.value1 = "";
        this.page = 1;
        this.getList();
      });
    },
    details(id) {
      this.$router.push({
        path: "/index/create",
        query: {
          id: id,
        },
      });
    },
    userDetails() {
      userCenter().then((res) => {
        this.userDatalt = res.data.baseInfo;
        const now = new Date();
        const hour = now.getHours();

        if (hour < 6) {
          this.userDatalt.good = "凌晨好";
        } else if (hour < 9) {
          this.userDatalt.good = "早上好";
        } else if (hour < 12) {
          this.userDatalt.good = "上午好";
        } else if (hour < 14) {
          this.userDatalt.good = "中午好";
        } else if (hour < 17) {
          this.userDatalt.good = "下午好";
        } else if (hour < 19) {
          this.userDatalt.good = "傍晚好";
        } else if (hour < 22) {
          this.userDatalt.good = "晚上好";
        } else {
          this.userDatalt.good = "夜里好";
        }
      });
    },
    detailsee(i) {
      this.$router.push({
        path: "/contact/detail",
        query: {
          contactId: i.contactId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@wait: #45c400;
@over: #c8cad0;
@finsh: red;

/deep/ .van-search {
  width: 84%;
  padding: 16px 16px 14px 16px;
  background-color: #3975c6;
}
/deep/ .van-search__content {
  background-color: #fff;
  opacity: 0.51;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  background: #3975c6;
  border-bottom: 1px solid #f1f1f1;
  .right {
    img {
      margin-right: 16px;
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.van-dropdown-menu__bar {
  height: 20px;
  box-shadow: none;
}

/deep/.van-tabbar {
  box-sizing: content-box !important;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}

.van-tabbar--fixed {
  left: unset !important;
}

/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .have-matter-wait {
  &:before {
    background: @wait;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .have-matter-over {
  &:before {
    background: @finsh;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .have-matter-finsh {
  &:before {
    background: @over;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .hide-icon .van-radio__icon {
  visibility: hidden;
}

/deep/ .hide-icon .van-radio__label {
  width: 126px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/ .van-radio__icon .van-icon {
  width: 18px;
  height: 18px;
}
.filter {
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  .filterLeft {
    margin-left: 4%;
    font-size: 12px;
    color: #a5a5a5;
  }
  .filterRight {
    margin-right: 4%;
    font-size: 12px;
    color: #515151;
    .icon {
      width: 11px;
      height: 11px;
      margin-right: 6px;
      margin-top: -2px;
    }
  }
}
.van-radio {
  width: 24px;
}

.title-overflow {
  position: relative;
  width: 28px;
  height: 28px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  line-height: 28px;
  left: -5px;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;

  /deep/ .van-radio__label {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.index {
  font-size: 14px;
  min-height: 100%;
  background-color: #f2f3f7;
  padding-bottom: 70px;

  .fs14 {
    opacity: 0.7;
    font-weight: 400;
    font-size: 14px;
  }

  .fs16 {
    font-size: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .tab {
    img {
      width: 22px;
      height: 22px;
    }
  }

  .iconS {
    transform: rotate(180deg);
    color: #1890ff !important;
  }

  .top-part {
    height: 310px;
    background: linear-gradient(180deg, #3974c7, #0a42c3 55%, #2b63cd);
    padding: 16px 12px 0;
    position: relative;

    .top-info {
      padding: 0 12px 0 8px;

      .time-tips-box {
        display: flex;
        align-items: center;
      }

      .time-tips {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 33px;
        font-weight: 500;
        color: #fff;
        width: 252.5px;
      }

      .van-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icon-message {
        position: absolute;
        top: 25px;
        right: 20px;
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url(../../assets/images/news.png) no-repeat;
        background-size: 100% 100%;
      }

      .time-detail {
        color: #c2d7ff;
        margin-top: 4px;
        line-height: 20px;
      }
    }

    .customer-data {
      cursor: pointer;
      height: 104px;
      background: url(../../assets/images/background.png) no-repeat 50% /
        contain;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      padding: 17px 30px 29px 30px;
      position: relative;
      top: 24px;
      color: #fff !important;

      .data-item {
        text-align: center;

        .customer-label {
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 20px;
          position: relative;
        }

        .customer-value {
          font-family: DINAlternateBold;
          font-weight: 700;
          color: #fff;
          line-height: 36px;
        }
      }
    }
  }

  .loading {
    width: 100%;
    height: 228.94px;
    /* background: #fff; */
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .main-content {
    background-color: #f2f3f7;
    /* margin-top: -131px; */
    /* z-index: 1;
      position: relative;
      top: 50px; */
    border-radius: 4px 4px 0 0;

    .tools-list {
      display: flex;
      flex-direction: row;
      padding-top: 32px;

      .tools-item {
        flex: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img-box {
          width: 36px;
          height: 36px;
          margin-bottom: 8px;

          img {
            width: 36px;
            height: 36px;
          }
        }

        .tools-name {
          color: #545454;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .tab-bar {
      padding: 0 12px;
      margin-top: 36px;
      display: flex;
      position: relative;
      align-items: center;

      .tab-item {
        color: #000;
        position: relative;
        line-height: 24px;
        font-weight: 500;
        cursor: pointer;
        padding: 0 4px;
        margin-right: 30px;
      }

      .active {
        color: #000;
        position: relative;
        line-height: 0.48rem;
        font-weight: 500;
        cursor: pointer;
        padding: 0 0.08rem;
        margin-right: 0.6rem;

        &:after {
          content: "";
          position: absolute;
          bottom: -11px;
          left: 0;
          width: 100%;
          height: 6px;
          background: rgba(87, 167, 255, 0.21);
        }
      }

      .check-more {
        cursor: pointer;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        color: rgb(189, 189, 193);
        color: #8c8c8c;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* padding: 0 14px; */
      background: #fff;
      margin: 0 14px;
      padding: 0 7px;
      height: 41px;

      .title {
        font-size: 14px;
        font-weight: bold;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .qieh {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f2f3f7;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }

    .chose {
      margin: 0 14px;
      background: #fff;
      border-radius: 0px 0px 16px 16px;
      transition: 0.5s;

      .div {
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 8px;

        &:hover {
          color: #1890ff;
          background: #f6fbff;
        }
      }
    }
  }

  .data-list {
    margin: 8px 16px;
    color: #767676;
    font-weight: 500;
    //background-color: #fff;

    .title {
      background: #f2f3f7;
      display: flex;
      align-items: center;
      padding-left: 7px;
      font-size: 14px;
      /* color: #fff; */
      padding: 7px 10px;
      // margin-top: 5px;

      div {
        display: flex;
        align-items: center;
      }

      img {
        width: 16px;
        margin-right: 5px;
      }
    }

    .content {
      background: #fff;
      min-height: 80px;
      padding: 7px 10px;

      .tips {
        display: inline-block;
        background: #ee5855;
        color: #fff;
        padding: 1px 3px;
        border-radius: 4px;
        margin-right: 14px;
        font-size: 11px;
        height: 22px;
        line-height: 19px;
        color: #ffffff;
      }

      .content-user {
        color: #949494;
        font-size: 14px;
        // display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 12px;
          height: 12px;
          margin-right: 4px !important;
        }
      }

      .content-name {
        // background: #f9fafe;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 0 7px 0;
        height: 30px;
        // margin-top: 10px;

        .name-user {
          display: flex;
          align-items: center;
          font-size: 14px;

          img {
            width: 16px;
            margin-right: 5px;
            border-radius: 50%;
          }
        }

        .name-button {
          width: 55px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          background: #e6e8f4;
          border-radius: 12px;
          font-size: 12px;
          font-weight: 500;
          color: #4871bf;
        }
      }
    }
  }
}
</style>