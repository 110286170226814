<template>
<div class="all">
    <div class="top">
      <div class="left"><img src="../../assets/xinye.png" alt="" /></div>
      <div class="right">
        <p>我的积分</p>
        <h5>{{ IntegralInfo }}<span> 分</span></h5>
      </div>
      <div class="m16" @click="skipRule">积分规则</div>
    </div>
    <div class="section">
      <div class="tb">
        <div>日期</div>
        <div>客户添加</div>
        <div>标签化率</div>
        <div>关键标签率</div>
        <div>总分</div>
      </div>
      <div class="tr" v-for="(data, index) in IntegralList" :key="index">
        <div style="width: 17%">{{ data.date.slice(5,) }}</div>
        <div style="width: 23%; margin-left: -8px">
          {{ data.addContactNum }}
        </div>
        <div>{{ data.tagRate }}</div>
        <div>{{ data.tagCruxRate }}</div>
        <div>{{ data.totalNum }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { MyApi } from "@/api/my";
import { List } from "vant";
export default {
  data() {
    return {
      IntegralInfo: 0,
      IntegralList:[]
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      MyApi.memberIntegral().then((res) => {
        console.log("行为积分", res);
        this.IntegralInfo = res.data.behavior_score.behaviorScore;
        this.IntegralList=res.data.list
      });
    },
    skipRule() {
      this.$router.push({ path: "/my/IntegralRule" });
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  width: 93%;
  height: 140px;
  position: relative;
  margin: 0 auto;
  .left {
    width: 34%;
    float: left;
    margin-top: 20px;
    img {
      width: 100%;
      height: 80px;
    }
  }
  .right {
    margin-top: 20px;
    float: left;
    margin-left: 30px;
    p {
      margin: 0;
      height: 28px;
      font-size: 20px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #929292;
    }
    h5 {
      height: 56px;
      font-size: 40px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #181818;
      span {
        font-size: 20px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #181818;
      }
    }
  }
  .m16 {
    position: absolute;
    right: -10px;
    top: 20px;
    width: 69px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #f1f1f1;
    border-radius: 50px 0px 0px 50px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #929292;
  }
}
.section {
  width: 93%;
  margin: 0 auto;
  .tb {
    display: flex;
    justify-content: space-evenly;
    height: 33px;
    line-height: 33px;
    background: #ebeef5;
    border-radius: 4px;
    font-size: 15px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #3974c6;
    margin-bottom: 6px;
  }
  .tr {
    display: flex;
    justify-content: space-evenly;
    height: 33px;
    line-height: 33px;
    background: #ebeef5;
    border-radius: 4px;
    margin-bottom: 6px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #717174;
    div {
      width: 20%;
      text-align: center;
    }
  }
}
</style>