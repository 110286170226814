<template>
  <div class="index">
    <div class="top">
      <div class="fliter">
        <van-dropdown-menu active-color="#2a8ff7">
          <van-dropdown-item
            v-model="value1"
            :options="option1"
            @change="changeSelect1"
          />
          <van-dropdown-item
            v-model="value2"
            :options="option2"
            @change="changeSelect2"
          />
        </van-dropdown-menu>
      </div>
      <div class="search">
        <van-search
          v-model="searchVal"
          placeholder="请输入搜索关键词"
          @search="onSearch"
          shape="round"
        />
      </div>
    </div>
    <van-tabs
      v-model="activeName"
      color="#2A8FF7"
      title-active-color="#2A8FF7"
      @change="handleTabs"
    >
      <van-tab
        v-for="(item, index) in typeOption"
        :key="index"
        :title="item.name"
        :name="item.id"
      >
        <div class="main">
          <div class="mainLeft">
            <div
              :class="spanIndex == index ? 'active' : 'm16'"
              v-for="(item, index) in twoCategory"
              :key="index"
              @click="handleClick(index, item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="mainRight">
            <div v-if="mediumList.length > 0">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
              >
                <div
                  class="content"
                  v-for="(res, ind) in mediumList"
                  :key="ind"
                >
                  <div v-if="res.type == '文本'">
                    <div class="content_title">
                      {{ res.content.content }}
                    </div>
                    <div class="bottom">
                      <div class="bottom_left">转发{{ res.sendNum }}</div>
                      <!-- <div class="bottom_right">发送</div> -->
                    </div>
                  </div>
                  <div v-else>
                    <div class="content_img">
                      <img
                        v-if="res.type == '视频'"
                        src="../../assets/images/vidoeType.png"
                        alt=""
                      />
                      <img
                        v-else-if="res.type == '文件'"
                        src="../../assets/images/fileType.png"
                        alt=""
                      />
                      <img v-else :src="res.content.imageFullPath" alt="" />
                      <span class="picName">{{ res.content.title }}</span>
                    </div>
                    <div class="bottom">
                      <div class="bottom_left">转发{{ res.sendNum }}</div>
                      <!-- <div class="bottom_right">发送</div> -->
                    </div>
                  </div>
                </div>
              </van-list>
            </div>
            <div v-else>
              <van-empty description="暂无数据" />
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { socialList, socialGroupList } from "../../api/medium";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      activeName: "",
      searchVal: "",
      spanIndex: 0,
      value1: 1,
      value2: 'asc',
      option1: [
        { text: "共享素材", value: 1 },
        { text: "企业素材", value: 2 },
      ],
      option2: [
        { text: "按发布时间", value: 'asc' },
        { text: "按发布次数", value: 'desc' },
      ],
      typeOption: [
        { name: "所有", id: "" },
        { name: "文本", id: 1 },
        { name: "图片", id: 2 },
        { name: "图文", id: 3 },
        { name: "音频", id: 4 },
        { name: "视频", id: 5 },
        { name: "文件", id: 6 },
      ],
      twoCategory: [],
      mediumList: [],
      mediumGroupId: "",
      social_type:1
    };
  },
  created() {
    this.getCategory();
    this.getList();
  },
  methods: {
    changeSelect1(e) {
      console.log('e',e)
      this.social_type = e
      this.mediumList = [];
      this.page = 1;
      this.getList()
      this.getCategory()
    },
    handleClick(i, id) {
      this.spanIndex = i;
      this.mediumGroupId = id;
      this.page = 1;
      this.mediumList = [];
      this.getList();
    },
    getCategory() {
      socialGroupList({social_type:this.social_type}).then((res) => {
        this.twoCategory = res.data;
      });
    },
    getList() {
      socialList({
        page: this.page,
        perPage: 10,
        searchStr: this.searchVal,
        mediumGroupId: this.mediumGroupId,
        type: this.activeName,
        orderRule: this.value2,
        social_type: this.value1,
      }).then((res) => {
        // this.mediumList = res.data.list;
        this.mediumList.push(...res.data.list);
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.mediumList.length >= res.data.page.total) {
          this.finished = true;
        }else{
          this.finished = false;
        }
      });
    },
    onLoad() {
      this.page++;
      this.getList();
    },
    onSearch() {
      this.mediumList = [];
      this.page = 1;
      this.getList();
    },
    handleTabs() {
      this.mediumList = [];
      this.page = 1;
      this.getList();
    },
    changeSelect2() {
      this.mediumList = [];
      this.page = 1;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-ellipsis {
  font-size: 13px;
}
/deep/ .van-dropdown-menu__bar {
  box-shadow: none;
}
/deep/ .van-tabs__wrap {
  width: 100%;
}
/deep/ .van-tabs__line {
  bottom: 8px;
  height: 1px;
}
/deep/ .van-tabs__content {
  height: 100vh;
}
/deep/ .van-tab__pane {
  height: 100vh;
}
.top {
  width: 100%;
  height: 50px;
}
.fliter {
  float: left;
  width: 50%;
}
.search {
  float: left;
  width: 50%;
}
.main {
  height: 100%;
  width: 100%;
  background: #FAFBFD;
  .mainLeft {
    float: left;
    width: 22%;
    height: 100%;
    .m16 {
      text-align: center;
      padding-top: 6px;
      padding-bottom: 6px;
      line-height: 24px;
      font-size: 12px;
      color: #515151;
      width: 100%; //宽度
      overflow: hidden; //超出文本隐藏
      text-overflow: ellipsis; ///超出部分省略号显示
      display: -webkit-box; //弹性盒模型
      -webkit-box-orient: vertical; //上下垂直
      -webkit-line-clamp: 2; //自定义行数
    }
    .active {
      background: #fff;
      border-radius: 0 6px 6px 0;
      text-align: center;
      padding-top: 6px;
      padding-bottom: 6px;
      line-height: 24px;
      font-size: 12px;
      border-left: 2px solid #2a8ff7;
      color: #2a8ff7;
      width: 100%; //宽度
      overflow: hidden; //超出文本隐藏
      text-overflow: ellipsis; ///超出部分省略号显示
      display: -webkit-box; //弹性盒模型
      -webkit-box-orient: vertical; //上下垂直
      -webkit-line-clamp: 2; //自定义行数
    }
  }
  .mainRight {
    float: left;
    width: 78%;
    height: 100%;
    background-color: #f3f4f8;
    .content {
      width: 92%;
      min-height: 100px;
      background: #ffffff;
      border-radius: 6px;
      margin: 0 auto;
      margin-top: 11px;
      overflow: hidden;
      .content_title {
        width: 96%;
        margin: 0 auto;
        min-height: 54px;
        font-size: 13px;
        color: #4a4a4a;
        line-height: 18px;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .content_img {
        height: 44px;
        margin: 12px 0 12px 10px;
        img {
          float: left;
          width: 44px;
          height: 44px;
          background: #d8d8d8;
          border-radius: 8px;
          margin-right: 8px;
        }
        .picName {
          float: left;
          vertical-align: top;
          font-size: 14px;
          font-weight: 500;
          color: #4a4a4a;
          height: 100%;
          width: calc(100% - 60px); //宽度
          overflow: hidden; //超出文本隐藏
          text-overflow: ellipsis; ///超出部分省略号显示
          display: -webkit-box; //弹性盒模型
          -webkit-box-orient: vertical; //上下垂直
          -webkit-line-clamp: 2; //自定义行数
        }
      }
      .bottom {
        width: 94%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 42px;
        margin: 0 auto;
        border-top: 1px solid #f1f1f1;
        .bottom_left {
          font-size: 11px;
          color: #a5a5a5;
        }
        .bottom_right {
          text-align: center;
          width: 49px;
          height: 23px;
          line-height: 23px;
          background: #f5f7ff;
          border-radius: 12px;
          font-size: 12px;
          color: #2a8ff7;
        }
      }
    }
  }
}
</style>