import request from "../plugins/axios";

// 专题分类列表
export function category(params) {
    return request({
        url: '/subject/category',
        method: 'get',
        params: params
    })
}
// 专题列表
export function topicList(params) {
    return request({
        url: '/subject/index',
        method: 'get',
        params: params
    })
}
// 专题详情
export function topicDetail(params) {
    return request({
        url: '/subject/detail',
        method: 'get',
        params: params
    })
}

// 分享回调
export function shareCallBack(params) {
    return request({
        url: '/subject/setShare',
        method: 'post',
        data: params
    })
}