<template>
  <div>
    <div class="top">
      <van-tabs v-model="value1" @click="onClick" title-active-color="#3974C6">
        <van-tab
          v-for="(item, index) in option1"
          :name="item.value"
          :title="item.text"
          :key="index"
        >
          <div class="section"></div>
          <div class="main">
            <div
              class="left"
              v-for="(item, index) in listData"
              :key="index"
              @click="skip(item.content.imageFullPath)"
            >
              <img
                style="border-radius: 6px"
                v-if="item.content.imageFullPath"
                :src="item.content.imageFullPath"
                alt=""
              />
              <!-- <img style="border-radius:6px" v-else :src="item.fullPath" alt="" /> -->
              <div class="zi">{{ item.content.title }}</div>
            </div>
          </div>
          <div style="height: 70px;"></div>
          <van-uploader
            multiple
            :after-read="afterRead"
          >
            <van-button>上传图片</van-button>
          </van-uploader>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { ContactApi } from "../../api/contact";
import { mediumList, mediumGroup } from "../../api/medium";

export default {
  data() {
    return {
      active: 0,
      upfileList: [],
      perPage: 10000,
      listData: { img: [] },
      option1: [],
      value1: "",
      c: { content: {} },
    };
  },
  watch: {
    value1: {
      handler(newVal, oldVal) {
        console.log("newVal", newVal);
        console.log("oldVal", oldVal);
        this.getList();
      },
    },
  },
  created() {
    this.getList();
    this.group();
  },
  methods: {
    onClick(name, title) {
      this.value1 = name;
      console.log("id", name);
      console.log("title", title);
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var formData = new FormData();
      formData.append("file", file.file);
      
      ContactApi.upload(formData).then((res) => {
        console.log("上传成功");
        console.log("res", res);
        var fileType2=res.data.fullPath
        var fileType3 = fileType2.substring(
          fileType2.lastIndexOf("/") + 1,
          fileType2.length
        );
        var fileType=fileType3.toLowerCase().split('.')[1];
        console.log('fileType',fileType)
        var type = fileType == "jpg" || fileType == "png"||fileType == "jpeg";
        console.log("type", type);
        if (!type) {
          this.$toast("上传图片只能是jpg、png、jpeg格式!");
          return false;
        }
        console.log(file);
        this.$router.push({
          path: "/poster/create",
          query: { imgurl: res.data.fullPath },
        });
        // var a = JSON.parse(
        //   JSON.stringify(res.data).replace(/fullPath/g, "imageFullPath")
        // );
        // var b = JSON.parse(JSON.stringify(a).replace(/name/g, "title"));
        // console.log("处理后的数据", b);
        // this.c.content = b
        // console.log('c[0]',this.c.content)
        // this.listData.push(this.c)
        // console.log('处理后的listdata',this.listData)
      });
      console.log("4345", this.upfileList);
    },
    skip(imgurl) {
      this.$router.push({ path: "/poster/create", query: { imgurl } });
    },
    //获取分组
    group() {
      mediumGroup().then((res) => {
        console.log("分组1", res);
        this.option1.push(
          ...res.data.map((i) => {
            return {
              text: i.name,
              value: i.id,
            };
          })
        );
        console.log("分组2", this.option1);
      });
    },
    // 获取数据
    getList() {
      mediumList({
        mediumGroupId: this.value1,
        page: 1,
        perPage: this.perPage,
      }).then((res) => {
        let listData = {
          img: [],
        };
        res.data.list.forEach((i) => {
          switch (i.type) {
            case "图片":
              listData.img.push(i);
              break;
          }
        });
        this.listData = listData.img;
        console.log("listData", this.listData);
      });
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/ .van-tabs__line{
  bottom: 0;
}
/deep/ .van-tabs__line {
  background-color: #3974c6;
  height: 1px;
}
/deep/ .van-tabs__nav--line{
  padding-bottom:0
}
/deep/ .van-uploader {
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* height: 44px; */
  background: #3974c7;
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  /* line-height: 44px; */
  padding: 10px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin: 0 auto;
  background-color: #FFFFFF;
}
/deep/ .van-uploader__input-wrapper {
  width: 100%;
}
/deep/ .van-button--default {
  width: 94%;
  /* margin: 0 2%; */
  /* height: 42px; */
  background: #3974c7;
  border: none;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  line-height: 44px;
  /* bottom: 6px; */
}
.top {
  height: 43px;
}
.section {
  height: 8px;
  background: #f2f4f6;
}
.main {
  height: 100%;
  width: 94%;
  margin: 0 auto;
  margin-top: 10px;
  overflow: auto;
  background: #fff;
  .left {
    display: inline-block;
    width: 47%;
    margin-right: 10px;
  }
}

.zi {
  height: 50px;
  font-size: 14px;
  color: #262626;
  line-height: 50px;
}

img {
  width: 100%;
  height: 293px;
}
.left :nth-child(even) {
  margin-right: 0;
}
.bottom {
  position: fixed;
  bottom: 6px;
  width: 94%;
  margin: 0 2%;
  height: 44px;
  background: #3974c7;
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  line-height: 44px;
}
</style>