<template>
  <div class="statistics">   
    <div class="banxin">
      <div class="top">
        <div class="topLeft">工单统计</div>
        <div class="topRight">
          <van-dropdown-menu active-color="#1989fa">
            <van-dropdown-item v-model="selectVal1" :options="option1" />
            <van-dropdown-item v-model="selectVal2" :options="option2" />
          </van-dropdown-menu>
        </div>
      </div>
      <van-loading v-if="isGet == false" style="text-align: center" size="24px"
      >加载中...</van-loading
    >
      <div class="content"  v-else>
        <div class="tableLeft">
          <div class="m16">工单池(总)</div>
          <div class="m16">待审批</div>
          <div class="m16">已完成</div>
          <div class="m16">审批驳回</div>
          <div class="m16">驳回到工单</div>
        </div>
        <div class="tableRight">
          <div class="m16">{{sData.count_num}}</div>
          <div class="m16">{{sData.pending_num}}</div>
          <div class="m16">{{sData.finish_num}}</div>
          <div class="m16">{{sData.reject_num}}</div>
          <div class="m16">{{sData.reject_sheet_num}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { orderStatistics } from "@/api/workOrder";
import { getCurrentTime, getMonday, getfirstDayOfMonth } from "@/utils/index";

export default {
  data() {
    return {
      isGet:false,
      selectVal1: '',
      selectVal2: '',
      sData:{},
      option1: [
        { text: "全部", value: '' },
        { text: "我发起的", value: 1 },
        { text: "我协同的", value: 2 },
      ],
      option2: [
        { text: "全部", value: ''},
      ],
    };
  },
  created() { 
    this.getData();
    var time = getCurrentTime()
    this.option2.push({text: "今日", value: time})
    var str = JSON.stringify(getMonday())
    var monday = str.substring(1,11)+' '+ '00' + ':' + '00' + ':' + '01'
    this.option2.push({text: "本周", value: monday})
    var firstDay = getfirstDayOfMonth()+' '+ '00' + ':' + '00' + ':' + '02'
    this.option2.push({text: "本月", value: firstDay})
    console.log('dfdf',this.option2)
  },
  watch: {
    selectVal1(){
      this.isGet = false
      this.getData()
    },
    selectVal2(){
      this.isGet = false
      this.getData()
    },
  },
  methods:{
    getData() {
      orderStatistics({
        type: this.selectVal1,
        startTime: this.selectVal2,
      }).then((res) => {
        this.isGet = true;
        this.sData = res.data
      });
    },
  }
};
</script>
<style lang="less" scoped>
/deep/ .van-dropdown-menu__bar{
    box-shadow: none;
    width: 160px;
}
.banxin {
  width: 94%;
  margin: 0 auto;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  .topLeft {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }
}
.content {
  display: flex;
  .tableLeft {
    width: 50%;
    height: 154px;
    background: #f3f4f8;
  }
  .m16 {
    padding: 6px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    color: #515151;
  }
  .tableRight {
    width: 50%;
    height: 154px;
    background: #f9f9f9;
  }
}
</style>