<template>
  <div class="index">
    <div class="top">
      <div class="top_content">
        <div class="totalNum">
          <div class="m16">客户总人数</div>
          <div class="num">{{ coverageRate.count_contact }}</div>
        </div>
        <div class="tagProbability">
          <div class="flex_box">
            <div class="m17">客户标签覆盖率</div>
            <div class="probability">{{ coverageRate.contact_tag_ratio }}</div>
          </div>
          <div class="flex_box">
            <div class="m17">手动标签覆盖率</div>
            <div class="probability">{{ coverageRate.manual_contact_tag_ratio }}</div>
          </div>
          <div class="flex_box">
            <div class="m17">行为标签覆盖率</div>
            <div class="probability">{{ coverageRate.auto_contact_tag_ratio }}</div>
          </div>
        </div>
        <div class="untagged" @click="$router.push('/contact/index')">还有{{coverageRate.noTag}}位客户未打标签 <span>></span></div>
      </div>
    </div>
    <div class="tab">
      <van-tabs v-model="active" color="#3974C6" title-active-color="#3974C6">
        <van-tab title="营销数据">
          <MarketData />
        </van-tab>
        <van-tab title="客户数据" v-if="isStringInArray('system:ofbnr')"><ContactData :emitmessage="parentMessage1" /></van-tab>
        <van-tab title="客户群数据" v-if="isStringInArray('system:ofbnr')"><ContactData :emitmessage="parentMessage2" /></van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import MarketData from "./components/marketData";
import ContactData from "./components/contactData";
import { tagData } from "@/api/dataBurialPoint";
import store from "@/store";
export default {
  components: { MarketData, ContactData },
  data() {
    return {
      active: 0,
      coverageRate:{},
      parentMessage1:1,
      parentMessage2:2,
    };
  },
  created(){
    this.getData()
  },
  methods:{
     isStringInArray(str) {
      const actionList = JSON.parse(localStorage.getItem('SET_INIT_PERMISSION'));
      return actionList.includes(str);
      // return store.getters.permission.includes(str);
    },
    getData(){
        tagData().then(res=>{
            this.coverageRate = res.data
        })
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .van-tabs__nav--line {
  padding-bottom: 0;
}

/deep/ .van-tabs__line {
  bottom: 0;
}

.top {
  height: 170px;
  background: #3975c6;

  .top_content {
    overflow: hidden;

    .totalNum {
      font-family: PingFang-SC-Bold, PingFang-SC;
      margin-top: 17px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
    }

    .tagProbability {
      display: flex;
      margin-top: 17px;

      .flex_box {
        width: 33.3%;
        text-align: center;

        .m17 {
          font-size: 11px;
          color: #ffffff;
        }

        .probability {
          height: 24px;
          font-size: 15px;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
        }
      }
    }

    .untagged {
      margin-left: 3%;
      margin-top: 17px;
      font-size: 11px;
      color: #ffffff;
    }
  }
}

.tab {
  height: 47px;
}
</style>