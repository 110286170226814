<template>
    <div class="index">
      <div class="banxin">
        <h5>{{ detail.name }}</h5>
        <div class="top">
          <div class="left">
            &nbsp;&nbsp;阅读 {{ detail.viewNum }}
          </div>
          <div class="right">
            {{ detail.createdAt }}
          </div>
        </div>
        <div style="margin-top: 8px; width: 100%; font-size: 14px; line-height: 18px" v-html="detail.content">
        </div>
      </div>
    </div>
  </template>
  <script>
import { getRichDetail } from "../../../api/circle";
    export default {
      data() {
        return {
            detail:{}
        }
      },
      created() {
        this.getData();

      },
      methods: {
        getData() {
      getRichDetail({ id: this.$route.query.id }).then((res) => {

          this.detail = res.data;

      });
    },
      }
    }
  </script>
  <style lang="less" scoped>
    .index{
        padding-top: 15px;
    }
    .banxin {
      width: 94%;
      margin: 0 auto;
    }

    h5 {
      margin: 0px 10px 10px 10px;
      min-height: 32px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #000000;
      line-height: 24px;
      text-align: center;
    }

    .top {
      display: flex;
      justify-content: space-between;
      height: 19px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #717174;
      line-height: 19px;
    }

    .left {
      width: 56%;
    }

    /deep/ img {
      max-width: 100% !important;
    }
    /deep/ .ql-align-center{
      text-align: center;
    }
  </style>