<template>
    <div>
        <div class="top">
            <van-search shape="round" v-model="searchVal" @search="searchDraw" placeholder="请输入姓名或者手机号" />
        </div>
        <div class="filter">
            <div class="filterLeft">共 {{ total }} 个结果</div>
            <div class="filterRight" @click="$refs.fit.$show()">
                <img src="../../../assets/filter.png" class="icon" alt="" /><span>筛选</span>
            </div>
        </div>
        <div class="content">
            <div v-if="creditData.length>0">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div class="box" v-for="(item,index) in creditData" :key="index"
                        :style="index > 0 ? { 'margin-top': '10px' } : {}" @click="goPage(item)">
                        <div class="heard">
                            <div class="title">{{item.name}}</div>
                            <div class="heard-right" @click.stop="playPhone(item.phone)">
                                <span>{{item.phone}}</span>
                                <img src="../../../assets/credit/call.png" alt="">
                            </div>
                        </div>
                        <div class="infoBox">
                            <div class="left">
                                <div>所属团场连队：{{item.teamOrFarmId}}</div>
                                <div>种植类目1:{{item.businessCategory.length>0?item.businessCategory[0].businessCategory:'无'}}</div>
                                <div>进件时间：{{item.createdAt}}</div>
                            </div>
                            <img class="rightIcon" src="../../../assets/credit/jiantou.png" alt="">
                        </div>

                    </div>
                </van-list>
            </div>
            <div v-else>
                <van-empty description="暂无数据" />
            </div>
        </div>

        <Moment ref="fit" @filterData="getFliterData" />
    </div>
</template>
<script>
    import Moment from "./filter.vue";
    import { getCredit } from "@/api/credit";
    export default {
        components: { Moment },
        data() {
            return {
                searchVal: '',
                total: 0,
                creditData: [],
                loading: false, // 是否处在加载状态
                finished: false, // 是否已加载完成
                page: 1, // 分页
                filterParam: {},
            }
        },
        created() {
            this.getList()
        },
        methods: {
            onLoad() {
                this.page++;
                this.getList();
            },
            getList() {
                let params = {
                    page: this.page,
                    perPage: 10,
                    ...this.filterParam,
                    searchStr:this.searchVal
                }
                getCredit(params).then((res) => {
                    this.creditData.push(...res.data.list);
                    this.total = res.data.page.total
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if (this.creditData.length >= res.data.page.total) {
                        this.finished = true;
                    } else {
                        this.finished = false;
                    }
                })
            },
            //搜索事件
            searchDraw() {
                this.creditData=[]
             this.getList()
            },
            //筛选结果
            getFliterData(e) {
                let newData=JSON.parse(JSON.stringify(e))//避免直接修改原数据
                newData.categoryName=newData.merchantType.concat(newData.reviewStatus)
                delete newData['merchantType']
                delete newData['reviewStatus']
                this.filterParam = newData;
                this.creditData = [];
                this.finished = false;
                this.page = 1;
                this.getList();

            },
            playPhone(phoneNum) {
                window.location.href = `tel:${phoneNum}`;
            },
            //前往详情
            goPage(item) {
                this.$router.push({
                    path: "/credit/detail",
                    query: {
                        id: item.id,
                    },
                });
            }
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-search {
        width: 100%;
        padding: 16px 16px 0px 16px;
        background-color: #FFFFFF;
        border-top: 1px solid #E8E8E8;
    }

    /deep/ .van-search__content {
        background-color: #F3F4F8;
        opacity: 0.51;
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        background: #FFFFFF;
        /* border-bottom: 1px solid #f1f1f1; */

        .right {
            img {
                margin-right: 16px;
                width: 25px;
                height: 25px;
            }
        }
    }

    .filter {
        width: 100%;
        height: 49px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;

        .filterLeft {
            margin-left: 4%;
            font-size: 12px;
            color: #a5a5a5;
        }

        .filterRight {
            margin-right: 4%;
            font-size: 12px;
            color: #515151;

            .icon {
                width: 11px;
                height: 11px;
                margin-right: 6px;
                margin-top: -2px;
            }
        }
    }

    .content {
        padding: 10px 12px;

        .box {
            padding: 17px;
            background: #FFFFFF;
            border-radius: 5px;

            .heard {
                display: flex;
                justify-content: space-between;

                .title {
                    font-weight: bold;
                    font-size: 17px;
                    color: #000000;
                }

                .heard-right {
                    display: flex;
                    align-items: center;

                    span {
                        font-weight: 500;
                        font-size: 12px;
                        color: #3975C6;
                    }

                    img {
                        width: 7px;
                        height: 9px;
                        margin-left: 5px;
                    }

                }
            }

            .infoBox {
                margin-top: 15px;
                border-top: 1px solid #F1F1F1;
                padding: 8px 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {

                    div {
                        font-weight: 400;
                        font-size: 14px;
                        color: #9B9B9B;
                        margin: 4px;
                    }
                }

                .rightIcon {
                    width: 6px;
                    height: 10px;
                }
            }
        }
    }
</style>