<template>
    <div class="searchPage">
        <div class="head">
            <!-- @change="handleChange" -->
            <input type="text"  @input="handleChange"  placeholder="请输入关键字" />
            <div class="icon"> <img src="../../assets/sousuoxiao@2x.png" /></div>
        </div>
        <div class="content">
            <div v-for="(item,index) in resultData" :key="index">
                <div class="content_box" @click="backPage(item)">
                    <div class="title" v-html="item.helpName"></div>
                    <img src="../../assets/more.png">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getDetail } from "@/api/helpCenter";
    export default {
        components: {
        },
        data() {
            return {
                resultData: [],
                value: ''
            }
        },
        methods: {
            openCata(index) {
                console.log(index)
            },
            backPage(data) {
                //传递手册ID
                localStorage.setItem("eventCategory", data.helpCategoryId);
                this.$router.back();
            },
            //搜索事件
            handleChange(e) {
                this.value = e.target.value;
                if (e.target.value == '') {
                    this.resultData = []
                    return
                }
                this.getDetailData(e.target.value)
            },
            getDetailData(str) {
                getDetail({ searchStr: str }).then((res) => {
                    let newData = res.data.map((item) => {
                        const reg = new RegExp(this.value, "ig");
                        item.helpName = item.helpName.replace(reg, (val) => `<span style='color:#2B60DD;font-weight: bold'>${val}</span>`)
                        return item
                    });
                    this.resultData = newData
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    input:-moz-placeholder,
    textarea:-moz-placeholder {
        color: #D8D8D8;
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        color: #D8D8D8;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: #D8D8D8;
    }

    .searchPage {
        width: 100%;
        height: 100%;
        background: #F8F9FB;

        .head {
            width: 100%;
            display: inline-flex;
            align-items: center;
            height: 62px;
            background: #FFFFFF;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
            padding: 13px 14px;
            border-bottom: 1px solid #D8D8D8;

            input {
                width: 90%;
                height: 37px;
                background: #F9F9F9;
                border-radius: 21px;
                border: none;
                outline: none;
                padding: 12px 16px;
                font-size: 14px;
                font-weight: 400;
                color: #000000;
            }

            .icon {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #F6F6FB;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 7px;

                img {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .content {
            width: 100%;
            height: auto;
            background: #FFFFFF;
            padding: 0 12px;

            :nth-child(n+2) {
                border-top: 1px solid #D8D8D8;
            }

            .content_box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 13px 19px 16px 13px;
                cursor: pointer;

                .title {
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;

                }

                img {
                    width: 6px;
                    height: 9px;
                }
            }
        }
    }
</style>