<template>
    <div>
        <Popup v-model:show="upStatePopup" position="bottom" closeable :close-on-click-overlay="false">
            <div class="title">黑名单详情</div>
            <Divider />
            <div class="userInfo" v-if="infoDetail.isBlack==1">
                <div class="info">
                    <img :src="infoDetail.avatar" alt="" class="avatar">
                    <div class="right">
                        <div class="name">{{ infoDetail.name }}</div>
                        <div class="tips">@微信</div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="item">
                    <div class="item_title">加入黑名单</div>
                    <div class="item_right">
                        <img src="../../../assets/gouxuan.png" alt="">
                        <span>加入</span>
                    </div>
                </div>
                <div class="item flexStart">
                    <div class="item_title">增加备注
                        <span class="tips">*</span>
                    </div>
                    <div class="item_right">
                        <van-field class="textarea" v-model="remark" rows="2" autosize type="textarea"
                            placeholder="请输入留言" show-word-limit />
                    </div>
                </div>
                <div class="item p10">
                    <div class="item_title"></div>
                    <div class="item_right">
                        <van-uploader v-model="fileList" multiple :after-read="afterReadImg" />
                    </div>
                </div>
                <div class="tips">注：可联系后台管理员解除黑名单</div>
                <van-button class="infoBtn" type="info" block @click="submit">确定加入黑名单</van-button>
            </div>
            <div class="userInfo" v-if="infoDetail.isBlack==2">
                <div class="info">
                    <img :src="infoDetail.avatar" alt="" class="avatar">
                    <div class="right">
                        <div class="name">{{ infoDetail.name }}</div>
                        <div class="tips">@微信</div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="item">
                    <div class="item_title">黑名单添加时间</div>
                    <div class="item_right">
                        <span>{{showDetail.created_at}}</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item_title">黑名单添加客服</div>
                    <div class="item_right">
                        <span>{{showDetail.employeeName}}</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item_title">备注</div>
                    <div class="item_right">
                        <span>{{showDetail.remark}}</span>
                    </div>
                </div>
                <div class="item p10">
                    <div class="item_title"></div>
                    <div class="item_right">
                        <van-uploader v-model="fileList" multiple :after-read="afterReadImg" />
                    </div>
                </div>
            </div>
        </Popup>
    </div>
</template>
<script>
    import { ContactFollowApi } from '@/api/contactFollow'
    import { joinBlack, blackShow } from "@/api/contact";
    import { Popup, Divider, Toast, Dialog } from 'vant'
    import { upload } from "@/api/contactWelcome";
    export default {
        components: {
            Popup, Divider, Toast
        },
        props: {
            infoDetail: {
                default: {},
                type: Object
            },
            contactId: {
                default: '',
                type: String
            }
        },
        data() {
            return {
                upStatePopup: false,
                optionList: [],
                selectKey: null,
                fileList: [],
                remark: '',
                images: [],
                showDetail: {}
            }
        },
        methods: {
            show(row) {
                this.images = []
                this.fileList = []
                this.upStatePopup = true
                if (row.isBlack == 2) {
                    blackShow({ contactId: row.contactId }).then((res) => {
                        if (res.code == 200) {
                            this.showDetail = res.data
                            if (res.data.images.length != 0) {
                                res.data.images.forEach(ele => {
                                    this.fileList.push({ url: ele })
                                });
                            }
                        }
                    });
                }
            },
            afterReadImg(file) {
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    this.images.push(res.data.path)
                });
            },
            submit() {
                Dialog.confirm({
                    message: '确定客户加入黑名单吗？ 加入后其他客户经理也可查看，并且客户无法参与活动。',
                    confirmButtonColor: '#3975C6'
                }).then(() => {
                    let params = {
                        contactId: this.contactId,
                        remark: this.remark,
                        images: this.images
                    }
                    joinBlack(params).then((res) => {
                        if (res.code == 200) {
                            Toast('设置成功')
                            this.upStatePopup = false
                            this.$emit('change')
                        }
                    })
                }).catch(() => {
                })
            }
        }
    }
</script>
<style scoped lang="less">
    .option_list {
        text-align: center;
        padding: 15px 0;
        color: #595959;
        cursor: pointer;
    }

    .option_list:hover {
        background: #EFFAFF;
    }

    .select_row {
        color: #319CFF;
        background: #EFFAFF;
    }

    .title {
        font-weight: bold;
        font-size: 15px;
        color: #000000;
        text-align: center;
        padding-top: 18px;
    }

    .userInfo {
        padding: 0 12px 19px 12px;

        .info {
            margin-left: 18px;
            display: flex;

            .avatar {
                width: 50px;
                height: 50px;
                border-radius: 8px;
            }

            .right {
                margin-left: 10px;

                .name {
                    font-weight: bold;
                    font-size: 14px;
                    color: #303030;
                }

                .tips {
                    font-weight: 400;
                    font-size: 12px;
                    color: #5FAD0B;
                    margin-top: 8px;
                }
            }
        }

        .line {
            margin-top: 19px;
            margin-bottom: 12px;
            width: 100%;
            height: 1px;
            background: rgba(6, 15, 26, 0.1);
        }

        .item {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;

            .item_title {
                font-weight: bold;
                font-size: 14px;
                color: #000000;

                .tips {
                    font-weight: bold;
                    font-size: 14px;
                    color: #D0021B;
                }
            }

            .item_right {
                img {
                    width: 16px;
                    height: 16px;
                }

                span {
                    margin-left: 10px;
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                }

                .textarea {
                    width: 238px;
                    background: #F6F6F6;
                    border-radius: 4px;
                }

            }
        }

        .flexStart {
            display: inline-flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        .p10 {
            padding: 0px;
        }

        .tips {
            margin-top: 100px;
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            color: #A5A5A5;
        }

        .infoBtn {
            margin: 20px 0px;
            background: #3974C6;
            border-radius: 5px;
            font-weight: bold;
            font-size: 15px;
            color: #FFFFFF;
        }

    }
</style>