<template>
  <div class="index">
    <div class="top">
      <div class="topContent">
        <div class="topRight">
          <van-search style="width: 100%;padding: 16px 16px 14px 0px;" background="#3975c6" shape="round" v-model="serchValue" @search="onSearch" @clear="clearSerch" placeholder="搜索" />
          <img class="add" @click="routerPush()" src="../../assets/add.png" alt="" />
        </div>
      </div>
    </div>
    <div class="tab">
      <div class="filter">
        <div class="filterLeft">共{{codeList.length}}个结果</div>
        <div class="filterRight" @click="$refs.grouping.$show()">
          <img src="../../assets/filter.png" class="icon" alt="" /><span>筛选</span>
        </div>
      </div>
      <div class="main">
        <div style="width: 94%; margin: 0 auto">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="mainList" v-for="(item,index) of codeList" :key="index" @click="detailItem(item)">
              <div class="content" style="width: 90%; margin: 0 auto">
                <div class="listTop">
                  <div class="name">
                    {{item.name}}
                  </div>
                  <div class="status" @click.stop="editCode(item)">
                    {{item.employees[0].name}}等{{item.employees.length}}人使用 >
                  </div>
                </div>
                <div class="cenBox">
                  <div class="cenFlex">
                    <div>{{ item.contactNum }}</div>
                    <div>{{ item.contactToDayNum||'0'}}</div>
                  </div>
                  <div class="tipsFelx">
                    <div>扫码添加客户数</div>
                    <div>今日扫码添加客户数</div>
                  </div>
                </div>
                <div class="m16Box">
                  <div class="m16">
                    {{item.createdAt}} 创建
                  </div>
                  <!-- <div class="imgBox" @click.stop="goStylePage()"> -->
                    <img src="../../assets/codeIcon.png" class="imgBox" @click.stop="goStylePage(item)" alt="" />
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
    <grouping ref="grouping" @change="groupDate" />
  </div>
</template>
<script>
 import grouping from "./components/grouping";
  import { channelCodeIndex, channelCodeRemove } from "../../api/channelCode";
  export default {
    components: {
      grouping,
    },
    data() {
      return {
           // 文字
           serchValue: "",
        message: "",
        // tbas切换
        tabsChose: 0,
        // 分组名称
        groupName: "",
        // 数据
        codeList: [],
        listParams: {
          page: 1,
          perPage: 10
        },
        finished: false,
        loading: false,
        refreshing: false,
      };
    },
    created() {
      // this.codeData();
    },
    methods: {
      onLoad() {
        this.listParams.page++;
        this.codeData()
      },
      onClickLeft() {
        this.$router.back();
      },
      routerPush() {
        this.$router.push("/channelCode/create");
      },
      // 搜索
      onSearch() {
        this.loading=true;
        this.codeData(this.serchValue);
        this.codeList=[]
      },
      clearSerch() {
        this.serchValue = "";
        this.codeData();
      },
      //清除
      clear() {
        this.groupName = "";
        this.codeData();
      },
      // 获取分组
      groupDate(data) {
        this.groupName = data;
        this.codeData();
      },
      // 获取数据
      codeData(name) {
        let params = {
          name,
          // ...this.listParams
        }
        channelCodeIndex(params).then((res) => {
          // this.codeList = res.data;
        if (res.code == 200) {
            // this.listInfo = res.data.page;
            this.codeList.push(...res.data);
            // 加载状态结束
            this.loading = false;
            // 数据全部加载完成
            // if (this.codeList.length >= res.data.page.total) {codeList
              this.finished = true;
            // } else {
            //   this.finished = false;
            // }
          }
        });
      },
  
      // 编辑
      editCode(item) {
        this.$router.push({
          path: "/channelCode/create",
          query: {
            id: item.channelCodeId,
          },
        });
      },
      // 詳情
      detailItem(i) {
        this.$router.push({
          path: "/channelCode/detail",
          query: {
            item: JSON.stringify(i),
          },
        });
      },
      //活码样式
      goStylePage(row){
        this.$router.push({
          path: "/channelCode/stylePage",
          query:{
             codeData:row
          }
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  /* /deep/ .van-search__content {
    background-color: #f7f8fa;
    opacity: 0.51;
  }

  /deep/ .van-tabs__nav--line {
    line-height: 48px;
    padding-bottom: 0;
  }

  /deep/ .van-tabs__line {
    bottom: 0;
  } */

  .index {
    height: 100%;
    font-size: 16px;
  }

  .top {
    width: 100%;
    height: 64px;
    background: #3975c6;

    .topContent {
      width: 94%;
      height: 64px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .topLeft {
      display: flex;
      align-items: center;

      .team {
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        margin-right: 6px;
      }

      .change {
        width: 14px;
        height: 11px;
      }
    }

    .topRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .inputText{
        width: 100%;
        padding: 16px 16px 14px 0px;
        background-color: #3975c6;
      
      }
    }

    .add {
      width: 25px;
      height: 25px;
    }
  }

  .tab {
    height: 48px;
    // line-height: 48px;
  }

  .filter {
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-top: 1px solid #e8e8e8;

    .filterLeft {
      margin-left: 4%;
      font-size: 12px;
      color: #a5a5a5;
    }

    .filterRight {
      margin-right: 4%;
      font-size: 12px;
      color: #515151;

      .icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
        margin-top: -2px;
      }
    }
  }

  .main {
    height: 100vh;
    background: #f2f3f7;
    padding-top: 20px;

    .mainList {
      width: 100%;
      padding: 0px 0 17px 0px;
      background: #ffffff;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .listTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      border-bottom: 1px solid #f1f1f1;

      .name {
        height: 56px;
        line-height: 56px;
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .status {
        font-size: 12px;
        font-weight: 400;
        color: #9B9B9B;
      }

      .completed {
        font-size: 12px;
        font-weight: 500;
        color: #a5a5a5;
      }
    }

    .cenBox {
      width: 100%;
      padding: 10px;
      background: #F2F3F7;
      border-radius: 4px;

      .cenFlex {
        display: flex;
        justify-content: space-around;

        div {
          font-size: 17px;
          font-weight: bold;
          color: #000000;
          line-height: normal;
        }
      }

      .tipsFelx {
        margin-top: 10px;
        display: flex;
        justify-content: space-around;

        div {
          font-size: 12px;
          font-weight: 400;
          color: #9B9B9B;
          line-height: normal;
        }
      }
    }

    .m16Box {
      margin-top: 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .m16 {


        font-size: 12px;
        color: #9b9b9b;
      }

      .imgBox {
        width: 33px;
        height: 33px;
        background: #E1E7F7;
        border-radius: 4px;
        border: 1px solid #DCE2F2;
        img{
          width: 33px;
        height: 33px;
        }
      }
    }
  }
</style>