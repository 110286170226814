<template>

    <div class="clockPage">
        <div class="heard">
            <div class="heardBox" @click="$refs.employee.$show(serviceList, 'serviceList')">
                <span>{{serviceList.length==0?'选择成员':serviceList[0].name}}</span>
                <img src="../../assets/images/clockIn/select.png" alt="">
            </div>
            <div class="heardBox" @click="popupShow=true">
                <span> {{currentDate==''?'请选择时间':currentDate}}</span>
                <img src="../../assets/images/clockIn/select.png" alt="">
            </div>
        </div>
        <div class="content">
            <van-empty description="暂无数据" v-if="listData.length==0" />
            <van-list v-else v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div v-for="(item,index) in listData" :key="index">
                    <div class="listBox" @click="goDetail(item)">
                        <div class="boxleft">
                            <img src="../../assets/images/clockIn/notice.png" alt="">
                        </div>
                        <div class="boxright">
                            <div class="rightHeard">
                                <div>{{item.clockTime}}{{item.employee_name}}外拓打卡</div>
                            </div>
                            <div class="clockAddress">
                                <div class="clock1">打卡地点：</div>
                                <div class="clock2">{{item.addr}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <div class="flexBom">
            <div class="btmBox" @click="goPage()">外拓打卡</div>
        </div>
        <!--  员工  -->
        <employeeIndex ref="employee" @change="userChange" />
        <van-popup v-model="popupShow" round position="bottom" :style="{ height: '50%' }">
            <van-datetime-picker v-model="selectedDate" type="datetime" title="请选择时间" :min-date="minDate"
                :max-date="maxDate" @confirm="onConfirm" @cancel="show = false" />
        </van-popup>
    </div>
</template>
<script>
    import employeeIndex from "./components/employeeIndex";
    import { getClockInList } from "@/api/externalClock";
    export default {
        components: {
            employeeIndex,
        },
        data() {
            return {
                serviceList: [],
                popupShow: false,
                minDate: new Date(2022, 0, 1),
                maxDate: new Date(2050, 10, 1),
                currentDate: '',
                selectedDate: new Date(),
                listData: [],
                endTime: undefined,
                finished: false,
                loading: false,
                listParams: {
                    page: 0,
                    perPage: 10
                },
            }
        },
        created() {
            this.listParams.page++;
            this.getData(this.listParams)
        },
        mounted() {
        },
        methods: {
            onLoad() {
                this.listParams.page++;
                this.getData(this.listParams)
            },
            async getData(row) {
                try {
                    let params = {
                        employee_id: this.serviceList.length == 0 ? undefined : this.serviceList[0].id,
                        startTime: this.currentDate == '' ? undefined : this.currentDate,
                        endTime: this.endTime,
                        ...row
                    }
                    const { code, data } = await getClockInList(params)
                    if (code == 200) {
                        this.listData.push(...data.list);
                        // 加载状态结束
                        this.loading = false;
                        // 数据全部加载完成
                        if (this.listData.length >= data.page.total) {
                            this.finished = true;
                        } else {
                            this.finished = false;
                        }
                    }
                } catch (error) { }
            },
            async goPage() {
                let isMobile = await this.isMobileDevice()
                if (isMobile) {
                    this.$router.push({ path: '/externalClock/createClock' })
                } else {
                    this.$message.warning("请使用移动端进行外拓打卡服务");
                }
            },
            // 判断是否为移动端
            isMobileDevice() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            goDetail(row) {
                this.$router.push({ path: '/externalClock/clockDetail?detail=' + JSON.stringify(row) })
            },
            // 选择员工
            userChange(data, info) {
                this.listData=[]
                this.serviceList = data;
                this.getData()
            },
            //时间筛选
            onConfirm() {
                this.listData=[]
                this.popupShow = false;
                this.currentDate = this.formatter(this.selectedDate);
                this.getData()
            },
            formatter(date) {
                const year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let hour = date.getHours();
                let minutes = date.getMinutes();
                if (month < 10) {
                    month = "0" + month;
                }
                if (day < 10) {
                    day = "0" + day;
                }
                if (hour < 10) {
                    hour = "0" + hour;
                }
                if (minutes < 10) {
                    minutes = "0" + minutes;
                }
                let second = '00'
                this.endTime = `${year}-${month}-${day} 23:59:59`
                return `${year}-${month}-${day} ${hour}:${minutes}:${second}`;
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-cell {
        font-size: 16px;
    }

    /deep/ .van-switch__node {
        top: -1.98px;
    }

    /deep/ .van-cell__title {
        display: contents;
    }

    .clockPage {
        width: 100%;
        height: 100%;
        background: #F2F3F7;
        font-size: 16px;
        .heard {
            position: fixed;
            top: 0px;
            left: 0;
            width: 100%;
            height: 57px;
            background: #FFFFFF;
            box-shadow: 0px 1px 2px 0px rgba(195, 195, 195, 0.26);
            padding: 12px;
            display: inline-flex;
            justify-content: space-between;
            .heardBox {
                width: 49%;
                height: 31px;
                background: #F2F3F7;
                border-radius: 2px;
                padding: 0px 10px;
                border: 0px solid #BBBBBB;
                display: flex;
                align-items: center;
                justify-content: space-between;


                span {
                    font-size: 15px;
                    font-weight: 400;
                    color: #999999;

                }

                img {
                    width: 10px;
                    height: 15px;
                }
            }
        }

        .content {
            width: 100%;
            // height: calc(100% - 57px);
            background: #F2F3F7;
            padding: 0px 12px;
            padding-top: 57px;
            padding-bottom: 120px;

            .listBox {
                width: 100%;
                background: #FFFFFF;
                border-radius: 5px;
                padding: 10px 16px;
                display: flex;
                align-items: center;
                margin-top: 12px;

                .boxleft img {
                    width: 25px;
                    height: 21px;
                }

                .boxright {
                    width: calc(100% - 41px);
                    margin-left: 17px;
                    line-height: 0;

                    .rightHeard {
                        display: flex;
                        // justify-content: space-between;
                        align-items: center;
                        font-size: 14px;
                        font-weight: bold;
                        color: #000000;
                        line-height: 22px;
                        padding-bottom: 6px;
                        border-bottom: 1px solid #F1F1F1;
                    }

                    .clockAddress {
                        margin-top: 7px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #9B9B9B;
                        line-height: 17px;
                        display: inline-flex;
                        width: 100%;

                        .clock1 {
                            width: 22%;
                        }

                        .clock2 {
                            width: 78%;
                        }
                    }
                }
            }
        }

        .flexBom {
            width: 100%;
            position: fixed;
            left: 0;
            bottom: 0;
            height: 106px;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
            padding: 16px;

            .btmBox {
                width: 100%;
                height: 48px;
                background: #3974C6;
                border-radius: 5px;
                font-size: 17px;
                font-weight: bold;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>