<template>
  <div class="index">
    <div class="header">
      <div class="title">{{ detail.name }}</div>
      <div class="content" v-html="detail.content"></div>
    </div>
  </div>
</template>
<script>
import { activityDetail, vcardIndex } from "@/api/electronicCard";
import { shareMyData } from "@/utils/wxCodeAuth";

export default {
  data() {
    return {
      detail: "",
      shareUrl: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getUserInfo() {
      vcardIndex().then((res) => {
        console.log("res,", res);
        const data = res.data;
        const shareData = {
          title: `${data.employee.departmentName}金融管家：${data.employee.name}为您服务！`,
          description: data.summary,
          imgUrl: data.employee.fullImage,
          url: this.shareUrl,
        };
        console.log("fenxiang", shareData);
        shareMyData(shareData);
      });
    },
    getData() {
      const id = this.$route.query.id;
      activityDetail({ id }).then((res) => {
        //雷达或链接类型
        if (res.data.type == 2 || res.data.type == 3) {
          window.location.href = res.data.url;
          return;
        } else {
          this.detail = res.data;
          this.shareUrl = res.data.shareUrl;
          this.getUserInfo();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 94%;
  margin: 0 auto;
  overflow: auto;
  .title {
    text-align: center;
    width: 100%;
    height: 35px;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    margin-top: 20px;
  }
  .content {
    font-size: 14px;
    color: #4a4a4a;
    line-height: 20px;
  }
}
/deep/ img {
  max-width: 100% !important;
}
</style>
  