-<template>
  <div class="create">
    <div class="banxin">
      <div class="top">
        <van-field
          v-model="taskName"
          label="任务名称"
          placeholder="请输入任务名称"
        />
        <div v-if="selectType == 'toCustom' || selectType == 'toCircle'">
          <div class="filed">
            <div class="top_filed">选择群发账号</div>
            <div class="input">
              <selectEmployee @change="getCount" ref="groups" />
            </div>
          </div>
          <!-- <div class="filed">
            <div class="top_filed">按标签筛选发送对象</div>
            <div class="input">
              <selectTag ref="tags" />
            </div>
          </div> -->
          <van-field
            v-model="filterMark"
            label="发送范围"
            placeholder="请选择"
            right-icon="arrow"
            readonly
            @click="showfilter"
          />
        </div>
        <div v-else>
          <div class="filed">
            <div class="top_filed">选择群主</div>
            <div class="input">
              <selectEmployee @change="getCount" ref="groups" />
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <van-field
          class="myfiled"
          v-model="message"
          rows="5"
          autosize
          label="发送内容"
          type="textarea"
          maxlength="1000"
          placeholder="可发送产品信息、节日关怀、优惠活动等内容"
          show-word-limit
        />
        <div class="select" @click="selectMaterial">
          <img class="icon" src="@/assets/images/qunfa/add2.png" alt="" />
          <span class="medium">选择素材</span>
        </div>
        <div v-if="selectMetedata && selectMetedata.length != 0">
          <div
            class="filesitem"
            v-for="(v, index) in selectMetedata"
            :key="index"
          >
            <div class="filesleft">
              【{{ v.typeName ? v.typeName : v.type }}】：{{
                v.content ? v.content.title : v.title
              }}
            </div>
            <div class="filesright fz16">
              <a-icon
                type="delete"
                class="pointer"
                @click="deleteMaterial(v, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 88px"></div>
    <div class="last">
      <div class="cancel" @click="$router.go(-1)">取消</div>
      <div class="timer" @click="(show = true), (sendWay = 2)">定时发送</div>
      <div class="imme" @click="sendNow">立即发送</div>
    </div>

    <van-action-sheet v-model="show">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-action-sheet>
    <van-dialog
      v-model="showPopup"
      @confirm="onSubmit"
      show-cancel-button
      confirmButtonColor="#3974C6"
    >
      <div class="popupContent">确定创建任务</div>
      <div v-show="sendWay == 2" class="popupTip">
        预计{{ this.time }}由员工发送给客户
      </div>
    </van-dialog>
    <!-- 筛选发送对象 -->
    <selectRange ref="rangeChild2" @filterData="filterData"></selectRange>
    <!-- 素材库 -->
    <van-popup v-model="matePop" position="bottom" :style="{ height: '100%' }">
      <matePopup
        ref="mateChild"
        @closePop="closePop"
        @popSubmit="popSubmit"
        :key="datekey"
      ></matePopup>
    </van-popup>
    <van-popup
      v-model="mateSele"
      round
      position="bottom"
      closeable
      :style="{ height: '50%', background: '#FFFFFF' }"
    >
      <popPage
        ref="matebpx"
        @popBtn="popBtn"
        @imagesData2="imagesData2"
      ></popPage>
    </van-popup>
  </div>
</template>
  <script>
import selectEmployee from "@/components/select/employee/select";
import selectTag from "@/components/select/tag/select";
import matePopup from "@/components/select/material/index";
import popPage from "@/components/select/material/popPage";

import { createQunfa, createkehuqun, createQunfaCircle } from "@/api/qunfa";
import selectRange from "./selectRange";
export default {
  components: {
    selectEmployee,
    selectTag,
    matePopup,
    selectRange,
    popPage,
  },
  data() {
    return {
      filterMark: "",
      time: "",
      taskName: "",
      custom: "",
      message: "",
      show: false,
      currentDate: new Date(),
      showPopup: false,
      employeeIds: [],
      selectType: "",
      sendWay: "",
      selectMetedata: [], //已选素材库
      currentGroup: "text", //当前选择
      selectMateIndex: 0, //当前内容选项
      datekey: Date.now(),
      filterParams: "",
      mateSele: false,
      matePop: false,
    };
  },
  created() {
    this.selectType = this.$route.query.type;
  },
  methods: {
    //素材库选项
    popBtn(row) {
      this.matePop = true;
      this.mateSele = false;
      this.datekey = Date.now(); //刷新子组件
      this.$nextTick(() => {
        this.$refs.mateChild.selectPop = true; //修改子组件data
        this.$refs.mateChild.currentID = row.id; //修改子组件变量
        this.$refs.mateChild.activeName = row.id; //修改子组件选中tab
        (this.$refs.mateChild.typeOption = [
          { name: "所有", id: "" },
          { name: "文本", id: 1 },
          { name: "图片", id: 2 },
          { name: "图文", id: 3 },
          { name: "视频", id: 5 },
          { name: "小程序", id: 6 },
          { name: "文件", id: 7 },
        ]),
          (this.$refs.mateChild.oldSelectData =
            this.ruleList[this.currentrItem].content[1].childMediumData || []); //传递已选择数据
      });
    },
    //子组件图片数据
    imagesData2(data) {
      console.log("dataaaa", data);
      this.selectMetedata.push({
        content: {
          title: "本地图片",
          imageFullPath: data.url,
          imagePath: data.path,
        },
        type: "图片",
      });
      this.mateSele = false;
    },
    showfilter() {
      this.$refs.rangeChild2.show();
    },
    filterData(data, mark) {
      console.log("ddd", data);
      if (mark == 1) {
        this.filterMark = "全部客户";
        this.filterParams = {};
      } else {
        this.filterMark = "筛选客户";
        this.filterParams = data;
      }
    },
    //选择素材
    selectMaterial() {
      this.mateSele = true;
      this.$nextTick(() => {
        this.$refs.matebpx.deltelocalfun([2, 3, 4, 5, 6]);
      });
      this.$nextTick(() => {
        this.$refs.matebpx.deltefun([4, 8]);
      });
      if (this.$route.query.type == "toCircle") {
        this.$nextTick(() => {
          this.$refs.matebpx.deltefun([6, 7]);
        });
      }
    },
    //关闭弹窗
    closePop() {
      this.matePop = false;
    },
    //素材库选择确认
    popSubmit(data) {
      console.log("素材库", data);
      this.matePop = false;
      if (data.length > 9) {
        this.$message.error("最多添加9个素材!");
        return;
      }
      // this.conList[this.selectMateIndex].selectMetedata = data;
      this.selectMetedata = data;
    },
    //素材删除
    deleteMaterial(v, index) {
      // index子下标
      this.selectMetedata.splice(index, 1);
      let newData = this.selectMetedata[index];
      this.$delete(this.selectMetedata[index], "selectMetedata");
      this.$set(this.selectMetedata[index], "selectMetedata", newData);
    },
    validate() {
      if (this.taskName == "") {
        this.$message.error("请输入名称");
        return false;
      } else if (this.employeeIds.length == 0) {
        this.$message.error("请选择群发账号");
        return false;
      } else if (this.textContent == "") {
        this.$message.error("请输入发送内容");
        return false;
      }
    },
    //确认创建
    async onSubmit() {
      //立即发送
      if (this.sendWay == 1) {
        this.time = this.formatter(new Date());
      }
      const contentJSon = [];
      let msgArray = {};
      if (this.message !== "") {
        msgArray = {
          msgType: "text",
          content: this.message,
        };
        contentJSon.push(msgArray);
      }
      this.selectMetedata.map((res) => {
        if (res.type === "文本" || res.type == "") {
          msgArray = {
            msgType: "text",
            content: res.content.content,
          };
          contentJSon.push(msgArray);
        } else if (res.type === "图片") {
          msgArray = {
            msgType: "image",
            media_id: res.mediaId,
            pic_url: res.content.imageFullPath,
            imagePath: res.content.imagePath,
            id: res.id,
          };
          contentJSon.push(msgArray);
        } else if (res.type === "图文") {
          msgArray = {
            msgType: "link",
            title: res.content.TextTitle,
            desc: res.content.description,
            url: res.content.imageLink,
            picurl: res.content.imageFullPath,
            media_id: res.mediaId,
            imagePath: res.content.imagePath,
            id: res.id,
          };
          contentJSon.push(msgArray);
        } else if (res.type === "视频" || res.type === "音频") {
          msgArray = {
            msgType: "video",
            title: res.content.title,
            pic_url: res.content.voiceFullPath
              ? res.content.voiceFullPath
              : res.content.videoFullPath,
            media_id: res.mediaId,
            desc: res.content.fileTitle,
            url: res.content.voicePath,
            id: res.id,
          };
          contentJSon.push(msgArray);
        } else if (res.type === "小程序") {
          msgArray = {
            msgType: "miniprogram",
            title: res.content.title,
            pic_media_id: res.content.imageFullPath,
            appid: res.content.appid,
            page: res.content.page,
          };
          contentJSon.push(msgArray);
        } else if (res.type === "文件") {
          msgArray = {
            msgType: "file",
            title: res.content.fileName,
            pic_url: res.content.fileFullPath,
            media_id: res.mediaId,
            desc: res.content.fileTitle,
            id: res.id,
          };
          contentJSon.push(msgArray);
        }
      });
      //创建客户群发
      if (this.selectType == "toCustom") {
        console.log("kkkk", contentJSon);
        createQunfa({
          name: this.taskName,
          employeeIds: this.employeeIds,
          filterParams: JSON.stringify(this.filterParams),
          textContent: this.message,
          definiteTime: this.time,
          type: 1,
          sendWay: this.sendWay, //1代表立即发送 2代表定时发送
          content: JSON.stringify(contentJSon),
          sendType: 1, //员工一键发送
        }).then((res) => {
          console.log("res", res);
          this.$message.success("创建成功");
          this.$router.push({ path: "/qunfa/index" });
        });
      }

      // 创建客户群群发
      if (this.selectType == "tokehuqun") {
        createkehuqun({
          batchTitle: this.taskName,
          employeeIds: this.employeeIds,
          textContent: this.message,
          definiteTime: this.time,
          type: 2,
          sendWay: this.sendWay, //1代表立即发送 2代表定时发送
          content: JSON.stringify(contentJSon),
          sendType: 1, //员工一键发送
        }).then((res) => {
          console.log("res", res);
          this.$message.success("创建成功");
          this.$router.push({ path: "/qunfa/index" });
        });
      }
      //创建朋友圈群发
      if (this.selectType == "toCircle") {
        console.log("ddd");
        createQunfaCircle({
          name: this.taskName,
          employeeIds: this.employeeIds,
          filterParams: JSON.stringify(this.filterParams),
          textContent: this.message,
          definiteTime: this.time,
          type: 3,
          sendWay: this.sendWay, //1代表立即发送 2代表定时发送
          content: JSON.stringify(contentJSon),
          sendType: 1, //员工一键发送
        }).then((res) => {
          console.log("res", res);
          this.$message.success("创建成功");
          this.$router.push({ path: "/qunfa/index" });
        });
      }
    },
    // 获取员工Id
    getCount() {
      this.employeeIds = this.$refs.groups.get().map((v) => {
        return v.id;
      });
      console.log("employeeIds", this.employeeIds);
    },
    async sendNow() {
      let judgeData = await this.validate();
      if (judgeData == false) return;
      console.log("retts");
      this.sendWay = 1;
      this.showPopup = true;
    },
    async onConfirm() {
      this.show = false;
      console.log("ddd", this.currentDate);
      this.time = this.formatter(this.currentDate);
      console.log("dddss", this.time);
      let judgeData = await this.validate();
      if (judgeData == false) return;
      this.showPopup = true;
    },
    formatter(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return `${year}-${month}-${day} ${hour}:${minutes}`;
    },
  },
};
</script>
  <style lang="less" scoped>
/deep/ .van-field__label {
  height: 80px;
  color: #515151;
}
/deep/ .van-cell {
  display: block;
  border-radius: 5px;
}
/deep/ .van-cell__title {
  height: 40px !important;
}
/deep/ .myfiled .van-field__value {
  height: 163px !important;
  background: #f2f3f7 !important;
  border-radius: 5px;
  padding: 10px !important;
}
.create {
  width: 100%;
  height: 100%;
  background: #f2f3f7;
  .banxin {
    width: 94%;
    margin: 0 auto;
  }
}
.filed {
  width: 100%;
  min-height: 83px;
  background-color: #fff;
  .top_filed {
    width: 92%;
    margin: 0 auto;
    padding-top: 10px;
    margin-bottom: 12px;
    height: 20px;
    font-size: 14px;
    color: #515151;
    line-height: 20px;
  }
  .input {
    display: flex;
    width: 92%;
    margin: 0 auto;
  }
}
.top {
  margin-top: 13px;
}
.middle {
  margin-top: 13px;
  min-height: 283px;
  background: #ffffff;
  border-radius: 5px;
}
.select {
  margin-top: 14px;
  margin-left: 16px;
  height: 20px;
  display: flex;
  align-items: center;
  .icon {
    width: 12px;
    height: 12px;
    margin-top: 1px;
  }
  .medium {
    margin-left: 10px;
    font-size: 15px;
    color: #515151;
  }
}
.bottom {
  margin-top: 13px;
}
.last {
  position: fixed;
  bottom: 0px;
  margin-top: 13px;
  display: flex;
  align-items: center;
  height: 82px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
  text-align: center;
  .cancel {
    height: 23px;
    font-size: 16px;
    font-weight: 500;
    color: #595a5e;
    margin-right: 20px;
    margin-left: 28px;
  }
  .timer {
    width: 134px;
    height: 46px;
    line-height: 46px;
    background: #f1f2f6;
    border-radius: 5px;
    border: 1px solid #d7d7d9;
    font-size: 16px;
    font-weight: 500;
    color: #595a5e;
    margin-right: 10px;
  }
  .imme {
    width: 134px;
    height: 46px;
    line-height: 46px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
}
.popupContent {
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
}
.popupTip {
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
}
.filesitem {
  width: 96%;
  margin: 0 auto;
  font-size: 14px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filesleft {
    color: #565656;
  }

  &:last-child {
    padding-bottom: 12px;
  }
}
</style>