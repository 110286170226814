<template>
  <div class="statistics">
    <!-- 客户群的个人详情 -->
    <div class="main" v-if="$route.query.type=='tokehuqun'">
      <div class="taskDetail">
        <div class="taskTop">完成概括</div>
        <div class="chart-container" id="myChart2" ref="myChart2"></div>
        <div class="rate">
          <div class="rate2">{{ rate * 100 }}%</div>
          <div class="rota">送达率</div>
        </div>
        <div class="text" style="margin-top: 40px">
          <div class="m17" style="border-bottom: 1px solid #e8e8e8">
            <div class="custom">预计送达客户群</div>
            <div class="num">{{ totalList.length }}</div>
          </div>
          <div class="m17">
            <div class="custom">未送达客户群</div>
            <div class="num">{{ notSendList.length }}</div>
          </div>
          <div class="m17">
            <div class="custom">已送达客户群</div>
            <div class="num">{{ hasSendList.length }}</div>
          </div>
        </div>
      </div>
      <div class="dataDetail">
        <div class="taskTop">数据详情</div>
        <div class="status">
          <div
            class="m19"
            :class="[spanIndex2 == 0 ? 'active' : '']"
            @click="selectStatus2(0)"
          >
            未送达
          </div>
          <div
            class="m19"
            :class="[spanIndex2 == 1 ? 'active' : '']"
            @click="selectStatus2(1)"
          >
            已送达
          </div>
          <div
            class="m19"
            :class="[spanIndex2 == 2 ? 'active' : '']"
            @click="selectStatus2(2)"
          >
            送达失败
          </div>
        </div>
      </div>
      <div class="members">
        <div class="m20">客户群名称</div>
        <div class="m20" v-if="spanIndex2 == 1">送达时间</div>
      </div>
      <div class="membersData">
        <div style="width: 94%; margin: 0 auto">
          <div
            class="membersDetail"
            v-for="(item, index) in employeeList"
            :key="index"
          >
            <div class="left">
              <img src="../../../assets/room.svg" alt="" />
              <span> {{ item.roomName }}</span>
            </div>
            <div class="right" v-if="spanIndex2 == 1">
              {{ item.sendTime }} >
            </div>
            <div class="right" v-else> ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户和客户朋友圈的个人详情 -->
    <div class="main" v-else>
      <div class="taskDetail">
        <div class="taskTop">完成概括</div>
        <div class="chart-container" id="myChart2" ref="myChart2"></div>
        <div class="rate">
          <div class="rate2">{{ rate * 100 }}%</div>
          <div class="rota">送达率</div>
        </div>
        <div class="text" style="margin-top: 40px">
          <div class="m17" style="border-bottom: 1px solid #e8e8e8">
            <div class="custom">预计送达客户</div>
            <div class="num">{{ totalList.length }}</div>
          </div>
          <div class="m17">
            <div class="custom">未送达客户</div>
            <div class="num">{{ notSendList.length }}</div>
          </div>
          <div class="m17">
            <div class="custom">已送达客户</div>
            <div class="num">{{ hasSendList.length }}</div>
          </div>
        </div>
      </div>
      <div class="dataDetail">
        <div class="taskTop">数据详情</div>
        <div class="status">
          <div
            class="m19"
            :class="[spanIndex2 == 0 ? 'active' : '']"
            @click="selectStatus2(0)"
          >
            未送达
          </div>
          <div
            class="m19"
            :class="[spanIndex2 == 1 ? 'active' : '']"
            @click="selectStatus2(1)"
          >
            已送达
          </div>
          <div
            class="m19"
            :class="[spanIndex2 == 2 ? 'active' : '']"
            @click="selectStatus2(2)"
          >
            不是好友
          </div>
          <div
            class="m19"
            style="width: 81px"
            :class="[spanIndex2 == 3 ? 'active' : '']"
            @click="selectStatus2(3)"
          >
            接收上限
          </div>
        </div>
      </div>
      <div class="members">
        <div class="m20">客户</div>
        <div class="m20" v-if="spanIndex2 == 1">送达时间</div>
      </div>
      <div class="membersData">
        <div style="width: 94%; margin: 0 auto">
          <div
            class="membersDetail"
            v-for="(item, index) in employeeList"
            :key="index"
          >
            <div class="left" @click="skipContactDetail(item)">
              <img :src="item.contact.avatar" alt="" />
              <span> {{ item.contact.name }}</span>
            </div>
            <div class="right" v-if="spanIndex2 == 1" @click="skipContactDetail(item)">
              {{ item.createdAt }} >
            </div>
            <div class="right" v-else @click="skipContactDetail(item)"> ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import { employeeDetail, receiveDetail,circleStatic } from "@/api/qunfa";

export default {
  data() {
    return {
      showChart: false,
      spanIndex2: 0,
      dom: "myChart2",
      showInteraction: false,
      rate: 0,
      batchid: "",
      createdTime: "",
      carryOutList: [],
      employeeList: [],
      employeeId: "",
      notSendList: [],
      hasSendList: [],
      totalList: [],
      notFriendfailSend: [],
      ExcessiveSend: [],
      customerData:[],
      roomData:[]
    };
  },
  watch: {
    spanIndex2: {
      handler(val, oldVal) {
        this.spanIndex2 = val;
        this.getData();
      },
    },
  },
  mounted() {
    this.batchid = this.$route.query.id;
    this.employeeId = this.$route.query.employeeId;
    this.getData();
  },
  methods: {
    skipContactDetail(item){
      this.$router.push({
        path: "/contact/detail",
        query: {
          contactId: item.contactId,
        },
      });
    },
   async getData() {
    //客户
      if (this.$route.query.type == "toCustom") {
        await employeeDetail({
          batchId: this.batchid,
          employeeId: this.employeeId,
        }).then((res) => {
          this.customerData = res.data.list;
        });
      } else if (this.$route.query.type == "tokehuqun") {
        //客户群
       await receiveDetail({
          batchId: this.batchid,
          employeeId: this.employeeId,
        }).then((res) => {
          this.customerData = res.data.list;
        });
      }
      else{
        await circleStatic({
          batchId: this.batchid,
          employeeId: this.employeeId,
        }).then((res) => {
          this.customerData = res.data.list;
        });
      }

      switch (this.spanIndex2) {
        //  0-未发送 1-已发送 2-因客户不是好友导致发送失败 3-因客户已经收到其他群发消息导致发送失败',
        case 0:
          this.employeeList = this.customerData.filter((item) => {
            return item.status == 0;
          });
          break;
        case 1:
          this.employeeList = this.customerData.filter((item) => {
            return item.status == 1;
          });
          break;
        case 2:
          this.employeeList = this.customerData.filter((item) => {
            return item.status == 2;
          });
          break;
        case 3:
          this.employeeList = this.customerData.filter((item) => {
            return item.status == 3;
          });
      }
      this.totalList = this.customerData;
      this.notSendList = this.customerData.filter((item) => {
        return item.status == 0;
      });
      this.hasSendList = this.customerData.filter((item) => {
        return item.status == 1;
      });
      // // 因客户不是好友导致发送失败
      this.notFriendfailSend = this.customerData.filter((item) => {
        return item.status == 2;
      });
      // // 因客户已经收到其他群发消息导致发送失败
      this.ExcessiveSend = this.customerData.filter((item) => {
        return item.status == 3;
      });
      this.rate = (this.hasSendList.length / this.customerData.length).toFixed(2);
      this.getEchart();
    },

    getEchart() {
      this.$nextTick(() => {
        const chart = echarts.init(document.getElementById(this.dom));
        console.log("ccc", chart);
        const option = {
          tooltip: {
            show: false,
          },
          series: [
            {
              name: "饼图",
              type: "pie",
              //内圆和外圆的比例
              radius: ["70%", "90%"],
              avoidLabelOverlap: false,
              // emphasis：设置鼠标放到哪一块扇形上面的时候，扇形样式、阴影
              emphasis: {
                label: {
                  show: false,
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: this.rate * 100,
                  itemStyle: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#3975C6", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#64CFAE", // 100% 处的颜色
                        },
                      ],
                      global: false, // 缺省为 false
                    },
                  },
                },
                {
                  value: 100 - this.rate * 100,
                  itemStyle: {
                    color: "#E2E9FD",
                  },
                },
              ],
              animationDuration: 2000,
              animationEasing: "easeOutBounce",
              animationDelay: function (idx) {
                return idx * 100;
              },
              animationDurationUpdate: 2000,
              animationEasingUpdate: "easeOutBounce",
            },
          ],
        };
        chart.setOption(option, true);
        let progress = 0;
        var that = this;
        // setInterval(function () {
        //   if (progress <= that.rate) {
        //     progress += 10;
        //     console.log('progress',progress)
        //     option.series[0].data[0].value = progress;
        //     chart.setOption(option, true);
        //   }
        // }, 500);
      });
    },
    selectStatus2(index) {
      this.spanIndex2 = index;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-tabs__nav--line {
  padding-bottom: 0;
}
/deep/ .van-tabs__line {
  bottom: 0;
}
/deep/ .van-tabs__nav {
  background-color: transparent;
}
.chart-container {
  // position: relative;
  display: inline-block;
  margin: 21px 31px 6px 14px;
  width: 150px;
  height: 150px;
}
.rate {
  position: absolute;
  text-align: center;
  width: 145px;
  top: 124px;
  left: 16px;
  font-size: 22px;
  font-weight: bold;
  color: "#000";
}
.rate2 {
  font-size: 22px;
  font-weight: bold;
  color: "#000";
}
.rota {
  font-size: 12px;
  color: #9b9b9b;
}
.text {
  display: inline-block;
  vertical-align: top;
  margin-top: 43px;
  width: 143px;
}
.btn {
  width: 320px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  margin: 0 auto;
  background: #3974c6;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.m17 {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .custom {
    font-size: 12px;
    color: #9b9b9b;
  }
  .num {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
}
.statistics {
  height: 100vh;
  background-color: #f2f3f7;
  overflow: auto;
}
.main {
  margin-top: 10px;
  .taskDetail {
    position: relative;
    width: 94%;
    margin: 0 auto;
    min-height: 200px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 9px;
    padding-bottom: 20px;
  }
  .taskTop {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    padding-left: 13px;
    border: 1px solid #e8e8e8;
  }
}
.dataDetail {
  margin: 0 auto;
  margin-top: 12px;
  width: 94%;
  height: 98px;
  background: #ffffff;
  border-radius: 5px;
  .status {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;
    margin-top: 10px;
    .m19 {
      text-align: center;
      width: 28%;
      height: 28px;
      line-height: 28px;
      background: #f1f2f6;
      border-radius: 14px;
      font-size: 12px;
      color: #000;
      margin-right: 1%;
    }
    .active {
      background: #ecf0fc;
      color: #3974c6;
    }
  }
}
.members {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  width: 90%;
  .m20 {
    font-size: 12px;
    color: #515151;
  }
}
.membersData {
  width: 94%;
  margin: 0 auto;
  min-height: 144px;
  background: #ffffff;
  border-radius: 5px;
  .membersDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 68px;
    border-bottom: 1px solid #f5f5f5;
    .left {
      img {
        width: 32px;
        height: 32px;
      }
      span {
        font-size: 14px;
        color: #303030;
      }
    }
    .right {
      font-size: 14px;
      color: #303030;
    }
  }
}
</style>