<template>
    <div class="page">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
                <div v-for="(item,index) in logData" :key="index">
                    <div class="box">
                       <div class="title">{{item.createdTime}} 更新日志</div>
                       <div class="content">{{item.content}}</div>
                    </div>
                  </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
    import { getLogList } from "@/api/updateLog";
    export default {
        components: {
        },
        data() {
            return {
                logData: [],
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                params:{
                    page: 0, 
                    perPage: 10
                }
            }
        },
        methods: {
            getData() {
                getLogList(this.params).then((res) => {
                    this.logData.push(...res.data.list);
                    this.loading = false;
                    if (this.logData.length >= res.data.page.total) {
                        this.finished = true;
                    }
                })
            },
            onLoad() {
                this.params.page++
                setTimeout(() => {
                    if (this.refreshing) {
                        this.logData = [];
                        this.refreshing = false;
                    }
                    this.getData()
                }, 1000);
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.params={
                    page: 0, 
                    perPage: 10
                }
                this.onLoad();
            },
        }
    }
</script>

<style lang="less" scoped>
    .page{
        width: 100%;
        height: 100%;
        background: #fff;
    }
    .box {
        padding: 15px;
        border-top: 1px solid #D8D8D8;
        background: #fff;
        .title {
            font-size: 16px;
        }

        .content {
            font-size: 12px;
            white-space: pre-wrap;
        }
    }
</style>