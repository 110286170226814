<template>
  <div class="index">
    <!-- <van-loading size="24px" type="spinner" color="#1989fa" vertical v-show="pageLoad">加载数据中...</van-loading> -->
    <div class="content">
      <div class="customManage">
        <div class="customTop">
          <div class="m16">客户管理</div>
        </div>
        <div class="customMiddle">
          <div class="left" v-permission="'system:wCsBA'">
            <div class="statistics">
              <img class="leftImg" src="../../assets/tool/statistics.png" alt="" />
              <!-- <div class="fs16" @click="routerPush('/contactStatistic/index')">数据看板 ></div> -->
              <div class="fs16" @click="routerPush('/dataBurialPoint/index')">
                数据看板 >
              </div>
            </div>
            <div class="increaseNum">
              <div class="box">
                <div class="kehu">今日新增</div>
                <div class="num">{{ markData.count_room }}</div>
              </div>
              <div class="box">
                <div class="kehu">今日新增用户</div>
                <div class="num">{{ markData.count_contact }}</div>
              </div>
            </div>
          </div>
          <div class="right" v-permission="'system:9250c'">
            <div class="mingpian">
              <img class="rightImg" src="../../assets/tool/mingPian.png" alt="" />
              <div class="fs16">渠道活码</div>
            </div>
            <div class="middle">使用活码添加客户</div>
            <div class="bottom" @click="routerPush('/channelCode/index')">
              去创建 >
            </div>
          </div>
        </div>
      </div>
      <div class="customBot">
        <div class="commonbox" @click="routerPush('/roomTemplatePull/index')">
          <div class="boxTop">
            <img class="img" src="../../assets/index/laqun.png" alt="" />
          </div>
          <div class="boxBot">一键拉群</div>
        </div>
        <div class="commonbox" @click="routerPush('/contactDeleteRemind/index')" v-permission="'system:E0Pss'">
          <div class="boxTop">
            <img class="img" src="../../assets/index/del.png" alt="" />
          </div>
          <div class="boxBot">删人提醒</div>
        </div>
        <div class="commonbox" @click="routerPush('/contactLost/index')" v-permission="'system:E0Pss'">
          <div class="boxTop">
            <img class="img" src="../../assets/index/liushi.png" alt="" />
          </div>
          <div class="boxBot">流失提醒</div>
        </div>
        <div class="commonbox" @click="routerPush('/contactWelcome/index')" v-permission="'system:JV9B6'">
          <div class="boxTop">
            <img class="img" src="../../assets/index/huanyingyu.png" alt="" />
          </div>
          <div class="boxBot">欢迎语</div>
        </div>
      </div>
      <div class="yingxiao" v-if="businessFun()">
        <div class="yingxiao_top">
          <!-- <img class="minIcon" src="../../assets/tool/yingxiao.png" alt=""> -->
          <span class="m16">业务转化</span>
        </div>
        <div class="customMiddle">
          <div class="left" style="background: #fbf4e2" v-permission="'system:t9Buh'">
            <div class="statistics">
              <img class="leftImg" src="../../assets/tool/leida.png" alt="" />
              <div class="fs16" @click="routerPush('/contactRadar/index')">
                雷达统计 >
              </div>
            </div>
            <div class="increaseNum">
              <div class="box">
                <div class="kehu">今日访问人数</div>
                <div class="num">{{ markData.count_user }}</div>
              </div>
              <div class="box">
                <div class="kehu">今日访问次数</div>
                <div class="num">{{ markData.count_num }}</div>
              </div>
            </div>
          </div>
          <div class="right" v-permission="'system:X65Ib'">
            <div class="mingpian">
              <img class="rightImg" src="../../assets/tool/sucai.png" alt="" />
              <div class="fs16">素材库</div>
            </div>
            <div class="middle">使用素材激活客户</div>
            <div class="bottom" @click="routerPush('/medium/index')">
              去分享 >
            </div>
          </div>
        </div>
        <div class="yingxiao_content" style="height: 160px">
          <div class="commonbox" @click="routerPush('/sopList/index')" v-permission="'system:7u29x'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/sop.png" alt="" />
            </div>
            <div class="boxBot">SOP</div>
          </div>
          <div class="commonbox" @click="routerPush('/qunfa/index')" v-if="qunfaPermiss()">
            <div class="boxTop">
              <img class="img" src="../../assets/index/qunfa3.png" alt="" />
            </div>
            <div class="boxBot">群发</div>
          </div>
          <div class="commonbox" @click="routerPush('/socialinteraction/index')" v-permission="'system:MvevR'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/shequn.png" alt="" />
            </div>
            <div class="boxBot">话术中心</div>
          </div>
          <div class="commonbox" @click="routerPush('/appointment/index')" v-permission="'system:Vp6nf'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/yuyue.png" alt="" />
            </div>
            <div class="boxBot">预约处理</div>
          </div>
          <div class="commonbox" @click="routerPush('/workOrder/index')" v-permission="'system:workmenage'">
            <div class="boxTop">
              <img class="img" src="../../assets/workOrder/index.png" alt="" />
            </div>
            <div class="boxBot">工单管理</div>
          </div>
          <div class="commonbox" @click="routerPush('/Inspection/index')" v-permission="'system:Shxj'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/merchant.png" alt="" />
            </div>
            <div class="boxBot">商户巡检</div>
          </div>
          <div class="commonbox" @click="routerPush('/credit/index')" v-permission="'system:ZCsxx'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/shouxin.png" alt="" />
            </div>
            <div class="boxBot">整村授信</div>
          </div>
        </div>
      </div>
      <!-- 管理工具 -->
      <div class="yingxiao">
        <div class="yingxiao_top">
          <!-- <img class="minIcon" src="../../assets/tool/guanli.png" alt=""> -->
          <span class="m16">互动营销</span>
        </div>
        <div class="yingxiao_content">
          <div class="commonbox" @click="routerPush('/shareDraw/index')" v-permission="'system:T0xRO'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/choujiang.png" alt="" />
            </div>
            <div class="boxBot">分享抽奖</div>
          </div>
          <div class="commonbox" @click="routerPush('/poster/index')">
            <div class="boxTop">
              <img class="img" src="../../assets/index/poster.png" alt="" />
            </div>
            <div class="boxBot">生成海报</div>
          </div>
          <div class="commonbox" @click="routerPush('/lottery/index')">
            <div class="boxTop">
              <img class="img" src="../../assets/index/hexiao.png" alt="" />
            </div>
            <div class="boxBot">兑奖核销</div>
          </div>
          <div class="commonbox" @click="routerPush('/electronicCard/circle')" v-permission="'system:xov6q'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/pengyouquan.png" alt="" />
            </div>
            <div class="boxBot">朋友圈</div>
          </div>
          <div class="commonbox" @click="routerPush('/index/index')">
            <div class="boxTop">
              <img class="img" src="../../assets/index/tool.png" alt="" />
            </div>
            <div class="boxBot">待办事项</div>
          </div>
          <div class="commonbox" @click="routerPush('/contact/index')" v-permission="'system:wrnTo'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/kehulist.png" alt="" />
            </div>
            <div class="boxBot">客户列表</div>
          </div>
          <div class="commonbox" @click="routerPush('/festival/index')" v-permission="'system:6l5qC'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/care.png" alt="" style="width: 17px; height: 22px" />
            </div>
            <div class="boxBot">节点关怀</div>
          </div>
          <div class="commonbox" @click="routerPush('/externalClock/index')" v-permission="'system:JqIPU'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/clockIn.png" alt="" style="width: 20px; height: 22px" />
            </div>
            <div class="boxBot">外拓打卡</div>
          </div>
        </div>
      </div>
      <!-- 网格管理 -->
      <div class="yingxiao" style="margin-bottom: 100px">
        <div class="yingxiao_top">
          <span class="m16">网格管理</span>
        </div>
        <div class="customBot">
          <div class="commonbox" @click="routerPush('/gridManage/info')" v-permission="'system:wggl'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/gridInfo.png" alt="" />
            </div>
            <div class="boxBot">网格信息</div>
          </div>
          <div class="commonbox" @click="routerPush('/gridManage/customer')" v-permission="'system:wggl'">
            <div class="boxTop">
              <img class="img" src="../../assets/index/gridCost.png" alt="" />
            </div>
            <div class="boxBot">网格客户</div>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active" class="tab">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/home-active.png" />
          <img v-else src="../../assets/images/home.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/contact/index" icon="search" v-show="concantPre()">
        <span>客户</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/customer-active.png" />
          <img v-else src="../../assets/images/customer.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/xiaoban/index" icon="setting-o" v-permission="'system:dhXa3'">
        <span>AI助手</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/index/aiActive.png" />
          <img v-else src="../../assets/index/ai.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tool/index">
        <span>营销台</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/index/operate-active.png" />
          <img v-else src="../../assets/index/operate.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="setting-o">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/my-active.png" />
          <img v-else src="../../assets/images/my.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
  import { isShowVcard } from "@/api/electronicCard";
  import { getMarketing } from "@/api/tool";
  import { vcardIndex } from "@/api/electronicCard";
  import { judgePermission } from "@/utils/index";
  import { getPermissionsList } from "@/api/electronicCard";
  import store from "@/store";
  export default {
    data() {
      return {
        // 文字
        message: "",
        loading: false,
        pageLoad: false,
        // tbas切换
        tabsChose: 0,
        active: 3,
        isShow: 0,
        markData: {},
        barThumbList: [
          "#06a5e2",
          "linear-gradient(90deg, #F9E2AC 0%, #DEB676 100%)",
          "linear-gradient(200deg, #8991FA 0%, #6770FF 100%)",
          "#6A98C9",
          "linear-gradient(135deg, #F5B0AD 0%, #E53429 100%)",
          "linear-gradient(90deg, #D1EAE9 0%, #CBE1CB 100%)",
          "#D73112",
        ],
        checkStyle: "",
      };
    },
    created() {
      this.getData();
      this.getPermissions();
    },
    watch: {},
    methods: {
       // 获取权限
       getPermissions() {
      getPermissionsList().then((res) => {
        store.commit("SET_INIT_PERMISSION", res.data);
        localStorage.setItem("SET_INIT_PERMISSION", JSON.stringify(res.data));
      });
    },
      getData() {
        var that = this;
        getMarketing().then((res) => {
          this.markData = res.data;
        });
        //获取当前缓存,存在缓存则取接口，不存在缓存则走接口
        let localStyle = localStorage.getItem('currentStyle')
        if (localStyle) {
          this.applyStyle(localStyle)
        } else {
          vcardIndex()
            .then((res) => {
              this.applyStyle(res.data.style)
            })
        }
      },
      applyStyle(style) {
        this.checkStyle = style;
        //样式更换
        // setTimeout(() => {
        this.$nextTick(() => {
          const itemPic = document.querySelectorAll(".boxTop");
          for (let i = 0; i < itemPic.length; i++) {
            itemPic[i].style.background =
              this.barThumbList[style - 1];
          }
          // }, 800);
        })
      },
      routerPush(e) {
        this.$router.push(e);
      },
      // 群发功能限制
      qunfaPermiss() {
        // let array=store.getters.permission
        const array = JSON.parse(localStorage.getItem("SET_INIT_PERMISSION"));
        return (
          array.includes("system:Aw1Jv") ||
          array.includes("system:ptLDc") ||
          array.includes("system:xov6q")
        );
      },
      // 客户功能限制
      concantPre() {
        let array = JSON.parse(localStorage.getItem("SET_INIT_PERMISSION"));
        return array.includes("system:wrnTo") || array.includes("system:zSTgX");
      },
      // 业务转化限制
      businessFun() {
        let array = JSON.parse(localStorage.getItem("SET_INIT_PERMISSION"));
        let qunfa =
          array.includes("system:Aw1Jv") ||
          array.includes("system:ptLDc") ||
          array.includes("system:xov6q");
        return (
          array.includes("system:t9Buh") ||
          array.includes("system:X65Ib") ||
          array.includes("system:7u29x") ||
          array.includes("system:MvevR") ||
          qunfa ||
          array.includes("system:Vp6nf")
        );
      },
    },
  };
</script>
<style lang="less" scoped>
  /deep/ .van-loading {
    margin: 0 auto;
    margin-top: 50%;
    display: flex;
    justify-content: center;
  }

  /deep/.van-tabbar {
    box-sizing: content-box !important;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }

  .van-tabbar--fixed {
    left: unset !important;
  }

  .index {
    width: 100%;
    height: 100%;
    background-color: #f9f9fc;
    overflow: auto;
  }

  .content {
    width: 93%;
    margin: 0 auto;

    .customManage {
      .customTop {
        display: flex;
        width: 100%;
        height: 47px;
        align-items: center;
      }
    }
  }

  .minIcon {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }

  .m16 {
    height: 23px;
    font-size: 19px;
    font-weight: bold;
    color: #262626;
    line-height: 23px;
  }

  .customMiddle {
    display: flex;
    justify-content: space-between;

    .left {
      width: 48%;
      height: 113px;
      background: #e9effb;
      border-radius: 12px;

      .statistics {
        margin-top: 12px;
        margin-left: 6px;
        display: flex;
        height: 18px;
        width: 100%;
      }

      .leftImg {
        margin: 0 4px;
        width: 17px;
        height: 17px;
      }

      .fs16 {
        height: 17px;
        font-size: 12px;
        color: #4a4a4a;
      }

      .increaseNum {
        display: flex;
        width: 162px;
        height: 68px;
        background: #ffffff;
        border-radius: 8px;
        margin: 0 auto;
        margin-top: 10px;

        .box {
          /* width: 40%; */
          margin: 0 auto;

          .kehu {
            margin-top: 12px;
            height: 17px;
            font-size: 12px;
            color: #717174;
            line-height: 17px;
          }

          .num {
            width: 23px;
            height: 27px;
            font-size: 19px;
            font-weight: bold;
            color: #262626;
            line-height: 27px;
          }
        }
      }
    }

    .right {
      width: 48%;
      height: 113px;
      background: #ffffff;
      border-radius: 12px;

      .mingpian {
        display: flex;
        margin: 14px 0 0 16px;

        .rightImg {
          width: 21px;
          height: 21px;
        }

        .fs16 {
          margin-left: 9px;
          height: 24px;
          font-size: 12px;
          color: #4a4a4a;
          line-height: 24px;
        }
      }

      .middle {
        margin: 12px 0 7px 16px;
        height: 20px;
        font-size: 14px;
        font-weight: bold;
        color: #515151;
        line-height: 20px;
      }

      .bottom {
        margin-left: 16px;
        height: 17px;
        font-size: 12px;
        color: #3975c6;
        line-height: 17px;
      }
    }
  }

  .customBot {
    margin-top: 12px;
    width: 100%;
    height: 86px;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    text-align: center;
  }

  .commonbox {
    float: left;
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-top: 12px;

    .boxTop {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      .img {
        width: 20px;
        height: 20px;
      }
    }

    .boxBot {
      margin-top: 12px;
      font-size: 12px;
      color: #4a4a4a;
    }
  }

  .yingxiao {
    .yingxiao_top {
      margin: 10px 0;
    }

    .yingxiao_content {
      margin-top: 12px;
      width: 100%;
      height: 160px;
      background: #ffffff;
      border-radius: 12px;
      text-align: center;
    }
  }
</style>