<template>
  <div class="index">
    <matePopup ref="mateChild"></matePopup>
  </div>
</template>

<script>
  import matePopup from "@/components/select/material/index";
  export default {
    components: {
      matePopup
    },
    data() {
      return {

      }
    },
    mounted(){
    }
  }
</script>

<style lang="less" scoped>
 
</style>