import request from "../plugins/axios";

// 打卡列表
export function getClockInList(params) {
    return request({
        url: '/ClockIn/IndexList',
        method: 'get',
        params: params
    })
}
//创建打卡
export function createClock(params) {
    return request({
        url: '/ClockIn/store',
        method: 'post',
        data: params
    })
}
export function upload(data) {
    return request({
        url: '/common/upload',
        method: 'POST',
        data
    })
}