import request from "../plugins/axios";

//单聊sop创建
export function storeContactSop(params) {
    return request({
        url: '/contactSop/store',
        method: 'post',
        data:params
    })
}
//sop列表
export function contactSopList(params) {
    return request({
        url: '/contactSop/index',
        method: 'get',
        params:params
    })
}
//sop详情
export function sopDetail(params) {
    return request({
        url: '/contactSop/detail',
        method: 'get',
        params:params
    })
}
//sop更新
export function sopUpdate(params) {
    return request({
        url: '/contactSop/update',
        method: 'put',
        data:params
    })
}
//sop删除
export function sopDelete(params) {
    return request({
        url: '/contactSop/delete',
        method: 'delete',
        data:params
    })
}
//sop开启关闭
export function sopStatus(params) {
    return request({
        url: '/contactSop/setSopStatus',
        method: 'get',
        params:params
    })
}
