<template>
    <div>
      <van-calendar
        ref="calendar"
        v-model="show"
        color="#3974C6"
        type="range"
        :min-date="minDate"
        @select="selectDate"
      >
        <template #footer>
          <div class="footer">
            <div class="left">
              <!-- <div class="m17" v-if="startT==''">开始时间</div> -->
              <div class="m16">{{ startT }}</div>
              <span style="font-size: 12px; padding: 0 5px; color: #000000"
                >至</span
              >
              <!-- <div v-if="endT==''" class="m17">结束时间</div> -->
              <div class="m16">{{ endT }}</div>
            </div>
            <div class="right">
              <div class="reset" @click="reset">重置</div>
              <div class="confirm" @click="onConfirm">确定</div>
            </div>
          </div>
        </template>
      </van-calendar>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        show: false,
        minDate: new Date("Jul 12 2011"),
        startT: "",
        endT: "",
        currenrDate: new Date(),
        nextDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      };
    },
    methods: {
      $show() {
        this.show = true;
        this.startT = this.formatter(this.currenrDate);
        this.endT = this.formatter(this.nextDate);
      },
      reset() {
        this.$refs.calendar.reset();
        this.startT = this.formatter(this.currenrDate);
        this.endT = this.formatter(this.nextDate);
      },
      selectDate(date) {
        console.log("date", date);
        this.startT = date[0] ? this.formatter(date[0]) : "";
        this.endT = date[1] ? this.formatter(date[1]) : "";
      },
      formatter(date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month}-${day}`;
      },
      onConfirm() {
        this.show = false;
        this.$emit("changeTime", this.startT, this.endT);
      },
    },
  };
  </script>
  <style lang="less" scoped>
  /deep/ .van-calendar__bottom-info {
    font-size: 0;
  }
  /deep/ .van-calendar__footer {
    padding: 10px 3%;
  }
  /deep/ .van-calendar__day--start,
  /deep/ .van-calendar__day--end {
    border-radius: 50%;
  }
  /deep/ .van-calendar__day {
    height: 40px;
    width: 40px;
  }
  .footer {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .m16 {
      font-size: 14px;
      color: #267ef0;
    }
    .m17 {
      font-size: 12px;
      padding: 0 10px;
      color: #595a5e;
    }
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
  
      .reset {
        text-align: center;
        width: 55px;
        height: 31px;
        line-height: 31px;
        background: #f1f2f6;
        border-radius: 5px;
        border: 1px solid #d7d7d9;
        font-size: 14px;
        font-weight: 500;
        color: #595a5e;
        margin-right: 10px;
      }
      .confirm {
        text-align: center;
        width: 55px;
        height: 31px;
        line-height: 31px;
        background: #3974c6;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  </style>