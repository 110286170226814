<template>
  <div class="index">
    <div class="content">
      <div class="banxin">
        <div class="tab" v-if="tabList.length!=0">
          <div class="list" :class="tabIndex == index ? 'active' : ''" @click="handleTabChange(item,index)"
            v-for="(item, index) in tabList" :key="index">
            {{ item.name }}
            <img v-show="tabIndex == index" class="activeIcon" src="../../assets/images/manageMoney/line.png" />
          </div>
        </div>
        <div class="banner">
          <div class="bannerTitle">{{currentGroupInfo.title}}</div>
          <img class="bannerImg" src="../../assets/images/manageMoney/banner.png" alt="" />
          <div class="describe">{{currentGroupInfo.abstract}}</div>
        </div>
        <div class="selection">
          <van-dropdown-menu active-color="#1989fa">
            <van-dropdown-item v-model="value1" :options="option1" @change="menuChange($event,'order')" />
            <van-dropdown-item v-model="value2" :options="option2" @change="menuChange($event,'startAmount')" />
            <van-dropdown-item v-model="value3" :options="option3" @change="menuChange($event,'term')" />
          </van-dropdown-menu>
        </div>
        <van-empty description="暂无数据" v-if="finaData.length==0" />
        <div v-else>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <van-swipe-cell v-for="(i,index) in finaData" :key="index">
              <div class="info" @click="goDetail(i)">
                <div class="top"></div>
                <div class="infoContent">
                  <div class="topTitle">{{i.name}}</div>
                  <div class="tag">
                    <div class="tagList" v-for="tagI in i.keywords">{{tagI}}</div>
                  </div>
                  <div class="infoMiddle topHeight">
                    <div class="lilv">
                      {{i.annualizedRate}}%
                    </div>
                    <div class="shen">{{i.characteristic}}</div>
                  </div>
                  <div class="infoMiddle">
                    <div class="time">{{i.annualizedNotes}}
                      <img class="icon" src="../../assets/images/manageMoney/guanyu.png" alt="" />
                    </div>
                    <div class="money">{{i.startAmount}}{{i.startAmountType==1?'元':'美元'}}起购</div>
                  </div>
                  <div class="infoBot">
                    <showMore :length="50" :longStr="i.briefIntroduction" />
                  </div>
                </div>
              </div>
              <template #right>
                <div class="rightBtn">
                  <div class="btn send" @click="massHair(i,'Contact')">发送</div>
                  <div class="btn roomSend" @click="massHair(i,'Chat')">群发</div>
                </div>
              </template>
            </van-swipe-cell>
          </van-list>
        </div>
      </div>
    </div>
    <div style="height: 16px"></div>
  </div>
</template>

<script>
  import showMore from "./components/showMore.vue";
  import { getFina, getCate } from "@/api/financial";
  import {  shareToExternalContact, shareToExternalChat } from "@/utils/wxCodeAuth";
  import { getVcardData } from "@/api/electronicCard";
  export default {
    components: { showMore },
    data() {
      return {
        tabIndex: 0,
        tabList: [],
        value1: 0,
        value2: 0,
        value3: 0,
        option1: [
          { text: "排序", value: 0 },
          { text: "推荐", value: 1 },
          { text: "收益率从高到低", value: 2 },
          { text: "起购金额从低到高", value: 3 },
          { text: "期限从长到短", value: 4 },
        ],
        option2: [
          { text: "起购金额", value: 0 },
          { text: "小于1万", value: 1 },
          { text: "1-5万", value: 2 },
          { text: "5-20万", value: 3 },
          { text: "20-100万", value: 4 },
          { text: "100-300万", value: 5 },
          { text: "300-1000万", value: 6 },
          { text: "大于1000万", value: 7 },
        ],
        option3: [
          { text: "期限", value: 0 },
          { text: "1个月内", value: 1 },
          { text: "1-3月", value: 2 },
          { text: "3-6月", value: 3 },
          { text: "6-9月", value: 4 },
          { text: "9-12月", value: 5 },
          { text: "1年以上", value: 26 },
        ],
        queryParams: {
          groupid: undefined,
          page: 1,
          perPage: 10
        },
        finaData: [],
        currentGroupInfo: {},
        loading: false,
        finished: false,
        employeeId:''
      };
    },
    created() {
      this.getGroupData()
      this.getEmplyeeId()
    },
    methods: {
      getEmplyeeId(){
      getVcardData().then(res=>{
        this.employeeId = res.data.id
      })
    },
      onLoad() {
        this.getData()
      },
      handleTabChange(row, index) {
        this.resultQuery()
        this.tabIndex = index;
        this.queryParams.groupid = row.id
        this.currentGroupInfo = row
        this.getData()
      },
      resultQuery() {
        this.value1 = 0;
        this.value2 = 0;
        this.value3 = 0;
        this.finaData = []
        this.queryParams = {
          groupid: this.currentGroupInfo.id,
          page: 1,
          perPage: 10
        }
      },
      getGroupData() {
        getCate().then((res) => {
          this.tabList = res.data
          if (res.data.length != 0) {
            this.queryParams.groupid = res.data[0].id
            this.currentGroupInfo = res.data[0]
            this.getData()
          }
        })
      },
      getData() {
        getFina(this.queryParams).then((res) => {
          let newData = res.data.list.map((i) => {
            i.keywords = i.keywords.split(', ')
            return i
          })
          setTimeout(() => {
            this.queryParams.page++
            this.finaData.push(...newData)
            this.loading = false;
            if (this.finaData.length >= res.data.page.total) {
              this.finished = true;
              return
            }
          }, 1000);
        })

      },
      //菜单变化
      menuChange(e, type) {
        this.queryParams[type] = e;
        this.getData()
      },
      //前往详情页
      goDetail(row) {
        this.$router.push({
          path: "/manageMoney/detail",
          query: {
            id: row.id,
          },
        });
      },
      //分享
      massHair(row,type) {
        let link = ''
        if (row.detailType == 3) {
          link = row.detail
        }
        if (row.detailType == 2||row.detailType == 1) {
          link = row.shareUrl + '?id=' + row.id + '&employeeId=' + this.employeeId
        }

        let data = {
          type: 7,
          title: row.name,
          share_link: link,
        };
        //shareToExternalChat  群发
        console.log('data',data)
        type=='Chat'?shareToExternalChat(data):shareToExternalContact(data)
      }
    },
  };
</script>

<style lang="less" scoped>
  /deep/ .van-swipe-cell__right {
    top: calc(50% - 25px) !important;
  }

  .index {
    background-color: #fff;
  }

  .content {
    width: 100%;

    .banxin {
      width: 96%;
      margin: 0 auto;
    }
  }

  .tab {
    width: 100%;
    height: 46px;
    overflow-x: auto;
    white-space: nowrap;
    background-color: #fff;

    .list {
      display: inline-block;
      position: relative;
      padding: 0 16px;
      font-size: 13px;
      color: #4a4a4a;
      text-align: center;
    }

    .active {
      font-size: 14px;
      color: #3974c6;
    }

    .activeIcon {
      position: absolute;
      left: calc(50% - 12px);
      top: 22px;
      width: 24px;
      height: 6px;
    }
  }

  .banner {
    position: relative;

    .bannerTitle {
      position: absolute;
      top: 20px;
      left: 16px;
      font-weight: 500;
      font-size: 22px;
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 90%;
    }

    .describe {
      width: 160px;
      position: absolute;
      top: 56px;
      left: 16px;
      font-size: 13px;
      color: #ffffff;
      line-height: 17px;
    }
  }

  .bannerImg {
    width: 100%;
    height: 130px;
  }

  .icon {
    margin: 4px;
    width: 14px;
    height: 14px;
  }

  .info {
    position: relative;
    min-height: 209px;
    border-radius: 4px;
    margin-top: 11px;
    overflow: hidden;
    border: 1px solid #fff4e7;
    padding-bottom: 20px;

    .top {
      width: 100%;
      position: absolute;
      height: 30px;
      background: linear-gradient(#fee8ca 0%, #ffffff 100%);
      z-index: 1;
    }

    .infoContent {
      position: relative;
      width: 92%;
      margin: 0 auto;
      z-index: 99;
    }

    .topTitle {
      margin-top: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: #000000;
      margin-bottom: 6px;
    }

    .tag {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;

      .tagList {
        background: #fff8e9;
        border-radius: 2px;
        font-size: 12px;
        color: #93630d;
        padding: 4px;
        margin: 2px 10px 2px 0;
      }
    }

    .lilv {
      font-size: 20px;
      color: #d81e06;
      width: 30%;
      font-weight: 600;
    }

    .shen {
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      ;

    }

    .time {
      width: 30%;
      font-size: 12px;
      color: #515151;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      ;
    }

    .money {
      font-size: 12px;
      color: #515151;
    }

    .infoMiddle {
      display: flex;
      min-height: 30px;
      align-items: center;
      /* margin-bottom: 5px; */
    }

    .topHeight {
      margin-top: 10px;
    }

    .infoBot {
      font-size: 12px;
      color: #515151;
      line-height: 17px;
      text-align: left;
      line-height: 18px;
      border-top: 1px solid #F2F3F7;
      margin-top: 10px;
      padding-top: 15px;
    }
  }

  .rightBtn {
    padding: 0 10px;
  }

  .btn {
    display: block;
    width: 50px;
    height: 23px;
    line-height: 23px;
    border-radius: 12px;
    border: 1px solid #3974c6;
    font-size: 12px;
    color: #3974c6;
    text-align: center;
    margin-bottom: 10px;
  }

  .roomSend {
    background: #3974c6;
    color: #fff;
  }
</style>