<template>
  <div>
    <div class="box">
      <a-button @click="$refs.popup.show()"> 选择标签 </a-button>
      <div class="tags">
        <div class="tag" v-for="tag in tags">
          <a-tag @close="closeTagChange(tag)" closable>{{ tag.name }}</a-tag>
        </div>
      </div>
    </div>
    <!-- <customerLabel ref="customerLabel" @change="userTagsChose" /> -->

    <popup ref="popup" @change="(e) => (tags = e)" />
  </div>
</template>

<script>
import popup from "./popup";

export default {
  data() {
    return {
      tags: [],
      tagsText: "",
      // 客户标签
      customerLabel: false,
    };
  },
  methods: {
    get() {
      return this.tags.filter((v) => {
        return !v.closable;
      });
    },

    closeTagChange(e) {
      for (const k in this.tags) {
        if (this.tags[k].id === e.id) {
          this.tags[k].closable = true;

          return false;
        }
      }
    },
  },
  components: { popup },
};
</script>

<style lang="less" scoped>
.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;

  .tag {
    margin-bottom: 6px;
  }
}
</style>
