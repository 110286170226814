<template>
    <div class="index" >
        <div class="title-serch">
            <van-search style="flex: 1;" v-model="serchValue" show-action placeholder="搜索素材名称" shape="round"
                @search="onSearch">
                <template #action>
                </template>
            </van-search>
        </div>
        <div class="content" >
            <van-tabs v-model="activeName" style="margin-top: 10px;" swipe-threshold="7" color="#1890ff"
                title-active-color="#1890ff" @click="onClick">
                <van-tab v-for="(item, index) in typeOption" :key="index" :title="item.name" :name="item.id">
                    <van-dropdown-menu active-color="#2a8ff7">
                        <van-dropdown-item @change="getList" v-model="value3" :options="option3" />
                        <van-dropdown-item @change="getList" v-model="value2" :options="option2" />
                    </van-dropdown-menu>
                    <div class="main">
                        <div class="mainLeft">
                            <div :class="spanIndex == index ? 'active' : 'm16'" v-for="(item, index) in option1"
                                :key="index" @click="handleClick(index, item.value)">
                                {{ item.text }}
                            </div>
                        </div>
                        <div class="mainRight">
                            <div v-if="mediumList.length > 0">
                                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                                    <div class="item" :class="selectPop?'item-flex':''">
                                        <van-checkbox-group v-model="selectData">
                                            <div v-for="(i,is) in mediumList" :key="is" class="items"
                                                :class="selectPop?'items-flex':''">
                                                <div class="checkbox" v-if="selectPop">
                                                    <van-checkbox :name="i"></van-checkbox>
                                                </div>
                                                <div class="title" v-if="currentID!=8">
                                                    <!-- 不显示图片  文本 -->
                                                    <img v-if="i.type=='图片'" :src="i.content.imageFullPath" />
                                                    <img v-if="i.type=='图文'" :src="i.content.imageFullPath" />
                                                    <img v-if="i.type=='视频'" style="width: 69px;height: 69px;"
                                                        src="@/assets/images/vidoeType.png" />
                                                    <div v-if="i.type=='音频'" class="typeBox"
                                                        style="background: #E2D4FF;">
                                                        <img style="width: 31px;height: 33px;"
                                                            src="@/assets/images/yinpin.png" />
                                                    </div>
                                                    <div v-if="i.type=='小程序'" class="typeBox"
                                                        style="background: #D9E9FF;">
                                                        <img style="width: 38px;height: 38px;"
                                                            src="@/assets/images/chengxu.png" />
                                                    </div>
                                                    <div v-if="i.type=='文件'" class="typeBox"
                                                        style="background: #FFF0C3;">
                                                        <img style="width: 37px;height: 33px;"
                                                            src="@/assets/images/wenjian.png" />
                                                    </div>
                                                    <img v-if="i.type=='雷达'" :src="i.content.imageFullPath" />
                                                    <div class="share">
                                                        <div>
                                                            <span v-if="i.type=='文本'">{{i.content.content}}</span>
                                                            <span
                                                                v-else-if="i.type=='图片'">{{i.content.fileTitle}}</span>
                                                            <span
                                                                v-else-if="i.type=='图文'">{{i.content.TextTitle}}</span>
                                                            <span
                                                                v-else-if="i.type=='音频'">{{i.content.fileTitle}}</span>
                                                            <span
                                                                v-else-if="i.type=='视频'">{{i.content.fileTitle}}</span>
                                                            <span
                                                                v-else-if="i.type=='小程序'">{{i.content.TextTitle?i.content.TextTitle:i.content.titleApplet}}</span>
                                                            <span
                                                                v-else-if="i.type=='文件'">{{i.content.fileTitle}}</span>
                                                            <span
                                                                v-else-if="i.type=='雷达'">{{i.content.TextTitle}}</span>
                                                            <span v-else-if="i.type==''">{{i.content.content}}</span>
                                                        </div>
                                                        <div>
                                                            <span style="margin-top:7px">转发 {{ i.sendNum }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="title" v-else>
                                                    <div class="typeBox" style="background: #F3EBFF;">
                                                        <img style="width: 30px;height: 33px;"
                                                            src="@/assets/images/leida.png" />
                                                    </div>
                                                    <div class="share">
                                                        <div>
                                                            <span>{{i.title}}</span>
                                                        </div>
                                                        <div>
                                                            <span style="margin-top:7px">类型: {{ i.typeName }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="share-button"
                                                    v-if="!selectPop&&i.type!='音频'&&i.type!='小程序'">
                                                    <div @click="massHair(i,'Chat')">群发</div>
                                                    <div @click="shareBtn(i,'Contact')">发送</div>
                                                </div>
                                            </div>
                                        </van-checkbox-group>
                                    </div>
                                   
                                </van-list>
                                <div style="width: 100%;height: 100px;" v-if="selectPop&&mediumList.length>10"></div> 
                            </div>
                            <div v-else>
                                <van-empty description="暂无数据" />
                            </div>
                        </div>
                    </div>
                    <!-- -->
                </van-tab>
            </van-tabs>
        </div>
        <!--  编辑删除  -->
        <van-action-sheet v-model="editShow" :actions="editActions" cancel-text="取消" close-on-click-action
            @cancel="editShow = false" @select="editOr" />
        <!--  新建  -->
        <van-action-sheet v-model="shareShow" cancel-text="取消" close-on-click-action @cancel="shareShow = false">
            <template slot="default">
                <div class="share-icon">
                    <div @click="forwardData">
                        <img src="@/assets/images/marketSend.svg" />
                        转发
                    </div>
                    <div>
                        <img src="@/assets/images/wechatMaterial.svg" />
                        微信
                    </div>
                    <div @click="forwardDatap"
                        v-if="shareDataFile.type == '图片' || shareDataFile.type == '图文' || shareDataFile.type == '视频' || shareDataFile.type == '雷达' ">
                        <img src="@/assets/images/marketFriend.svg" />
                        客户朋友圈
                    </div>
                    <div @click="copy" id="copy_text" 　　　　　　ref="copy" 　　　　　　　data-clipboard-action="copy" 　　　　　　
                        :data-clipboard-text="shareData.url">
                        <img src="@/assets/images/marketCopy.svg" />
                        复制
                    </div>
                </div>
            </template>
        </van-action-sheet>
        <!-- 组件状态按钮 -->
        <div class="btnBox" v-if="selectPop">
            <div class="cancel" @click="cellback()">取消</div>
            <div class="submit" @click="btnClick()">确定</div>
        </div>
    </div>
</template>

<script>
    import { destroy, mediumGroup, mediumList, radarIndex } from "@/api/medium";
    import { wechatMoments, forward } from '@/utils/wxCodeAuth'
    import Clipboard from 'clipboard'
    import { sendChatMessage, shareToExternalMoments, shareToExternalContact, shareToExternalChat } from "@/utils/wxCodeAuth";
    export default {
        data() {
            return {
                // 文字
                active: 0,
                serchValue: '',
                message: '',
                // tbas切换
                spanIndex: 0,
                tabsChose: 0,
                activeName: "",
                value1: 0,
                value2: 1,
                value3: '',
                option1: [
                    { text: '全部分组', value: 0 },
                ],
                option2: [
                    { text: '所有人', value: 1 },
                    { text: '我自己', value: 2 },
                ],
                option3: [
                    { text: '上传时间优先', value: '' },
                    { text: '发送次数优先', value: 1 },
                ],
                // 新建操作
                shows: false,
                typeOption: [
                    { name: "所有", id: "" },
                    { name: "文本", id: 1 },
                    { name: "图片", id: 2 },
                    { name: "图文", id: 3 },
                    { name: "音频", id: 4 },
                    { name: "视频", id: 5 },
                    { name: '小程序', id: 6 },
                    { name: '文件', id: 7 },
                    // { name: '雷达', id: 8 }
                ],
                // 编辑操作
                editShow: false,
                editActions: [
                    { name: '编辑' },
                    { name: '删除', color: '#ee0a24' }
                ],
                // 分享操作
                shareShow: false,
                finished: false,
                loading: false,
                refreshing: false,
                perPage: 10000,
                listData: {
                },
                fileId: '',
                shareData: {
                    title: '',
                    url: ''
                },
                shareDataFile: {},
                mediumList: [],
                currentName: '',//tab当前选中的选项
                currentID: undefined,//当前选中分组ID
                checked: false,
                page: 1,
                selectPop: false,//当为组件状态
                selectData: [],//当为组件选中数据
                selectType: undefined,
                oldSelectData:[],//组件状态已选数据

            }
        },
        watch: {},
        created() {//使用nextTick 防止组件状态获取不到值
            this.$nextTick(() => {
               this.getList()
            })
            this.group()
         
        },
        methods: {
            // 轨迹搜索
            onSearch() {
                this.page = 1;
                this.mediumList = [];
                this.getList()
            },
            onClickLeft() {
                this.$router.back()
            },
            // 新建
            onSelect(e) {
                this.shows = false
                this.$router.push({
                    path: '/medium/create',
                    query: {
                        type: e.subname
                    }
                })
            },
            show() {
                this.shows = true
            },
            onRefresh(e) {
            },
            onLoad() {
                this.page++;
                this.currentID != 8 ? this.getList() : this.getRadar()
            },
            group() {
                mediumGroup().then(res => {
                    this.option1.push(...res.data.map(i => {
                        return {
                            text: i.name,
                            value: i.id
                        }
                    }))
                })
            },
            editOr(e) {
                if (e.name == '编辑') {
                    this.$router.push({
                        path: '/medium/create',
                        query: {
                            id: this.fileId
                        }
                    })
                } else {
                    this.detele()
                }
            },
            detele() {
                destroy({
                    id: this.fileId
                }).then(res => {
                    this.$message.success('删除成功')
                    this.getList()
                })
            },

            forwardDatap() {
                this.shareShow = false
                let data = this.shareDataFile
                let shareData = {}
                if (data.type == '图片') {
                    shareData.type = 2
                    shareData.content = data.mediaId
                } else if (data.type == '图文') {
                    shareData.type = 3
                    shareData.content = data.content
                } else if (data.type == '雷达') {
                    shareData.type = 8
                    shareData.content = data.content
                } else if (data.type == '视频') {
                    shareData.type = 5
                    shareData.content = data.mediaId
                } else if (data.type == '小程序') {
                    shareData.type = 6
                    shareData.content = data.content
                } else if (data.type == '文件' || data.type == '音频') {
                    shareData.type = 7
                    shareData.content = data.mediaId
                }
                shareToExternalMoments(data.content.title, shareData).then(res => {
                    this.$message.success('转发成功')
                })
            },
            forwardData() {
                this.shareShow = false
                let data = this.shareDataFile
                let shareData = {}
                if (data.type == '图片') {
                    shareData.type = 2
                    shareData.content = data.mediaId
                } else if (data.type == '图文') {
                    shareData.type = 3
                    shareData.content = data.content
                } else if (data.type == '雷达') {
                    shareData.type = 8
                    shareData.content = data.content
                } else if (data.type == '视频') {
                    shareData.type = 5
                    shareData.content = data.mediaId
                } else if (data.type == '小程序') {
                    shareData.type = 6
                    shareData.content = data.content
                } else if (data.type == '文件' || data.type == '音频') {
                    shareData.type = 7
                    shareData.content = data.mediaId
                }
                sendChatMessage(shareData.type, shareData).then(res => {
                    this.$message.success('转发成功')
                })
            }
            ,
            copy() {
                this.shareShow = false
                var clipboard = new Clipboard("#copy_text");
                clipboard.on("success", (e) => {
                    this.$message.success('复制成功')

                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    this.$message.error('复制失败')
                    // 释放内存
                    clipboard.destroy();
                });
            }
            ,
            details(i) {
                console.log('i', i)
                let url = ''
                switch (i.type) {
                    case '文本':
                        break
                    case '图片':
                        url = i.content.imageFullPath
                        break
                    case '图文':
                        url = i.content.imageLink
                        break
                    case '音频':
                        url = i.content.voiceFullPath
                        break
                    case '视频':
                        url = i.content.videoFullPath
                        break
                    case '小程序':
                        url = i.content.page
                        break
                    case '文件':
                        url = i.content.fileFullPath
                        break
                    case '雷达':
                        url = i.content.imageLink
                        break
                }

                this.$router.push({
                    path: '/medium/detail',
                    query: { url }
                })
            },

            // 获取数据
            getList(type) {
                mediumList({
                    mediumGroupId: this.value1,
                    creatorType: this.value2,
                    order: this.value3,
                    searchStr: this.serchValue || '',
                    page: this.page,
                    type: this.currentID,
                    perPage: 10,
                }).then(res => {
                    this.mediumList.push(...res.data.list);
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if (this.mediumList.length >= res.data.page.total) {
                        this.finished = true;
                    } else {
                        this.finished = false;
                    }
                })
            },
            //左边列表点击 筛选分组
            handleClick(i, id) {
                this.spanIndex = i;
                this.value1 = id;
                this.page = 1;
                this.mediumList = [];
                this.selectType = undefined;
                if (this.currentID == 8) {//单独获取雷达数据
                    this.getRadar(id)
                    return
                }
                this.getList(this.currentID);
            },
            //tab点击  筛选分类
            onClick(name, title) {
                this.currentName = title;
                this.currentID = name
                this.spanIndex = 0;
                this.value1 = 0;
                this.page = 1;
                this.mediumList = [];
                this.selectType = undefined;
                if (name == 8) {//单独获取雷达数据
                    this.getRadar()
                    return
                }
                this.getList(name);
            },
            getRadar(id) {
                let params = {
                    mediumGroupId: id ? id : undefined,
                    page: this.page,
                    perPage: 10,
                }
                radarIndex(params).then(res => {
                    this.mediumList.push(...res.data.list);
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if (this.mediumList.length >= res.data.page.total) {
                        this.finished = true;
                    }
                })
            },
            //分享转发
            shareBtn(row) {
                this.currentID != 8 ? this.convenFun(row) : this.leidaFun(row);
            },
            //常规素材库
            convenFun(row) {
                let data = {};
                switch (row.type) {
                    case '文本':
                        data = {
                            description: row.content.content
                        };
                        shareToExternalContact(data);
                        break;
                    case '图片':
                        data = {
                            type: 2,
                            mediaid: row.mediaId,
                            content: row.content
                        };
                        shareToExternalContact(data);
                        break;
                    case '图文':
                        data = {
                            type: 7,
                            title: row.content.title,
                            share_link: row.content.imageFullPath,
                        };
                        shareToExternalContact(data);
                        break;
                    case '音频':
                        data = {
                            type: 5,
                            content: row.mediaId
                        };
                        shareToExternalContact(data);
                        break;
                    case '视频':
                        data = {
                            type: 5,
                            content: row.mediaId
                        };
                        shareToExternalContact(data);
                        break;
                    case '小程序':
                        data = {
                            type: 4,
                            content: row.content
                        };
                        shareToExternalContact(data);
                        break;
                    case '文件':
                        data = {
                            type: 6,
                            mediaid: row.mediaId
                        };
                        shareToExternalContact(data);
                        break;
                }
            },
            //雷达素材库
            leidaFun(row) {
                let data;
                switch (row.typeName) {
                    case '链接':
                        data = {
                            title: row.link_title,
                            description: row.link_description,
                            cover: row.link_cover,
                            share_link: row.linkUrl,
                            type: 3,
                        };
                        break;
                    case 'PDF':
                        data = {
                            title: row.title,
                            share_link: row.linkUrl,
                            type: 7,
                        };
                    case '文章':
                        data = {
                            title: row.title,
                            share_link: row.linkUrl,
                            type: 7,
                        };
                        break;
                }
                shareToExternalContact(data);
            },
            //群发
            massHair(row, type) {
                let data;
                if (this.currentID != 8) {
                    switch (row.type) {
                        case '文本':
                            data = {
                                description: row.content.content
                            };
                            shareToExternalChat(data);
                            break;
                        case '图片':
                            data = {
                                type: 2,
                                mediaid: row.mediaId,
                                content: row.content
                            };
                            shareToExternalChat(data);
                            break;
                        case '图文':
                            data = {
                                type: 7,
                                title: row.content.title,
                                share_link: row.content.imageFullPath,
                            };
                            shareToExternalChat(data);
                            break;
                        case '音频':
                            data = {
                                type: 5,
                                content: row.mediaId
                            };
                            shareToExternalChat(data);
                            break;
                        case '视频':
                            data = {
                                type: 5,
                                content: row.mediaId
                            };
                            shareToExternalChat(data);
                            break;
                        case '小程序':
                            data = {
                                type: 4,
                                content: row.content
                            };
                            shareToExternalChat(data);
                            break;
                        case '文件':
                            data = {
                                type: 6,
                                mediaId: row.mediaId
                            };
                            shareToExternalChat(data);
                            break;
                    }
                } else {
                    switch (row.typeName) {
                        case '链接':
                            data = {
                                title: row.link_title,
                                description: row.link_description,
                                cover: row.link_cover,
                                share_link: row.linkUrl,
                                type: 3,
                            };
                            break;
                        case 'PDF':
                            data = {
                                title: row.title,
                                share_link: row.linkUrl,
                                type: 7,
                            };
                        case '文章':
                            data = {
                                title: row.title,
                                share_link: row.linkUrl,
                                type: 7,
                            };
                            break;
                    }
                    shareToExternalContact(data);
                }
            },
            //确认数据
            btnClick() {
                if (this.oldSelectData.length+this.selectData.length > 9) {
                    this.$toast({
                        message: '最多添加9个素材',
                        position: 'top',
                    });
                    return;
                }
                this.$emit('popSubmit', this.selectData)
            },
            //取消
            cellback() {
                this.$emit('closePop', '')
            },

        }
    }
</script>

<style lang="less" scoped>
/deep/ .van-dropdown-item {
    top: 92px !important;
  }
    /deep/ .van-dropdown-menu__bar {
        box-shadow: none;
    }

    /deep/ .van-tabs__line {
        bottom: 9px;
        margin-top: 0;
    }

    /deep/.van-tabs__nav--line {
        padding-right: 0;
        padding-left: 0;
    }

    /deep/ .van-tabs__nav--line {
        padding-bottom: 1px;
    }

    /deep/ .van-tab__text {
        margin-bottom: 10px;
    }

    /deep/ .van-tab {
        background: #ffffff;
    }

    /deep/ .van-tabs__nav {
        background: #F1f2f5;
    }

    /deep/ .van-empty__image img {
        object-fit: contain;
    }

    /deep/ .van-dropdown-menu__bar {
        height: 45px;
    }

    /deep/ .van-ellipsis {
        font-size: 13px;
    }

    /deep/ .van-action-sheet__description {
        font-size: 16px;
        color: #444;
    }

    /deep/ .van-tabs__content {
        height: calc(100vh - 138px);
    }

    /deep/ .van-tab__pane {
        height: calc(100vh - 138px);
    }
    /* /deep/ .van-list__finished-text{
       position: relative;
       bottom: 120px; 
    } */
    .index {
        font-size: 14px;
        height: 100%;
        background: #f2f4f8;

        p {
            margin: 0 !important;
        }

        .fs17 {
            font-size: 15px;
        }

        .fs13 {
            font-size: 12px;
        }

        .fs14 {
            font-size: 14px;
        }

        .title-serch {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;

        }

        .bet {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .checkMarkting {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 18px 10px 18px;

            .info {
                .title {
                    color: #000;
                    margin-bottom: 5px;
                }

                .infotxt {
                    color: #8c8c8c;
                    line-height: 21px;
                    flex-grow: 1;
                }

                .more {
                    width: 16px;
                    height: 16px;
                    margin-right: 20px;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .share-icon {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 12px 10px;

            div {
                display: flex;
                flex-direction: column;
                /* justify-content: center; */
                align-items: center;
                color: #797979;
            }
        }

        .content {
            background: #f2f4f8;
            min-height: 92%;
            margin-top: -15px;

            //padding-top: 5px;
            img {
                width: 22px;
                height: 22px;
            }

            .van-hairline--bottom {
                &::after {
                    border-bottom-width: 1px;
                    position: absolute;
                    box-sizing: border-box;
                    content: " ";
                    pointer-events: none;
                    top: -50%;
                    right: -50%;
                    bottom: -50%;
                    left: -50%;
                    border: 0 solid #f2f4f8;
                    -webkit-transform: scale(.5);
                    transform: scale(.5);
                }
            }

            .item-flex {
                padding: 12px 16px 12px 16px !important;
                /* margin-bottom: 120px; */
            }

            .item {
                padding: 12px 16px;
                width: 100%;
                .title {
                    width: 100%;
                    font-size: 12px;

                    div:nth-child(1) {
                        color: #444;
                        font-size: 12px;
                    }

                    img {
                        width: 14px;
                        height: 14px;
                    }

                    .typeBox {
                        width: 69px;
                        height: 69px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .item-body {
                    .body-item {
                        background: #f4f5f7;
                        padding: 18px 15px;
                        margin-top: 10px;
                        border-radius: 12px;
                        font-size: 12px;
                        color: #585757;

                        div {
                            margin-bottom: 5px;
                        }

                        div:nth-child(1) {
                            color: #444;
                            margin-bottom: 10px;

                            span {
                                background: #c277ff;
                                color: #fff;
                                padding: 2px 3px;
                                border-radius: 5px;
                                font-size: 12px;
                                margin-right: 9px;
                            }
                        }

                        div:last-child {
                            margin-top: 10px;
                            color: #939393;
                        }
                    }
                }

                .items-flex {
                    display: inline-flex;
                }

                .items {
                    background: #fff;
                    padding: 10px 13px;
                    margin-bottom: 10px;
                    border-radius: 5px;

                    width: 100%;

                    .checkbox {
                        display: flex;
                        align-items: center;
                        margin-right: 20px;
                    }

                    .title {
                        display: flex;

                        img {
                            width: 60px;
                            height: 60px;
                        }

                        .share {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin-left: 10px;
                            min-height: 50px;

                            div:nth-child(1) {
                                color: #444;
                                font-weight: 530;
                                // white-space: nowrap;
                                // text-overflow: ellipsis;
                                overflow: hidden;
                                // display: -webkit-box;
                                // -webkit-line-clamp: 2;
                                // -webkit-box-orient: vertical;
                                // margin-right:10px;

                            }

                            div:nth-child(2) {
                                color: #ccc;
                                font-size: 12px;
                                width: 80px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }

                            div span {
                                /* overflow: hidden;
                  -webkit-line-clamp: 2;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical; */

                                word-break: break-all;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                /* 这里是超出几行省略 */
                                overflow: hidden;
                            }

                        }
                    }

                    .share-button {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-top: 10px;
                        border-top: 1px solid #F1F1F1;
                        padding-top: 10px;

                        div:nth-child(1) {
                            padding: 0 15px;
                            background: #F3FFE6;
                            border-radius: 12px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #5FAD0B;
                            height: 24px;
                            line-height: 24px;
                        }

                        div:nth-child(2) {
                            margin-left: 5px;
                            padding: 0 15px;
                            background: #E6E8F4;
                            border-radius: 12px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #4871BF;
                            height: 24px;
                            line-height: 24px;

                            img {
                                width: 12px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .main {
        height: 100%;
        width: 100%;
        background: #FAFBFD;

        .mainLeft {
            float: left;
            width: 22%;
            height: 100%;

            .m16 {
                text-align: center;
                padding-top: 6px;
                padding-bottom: 6px;
                line-height: 24px;
                font-size: 12px;
                color: #515151;
                width: 100%; //宽度
                overflow: hidden; //超出文本隐藏
                text-overflow: ellipsis; ///超出部分省略号显示
                display: -webkit-box; //弹性盒模型
                -webkit-box-orient: vertical; //上下垂直
                -webkit-line-clamp: 2; //自定义行数
            }

            .active {
                background: #fff;
                border-radius: 0 6px 6px 0;
                text-align: center;
                padding-top: 6px;
                padding-bottom: 6px;
                line-height: 24px;
                font-size: 12px;
                border-left: 2px solid #2a8ff7;
                color: #2a8ff7;
                width: 100%; //宽度
                overflow: hidden; //超出文本隐藏
                text-overflow: ellipsis; ///超出部分省略号显示
                display: -webkit-box; //弹性盒模型
                -webkit-box-orient: vertical; //上下垂直
                -webkit-line-clamp: 2; //自定义行数
            }
        }

        .mainRight {
            float: left;
            width: 78%;
            height: 100%;
            background-color: #f3f4f8;
            overflow: hidden;
            overflow-y: scroll;

            .content {
                width: 92%;
                min-height: 100px;
                background: #ffffff;
                border-radius: 6px;
                margin: 0 auto;
                margin-top: 11px;
                overflow: hidden;

                .content_title {
                    width: 96%;
                    margin: 0 auto;
                    min-height: 54px;
                    font-size: 13px;
                    color: #4a4a4a;
                    line-height: 18px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                }

                .content_img {
                    height: 44px;
                    margin: 12px 0 12px 10px;

                    img {
                        float: left;
                        width: 44px;
                        height: 44px;
                        background: #d8d8d8;
                        border-radius: 8px;
                        margin-right: 8px;
                    }

                    .picName {
                        float: left;
                        vertical-align: top;
                        font-size: 14px;
                        font-weight: 500;
                        color: #4a4a4a;
                        height: 100%;
                        width: calc(100% - 60px); //宽度
                        overflow: hidden; //超出文本隐藏
                        text-overflow: ellipsis; ///超出部分省略号显示
                        display: -webkit-box; //弹性盒模型
                        -webkit-box-orient: vertical; //上下垂直
                        -webkit-line-clamp: 2; //自定义行数
                    }
                }

                .bottom {
                    width: 94%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 42px;
                    margin: 0 auto;
                    border-top: 1px solid #f1f1f1;

                    .bottom_left {
                        font-size: 11px;
                        color: #a5a5a5;
                    }

                    .bottom_right {
                        text-align: center;
                        width: 49px;
                        height: 23px;
                        line-height: 23px;
                        background: #f5f7ff;
                        border-radius: 12px;
                        font-size: 12px;
                        color: #2a8ff7;
                    }
                }
            }
        }
    }

    .card {
        /* margin-top: 20px; */
        width: 100%;
        background: #fff;
        border: 1px solid #f0f0f0;
        padding: 10px 12px;

        .title {
            font-size: 14px;
            color: #191919;
        }

        .flex {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .desc {
            width: 85%;
            font-size: 12px;
        }

        img {
            width: 47px;
            height: 47px;
            object-fit: cover;
        }
    }

    .btnBox {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 106px;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 10px 12px;

        .cancel {
            width: 30%;
            height: 48px;
            padding: 12px 0;
            text-align: center;
            background: #F1F2F6;
            border-radius: 5px;
            border: 1px solid #D7D7D9;
            font-size: 17px;
            font-weight: 500;
            color: #595A5E;
        }

        .submit {
            width: 65%;
            height: 48px;
            text-align: center;
            padding: 12px 0;
            background: #3974C6;
            border-radius: 5px;
            font-size: 17px;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
</style>