<template>
    <div class="page">
        <div class="banner"
            :style="{'background-image': 'url(' + configImg + ')','background-repeat':'no-repeat','background-size':'100% 100%' }">
        </div>
        <!-- <van-empty description="暂无数据" v-if="listData.length==0"/> -->
        <div class="listBox">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div class="bannerBox" v-for="(item,index) of listData" :key="index">
                    <img :src="item.avatar" alt="">
                    <div class="boxFlex">
                        <div class="flexTop">
                            <span>{{item.name}}</span>
                        </div>
                        <div class="flexTips">{{item.abstract}}</div>
                        <div class="flexBom" @click="goPage(item)">立即使用</div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>
<script>
    import { getTwoCategory } from "@/api/festival";
    export default {
        data() {
            return {
                listData: [],
                configImg: '',
                loading: false,
                finished: false,
                pageParams: {
                    pid: '',
                    page: 0,
                    perPage: 10
                }
            };
        },
        created() {
            let json = JSON.parse(this.$route.query.pidData)
            this.pageParams.pid = json.id
            this.configImg = json.avatar
            // this.getData()
        },
        methods: {
            getData() {
                getTwoCategory(this.pageParams).then((res) => {
                    if (res.code == 200) {
                        setTimeout(() => {
                            this.listData.push(...res.data.list)
                            // 加载状态结束
                            this.loading = false;
                            // 数据全部加载完成
                            if (this.listData.length >= res.data.page.total) {
                                this.finished = true;
                            } else {
                                this.finished = false;
                            }
                        }, 1000);
                    }
                })
            },
            goPage(row) {
                this.$router.push({ path: "/festival/festivalUse?id=" + row.id });
            },
            // 下拉加载
            onLoad() {
                this.pageParams.page++;
                this.getData()
            },
        },
    };
</script>
<style lang="less" scoped>
    .page {
        .banner {
            width: 100%;
            height: 187px;
            box-shadow: 0px 0px 3px 0px rgba(214,214,214,0.5);
            // position: fixed;
            // top: 0px;
            // z-index: 99;
        }
        .listBox {
            // margin-top: 187px;
            margin-top: 13px;
            .bannerBox {
                width: 100%;
                padding: 13px 10px;
                background: #FFFFFF;
                border-radius: 4px;
                display: flex;
                flex-direction: row;
                img {
                    width: 135px;
                    height: 90px;
                    border-radius: 4px;
                    box-shadow: 0px 0px 3px 0px rgba(214,214,214,0.5);
                }
                .boxFlex {
                    margin-left: 10px;
                    position: relative;
                    .flexTop {
                        line-height: 0.5;
                        :first-child {
                            font-size: 17px;
                            font-weight: bold;
                            color: #262626;
                        }
                    }
                    .flexTips {
                        margin-top: 8px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #717174;
                        overflow: hidden; //溢出内容隐藏
                        text-overflow: ellipsis; //文本溢出部分用省略号表示
                        display: -webkit-box; //特别显示模式
                        -webkit-line-clamp: 2; //行数
                        line-clamp: 2;
                        -webkit-box-orient: vertical; //盒子中内容竖直排列
                    }
                    .flexBom {
                        position: absolute;
                        bottom: 0;
                        width: 76px;
                        padding: 4px 13px;
                        background: #FD8927;
                        border-radius: 12px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
</style>