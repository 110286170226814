<template>
  <div class="all">
    <div class="fliter">
      <van-dropdown-menu active-color="#3974C6">
        <van-dropdown-item
          v-model="value1"
          :options="option1"
          @change="selectClassify1"
        />
        <van-dropdown-item
          v-model="value2"
          :options="option2"
          @change="selectClassify2"
        />
      </van-dropdown-menu>
    </div>
    <div v-if="newsData.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="我也是有底线的~"
        @load="onLoad"
      >
        <div class="index">
          <van-checkbox-group v-model="selectIds" :max="5">
            <div class="content" v-for="(item, index) in newsData" :key="index">
              <van-checkbox :name="item.id">
                <!-- <div class="conTop">{{ item.title }}</div>
                <div class="conMid" @click="skipDetail(item.id)">
                  {{ item.summary }}
                </div> -->
                <div v-if="item.content_type == 1">
                  <div class="conTop" @click="skipDetail(item.id)">
                    {{ item.title }}
                  </div>
                  <div class="conMid" @click="skipDetail(item.id)">
                    {{ item.summary }}
                  </div>
                  <button class="conBot" v-if="item.author_name != ''">
                    {{ item.author_name }}
                  </button>
                </div>
                <div v-else-if="item.content_type == 2">
                  <div class="conTop2">
                    <div class="conTopLeft">
                      <img
                        v-if="item.fullCover != ''"
                        class="linkCover"
                        :src="item.fullCover"
                        alt=""
                        @click="skipDetail(item.id)"
                      />
                      <img
                        class="linkCover"
                        v-else
                        src="../../../assets/link-url.png"
                        alt=""
                        @click="skipDetail(item.id)"
                      />
                    </div>
                    <div class="conTopRight">
                      <div class="title">{{ item.title }}</div>
                      <div class="summary" @click="skipDetail(item.id)">
                        {{ item.summary }}
                      </div>
                    </div>
                  </div>
                  <button class="conBot" v-if="item.author_name != ''">
                    {{ item.author_name }}
                  </button>
                </div>
                <div v-else>
                  <div class="conTop">{{ item.title }}</div>
                  <div class="conMid">
                    {{ item.summary }}
                  </div>
                  <div class="rightposter">
                    <img
                      class="poster"
                      :src="item.fullPoster"
                      @click.stop="createPoster(item.fullPoster)"
                      alt=""
                    />
                    <div class="userInfo" v-if="avatar != ''">
                      <div class="left">
                        <img class="avatar" :src="userInfo.fullImage" alt="" />
                        <div class="info">
                          <div class="name">{{ userInfo.name }}</div>
                          <div class="m12">{{ userInfo.departmentName }}</div>
                        </div>
                      </div>
                      <div class="right">
                        <img class="qrcode" :src="userInfo.qrCode" alt="" />
                      </div>
                    </div>
                  </div>
                  <button class="conBot" v-if="item.author_name != ''">
                    {{ item.author_name }}
                  </button>
                </div>
                <div style="height: 8px"></div>
              </van-checkbox>
            </div>
          </van-checkbox-group>
        </div>
      </van-list>
    </div>
    <div v-else>
      <van-empty description="暂无资讯~" style="padding: 0" />
    </div>
    <div class="last">
      <!-- <div class="btn1">随机选5条</div> -->
      <div class="btn2" @click="confirm">确定</div>
    </div>
  </div>
</template>
    <script>
import { newsList, newsCategory } from "@/api/electronicCard";
import { setDaodu } from "@/api/morningPaper";
import { vcardIndex, getbase64 } from "@/api/electronicCard";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      newsData: [],
      selectIds: [],
      option1: [],
      option2: [
        { text: "全部时间", value: 0 },
        { text: "最新资讯", value: 1 },
        { text: "近7日资讯", value: 2 },
      ],
      value1: "",
      value2: 0,
      type: "",
      category: "",
      time: "",
      userInfo: "",
      avatar: "",
    };
  },
  created() {
    this.getData();
    this.getCategory();
    this.getUserInfo();
    this.type = this.$route.query.type;
  },
  methods: {
    getCategory() {
      newsCategory().then((res) => {
        this.option1 = res.data.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      });
    },
    selectClassify1() {
      this.page = 1;
      this.newsData = [];
      this.category = this.value1;
      this.getData();
    },
    selectClassify2() {
      this.page = 1;
      this.newsData = [];
      this.time = this.value2;
      this.getData();
    },
    getData() {
      newsList({
        categoryId: this.category,
        time: this.time,
        page: this.page,
        perPage: 10,
      }).then((res) => {
        // this.newsData = res.data.list;
        this.newsData.push(...res.data.list);
        this.loading = false;
        if (this.newsData.length >= res.data.page.total) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      this.page += 1;
      this.getData();
    },
    skipDetail(id) {
      this.$router.push({
        path: "/electronicCard/caijing/detail",
        query: {
          id,
        },
      });
    },
    confirm() {
      console.log("ddd", this.selectIds);
      setDaodu({
        type: this.type,
        informationIds: this.selectIds,
      }).then((res) => {
        if (res.code == 200) {
          this.$router.go(-1);
          this.$toast("设置成功");
        }
      });
    },
    getUserInfo() {
      vcardIndex().then((res) => {
        this.userInfo = res.data.employee;
        this.avatar = res.data.employee.fullImage;
      });
    },
    createPoster(imgurl) {
      console.log("imgUrl", imgurl);
      if (this.avatar != "") {
        this.$router.push({
          path: "/poster/create",
          query: { imgurl, qrcode: this.qrcode, from: "topicDetail" },
        });
      }
    },
  },
};
</script>
    <style lang="less" scoped>
/deep/ .van-dropdown-item {
  top: 42px !important;
}
/deep/ .van-checkbox__icon {
  padding-left: 6px;
}
.all {
  height: 100%;
  background-color: #fff;
  overflow: auto;
}
.index {
  width: 94%;
  margin: 0 auto;
}
.header {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}
.content {
  min-height: 120px;
  background-color: #fff;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-bottom: 10px;
  margin-top: 10px;
  .conTop {
    padding: 8px;
    font-size: 16px;
    color: #333333;
  }
  .conMid {
    padding: 0 8px;
    /* min-height: 53px; */
    font-size: 13px;
    color: #4a4a4a;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .conBot {
    text-align: center;
    padding: 8px;
    border: none;
    margin-left: 8px;
    margin-top: 4px;
    min-height: 20px;
    border-radius: 4px;
    font-size: 11px;
    color: #333333;
    background-color: #f7f7f7;
  }
}
.last {
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  padding: 10px 10px;
  background-color: #ffffff;
  justify-content: center;
  .btn1,
  .btn2 {
    width: 85%;
    height: 42px;
    border-radius: 5px;
    line-height: 42px;
    text-align: center;
  }
  .btn1 {
    font-size: 14px;
    font-weight: bold;
    color: rgb(68, 60, 60);
    background: #f7f7f7;
  }
  .btn2 {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    background: #3974c6;
  }
}
.rightposter {
  margin-left: 8px;
  padding-bottom: 10px;
  .poster {
    width: 144px;
    height: 304px;
  }
  .userInfo {
    display: flex;
    justify-content: space-between;
    width: 144px;
    /* height: 100px; */
    .left {
      display: flex;
      width: 80%;
      margin-top: 8px;
      .avatar {
        width: 30%;
        height: 40px;
        vertical-align: unset !important;
      }
      .info {
        margin-left: 3px;
        .name {
          // margin-top: 8px;
          font-size: 10px;
        }
        .m12 {
          font-size: 8px;
        }
      }
    }
    .right {
      margin-top: 8px;
      width: 20%;
      .qrcode {
        width: 90%;
        height: 30px;
      }
    }
  }
}
.conTop2 {
  display: flex;
  width: 96%;
  height: 82px;
  margin: 0 auto;
  .conTopLeft {
    margin-top: 14px;
    .linkCover {
      width: 57px;
      height: 57px;
    }
  }
  .conTopRight {
    margin-top: 14px;
    width: 81%;
    margin-left: 10px;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .summary {
      font-size: 13px;
      color: #4a4a4a;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical; //盒子中内容竖直排列
    }
  }
}
</style>