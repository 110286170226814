import request from "@/utils/request";

/**
 * 我的
 */
export class MyApi {
  // 今日排行
  static todayRank(params) {
    return request({
      url: "/MySummary/todayRank",
      method: "get",
      params: params,
    });
  }
  // 行为积分
  static memberIntegral(params) {
    return request({
      url: "/behaviorScore/index",
      method: "get",
      params: params,
    });
  }
  // 我的排行
  static myRank(params) {
    return request({
      url: "/MySummary/rank",
      method: "get",
      params: params,
    });
  }
  // 每日小结
  static daySummary(params) {
    return request({
      url: "/MySummary/workSummary",
      method: "get",
      params: params,
    });
  }
  // 点赞
  static myLike(params) {
    return request({
      url: "/MySummary/likes",
      method: "post",
      data: params,
    });
  }
}
