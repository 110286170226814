<template>
  <div class="component">
    <van-action-sheet v-model="show" title="添加图片素材">
      <div class="content" style="color: #818181">
        <div class="user-list">
          <van-field
            v-model="items.title"
            input-align="right"
            label="图片标题："
            placeholder="请输入图片标题"
          />
          <van-cell required title="图片">
            <van-uploader
              :after-read="afterRead"
              v-model="items.fileList"
              :max-size="2000 * 1024"
              @oversize="onOversize"
              multiple
              :max-count="1"
            />
          </van-cell>
        </div>

        <van-button
          @click="determine"
          block
          style="
            width: 90%;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
          "
          type="info"
          :disabled="isdisabled"
          ref="btn"
          >确定
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { store, upload } from "../../../api/contactWelcome";

export default {
  data() {
    return {
      isdisabled: true,
      show: false,
      items: {
        title: "",
        status: "",
        fileList: [],
      },
      index: "无",
      edit: false,
    };
  },
  watch: {},
  methods: {
    $show(data) {
      this.isdisabled = true;
      this.show = true;
      this.items = {
        title: "",
        fileList: [],
      };
      if (data.edit) {
        this.data = data;
        this.items = data.content;
        this.index = data.index;
      } else {
        this.data = data;
      }
    },
    determine(e) {
      if (this.items.fileList.length <= 0) {
        this.$message.warning("请选择图片素材");
        this.show = false;
        return;
      } else {
        store({
          content: {
            imageName: this.items.title,
            title: this.items.title,
            imagePath: this.items.fileList[0].path,
          },
          mediumGroupId: 0,
          type: 2,
        }).then((res) => {
          const data = {
            content: {
              title: this.items.title,
              fileList: this.items.fileList,
              file: this.items.fileList[0].url,
              id: res.data.id,
              type: "【图片】：",
              types: 1,
            },
            data: this.data,
          };
          this.$emit("change", data);
          this.show = false;
        });
      }
    },
    // 文件大小
    onOversize(file) {
      this.$message.warning("文件大小不能超过 2MB");
    },
    // 上传文件
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      var formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        file.status = "done";
        this.isdisabled = false;
        this.items.title = res.data.name;
        this.items.fileList[0] = {
          url: res.data.fullPath,
          path: res.data.path,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
  .content {
    padding: 0;

    .user-list {
      height: 275px;
      overflow: auto;

      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }
    }
  }
}
</style>
