<template>
  <div class="recharge">
    <div class="top">小班提问充值的次数永久有效</div>
    <div class="time">
      <div
        :class="[spanIndex == index ? 'activeSp' : 'sp']"
        v-for="(res, index) in Times"
        :key="index"
        @click="handClick(res, index)"
      >
        {{ res.name }}
      </div>
    </div>
    <div class="bottom">
      <div class="left">¥ {{ momey }}</div>
      <div class="right" @click="payOrder">去支付</div>
    </div>
    <van-dialog
      v-model="showQrcode"
      title="请扫码支付"
      show-cancel-button
      @confirm="queding"
      @cancel="quexiao"
    >
      <div class="qrcode" ref="qrCodeDiv"></div>
    </van-dialog>
    <!-- <van-dialog
      v-model="showSucced"
      @confirm="goCharge"
      @cancel="fanhui"
      show-cancel-button
      cancelButtonText="返回"
      confirmButtonText="继续充值"
    >
      <div class="bind2">付款成功，对公业务更新次数已更新。</div>
    </van-dialog> -->
  </div>
</template>
<script>
import { createOrder, queryStatus } from "@/api/xiaoban";
import { judge } from "../../utils/judge.js";
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      spanIndex: 0,
      momey: 0.2,
      rechargeTimes: 1,
      device: "",
      order: "",
      showQrcode: false,
      timer: "",
      Times: [
        { name: "1次", value: 1 },
        { name: "5次", value: 5 },
        { name: "10次", value: 10 },
        { name: "50次", value: 50 },
        { name: "100次", value: 100 },
        { name: "500次", value: 500 },
      ],
    };
  },
  created() {
    this.device = judge();
    console.log("this.device", this.device);
  },
  methods: {
    handClick(res, index) {
      console.log("res", res);
      this.spanIndex = index;
      this.rechargeTimes = res.value;
      this.momey = res.value * 0.2;
    },
    payOrder() {
      createOrder({
        rechargeTimes: this.rechargeTimes,
        device: this.device,
      }).then((res) => {
        console.log("下单", res);
        this.order = res.data.orderNo;
        console.log("this.order", this.order);
        this.handlePay(res);
      });
    },
    handlePay(res) {
      if (res.code == 200) {
        if (this.device == "m") {
          let orderId = this.order;
          let url = encodeURIComponent(
            `${
              location.protocol + "//" + location.host
            }/payPopu/index?id=${orderId}&type=2`
          );
          window.location.href = res.data.wechctUrl + `&redirect_url=${url}`;
        } else {
          this.showQrcode = true;
          this.$nextTick(() => {
            new QRCode(this.$refs.qrCodeDiv, {
              text: res.data.wechctUrl, //url地址  文本等需要转换为二维码的内容
              width: 80,
              height: 80,
              colorDark: "#333333", //二维码颜色
              colorLight: "#ffffff", //二维码背景色
              correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
            });
          });
          var that = this;
          this.timer = setInterval(function () {
            console.log("order", that.order);
            queryStatus({ orderNo: that.order }).then((res) => {
              console.log("res", res);
              if (res.data.status == 2) {
                that.showQrcode = false;
                this.$toast("充值成功");
                location.reload();
                clearInterval(that.timer);
              }
            });
          }, 1000);
        }
      } else {
        this.$toast(res.sMsg);
      }
    },
    quexiao() {
      clearInterval(this.timer);
      this.$router.push({
        path: "/xiaoban/index",
      });
    },
    queding() {
      clearInterval(this.timer);
    },
  },
};
</script>
<style lang="less" scoped>
.recharge {
  overflow: auto;
}
.top {
  margin: 33px 0 40px 18px;
  font-size: 15px;
  color: #000000;
}

.sp,
.activeSp {
  float: left;
  margin-bottom: 20px;
  width: 97px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.activeSp {
  margin-left: 24px;
}
.sp {
  margin-left: 24px;
  background: #f2f3f7;
}
.activeSp {
  background: #ecf0fc;
  border: 1px solid #3974c6;
}
.sp:nth-child(3n + 1),
.activeSp:nth-child(3n + 1) {
  margin-left: 18px;
}
.bottom {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 20px;
  width: 90%;
  margin: 0 auto;
  height: 102px;
  background: #ffffff;
}
.right {
  width: 134px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  background: #3974c6;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}
.left {
  font-size: 22px;
  font-weight: bold;
  color: #3974c6;
}
.qrcode {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  margin-top: 16px;
  margin-bottom: 10px;
}
</style>