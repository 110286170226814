<template>
    <div class="pageBox">
        <div class="box">
            <div class="title">请在虚线框内签字</div>
            <vue-esign class="esign" ref="esign" :width="390" :height="492" :isCrop="isCrop" :lineWidth="lineWidth"
                :lineColor="lineColor" :bgColor.sync="bgColor" :isClearBgColor="clearBgColor" />

        </div>
        <div class="footer">
            <div class="cancel" @click="handleReset">重置</div>
            <div class="btn" @click="handleGenerate">确定</div>
        </div>
    </div>
</template>

<script>
    import vueEsign from 'vue-esign'
    import { merchantImgSig, uploadBase64Image } from "@/api/Inspection";
    export default {
        components: { vueEsign },
        data() {
            return {
                lineWidth: 3,
                lineColor: '#000000',
                bgColor: '#F9F9F9',
                resultImg: '',
                isCrop: false,
                clearBgColor: false
            };
        },
        mounted() {

        },
        methods: {
            handleReset() {
                this.$refs['esign'].reset() //清空画布
            },
            handleGenerate() {
                this.$refs['esign'].generate().then(res => {
                    uploadBase64Image({ base64ImageContent: res }).then((row) => {
                        if (row.code == 200) {
                            // let params = {
                            //     id: this.$route.query.id,
                            //     merchantImgSig: row.data.path,
                            // }
                            // merchantImgSig(params).then((sig) => {
                            //     if (sig.code == 200) {
                            //         this.$message.success('已提交签名')
                            //         this.$router.back();
                            //     }
                            // })

                            // this.resultImg =  row.data.path // 得到了签字生成的base64图片
                            localStorage.setItem("merchantImgSig", row.data.path);

                             this.$emit('submit',row.data.path);
                            // setTimeout(()=>{
                            //     this.$router.back();
                            // },500)
                        }
                    })
                }).catch(err => { //  没有签名，点击生成图片时调用
                    console.log("err",err)
                    this.$message.warning('未签名！');
                })
            }

        }
    };
</script>

<style lang="less" scoped>
    .pageBox {
        .box {
            padding: 24px;

            .title {
                font-weight: 400;
                font-size: 14px;
                color: #9B9B9B;
                margin: 14px 0;
            }
        }

        .footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(214, 214, 214, 0.5);
            position: absolute;
            bottom: 0px;
            width: 100%;
            padding: 24px;

            .cancel {
                width: 97px;
                height: 48px;
                line-height: 48px;
                background: #F1F2F6;
                border-radius: 5px;
                border: 1px solid #D7D7D9;
                /* padding: 12px 32px; */
                font-weight: 500;
                font-size: 17px;
                color: #595A5E;
                text-align: center;
            }

            .btn {
                width: 244px;
                height: 48px;
                line-height: 48px;
                background: #3974C6;
                border-radius: 5px;
                font-weight: 500;
                font-size: 17px;
                color: #FFFFFF;
                /* padding: 12px 32px; */
                text-align: center;
            }
        }
    }

    .esign {
        background: #F9F9F9;
        border-radius: 10px;
        border: 2px dashed #A6C5F6;
    }
</style>