<template>
    <div class="page">
        <div class="conBox">
            <div class="con">
                <div class="title">客户信息</div>
                <div class="t1">
                    <div class="t1-left">客户编号</div>
                    <div class="t1-right">{{merchantData.serialNumber}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户名称</div>
                    <div class="t1-right">{{merchantData.name}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户类型</div>
                    <div class="t1-right">{{merchantData.type==1?'个人':'商户'}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">联系人姓名</div>
                    <div class="t1-right">{{merchantData.contactName}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">联系电话</div>
                    <div class="t1-right">{{merchantData.contactPhone}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">地址</div>
                    <div class="t1-right">{{merchantData.address}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">经纬度</div>
                    <div class="t1-right">{{merchantData.longitude}}，{{merchantData.latitude}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户状态</div>
                    <div class="t1-right">{{merchantData.status==1?'正常':'注销'}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">经办支行</div>
                    <div class="t1-right">{{merchantData.departmentName}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户经理</div>
                    <div class="t1-right">{{merchantData.accountManagerName}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">创建时间</div>
                    <div class="t1-right">{{merchantData.createdAt}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">最近更新时间</div>
                    <div class="t1-right">{{merchantData.updatedAt}}</div>
                </div>
            </div>
            <div class="nextCon m10" v-if="historyData.length>0">
                <div class="title">历史拓展内容记录</div>
                <van-steps direction="vertical" :active="0">
                    <van-step v-for="(item,index) in historyData" :key="index">
                        <div class="stepBox">
                            <div class="heard">
                                <div class="time">{{item.createdAt}}</div>
                            </div>
                            <div class="content" v-if="item.launch">
                                <div class="text">拓展地址：{{item.address}}</div>
                                <div class="text">图片:
                                    <img style="width: 50px;height: 50px;" :src="i" @click="preview(i)" v-for="(i,index) in item.photographyRecords" key="index" alt="">
                                </div>
                                <div class="text">备注：{{item.remark}}</div>
                            </div>
                            <div class="btnImg">
                                <img v-if="!item.launch" src="../../../assets/images/ins/down.png" alt=""
                                    @click="toggleExpand(item,index)">
                                <img v-else src="../../../assets/images/ins/up.png" alt=""
                                    @click="toggleExpand(item,index)">
                            </div>
                        </div>
                    </van-step>
                </van-steps>
            </div>
            <!-- 底部按钮 -->
            <div class="footer m10">
                <div class="box">
                    <div class="btn2" @click="submitBtn">拓展商户</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store, upload } from "@/api/contactWelcome";
    import { customDetail } from "@/api/gridManage";
    import { ImagePreview } from 'vant';
    export default {
        data() {
            return {
                detailJson: {
                    merchantImgPhoto: '',
                    merchantImgCashier: '',
                    merchantImgPremisesPhoto: '',
                    merchantImgPaymentCode: '',
                    merchantImgInformation: '',
                    merchantImgOther: '',
                    informationOne: undefined,
                    informationTwo: undefined,
                    informationThr: undefined,
                    informationFou: undefined,
                    informationFiv: undefined,
                    informationSix: undefined,
                    informationSev: undefined,
                    informationEig: undefined,
                    informationNie: undefined,
                    informationTen: undefined,
                    informationEle: undefined,
                    informationTwe: undefined,
                    informationThi: '',
                    trainingContent: [],
                    abnormalStatus: 1,
                    abnormalType: undefined,
                },
                merchantData: {},
                serviceList: [],// 请选择成员
                params: {

                },
                imgData: {
                    merchantImgPhoto: [],
                    merchantImgCashier: [],
                    merchantImgPremisesPhoto: [],
                    merchantImgPaymentCode: [],
                    merchantImgInformation: [],
                    merchantImgOther: [],
                },
                options: [
                    {
                        key: 1,
                        value: "POS及二维码交易的操作流程和要求"
                    },
                    {
                        key: 2,
                        value: "银行卡的识别、伪卡辨别、没收卡的处理及受理操作 流程"
                    },
                    {
                        key: 4,
                        value: "刷卡交易完成后，需核对持卡人签名与卡背面预留签 名是否一致"
                    },
                    {
                        key: 8,
                        value: "银行卡交易资金结算、对账数据获取方式及要求、调 账、差错处理流程"
                    },
                    {
                        key: 16,
                        value: "明确消费撤销、退货、消费调整等高风险交易的授权管理"
                    },
                    {
                        key: 32,
                        value: "POS机受理IC卡及非接触式IC卡的操作方法及注意事项"
                    },
                    {
                        key: 64,
                        value: "交易单据的保管方式与保管时限要求"
                    },
                    {
                        key: 128,
                        value: "机具及二维码的正确使用方法和简单故障排查"
                    },
                    {
                        key: 256,
                        value: "常见欺诈行为的识别及应用技巧"
                    },
                    {
                        key: 512,
                        value: "商户风险责任及风险防范知识"
                    },
                    {
                        key: 1024,
                        value: "违规行为及救济措施"
                    },
                    {
                        key: 2048,
                        value: "调单的处理要求"
                    },
                    {
                        key: 4096,
                        value: "财务处理流程、投诉、差错、争议处理要求"
                    }
                ],
                options2: [
                    {
                        key: 1,
                        value: "条码"
                    }, {
                        key: 2,
                        value: "POS"
                    }],
                options3: [
                    {
                        key: 1,
                        value: "终端丢失/损坏"
                    }, {
                        key: 2,
                        value: "商户失联"
                    }, {
                        key: 4,
                        value: "停业"
                    }, {
                        key: 8,
                        value: "迁址"
                    }, {
                        key: 16,
                        value: "其他"
                    }],
                options4: [
                    {
                        key: 1,
                        value: "无"
                    }, {
                        key: 2,
                        value: "异常"
                    }],
                historyData: [],
                currntTrainingContent: [],
                currntAbnormalStatus: [],
                currentAbnormalType: [],
                abnormalSituation: [],
                isEditFir: true,
                isEdit: false
            }
        },
        watch: {},
        created() {
            this.getMerData()
        },
        methods: {
            getMerData() {
                customDetail({ id: this.$route.query.id }).then((res) => {
                    if (res.code == 200) {
                        this.merchantData = res.data
                        this.historyData = res.data.expand.map((item) => {
                            item.launch = false;//添加展开选项
                            return item
                        })
                    }
                })
            },
            parseTrainingContent(trainingContent, type) {
                const dataSources = {
                    trainingContent: this.options,
                    abnormalType: this.options2,
                    abnormalSituation: this.options3,
                };
                let currentData = dataSources[type] || [];
                let selectedKeys = [];
                // 遍历options数组，检查每个option的key是否在trainingContent中被设置
                currentData.forEach((option) => {
                    if ((trainingContent & option.key) === option.key) {
                        selectedKeys.push(option.key);
                    }
                });
                return selectedKeys

            },
            initImageFields() {
                // 遍历 params 中的图片字段
                for (const field in this.detailJson) {
                    if (this.detailJson.hasOwnProperty(field) && this.detailJson[field]) {
                        // 构建图片数据对象
                        const imageData = {
                            url: this.detailJson[field], // 假设 params 中的值是图片的 Base64 或 URL
                            // name: field, // 用于标识图片来自哪个字段
                        };
                        // 将图片数据添加到对应的 fileList 数组中
                        this.imgData[field] = [imageData];
                    }
                }
            },
            //签字
            goAutogra() {
                this.$router.push({
                    path: '/Inspection/autograph',
                    query: {
                        id: this.$route.query.id,
                    },

                });
            },
            getBack() {
                this.$router.back();
            },
            setColor(type) {
                const colorMap = {
                    '未巡检': 'background: #FFDAD8; color: #D2261D',
                    '待审核': 'background: #EBEFFB; color: #3974C6', // 假设的颜色，根据实际情况调整
                    '审核通过': 'background: #D7F3D4; color: #39A52E', // 假设的颜色，根据实际情况调整
                    '审核驳回': 'background: #F2F3F7; color: #9B9B9B', // 假设的颜色，根据实际情况调整
                    '巡检过期': 'background: #F2F3F7; color: #9B9B9B', // 假设的颜色，根据实际情况调整
                };
                return colorMap[type] || ''; // 返回对应的状态颜色，如果状态不存在则返回空字符串
            },
            merchantTypeFun(type) {
                const colorMap = {
                    1: '周期巡检',
                    2: '风险巡检',
                    3: '临时巡检',
                };
                return colorMap[type] || ''; // 返回对应的状态颜色，如果状态不存在则返回空字符串
            },
            // 选择员工
            userChange(data, info) {
                let employeeId = data.map(i => i.id)[0]
                //走转接流程
                let params = {
                    id: this.$route.query.id,
                    employeeId: employeeId
                }
                transfer(params).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('已提交转接')
                    }
                })
            },
            // 文件大小
            onOversize(file) {
                this.$message.warning("文件大小不能超过 2MB");
            },
            // 上传图片文件
            afterReadImg(file, type) {
                let currentType = ''
                if (this.imgData.merchantImgPhoto.includes(file)) {
                    currentType = 'merchantImgPhoto'
                }
                if (this.imgData.merchantImgCashier.includes(file)) {
                    currentType = 'merchantImgCashier'
                }
                if (this.imgData.merchantImgPremisesPhoto.includes(file)) {
                    currentType = 'merchantImgPremisesPhoto'
                }
                if (this.imgData.merchantImgPaymentCode.includes(file)) {
                    currentType = 'merchantImgPaymentCode'
                }
                if (this.imgData.merchantImgInformation.includes(file)) {
                    currentType = 'merchantImgInformation'
                }
                if (this.imgData.merchantImgOther.includes(file)) {
                    currentType = 'merchantImgOther'
                }
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    this.detailJson[currentType] = res.data.fullPath;

                });
            },
            submitBtn() {
                this.$router.push({
                    path: '/gridManage/expand',
                    query: {
                        data: JSON.stringify(this.merchantData)
                    }
                })
            },
            //是否展开全文
            toggleExpand(item, index) {

                this.historyData[index].launch = !this.historyData[index].launch
                    this.$forceUpdate()
            },
            //预览图片
            preview(img) {
                ImagePreview([img]);
            },
            isEditFirFun() {
                this.isEdit = !this.isEdit
                this.isEditFir = !this.isEditFir
            },
            //商户编辑
            merChantEditFun() {
                if (this.merchantData.businessLicenseName == '' || this.merchantData.legalName == '' || this.merchantData.contactPhone == '' || this.merchantData.address == '') {
                    return this.$message.warning("内容为空，请检查！");
                }
                let params = {
                    id: this.$route.query.id,
                    businessLicenseName: this.merchantData.businessLicenseName,
                    legalName: this.merchantData.legalName,
                    contactPhone: this.merchantData.contactPhone,
                    address: this.merchantData.address,
                }
                merchantEdit(params).then((res) => {
                    if (res.code == 200) {
                        this.getMerData()
                        this.$message.success('编辑成功')
                        this.isEditFirFun()

                    }
                })
            },
            goInspe(row) {
                this.$router.push({
                    path: '/Inspection/insSubmit',
                    query: {
                        id: row.id,
                    },
                });
            }

        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-radio__label {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
    }

    /deep/.van-checkbox__label {
        display: flex;
        align-items: center;
    }

    .page {
        width: 100%;
        padding: 10px 0px;
        background: #F2F3F7;
        min-height: 100vh;

        .conBox {
            width: 100%;
            padding: 0px 13px;

        }

        .con {
            padding: 0px 16px;
            padding-bottom: 10px;
            padding-top: 1px;
            background: #FFFFFF;
            border-radius: 5px;


            .title {
                font-weight: bold;
                font-size: 17px;
                color: #000000;
                padding: 13px 0px;
                border-bottom: 1px solid #E8E8E8;

                .tips {
                    font-weight: 400;
                    font-size: 14px;
                    color: #9B9B9B;
                }
            }

            .line {
                margin-top: 13px;
                width: 100%;
                border-top: 1px solid #E8E8E8;
            }

            .li {
                display: flex;
                flex-direction: column;

                .li-title {
                    font-weight: 400;
                    font-size: 15px;
                    color: #000000;
                    margin: 15px 0px;
                }

                .showimg {
                    width: 100px;
                    height: 100px;
                }
            }

            .quBox {
                margin-top: 25px;

                .quBox-title {
                    font-weight: 400;
                    font-size: 14px;
                    color: #000000;
                }

                .radioBox {
                    margin-top: 20px;

                    .text_input {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #BBBBBB;
                        padding: 5px;
                    }
                }
            }

            .check-group {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                .check-item {
                    margin: 10px 0;

                    .check-text {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }
                }
            }

            .abnormal {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 9px 0;

                .title {
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                }

                .right {
                    width: 70%;

                    .row-checkbox {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .row-square {
                            flex-basis: calc(50% - 5px);
                            /* 减去间距的一半，假设两边各留2.5px间距 */
                            box-sizing: border-box;

                            /* 确保内边距和边框被包含在宽度内 */
                            .text {
                                font-weight: 400;
                                font-size: 14px;
                                color: #000000;
                            }
                        }

                        .row-square:nth-child(2n+3) {
                            margin-top: 10px;
                            /* 为奇数位置的元素添加底部外边距 */
                        }

                        .radio {
                            margin-right: 0 !important;
                        }

                    }
                }
            }

            .t1 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px 0;

                .t1-left {
                    font-weight: 400;
                    font-size: 15px;
                    color: #9B9B9B;
                }

                .t1-right {
                    font-weight: 400;
                    font-size: 15px;
                    color: #515151;
                    max-width: 70%;

                }

                .bor {
                    width: 100%;
                    border-bottom: 1px solid #E8E8E8;
                }
            }

            .t1-btn {
                margin: 20px 0;
                width: 100%;
                background: #3974C6;
                border-radius: 5px;
                padding: 13px;
                font-weight: bold;
                font-size: 17px;
                color: #FFFFFF;
                text-align: center;

            }

            .t1-btnin {
                margin: 20px 0;
                width: 100%;
                display: flex;
                flex-direction: row;

                .cancel {
                    width: 30%;
                    background: #F1F2F6;
                    border-radius: 5px;
                    padding: 13px;
                    font-weight: bold;
                    font-size: 17px;
                    color: #595A5E;
                    text-align: center;
                }

                .sub {
                    width: 70%;
                    background: #3974C6;
                    border-radius: 5px;
                    padding: 13px;
                    font-weight: bold;
                    font-size: 17px;
                    color: #FFFFFF;
                    text-align: center;
                }
            }
        }

        .noPadd {
            padding: 0px;
        }

        .m10 {
            margin-top: 10px;
        }

        .p10 {
            padding-top: 15px;
        }


        .nextCon {
            background: #FFFFFF;
            border-radius: 5px;
            width: 100%;
            padding: 16px 12px;
            margin-bottom: 100px;

            .title {
                font-weight: bold;
                font-size: 17px;
                color: #000000;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 13px;
                border-bottom: 1px solid #E8E8E8;

                .tips {
                    font-weight: 400;
                    font-size: 12px;
                    height: 21px;
                    line-height: 21px;
                    padding: 0px 4px;
                    border-radius: 3px;
                }
            }

            .line {
                margin-top: 13px;
                width: 100%;
                border-top: 1px solid #E8E8E8;
            }

            .infoText {
                font-weight: 400;
                font-size: 15px;
                color: #9B9B9B;
                margin-top: 10px;
            }
        }

        .footer {
            position: fixed;
            left: 0;
            bottom: 0px;
            width: 100%;
            padding: 15px 15px 30px 15px;
            background: #FFFFFF;
            box-shadow: 0px 1 3px 0px rgba(0, 0, 0, 0.26);

            .box {
                background: #FFFFFF;

                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                .btn1 {
                    padding: 6px 20px;
                    background: #F2F3F7;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #9B9B9B;
                    text-align: center;
                }

                .btn2 {
                    width: 100%;
                    text-align: center;
                    padding: 13px;
                    background: #3974C6;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .stepBox {
        background: #F8F8F8;
        border-radius: 4px;
        padding: 18px 15px;

        .heard {
            display: flex;
            justify-content: center;
            align-items: center;

            .time {
                padding: 6px 12px;
                border-radius: 50%;
                background: #FFFFFF;
                border-radius: 15px;
                color: #4A4A4A;
            }

            .status {
                font-weight: 400;
                font-size: 12px;
                height: 21px;
                line-height: 21px;
                padding: 0px 4px;
                border-radius: 3px;
            }
        }

        .content {
            margin-top: 10px;
            padding: 10px;
            background: #FFFFFF;
            border-radius: 4px;

            .text {
                font-weight: 400;
                font-size: 13px;
                color: #000000;
                margin: 10px 0px;
            }

            .statusBtn {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .btn {
                    width: 50px;
                    height: 24px;
                    background: #3974C6;
                    border-radius: 12px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #FFFFFF;
                    line-height: 24px;
                    text-align: center;
                }
            }
        }

        .btnImg {
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                width: 10px;
                height: 6px;
            }
        }
    }

    /deep/ .van-cell {
        padding: 0px;
    }

    /deep/ .van-field__control {
        text-align: right;
    }

    .labelInput {
        width: 100%;
        border: 0px solid #BBBBBB;
    }
</style>