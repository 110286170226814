<template>
  <div class="all">
    <div class="banxin">
      <div class="comment">
        <div class="top">
          <div class="m16">晚报点评:</div>
          <div class="clear" @click="clear">清空点评</div>
        </div>
        <div class="content">
          <van-field
            v-model="message"
            rows="8"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="请输入点评"
            show-word-limit
          />
        </div>
      </div>
      <div class="comment">
        <div class="top">
          <div class="m16">投资话题:</div>
          <div class="clear" @click="clearTopic">清空话题</div>
        </div>
        <div class="content">
          <van-field
            v-model="topic"
            rows="8"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="请输入话题"
            show-word-limit
          />
        </div>
      </div>

      <van-field>
        <template #input>
          <div v-if="selectList.length > 0">
            <div
              class="item flex"
              v-for="(item, i) in selectList"
              :key="i"
              style="margin-top: 4px"
            >
              <div class="input" style="display: inline-block">
                <a-input v-model="selectList[i]" />
              </div>
              <div class="icons" style="display: inline-block">
                <img
                  src="../../assets/images/electronicCard/reduce.png"
                  @click="del(i)"
                  alt=""
                />
                <img
                  src="../../assets/images/electronicCard/plus.png"
                  @click="add(i)"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div v-else>
            <a @click="add">
              <a-icon type="plus-circle" />
              添加选项
            </a>
          </div>
        </template>
      </van-field>
      <van-cell center title="晚报模版消息推送：">
        <template #right-icon>
          <van-switch v-model="isPush" size="24" />
        </template>
      </van-cell>
      <div style="height: 64px"></div>
      <div class="last" @click="save">保存设置</div>
    </div>
  </div>
</template>
    <script>
import {
  eveningPaperCommentSet,
  eveningPaperComment,
} from "@/api/morningPaper";

export default {
  data() {
    return {
      message: "",
      topic: "",
      selectList: [],
      isPush: false,
    };
  },
  created() {
    this.getCommentData();
  },
  methods: {
    clear() {
      this.message = "";
    },
    clearTopic() {
      this.topic = "";
    },
    save() {
      console.log(111);
      eveningPaperCommentSet({
        investmentTopicQuestion: this.topic,
        nightComment: this.message,
        investmentTopicOption: this.selectList,
        nightPushMessage: this.isPush == true ? 1 : 0,
      }).then((res) => {
        if (res.code == 200) {
          this.$router.go(-1);
          this.$toast("设置成功");
        }
      });
    },
    getCommentData() {
      eveningPaperComment().then((res) => {
        this.message = res.data.night_comment;
        this.topic = res.data.investment_topic_question;
        this.selectList = res.data.investment_topic_option;
        this.isPush = res.data.night_push_message == 0 ? false : true;
      });
    },
    del(i) {
      if (this.selectList.length === 1) {
        this.$message.error("不能删除最后一个哦");
        return false;
      }
      this.selectList.splice(i, 1);
    },
    add() {
      this.selectList.push("");
    },
  },
};
</script>
    <style lang="less" scoped>
/deep/ .van-switch {
  width: 50px;
  height: 26px;
}
/deep/ .van-cell__title {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  margin-left: -2px;
}
.icons {
  img {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
}
.all {
  height: 100%;
  background-color: #f2f3f7;
}
.banxin {
  margin: 0 auto;
  margin-top: 11px;
  height: 461px;
  width: 94%;
  background-color: #fff;
}
.m16 {
  font-size: 14px;
  font-weight: bold;
  color: #999999;
}
.comment {
  overflow: hidden;
  .top {
    width: 94%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .clear {
    font-size: 13px;
    color: #3974c7;
  }
  /deep/ .van-field__body {
    background-color: #f5f5f5;
  }
  /deep/ .van-cell {
    padding: 10px;
  }
  /deep/ .van-field__control {
    text-indent: 6px;
  }
}
.poster {
  width: 94%;
  margin: 0 auto;

  img {
    margin-top: 10px;
    width: 100%;
    height: 133px;
    background: rgba(0, 0, 0, 0.32);
    border-radius: 6px;
  }
}
.last {
  position: fixed;
  bottom: 0px;
  margin: 0 auto;
  width: 94%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #3974c6;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}
</style>