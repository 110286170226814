import request from "../plugins/axios";

// 热销产品查询累计数量
export function productTotal(params) {
    return request({
        url: '/dataBurialPointSummary/productTotal',
        method: 'get',
        params: params
    })
}

// 热销产品打开记录
export function productVisitor(params) {
    return request({
        url: '/dataBurialPointSummary/productVisitor',
        method: 'get',
        params: params
    })
}
// 朋友圈资讯查询累计数量
export function circleData(params) {
    return request({
        url: '/dataBurialPointSummary/informationTotal',
        method: 'get',
        params: params
    })
}
// 朋友圈资讯打开记录
export function circleVisitor(params) {
    return request({
        url: '/dataBurialPointSummary/informationVisitor',
        method: 'get',
        params: params
    })
}
// 朋友圈资讯数据总览
export function circleTotalData(params) {
    return request({
        url: '/dataBurialPointSummary/friendsCircleDataTotal',
        method: 'get',
        params: params
    })
}
// 客户标签覆盖率数据
export function tagData(params) {
    return request({
        url: '/Index/TagData',
        method: 'get',
        params: params
    })
}
// 名片数据总览
export function dataOverview(params) {
    return request({
        url: '/dataBurialPointSummary/dataOverview',
        method: 'get',
        params: params
    })
}