<template>
  <div class="payPopu">
    <div class="top">
      <img src="./pay.png" alt="" />
    </div>
    <div class="order">支付遇到问题？</div>
    <div class="last">
      <button class="left" @click="back">重新支付</button>
      <button class="left" @click="skip">我已支付</button>
    </div>
  </div>
</template>
<script>
import { publicBusinessApi } from "../../api/publicBusiness";
import { repay } from "@/api/xiaoban";

export default {
  data() {
    return {
      contactid: "",
      order: "",
      type: 1,
    };
  },
  created() {
    this.contactid = this.$route.query.contactid
      ? this.$route.query.contactid
      : "";
    this.type = this.$route.query.type ? this.$route.query.type : 1;
    console.log('this.type',this.type)
    console.log('this.contactid',this.contactid)
  },
  methods: {
    skip() {
      if (this.type == 1) {
        this.$router.push({
          path: "/contact/selectBinding",
          query: {
            contactId: this.contactid,
          },
        });
      } else {
        this.$router.push({
          path: "/xiaoban/index",
        });
      }
    },
    back() {
      let id = this.$route.query.id;
      let contactid = this.contactid;
      if (this.type == 1) {
        let url = encodeURIComponent(
          `${
            location.protocol + "//" + location.host
          }/payPopu/index?id=${id}&contactid=${contactid}`
        );
        publicBusinessApi.repay({ orderNo: id, device: "m" }).then((res) => {
          // this.order = res.data.orderNo;
          // console.log("this.order222", this.order);
          if (res.code == 200) {
            location.href = res.data.wechctUrl + `&redirect_url=${url}`;
          } else {
            this.$toast(res.sMsg);
          }
        });
      } else {
        let url = encodeURIComponent(
          `${
            location.protocol + "//" + location.host
          }/payPopu/index?id=${id}&type=2`
        );
        repay({ orderNo: id, device: "m" }).then((res) => {
          if (res.code == 200) {
            location.href = res.data.wechctUrl + `&redirect_url=${url}`;
          } else {
            this.$toast(res.sMsg);
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.payPopu {
  overflow: hidden;
  width: 82%;
  margin: 0 auto;
}
.top {
  width: 100%;
  margin-top: 50px;
}
.order {
  margin: 20px;
  text-align: center;
  height: 28px;
  font-size: 20px;
  color: #302c48;
}
button {
  margin: 15px;
  border: none;
  width: 90%;
  height: 42px;
  background: #3974c6;
  border-radius: 21px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 42px;
}
</style>

