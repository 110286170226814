<template>
  <div class="index">
    <div class="grayBg"></div>
    <div class="header">
      <div class="title">{{ detail.name }}</div>
      <div class="content" v-html="detail.content"></div>
    </div>
    <!-- <div class="icon">
      <img
        src="../../../assets/images/electronicCard/yuangong.png"
        alt=""
        @click="() => this.$router.push('/electronicCard/index')"
      />
      <img
        src="../../../assets/images/electronicCard/mobile.png"
        alt=""
        @click="onPhone"
        v-if="lightPhone == false"
      />
      <img
        src="../../../assets/images/electronicCard/mobile2.png"
        alt=""
        v-if="lightPhone"
      />
      <img
        src="../../../assets/images/electronicCard/weixin.png"
        alt=""
        @click="onWechat"
        v-if="lightWechat == false"
      />
      <img
        src="../../../assets/images/electronicCard/weixin2.png"
        alt=""
        v-if="lightWechat"
      />
    </div> -->
    <!-- 拨打电话弹窗 -->
    <!-- <van-dialog
      v-model="showPhoneCode"
      show-cancel-button
      cancelButtonText="否"
      confirmButtonText="是"
      confirmButtonColor="#3974C6"
    >
      <div class="phoneT">是否拨打行员电话？</div>
    </van-dialog> -->
    <!-- 微信二维码弹窗 -->
    <!-- <van-popup
      v-model="showWechat"
      closeable
      style="width: 260px; height: 286px; text-align: center; margin-left: 45%"
    >
      <img
        style="
          margin-top: 30px;
          width: 200px;
          height: 190px;
          text-align: center;
        "
        src="../../../assets/alarmImg.png"
        alt=""
      />
      <p style="font-size: 16px; color: #515151">长按扫一扫<br />即可联系我</p>
    </van-popup> -->
  </div>
</template>
<script>
import { productDetail, vcardIndex } from "@/api/electronicCard";
import { shareMyData } from "@/utils/wxCodeAuth";

export default {
  data() {
    return {
      lightPhone: false,
      lightWechat: false,
      showPhoneCode: false,
      showWechat: false,
      detail: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getUserInfo() {
      vcardIndex().then((res) => {
        console.log("res,", res);
        const data = res.data;
        const shareData = {
          title: `${data.employee.departmentName}金融管家：${data.employee.name}为您服务！`,
          description: data.summary,
          imgUrl: data.employee.fullImage,
          url: this.shareUrl,
        };
        console.log("fenxiang", shareData);
        shareMyData(shareData);
      });
    },
    getData() {
      const id = this.$route.query.id;
      console.log("ddd", id);
      productDetail({ id }).then((res) => {
        this.detail = res.data;
        this.shareUrl = res.data.shareUrl;
        this.getUserInfo();
      });
    },
    onPhone() {
      this.lightWechat = false;
      this.lightPhone = true;
      this.showPhoneCode = true;
    },
    onWechat() {
      this.lightWechat = true;
      this.lightPhone = false;
      this.showWechat = true;
    },
    showPhone() {
      this.showPhoneCode = true;
    },
  },
};
</script>
<style lang="less" scoped>
.grayBg {
  height: 8px;
  background: #f2f3f7;
}
.header {
  width: 94%;
  margin: 0 auto;
  overflow: auto;
  .title {
    text-align: center;
    width: 100%;
    height: 35px;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    margin-top: 20px;
  }
  .content {
    font-size: 14px;
    color: #4a4a4a;
    line-height: 20px;
  }
}
.icon {
  position: fixed;
  bottom: 20px;
  right: 18px;
  width: 33px;
  height: 86px;
  background: #f7f7f7;
  border-radius: 17px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  img {
    width: 14px;
    height: 15px;
  }
}
.phoneT {
  text-align: center;
  margin: 38px;
  height: 35px;
  font-size: 16px;
  color: #333333;
  line-height: 24px;
}
/deep/ img {
  max-width: 100% !important;
}
</style>
  