<template>
  <div class="index">
    <div class="content">
      <div class="titles" style="border-top: 7px solid #f2f4f8">基础信息</div>
      <div class="text-edit">
        <van-field v-model="message" rows="2" :autosize="{ maxHeight: 24, minHeight: 24 }" required label="二维码名称"
          type="textarea" maxlength="30" placeholder="请输入二维码名称" show-word-limit />
      </div>

      <van-divider style="width: 90%; margin: 0 auto" />

      <van-cell @click="$refs.grouping.$show()" required title="选择分组" is-link :value="choseProup.name" />

      <van-cell required title="选择客服员工">
        <van-radio-group @click="userYeGp" style="flex: 1; text-align: revert; float: right" icon-size="15px"
          v-model="radio" direction="horizontal">
          <van-radio shape="square" name="1">全天在线</van-radio>
          <!-- <van-radio shape="square" name="2">自动上下线</van-radio> -->
        </van-radio-group>
      </van-cell>

      <div v-if="radio == '1'">
        <van-cell @click="$refs.employee.$show(serviceList, 'serviceList')" required title="全天员工" is-link value="请选择成员">
          <div class="user-list" v-if="serviceList.length > 0">
            <div v-for="(item, index) in serviceList" :key="index">
              <img :src="item.avatar" />
              {{ item.name }}
            </div>
          </div>
          <div v-else>请选择成员</div>
        </van-cell>
      </div>

      <div v-else style="background: #f5f5f5; padding: 10px 3px; padding-bottom: 0">
        <div style="margin-bottom: 10px" v-for="(items, indexs) in autoSxs" :key="indexs">
          <van-cell @click="$refs.employee.$show(items.user, indexs)" title="员工" is-link value="请选择成员">
            <div class="user-list" v-if="items.user.length > 0">
              <div v-for="(item, index) in items.user" :key="index">
                <img :src="item.avatar" />
                {{ item.name }}
              </div>
            </div>
            <div v-else>请选择成员</div>
          </van-cell>
          <van-cell @click="$refs.cycleTime.$show(items.workWeek, indexs)" title="工作周期" is-link
            :value="items.workWeekName || '请选择'" />
          <van-cell @click="$refs.addTime.$show(indexs, 'autoSxs')" title="上线时间" is-link :value="items.goTime" />
        </div>
        <div @click="addAutoSxs" style="
            margin-top: -10px;
            font-size: 14px;
            color: #1890ff;
            padding: 5px 12px;
          ">
          <van-icon name="add-o" />
          添加其他工作周期
        </div>
      </div>

      <!-- <van-cell title="员工添加上限">
        <div
          class="openOr"
          style="display: flex; align-items: center; justify-content: end"
        >
          <van-switch
            @click="upperLimitText"
            :disabled="serviceList.length <= 0 && upperSteppert.length <= 0"
            v-model="upperLimit"
            size="16px"
          />
          <span>{{ upperLimit ? "已开启" : "已关闭" }}</span>
        </div>
      </van-cell> -->

      <div v-if="upperLimit">
        <div>
          <div class="ttips">
            员工添加上限：
            员工从该渠道添加的客户达到每日上限后，将自动下线当日不再接待该渠道新客户
          </div>
          <div style="display: none; align-items: center">
            <van-search style="width: 80%" v-model="serchUserValue" placeholder="请输入搜索关键词" />
            <span style="color: #1890ff">批量设置</span>
          </div>
          <div class="upper-limit">
            <div class="div1" style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              ">
              <div>
                <van-checkbox-group icon-size="15px" v-model="allRadio">
                  <van-checkbox v-if="radio == 1" @click="allUpperLimit" name="1" shape="square">
                    共{{ upperStepper.length }}个员工
                  </van-checkbox>
                  <van-checkbox v-else @click="allUpperLimit" name="1" shape="square">
                    共{{ upperSteppert.length }}个员工
                  </van-checkbox>
                </van-checkbox-group>
              </div>
              <div>
                每日添加客户上限
                <span v-if="!maxShow" @click="userMax" style="color: #1890ff">操作</span>
                <input style="border: 1px solid #999; width: 44px; height: 25px" v-else @keyup.enter="userEditMax"
                  type="number" max="100" v-model="maxText" />
              </div>
            </div>
            <div class="div2">
              <van-checkbox-group icon-size="15px" ref="checkUpperGroup" v-model="upperResult">
                <div v-if="radio == '1'">
                  <div v-for="(item, index) in upperStepper" class="bettown" style="width: 100%">
                    <van-checkbox shape="square" :name="item">
                      {{ item.name }}
                    </van-checkbox>
                    <van-stepper v-model="item.stepper" input-width="22px" button-size="16px" />
                  </div>
                </div>
                <div v-else>
                  <div v-for="(item, index) in upperSteppert" class="bettown" style="width: 100%">
                    <van-checkbox shape="square" :name="item">
                      {{ item.name }}
                    </van-checkbox>
                    <van-stepper v-model="item.stepper" input-width="22px" button-size="16px" />
                  </div>
                </div>
              </van-checkbox-group>
            </div>
          </div>
        </div>
      </div>

      <!-- <van-cell title="允许员工上下线">
        <div
          class="openOr"
          style="display: flex; align-items: center; justify-content: end"
        >
          <van-switch v-model="Offline" size="16px" />
          <span>{{ Offline ? "已开启" : "已关闭" }}</span>
        </div>
      </van-cell> -->

      <!-- <van-cell
        @click="$refs.employee.$show(StandbyStaffList, 'StandbyStaffList')"
        :required="Offline"
        title="备用员工"
        is-link
        value="请选择成员"
      >
        <div class="user-list" v-if="StandbyStaffList.length > 0">
          <div v-for="(item, index) in StandbyStaffList" :key="index">
            <img :src="item.avatar" />
            {{ item.name }}
          </div>
        </div>
        <div v-else>请选择成员</div>
      </van-cell> -->

      <van-cell required title="给客户打标签">
        <div class="openOr" style="display: flex; align-items: center; justify-content: end">
          <van-switch v-model="customerLabel" size="16px" />
          <span>{{ customerLabel ? "已开启" : "已关闭" }}</span>
        </div>
      </van-cell>
      <span style="color: #999; font-size: 14px; margin: 0 14px; display: block">
        *可以给扫码添加的客户自动打上你选择的标签
      </span>
      <div v-if="customerLabel">
        <div class="ttips">
          <van-icon style="color: #1890ff; font-size: 14px" name="bell" />
          <span>因企业微信限制，标签存在延时，预计会在添加客户后的2分钟之内打上</span>
        </div>
        <van-cell title="标签" @click="$refs.customerLabel.$show(tagsValue, tagsValueEdit)" is-link :value="tagsText" />
      </div>

      <van-cell required title="客户备注">
        <div class="openOr" style="display: flex; align-items: center; justify-content: end">
          <van-switch v-model="customerComments" size="16px" />
          <span>{{ customerComments ? "已开启" : "已关闭" }}</span>
        </div>
      </van-cell>
      <span style="color: #999; font-size: 14px; margin: 0 14px; display: block">
        *开启后可为客户昵称加备注，方便查看客户来源
      </span>
      <div v-if="customerComments" style="background: #fbfbfb">
        <van-cell title="备注位置">
          <van-radio-group style="flex: 1; text-align: revert; float: right" icon-size="15px" v-model="nameRadio"
            direction="horizontal">
            <van-radio name="1">备注在昵称前</van-radio>
            <van-radio name="2">备注在昵称后</van-radio>
          </van-radio-group>
        </van-cell>
        <van-field v-model="positionValue" input-align="right" label="备注" placeholder="请输入用户名" />
      </div>

      <!-- <van-cell required title="客户描述">
        <div
          class="openOr"
          style="display: flex; align-items: center; justify-content: end"
        >
          <van-switch v-model="customerDescription" size="16px" />
          <span>{{ customerDescription ? "已开启" : "已关闭" }}</span>
        </div>
        <span
          style="color: #999; font-size: 14px; margin: 0 14px; display: block"
        >
          *开启后可为客户添加描述，将在客户画像里展示
        </span>
      </van-cell> -->

      <div v-if="customerDescription">
        <van-field v-model="customerMessage" rows="2" autosize type="textarea" maxlength="150" placeholder="请输入留言"
          show-word-limit />
      </div>

      <div class="titles" style="border-top: 7px solid #f2f4f8">欢迎语设置</div>

      <div class="">
        <van-cell @click="showH = true" title="欢迎语设置" is-link :value="greeting">
          <template #title>
            <span style="">欢迎语</span>
            <!--            <span style="    color: #294ba3;-->
            <!--    margin-left: 10px;">-->
            <!--          <img style="width: 15px;height: 15px;vertical-align: middle;margin-top: -3px;"-->
            <!--               src="../../assets/images/preview.svg"/>-->
            <!--          预览</span>-->
          </template>
          <template #value>
            <span style="color: #444">{{ greeting }}</span>
          </template>
        </van-cell>

        <div v-if="greeting == '固定欢迎语'" class="content">
          <span
            style="color: #999; font-size: 14px; margin: 0 14px; display: block">*设置渠道欢迎语后将会覆盖原有的欢迎语，扫码添加的客户将会回复设置的渠道欢迎语</span>

          <div style="padding: 10px 12px">
            <!-- <van-field
              v-model="fixedMessage"
              rows="2"
              :autosize="{ maxHeight: 300, minHeight: 150 }"
              type="textarea"
              maxlength="1000"
              placeholder="请输入"
              show-word-limit
              style="background: #f8f9fb"
            > -->
            <EnterTextMsg style="background: #f8f9fb" v-model="fixedMessage" ref="defaultEnter" />

            <!-- </van-field> -->

            <div class="file-list">
              <div v-for="(item, index) in fixedFileList" :key="index" class="item">
                <div>
                  <a-icon @click="removeFile(index)" type="minus-circle" />
                  <span>{{ item.type }}{{ item.title }}</span>
                </div>
                <a-icon type="form" @click="editFile(item, index)" />
              </div>
            </div>

            <van-popover class="prop" v-model="showPopover" trigger="click" placement="top-start">
              <ul class="options">
                <li @click="choseImg(false)" class="clickable">
                  <img src="../../assets/images/multiSendabPicture.png" />
                  <span>图片</span>
                </li>
                <li @click="choseUrl(false)" class="clickable">
                  <img src="../../assets/images/multiSendableLink.png" />
                  <span>链接</span>
                </li>
                <li @click="choseApplet(false)" class="clickable">
                  <img src="../../assets/images/multiSendableWxapp.png" />
                  <span>小程序</span>
                </li>
                <li @click="choseLuckDraw(false)" class="clickable">
                  <img src="../../assets/images/multisendablechoujiang.png" />
                  <span>抽奖活动</span>
                </li>
              </ul>
              <template #reference>
                <div class="init-trigger fs14">
                  <div class="icon">
                    <img src="../../assets/images/plus-new.svg" class="icon-plus-new svg-icon" />
                  </div>
                  <span style="font-size: 14px">上传附件</span>
                </div>
              </template>
            </van-popover>
          </div>

          <van-cell title="分时段欢迎语">
            <div class="openOr" style="display: flex; align-items: center; justify-content: end">
              <van-switch v-model="timeInterval" size="16px" />
              <span>{{ timeInterval ? "已开启" : "已关闭" }}</span>
            </div>
          </van-cell>

          <div v-if="timeInterval">
            <div v-for="(item, index) in timeIntervalList" :key="index">
              <div class="header">
                <div class="left">
                  <span class="title">分时段欢迎语{{ index + 1 }}</span>
                  <!--                  <img src="../../assets/images/preview.svg"-->
                  <!--                       class="preview-icon svg-icon">-->
                  <!--                  <span class="preview">预览</span>-->
                </div>
                <div class="right">
                  <span v-if="index != 0 || timeIntervalList.length > 1" class="delete"
                    @click="deleteTime(index)">删除</span>
                </div>
              </div>
              <div class="select-time-period">
                <div class="time-range-wrapper">
                  <div class="time-tips">请选择日期</div>
                  <div class="picker">
                    <div :style="{
                        'background-color': itemw.chose ? '#7accc1' : '#ccc',
                      }" v-for="(itemw, indexw) in item.week" :key="indexw" @click="itemw.chose = !itemw.chose"
                      class="picker-item item fs12">
                      <span>{{ itemw.name }}</span>
                    </div>
                  </div>
                  <div class="time-wrapper" @click="$refs.addTime.$show(index, 'timeIntervalList')">
                    <div class="time-tips">请选择时间段</div>
                    <div class="time-box">
                      <span>从</span>
                      <span class="time">
                        {{ item.startTime || "00:00" }}
                      </span>
                      <span>至</span>
                      <span class="time">{{ item.endTime || "00:00" }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="padding: 10px 12px">
                <!-- <van-field
                  v-model="item.words"
                  rows="2"
                  :autosize="{ maxHeight: 300, minHeight: 150 }"
                  type="textarea"
                  maxlength="1000"
                  placeholder="请输入"
                  show-word-limit
                  style="background: #f8f9fb"
                > -->
                <EnterTextMsg style="background: #f8f9fb" v-model="item.words" :ref="`spanEnter${index}`" />
                <!-- </van-field> -->
                <div class="file-list" v-for="(items, indexs) in item.mediumId" :key="indexs">
                  <div class="item">
                    <div>
                      <a-icon @click="
                          removeFile({
                            index,
                            indexs,
                          })
                        " type="minus-circle" />
                      <span>{{ items.type }}{{ items.title }}</span>
                    </div>
                    <a-icon type="form" @click="
                        editFile(items, {
                          index,
                          indexs,
                        })
                      " />
                  </div>
                </div>
                <van-popover class="prop" v-model="item.showPopover" trigger="click" placement="top-start">
                  <ul class="options">
                    <li @click="choseImg(true, index)" class="clickable">
                      <img src="../../assets/images/multiSendabPicture.png" />
                      <span>图片</span>
                    </li>
                    <li @click="choseUrl(true, index)" class="clickable">
                      <img src="../../assets/images/multiSendableLink.png" />
                      <span>链接</span>
                    </li>
                    <li @click="choseApplet(true, index)" class="clickable">
                      <img src="../../assets/images/multiSendableWxapp.png" />
                      <span>小程序</span>
                    </li>
                    <li @click="choseLuckDraw(true, index)" class="clickable">
                      <img src="../../assets/images/multisendablechoujiang.png" />
                      <span>抽奖活动</span>
                    </li>
                  </ul>
                  <template #reference>
                    <div class="init-trigger fs14">
                      <div class="icon">
                        <img src="../../assets/images/plus-new.svg" class="icon-plus-new svg-icon" />
                      </div>
                      <span style="font-size: 14px">上传附件</span>
                    </div>
                  </template>
                </van-popover>
              </div>
            </div>
          </div>

          <div v-if="timeInterval" class="add-time-phased-welcome">
            <div class="box" @click="
                timeIntervalList.push({
                  words: '',
                  week: [
                    {
                      name: '日',
                      id: 0,
                      chose: false,
                    },
                    {
                      name: '一',
                      id: 1,
                      chose: false,
                    },
                    {
                      name: '二',
                      id: 2,
                      chose: false,
                    },
                    {
                      name: '三',
                      id: 3,
                      chose: false,
                    },
                    {
                      name: '四',
                      id: 4,
                      chose: false,
                    },
                    {
                      name: '五',
                      id: 5,
                      chose: false,
                    },
                    {
                      name: '六',
                      id: 6,
                      chose: false,
                    },
                  ],
                  startTime: '',
                  endTime: '',
                  mediumId: [],
                  showPopover: false,
                })
              ">
              <img src="../../assets/images/add-icon.svg" />
              <span>添加分时段欢迎语</span>
            </div>
          </div>

          <!-- <van-cell
            required
            style="border-bottom: 7px solid #f2f4f8"
            title="欢迎语屏蔽"
          >
            <div
              class="openOr"
              style="display: flex; align-items: center; justify-content: end"
            >
              <van-switch v-model="shield" size="16px" />
              <span>{{ shield ? "已开启" : "已关闭" }}</span>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: end;
                flex-wrap: wrap;
              "
            >
              <van-button
                v-if="!shieldShow && shield"
                @click="shieldShow = true"
                size="mini"
                icon="plus"
                type="default"
                >添加屏蔽昵称
              </van-button>
              <input
                v-if="shieldShow && shield"
                placeholder="请输入"
                @keyup.enter="shieldAdd"
                style="
                  width: 80px;
                  height: 22px;
                  border: 1px solid #ebedf0;
                  font-size: 12px;
                "
                v-model="shieldText"
              />
              <div v-if="shield" style="margin-left: 10px">
                <van-tag
                  v-for="(item, index) in shieldList"
                  :key="index"
                  plain
                  closeable
                  type="primary"
                  @close="shieldList.splice(index, 1)"
                  >{{ item }}
                </van-tag>
              </div>
            </div>
            <span
              style="
                color: #999;
                font-size: 14px;
                margin: 0 14px;
                display: block;
              "
              >*开启后，客户昵称中包含设定的关键词的客户不会收到欢迎语</span
            >
          </van-cell> -->
        </div>

        <div v-if="greeting == '默认欢迎语'">
          <span
            style="color: #999; font-size: 12px; margin: 0 14px; display: block">*将发送成员已设置的欢迎语，若所选成员未设置欢迎语，则不会发送欢迎语</span>
          <van-cell required title="欢迎语屏蔽">
            <div class="openOr" style="display: flex; align-items: center; justify-content: end">
              <van-switch v-model="shield" size="16px" />
              <span>{{ shield ? "已开启" : "已关闭" }}</span>
            </div>
            <div style="
                display: flex;
                align-items: center;
                justify-content: end;
                flex-wrap: wrap;
              ">
              <van-button v-if="!shieldShow && shield" @click="shieldShow = true" size="mini" icon="plus"
                type="default">添加屏蔽昵称
              </van-button>
              <input v-if="shieldShow && shield" placeholder="请输入" @keyup.enter="shieldAdd" style="
                  width: 80px;
                  height: 22px;
                  border: 1px solid #ebedf0;
                  font-size: 14px;
                " v-model="shieldText" />
              <div v-if="shield" style="margin-left: 10px">
                <van-tag v-for="(item, index) in shieldList" :key="index" plain closeable type="primary"
                  @close="shieldList.splice(index, 1)">{{ item }}
                </van-tag>
              </div>
            </div>
            <span style="
                color: #999;
                font-size: 14px;
                margin: 0 14px;
                display: block;
              ">开启后，客户昵称中包含设定的关键词的客户不会收到欢迎语"</span>
          </van-cell>
        </div>

        <div v-if="greeting == '不发送欢迎语'">
          <van-cell required title="欢迎语屏蔽">
            <div class="openOr" style="display: flex; align-items: center; justify-content: end">
              <van-switch v-model="shield" size="16px" />
              <span>{{ shield ? "已开启" : "已关闭" }}</span>
            </div>
            <div style="
                display: flex;
                align-items: center;
                justify-content: end;
                flex-wrap: wrap;
              ">
              <van-button v-if="!shieldShow && shield" @click="shieldShow = true" size="mini" icon="plus"
                type="default">添加屏蔽昵称
              </van-button>
              <input v-if="shieldShow && shield" placeholder="请输入" @keyup.enter="shieldAdd" style="
                  width: 80px;
                  height: 22px;
                  border: 1px solid #ebedf0;
                  font-size: 12px;
                " v-model="shieldText" />
              <div v-if="shield" style="margin-left: 10px">
                <van-tag v-for="(item, index) in shieldList" :key="index" plain closeable type="primary"
                  @close="shieldList.splice(index, 1)">{{ item }}
                </van-tag>
              </div>
            </div>
            <span style="
                color: #999;
                font-size: 12px;
                margin: 0 14px;
                display: block;
              ">开启后，客户昵称中包含设定的关键词的客户不会收到欢迎语"</span>
          </van-cell>
          <span style="color: #999; font-size: 12px; margin: 0 14px; display: block">开启后，客户昵称中包含设定的关键词的客户不会收到欢迎语"</span>
        </div>
      </div>

      <div class="titles">其他设置</div>
      <van-cell title="自动通过好友">
        <div class="openOr" style="display: flex; align-items: center; justify-content: end">
          <van-switch v-model="autoGo" size="16px" />
          <span>{{ autoGo ? "已开启" : "已关闭" }}</span>
        </div>
      </van-cell>
      <span style="color: #999; font-size: 14px; margin: 0 14px; display: block">*开启后，客户添加该企业微信时，无需好友验证，将会自动添加成功
      </span>
      <!-- <van-cell title="二维码预览" style="position: relative">
        <div style="position: relative; width: 79.98px; float: right">
          <van-uploader
            :class="uploadShow"
            style="
              position: absolute;
              width: 79.98px;
              height: 79.98px;
              right: 0;
              z-index: 9999;
            "
            @oversize="onOversize"
            :after-read="afterRead"
            v-model="fileList"
            :max-count="1"
          />
          <div style="width: 79.98px; position: relative">
            <img
              style="width: 79.98px; height: 79.98px"
              src="../../assets/images/qrpreview.png"
            />
            <img
              v-if="fileImage"
              style="
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-50%, -50%);
                left: 50%;
              "
              :src="fileImage"
            />
            <img
              v-else
              style="
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-50%, -50%);
                left: 50%;
              "
              src="../../assets/images/codeImage.png"
            />
          </div>
        </div>
        <div
          style="
            color: #999;
            font-size: 14px;
            float: right;
            margin: 0 14px;
            display: block;
          "
        >
          * 此二维码只是样式预览效果，请勿直接使用
        </div>
      </van-cell> -->

      <div class="filexBox">
        <van-button v-if="groupId" @click="removeCode()"  type="default" class="btn"
          style="background: #F1F2F6;" block>删除</van-button>
        <van-button :loading="loading" type="info" class="btn" :style="{'width':groupId?'40%':'90%'}" block
          @click="buildText">{{ groupId ? "确认修改" :"新建"}}</van-button>
      </div>
      <van-action-sheet v-model="showH" :actions="actionsH" cancel-text="取消" close-on-click-action @cancel="onCancelH"
        @select="onSelectH" />
    </div>

    <!--  分组  -->
    <grouping ref="grouping" @change="userGroup" />
    <!--  员工  -->
    <employeeIndex ref="employee" @change="userChange" />
    <!--  标签  -->
    <customerLabel ref="customerLabel" @change="userTagsChose" />
    <!--  时间周期选择  -->
    <cycleTime ref="cycleTime" @change="weekChose" />
    <!--  时间选择  -->
    <addTime ref="addTime" @change="timeChose" />
    <!--  欢迎语添加  -->
    <addImage ref="addImage" @change="addImg" />
    <!--  链接添加  -->
    <addUrl ref="addUrl" @change="addUrl" />
    <!--  添加小程序  -->
    <addApplet ref="addApplet" @change="addApplet" />
    <!--  添加抽奖活动  -->
    <addLuckDraw ref="addLuckDraw" @change="addLuckDraw" />
  </div>
</template>

<script>
  import EnterTextMsg from "@/components/EnterTextMsg";
  import grouping from "./components/grouping";
  import employeeIndex from "./components/employeeIndex";
  import customerLabel from "./components/customerLabel";
  import cycleTime from "./components/cycleTime";
  import addTime from "./components/addTime";
  import addImage from "../contactWelcome/components/addImage";
  import addUrl from "../contactWelcome/components/addUrl";
  import addApplet from "../contactWelcome/components/addApplet";
  import { upload } from "../../api/contactWelcome";
  import {
    channelCode,
    channelCodeShow,
    channelCodeUpdate,
    channelCodeRemove
  } from "../../api/channelCode";
  import addLuckDraw from "../contactWelcome/components/addLuckDraw";
  import { Dialog } from "vant";

  export default {
    components: {
      grouping,
      employeeIndex,
      customerLabel,
      cycleTime,
      addTime,
      addImage,
      addUrl,
      addApplet,
      addLuckDraw,
      EnterTextMsg,
    },
    data() {
      return {
        groupId: null,
        // 文字
        // 新建login
        loading: false,
        // tbas切换
        tabsChose: 0,
        showH: false,
        actionsH: [
          { name: "固定欢迎语" },
          { name: "默认欢迎语" },
          { name: "不发送欢迎语" },
        ],
        greeting: "固定欢迎语",

        // 新修改
        // 二维码名称
        message: "",
        // 选择分组
        choseProup: {
          name: "请选择",
        },
        // 选择员工客服
        radio: "1",
        // 请选择成员
        serviceList: [],
        // 员工添加上限
        upperLimit: false,
        serchUserValue: "",
        upperStepper: [],
        upperSteppert: [],
        upperResult: [],
        allRadio: [],
        //允许员工上下线
        Offline: false,
        // 备用员工
        StandbyStaffList: [],
        // 客户标签
        customerLabel: false,
        showPopover2: true,
        tagsValue: [],
        tagsValueEdit: false,
        tagsText: "",
        //客户备注
        customerComments: false,
        positionValue: "",
        // 客户描述
        customerDescription: false,
        // 自动上下线
        autoSxs: [
          {
            user: [],
            workWeek: [],
            workWeekName: "",
            goTime: "请选择",
          },
        ],

        // 固定欢迎语言
        fixedMessage: "",
        // 附件选择
        showPopover: false,
        // 欢迎语文件
        fixedFileList: [],
        // 分时段欢迎语
        timeIntervalList: [
          {
            words: "",
            week: [
              {
                name: "日",
                id: 0,
                chose: false,
              },
              {
                name: "一",
                id: 1,
                chose: false,
              },
              {
                name: "二",
                id: 2,
                chose: false,
              },
              {
                name: "三",
                id: 3,
                chose: false,
              },
              {
                name: "四",
                id: 4,
                chose: false,
              },
              {
                name: "五",
                id: 5,
                chose: false,
              },
              {
                name: "六",
                id: 6,
                chose: false,
              },
            ],
            startTime: "",
            endTime: "",
            mediumId: [],
            showPopover: false,
          },
        ],

        // 二维码预览
        fileList: [],
        fileImage: "",
        // 欢迎语屏蔽
        shield: false,
        shieldList: [],
        shieldText: "",
        shieldShow: false,
        // 客户添加上限操作
        maxShow: false,
        maxText: 0,
        // 自动通过好友
        autoGo: false,
        customerMessage: "",
        nameRadio: "1",
        checked: false,
        timeInterval: false,
        uploadShow: "hideUpload",
      };
    },
    watch: {
      radio() {
        this.upperLimit = false;
      },
    },
    methods: {
      onClickLeft() {
        this.$router.back();
      },
      onCancelH(e) {
        this.showH = false;
      },
      onSelectH(e) {
        this.greeting = e.name;
      },
      // 提示
      // 员工添加上限
      upperLimitText() {
        if (this.serviceList.length <= 0 && this.upperSteppert.length <= 0) {
          this.$message.warning("未添加使用成员，请先选择使用成员");
        } else {
          this.upperLimit = true;
        }
      },
      weekEdit(id) {
        switch (id) {
          case 0:
            return "星期天";
            break;
          case 1:
            return "星期一";
            break;
          case 2:
            return "星期二";
            break;
          case 3:
            return "星期三";
            break;
          case 4:
            return "星期四";
            break;
          case 5:
            return "星期五";
            break;
          case 6:
            return "星期六";
            break;
        }
      },
      ment(i) {
        let data = {};
        if (i.TextTitle) {
          data = {
            id: i.id,
            title: i.TextTitle,
            description: i.description,
            imageLink: i.imageLink,
            types: 1,
            type: "【链接】：",
            fileList: [
              {
                url: i.imageFullPath,
                path: i.imagePath,
              },
            ],
          };
        } else if (i.description) {
          data = {
            id: i.id,
            title: i.name,
            description: i.description,
            name: i.name,
            type: "【抽奖】：",
            types: 2,
          };
        } else if (i.appid) {
          data = {
            id: i.id,
            title: i.title,
            description: i.appid,
            imageLink: i.page,
            fileList: [
              {
                url: i.imageFullPath,
                path: i.imagePath,
              },
            ],
            types: 1,
            type: "【小程序】：",
          };
        } else if (i.imageName && !i.appid) {
          data = {
            id: i.id,
            title: i.title || i.name,
            fileList: [
              {
                url: i.imageFullPath,
                path: i.imagePath,
              },
            ],
            types: 1,
            type: "【图片】：",
          };
        }
        return data;
      },
      weekChoses(is) {
        const week = [
          {
            name: "日",
            id: 0,
            chose: false,
          },
          {
            name: "一",
            id: 1,
            chose: false,
          },
          {
            name: "二",
            id: 2,
            chose: false,
          },
          {
            name: "三",
            id: 3,
            chose: false,
          },
          {
            name: "四",
            id: 4,
            chose: false,
          },
          {
            name: "五",
            id: 5,
            chose: false,
          },
          {
            name: "六",
            id: 6,
            chose: false,
          },
        ];
        for (let i = 0; i < is.length; i++) {
          for (let k = 0; k < week.length; k++) {
            if (is[i] == week[k].id) {
              week[k].chose = true;
            }
          }
        }
        return week;
      },
      // 修改数据
      editCode() {
        channelCodeShow({
          channelCodeId: this.groupId,
        }).then((res) => {
          const data = res.data;
          this.message = data.baseInfo.name;
          this.choseProup = {
            groupId: data.baseInfo.groupId,
            name: data.baseInfo.groupName,
          };
          this.autoGo = data.baseInfo.autoAddFriend == 1 ? true : false;

          this.fileImage = data.baseInfo.image;

          this.customerMessage = data.baseInfo.contactDescription;
          this.customerDescription = data.baseInfo.contactDescription
            ? true
            : false;

          this.customerComments = data.baseInfo.contactRemark.status
            ? true
            : false;
          this.nameRadio = String(data.baseInfo.contactRemark.type);
          this.positionValue = data.baseInfo.contactRemark.remark;

          this.tagsValue = data.baseInfo.fullTags;
          this.customerLabel = data.baseInfo.fullTags.length > 0 ? true : false;
          this.tagsText = String(
            data.baseInfo.fullTags.map((item) => {
              return item.name;
            })
          );
          this.tagsValueEdit = data.baseInfo.fullTags.length > 0 ? true : false;

          this.shield = data.closeWords.status ? true : false;
          this.shieldList = data.closeWords.words;

          if (data.drainageEmployee.type == 1) {
            this.radio = data.drainageEmployee.type + "";
            this.serviceList = data.drainageEmployee.timeFull.map((item) => {
              return {
                avatar: item.avatar,
                id: item.id,
                name: item.name,
                stepper: 1,
              };
            });
            this.upperStepper = data.drainageEmployee.addMax.employees.map(
              (item) => {
                return {
                  // avatar: item.avatar,
                  id: item.id,
                  name: item.name,
                  stepper: item.max,
                };
              }
            );
            this.upperLimit = data.drainageEmployee.addMax.status;
            this.StandbyStaffList = data.drainageEmployee.specialPeriod.map(
              (item) => {
                return {
                  avatar: item.avatar,
                  id: item.id,
                  name: item.name,
                  stepper: 1,
                };
              }
            );
          } else {
            this.radio = data.drainageEmployee.type + "";
            this.autoSxs = data.drainageEmployee.timeSlot.map((item) => {
              return {
                user: item.employees.map((its) => {
                  return {
                    avatar: its.avatar,
                    id: its.id,
                    name: its.name,
                    stepper: 1,
                  };
                }),
                workWeek: item.week.map((its) => {
                  return {
                    id: its,
                    name: this.weekEdit(its),
                  };
                }),
                workWeekName: String(
                  item.week.map((its) => {
                    return this.weekEdit(its);
                  })
                ),
                goTime: item.startTime + "-" + item.endTime,
              };
            });
            this.upperSteppert = data.drainageEmployee.addMax.employees.map(
              (item) => {
                return {
                  // avatar: item.avatar,
                  id: item.id,
                  name: item.name,
                  stepper: item.max,
                };
              }
            );
            this.upperLimit = data.drainageEmployee.addMax.status;
            this.StandbyStaffList = data.drainageEmployee.specialPeriod.map(
              (item) => {
                return {
                  avatar: item.avatar,
                  id: item.id,
                  name: item.name,
                  stepper: 1,
                };
              }
            );
          }

          switch (data.type) {
            case 1:
              this.greeting = "固定欢迎语";
              break;
            case 2:
              this.greeting = "默认欢迎语";
              break;
            case 3:
              this.greeting = "不发送欢迎语";
              break;
          }

          // 欢迎语
          if (data.welcomeMessage.words.hasOwnProperty('words')) {
            this.fixedMessage = data.welcomeMessage.words
            this.$refs.defaultEnter.setData(data.welcomeMessage.words)
          }
          else {
            this.fixedMessage = data.welcomeMessage
            this.$refs.defaultEnter.setData(data.welcomeMessage)
          }
          console.log('this.fixedMessage', this.fixedMessage)
          setTimeout(() => {
            data.spanWelcome.forEach((v, i) => {
              this.$refs[`spanEnter${i}`][0].setData(v.words)
            })
          }, 0)
          this.fixedFileList = data.welcomeMessage.content.map((i) => {
            return this.ment(i);
          });
          this.timeInterval = data.spanWelcomeStatus ? true : false;
          this.timeIntervalList = res.data.spanWelcome.map((i) => {
            return {
              words: i.words.words,
              week: this.weekChoses(i.week),
              startTime: i.startTime,
              endTime: i.endTime,
              mediumId: i.content.map((is) => {
                return this.ment(is);
              }),
            };
          });
        });
      },

      // 员工客服切换
      userYeGp() {
        this.upperLimit = false;
      },
      // 员工操作max
      userMax() {
        if (this.upperResult.length <= 0) {
          this.$message.warning("请选择员工");
        } else {
          this.maxShow = true;
        }

        this.maxShow = false;
      },
      userEditMax() {
        if (this.radio == "1") {
          this.upperResult.map((item) => {
            item.stepper = Number(this.maxText);
          });
          this.upperStepper = Array.from(
            new Set(this.upperSteppert.concat(this.upperResult))
          );
        } else {
          this.upperResult.map((item) => {
            item.stepper = Number(this.maxText);
          });
          this.upperSteppert = Array.from(
            new Set(this.upperSteppert.concat(this.upperResult))
          );
        }
      },
      // 选择分组
      userGroup(data) {
        this.choseProup = data;
      },

      // 选择员工
      userChange(data, info) {
        if (info.name == "serviceList") {
          this.serviceList = data;
          const value = [...data];
          data.forEach((item) => {
            item.stepper = 1;
          });
          this.upperStepper = value;
        } else if (info.name == "StandbyStaffList") {
          this.StandbyStaffList = data;
        } else if (typeof info.name == "number") {
          this.autoSxs[info.name].user = data;
          const value = [...data];
          data.forEach((item) => {
            item.stepper = 1;
          });
          this.upperSteppert.push(...value);
        }
      },

      // 全选员工添加上限
      allUpperLimit() {
        if (this.allRadio.length > 0) {
          this.$refs.checkUpperGroup.toggleAll(true);
        } else {
          this.$refs.checkUpperGroup.toggleAll();
        }
      },

      // 添加其他工作周期
      addAutoSxs() {
        this.autoSxs.push({
          user: [],
          workWeek: [],
          workWeekName: "",
          goTime: "请选择",
        });
      },

      // 客户标签
      userTagsChose(data) {
        this.tagsValue = data;
        this.tagsText = String(
          data.map((item) => {
            return item.name;
          })
        );
      },

      // 时间周期选择
      weekChose(data, info) {
        this.autoSxs[info.name].workWeek = data;
        this.autoSxs[info.name].workWeekName = String(
          data.map((item) => {
            return item.name;
          })
        );
      },

      // 欢迎语部分
      timeChose(data) {
        if (data.name == "autoSxs") {
          this.autoSxs[data.index].goTime = data.time;
        } else if (data.name == "timeIntervalList") {
          data.time.split("-");
          this.timeIntervalList[data.index].startTime = data.time.split("-")[0];
          this.timeIntervalList[data.index].endTime = data.time.split("-")[1];
        }
      },
      // 删除分时段
      deleteTime(index) {
        if (index == 0) {
          this.timeIntervalList.splice(index, 1);
        } else {
          this.timeIntervalList.splice(index, 1);
        }
      },

      // 添加 删除 修改 图片
      choseImg(e, i) {
        if (e) {
          this.$refs.addImage.$show({
            name: "timeIntervalList",
            edit: false,
            index: i,
          });
          this.timeIntervalList[i].showPopover = false;
        } else {
          this.$refs.addImage.$show({ name: "fixedFileList", edit: false });
          this.showPopover = false;
        }
      },
      addImg(data) {
        if (!data.data.edit) {
          if (data.data.name == "fixedFileList") {
            this.fixedFileList.push(data.content);
          } else {
            this.timeIntervalList[data.data.index].mediumId.push(data.content);
          }
        } else {
          if (data.data.name == "fixedFileList") {
            this.fixedFileList[data.data.index] = data.content;
          } else {
            this.timeIntervalList[data.data.index.index].mediumId[
              data.data.index.indexs
            ] = data.content;
          }
        }
      },

      choseUrl(e, i) {
        if (e) {
          this.$refs.addUrl.$show({
            name: "timeIntervalList",
            edit: false,
            index: i,
          });
          this.timeIntervalList[i].showPopover = false;
        } else {
          this.$refs.addUrl.$show({ name: "fixedFileList", edit: false });
          this.showPopover = false;
        }
      },
      addUrl(data) {
        if (!data.data.edit) {
          if (data.data.name == "fixedFileList") {
            ("cg");
            this.fixedFileList.push(data.content);
          } else {
            ("cg");
            this.timeIntervalList[data.data.index].mediumId.push(data.content);
          }
        } else {
          if (data.data.name == "fixedFileList") {
            ("cg2");
            this.fixedFileList[data.data.index] = data.content;
          } else {
            ("cg");
            this.timeIntervalList[data.data.index.index].mediumId[
              data.data.index.indexs
            ] = data.content;
          }
        }
      },

      choseLuckDraw(e, i) {
        if (e) {
          this.$refs.addLuckDraw.$show({
            name: "timeIntervalList",
            edit: false,
            index: i,
          });
          this.timeIntervalList[i].showPopover = false;
        } else {
          this.$refs.addLuckDraw.$show({ name: "fixedFileList", edit: false });
          this.showPopover = false;
        }
      },
      addLuckDraw(data) {
        if (!data.data.edit) {
          if (data.data.name == "fixedFileList") {
            this.fixedFileList.push(data.content);
          } else {
            this.timeIntervalList[data.data.index].mediumId.push(data.content);
          }
        } else {
          if (data.data.name == "fixedFileList") {
            this.fixedFileList.splice(data.data.index, 1, data.content);
          } else {
            this.timeIntervalList[data.data.index.index].mediumId.splice(
              data.data.index.indexs,
              1,
              data.content
            );
          }
        }
      },

      choseApplet(e, i) {
        if (e) {
          this.$refs.addApplet.$show({
            name: "timeIntervalList",
            edit: false,
            index: i,
          });
          this.timeIntervalList[i].showPopover = false;
        } else {
          this.$refs.addApplet.$show({ name: "fixedFileList", edit: false });
          this.showPopover = false;
        }
      },
      addApplet(data) {
        if (!data.data.edit) {
          if (data.data.name == "fixedFileList") {
            this.fixedFileList.push(data.content);
          } else {
            this.timeIntervalList[data.data.index].mediumId.push(data.content);
          }
        } else {
          if (data.data.name == "fixedFileList") {
            this.fixedFileList[data.data.index] = data.content;
          } else {
            this.timeIntervalList[data.data.index.index].mediumId[
              data.data.index.indexs
            ] = data.content;
          }
        }
      },

      removeFile(index) {
        if (typeof index == "number") {
          this.fixedFileList.splice(index, 1);
        } else {
          this.timeIntervalList[index.index].mediumId[index.indexs];
          this.timeIntervalList[index.index].mediumId.splice(index.indexs, 1);
        }
      },
      editFile(item, index) {
        if (typeof index == "number") {
          switch (item.type) {
            case "【图片】：":
              this.$refs.addImage.$show({
                name: "fixedFileList",
                content: item,
                index,
                edit: true,
              });
              break;
            case "【链接】：":
              this.$refs.addUrl.$show({
                name: "fixedFileList",
                content: item,
                index,
                edit: true,
              });
              break;
            case "【小程序】：":
              this.$refs.addApplet.$show({
                name: "fixedFileList",
                content: item,
                index,
                edit: true,
              });
              break;
            case "【抽奖】：":
              this.$refs.addLuckDraw.$show({
                name: "fixedFileList",
                content: item,
                index,
                edit: true,
              });
              break;
          }
        } else {
          switch (item.type) {
            case "【图片】：":
              this.$refs.addImage.$show({
                name: "timeIntervalList",
                content: item,
                index: index,
                edit: true,
              });
              break;
            case "【链接】：":
              this.$refs.addUrl.$show({
                name: "timeIntervalList",
                content: item,
                index: index,
                edit: true,
              });
              break;
            case "【小程序】：":
              this.$refs.addApplet.$show({
                name: "timeIntervalList",
                content: item,
                index: index,
                edit: true,
              });
              break;
            case "【抽奖】：":
              this.$refs.addLuckDraw.$show({
                name: "timeIntervalList",
                content: item,
                index: index,
                edit: true,
              });
              break;
          }
        }
      },

      // 头像预览
      // 文件大小
      onOversize(file) {
        this.$message.warning("文件大小不能超过 2MB");
      },
      // 上传文件
      afterRead(file) {
        file.status = "uploading";
        file.message = "上传中...";
        this.uploadShow = "showUpload";
        var formData = new FormData();
        formData.append("file", file.file);
        upload(formData).then((res) => {
          // this.items.title = res.data.name
          this.uploadShow = "hideUpload";
          file.status = "success";
          file.message = "上传成功";
          this.fileList = [];
          this.fileImage = res.data.fullPath;
        });
      },

      // 添加屏蔽标签
      shieldAdd() {
        this.shieldList.push(this.shieldText);
        this.shieldShow = false;
        this.shieldText = "";
      },

      // 新建数据切分
      buildData() {
        const tagsId = this.tagsValue.map((item) => {
          return item.id;
        });
        const serviceId = this.serviceList.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
        const standby = this.StandbyStaffList.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
        const upper = this.upperStepper.map((item) => {
          return {
            id: item.id,
            name: item.name,
            max: item.stepper,
          };
        });
        const uppert = this.upperSteppert.map((item) => {
          return {
            id: item.id,
            name: item.name,
            max: item.stepper,
          };
        });
        const autoSxs = this.autoSxs.map((item) => {
          return {
            employees: item.user.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            }),
            week: item.workWeek.map((item) => {
              return item.id;
            }),
            startTime: item.goTime.split("-")[0],
            endTime: item.goTime.split("-")[1],
          };
        });
        const mediumId = this.fixedFileList.map((item) => {
          return {
            type: item.types,
            id: item.id,
          };
        });
        const spanWelcome = this.timeIntervalList.map((item) => {
          return {
            words: item.words,
            week: Array.from(
              new Set(
                item.week.map((items) => {
                  if (items.chose) {
                    return items.id;
                  }
                })
              )
            ).filter((i) => i || i == 0),
            startTime: item.startTime,
            endTime: item.endTime,
            mediumId: item.mediumId.map((items) => {
              return {
                type: items.types,
                id: items.id,
              };
            }),
          };
        });
        var drainageEmployee = null;

        if (this.radio == "1") {
          drainageEmployee = {
            type: Number(this.radio),
            timeFull: serviceId,
            specialPeriod: standby,
            addMax: {
              status: this.upperLimit,
              employees: upper,
            },
          };
        } else {
          drainageEmployee = {
            type: Number(this.radio),
            timeSlot: autoSxs,
            specialPeriod: standby,
            addMax: {
              status: this.upperLimit,
              employees: uppert,
            },
          };
        }

        var welcomeMessage = null;
        var type = null;
        const spanWelcomeStatus = this.timeInterval ? 1 : 0;
        if (this.greeting == "固定欢迎语") {
          type = 1;
          welcomeMessage = {
            type: '',
            words: this.fixedMessage.words,
            mediumId: mediumId,
          };
        } else if (this.greeting == "默认欢迎语") {
          type = 2;
        } else {
          type = 3;
        }
        const closeWords = {
          status: this.shield ? 1 : 0,
          words: this.shieldList,
        };
        return {
          tagsId,
          serviceId,
          drainageEmployee,
          mediumId,
          spanWelcome,
          welcomeMessage,
          type,
          spanWelcomeStatus,
          closeWords,
        };
      },
      // 新建必填
      requiredData() { },
      // 删除
      removeCode() {
        Dialog.confirm({
          title: "提示",
          message: "活码一旦被删除，不可被恢复",
          confirmButtonColor: "#1890ff",
        })
          .then(() => {
            channelCodeRemove({ channelCodeId: this.groupId }).then((res) => {
              this.$message.success("删除成功");
              setTimeout(() => {
                this.$router.back();
              }, 500)
            });
          })
          .catch(() => {
          });
      },
      // 新建
      buildText() {
        const datab = this.buildData();
        const data = {
          baseInfo: {
            groupId: this.choseProup.groupId,
            name: this.message,
            autoAddFriend: this.autoGo ? 1 : 2,
            tags: datab.tagsId,
            contactRemark: {
              status: this.customerComments ? 1 : 0,
              type: Number(this.nameRadio),
              remark: this.positionValue,
            },
            contactDescription: this.customerMessage,
            image: this.fileImage,
          },
          drainageEmployee: datab.drainageEmployee,
          type: datab.type,
          welcomeMessage: datab.welcomeMessage,
          spanWelcomeStatus: datab.spanWelcomeStatus,
          spanWelcome: datab.spanWelcome,
          closeWords: datab.closeWords,
        };

        const inspect = {
          groupId: this.choseProup.groupId,
          name: this.message,
          tags: datab.tagsId.length > 0 ? true : false,
          remark: this.positionValue,
          contactDescription: this.customerMessage,
          closeWords: datab.closeWords.words.length > 0 ? true : false,
        };

        // for (let key in inspect) {
        //     if(!inspect[key]){
        //       this.$message.error('请填写必填关键信息')
        //       return
        //       break
        //     }
        //   // foo, bar
        // }
        this.loading = true;
        if (this.groupId) {
          data.channelCodeId = this.groupId;
          channelCodeUpdate(data)
            .then((res) => {
              this.$router.back();
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          channelCode(data)
            .then((res) => {
              this.$router.back();
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      },
    },
    created() {
      if (this.$route.query.id) {
        this.groupId = this.$route.query.id;
        this.editCode();
      }
    },
  };
</script>

<style lang="less" scoped>
  /deep/ .van-cell {
    font-size: 16px;
  }

  /deep/ .van-switch__node {
    top: -1.98px;
  }

  ///deep/ .van-field__button {
  //  position: absolute;
  //  bottom: -5px;
  //  font-size: 12px;
  //  border: 1px solid #1890ff;
  //  padding: 0;
  //  color: #1890ff;
  //  background: #ededfb;
  //  padding: 1px 12px;
  //  border-radius: 18px;
  //  transform: scale(.8);
  //  left: -14px;
  //}

  /deep/ .van-cell__title {
    display: contents;
  }

  .van-popover__content {
    .options {
      margin-top: 0;
      margin-bottom: 0;
      list-style: none;
      opacity: 1;
      z-index: 10;
      background: #fff;
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 25%);
      border-radius: 2px;
      padding: 6px 0;

      .clickable {
        padding: 16px 24px 16px 21px;
        word-break: keep-all;
        white-space: nowrap;
        font-size: 16px;
        line-height: 1;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;

        img {
          width: 14px;
          height: 14px;
          margin-right: 8px;
          margin-bottom: 0;
        }
      }
    }
  }

  .index {
    font-size: 16px;
    height: 100%;
    background: #fff;

    p {
      margin: 0 !important;
    }

    .hideUpload {
      opacity: 0;
    }

    .showUpload {
      opacity: 1;
    }

    .fs17 {
      font-size: 18px;
    }

    .fs14 {
      font-size: 0.16px;
    }

    .bettown {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      &:nth-child(1) {
        margin: 0;
      }
    }

    .content {
      background: #fbfbfb;
      min-height: 92%;

      //padding-top: 5px;
      //padding: 7px 10px;
      img {
        width: 22px;
        height: 22px;
      }

      .file-list {
        background: rgb(248, 249, 251);

        .item {
          display: flex;
          align-items: center;
          padding: 5px 10px;
          justify-content: space-between;

          div:nth-child(1) {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            span {
              margin-top: -3px;
              display: block;
              width: 200px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .user-list {
        display: flex;
        align-items: center;
        justify-content: end;
        height: 100%;
        width: 200px;
        float: right;
        /* white-space: nowrap; */
        /* text-overflow: ellipsis; */
        /* overflow: hidden; */
        white-space: nowrap;
        overflow: auto;
        overflow: auto;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 2px;
          font-size: 14px;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }

      .titles {
        padding: 5px 15px;
        font-size: 16px;
        color: #444;
        font-weight: bold;
        border-bottom: 7px solid #f2f4f8;
      }

      .text-edit {
        /deep/ .van-field__word-limit {
          position: absolute;
          top: -23px;
          right: 0;
        }

        /deep/ .van-cell--required {
          display: flex;
          flex-direction: column;
        }

        /deep/ .van-field__body {
          margin-top: 10px;
        }

        /deep/ .van-cell__title {
          color: #323233;
        }
      }

      .contentss {
        padding: 10px 12px;
      }

      .openOr {
        span {
          margin-left: 3px;
          font-size: 14px;
        }
      }

      .upper-limit {
        margin: 10px 12px;
        border: 1px solid #eee;
        border-radius: 7px;
        overflow: hidden;

        .div1 {
          padding: 10px 13px;
          background: #fbfbfb;
        }

        .div2 {
          padding: 10px 13px;
          background: #fff;
        }
      }

      .init-trigger {
        display: flex;
        align-items: center;
        height: 33px;
        color: #000;
        cursor: pointer;
        margin-top: 16px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          border-radius: 8px;
        }
      }

      .ttips {
        /deep/ .van-icon {
          font-size: 16px !important;
          color: #1890ff !important;
          margin-right: 5px !important;
        }

        font-size: 14px;
        padding: 5px 14px;
        background: #effaff;
        margin: 7px 7px;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        background: #f2f4f8;

        .left {
          height: 100%;
          display: inline-block;
          vertical-align: middle;

          .title {
            height: 100%;
            display: inline-block;
            vertical-align: middle;
            color: #999;
            font-size: 14px;
            font-weight: 500;
            margin-right: 12px;

            .preview-icon {
              display: inline-block;
              vertical-align: middle;
              width: 1em;
              height: 1em;
              vertical-align: -0.15em;
              fill: currentColor;
              overflow: hidden;
            }
          }

          .preview {
            height: 100%;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            color: #294ba3;
            font-size: 14px;
            margin-left: 4px;
          }
        }

        .right {
          color: #294ba3;
          font-size: 14px;

          .delete {
            cursor: pointer;
          }
        }
      }

      .select-time-period {
        padding: 0 16px;

        .time-range-wrapper {
          .time-tips {
            padding-top: 16px;
            padding-bottom: 16px;
            line-height: 18px;
            color: #8c8c8c;
            font-size: 14px;
          }

          .picker {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .picker-item {
              border-radius: 50%;
              background-color: #7accc1;
              color: #fff;
            }

            .item {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 31px;
              height: 31px;
              //color: #545454;
            }
          }

          .time-wrapper {
            color: #545454;
            font-size: 15px;

            .time-tips {
              padding-top: 16px;
              padding-bottom: 16px;
              line-height: 18px;
              color: #8c8c8c;
              font-size: 14px;
            }

            .time-box {
              padding: 0 0 16px;

              .time {
                color: #000;
              }
            }
          }
        }
      }

      .add-time-phased-welcome {
        padding: 8px 0;
        background-color: #f2f4f8;

        .box {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 16px;
          background-color: #fff;

          img {
            width: 15px;
            height: 15px;
          }

          span {
            color: #294ba3;
            font-size: 16px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .filexBox {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);

    .btn {
      margin: 0 auto;
      margin-top: 12px;
      width: 40%;
      border-radius: 5px;
    }
  }
</style>