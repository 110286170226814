<template>
  <div class="detail">
    <div class="top">
      <div class="aside">
        <div class="name">
          {{ employeeData.batchTitle ? employeeData.batchTitle : name }}
        </div>
        <div class="status"></div>
      </div>
      <div class="m16" style="margin-top:8px">
        <div class="left">创建时间</div>
        <div class="right">{{ employeeData.createdAt }}</div>
      </div>
      <div class="m16">
        <div class="left">创建人</div>
        <div class="right">{{ employeeData.creator }}</div>
      </div>
      <!-- <div class="m16">
        <div class="left">发送范围</div>
        <div class="right" v-if="employeeData.filterParams.tag!=[]">筛选客户</div>
        <div class="right">全部客户</div>
      </div> -->
      <div class="m16">
        <div class="left">发送方式</div>
        <div class="right" v-if="employeeData.sendType">员工一键发送</div>
        <div class="right" v-else>通知员工转发</div>
      </div>
      <div
        class="m16"
        style="height: 60px; align-items: baseline; padding-top: 6px"
      >
        <div class="left">任务时间</div>
        <div class="right" style="width: 40%; height: 44px; line-height: 22px">
          {{ employeeData.sendTime }} 至 长期有效
        </div>
      </div>
      <!-- 群发客户群无筛选条件 -->
      <div class="m16" v-if="selectWay!='tokehuqun'">
        <div class="left">发送范围</div>
        <div class="right" v-if="employeeData.filterParams.length==0">全部客户</div>
        <div class="right" v-else>筛选客户</div>
      </div>
    </div>
    <div class="bottom">
      <!-- <div class="m16">
        <div class="left">发送范围</div>
        <div class="right">按条件筛选的1名客户 ></div>
      </div> -->
      <div class="m16" style="margin-bottom: 6px">
        <div class="left">发送内容</div>
        <div class="right"></div>
      </div>
      <div v-for="(item, index) in employeeData.content" :key="index">
        <div class="text" v-if="item.msgType == 'text'">
          {{ item.content }}
        </div>
        <div class="pic" v-else-if="item.msgType == 'image'">
          <div class="conLeft">
            <img class="avatar" :src="item.pic_url" alt="" />
          </div>
          <!-- <div class="conRight">标题标题标题标题标题标题标题标题</div> -->
        </div>
        <div class="pic" v-else-if="item.msgType == 'miniprogram'">
          <div class="conLeft">
            <img class="avatar" :src="item.pic_url" alt="" />
          </div>
          <div class="conRight">{{ item.title }}</div>
        </div>
        <div class="pic" v-else>
          <div class="conLeft">
            <img style="width: 28px" src="../../../assets/link-url.png" />
          </div>
          <div class="conRight">{{ item.title }}</div>
        </div>
      </div>
      <div style="height: 6px"></div>
    </div>
  </div>
</template>
  <script>
import {
  dataStatistics,
  roomDataStatistics,
  clientStatic,
} from "@/api/qunfa";

export default {
  data() {
    return {
      employeeData: "",
      batchid: "",
      selectWay: "",
      name: "",
    };
  },
  mounted() {
    this.batchid = this.$route.query.id;
    this.name = this.$route.query.name;
    this.selectWay = this.$route.query.selectWay;
    this.getData();
  },
  methods: {
    getData() {
      if (this.selectWay == "toCustom") {
        dataStatistics({ batchId: this.batchid }).then((res) => {
          this.employeeData = res.data;
        });
      } else if (this.selectWay == "tokehuqun") {
        roomDataStatistics({ batchId: this.batchid }).then((res) => {
          this.employeeData = res.data;
        });
      } else {
        clientStatic({ batchId: this.batchid }).then((res) => {
          this.employeeData = res.data;
        });
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.detail {
  height: 100vh;
  background-color: #f2f3f7;
}
.top {
  width: 94%;
  margin: 0 auto;
  min-height: 130px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 13px;
  .aside,
  .m16 {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 38px;
    .name {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      margin-top: 16px;
    }
    .status {
      font-size: 12px;
      font-weight: 500;
      color: #3975c6;
    }
    .left {
      font-size: 14px;
      color: #9b9b9b;
    }
    .right {
      font-size: 14px;
      color: #515151;
    }
  }
}
.m16 {
  width: 94%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  .name {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
  .status {
    font-size: 12px;
    font-weight: 500;
    color: #3975c6;
  }
  .left {
    font-size: 14px;
    color: #9b9b9b;
  }
  .right {
    font-size: 14px;
    color: #515151;
  }
}
.bottom {
  width: 94%;
  margin: 0 auto;
  min-height: 94px;
  background: #ffffff;
  margin-top: 12px;
  .pic {
    width: 331px;
    height: 44px;
    margin: 0 auto;
    margin-bottom: 10px;
    background: #f2f3f7;
    border-radius: 4px;
    display: flex;
    .conLeft {
      margin: 0 7px;
      .avatar {
        width: 24px;
        height: 24px;
      }
    }
    .conRight {
      margin: 7px;
      font-size: 11px;
      font-weight: 500;
      color: #4a4a4a;
    }
  }
  .text {
    width: 331px;
    margin: 0 auto;
    margin-bottom: 10px;
    min-height: 43px;
    padding: 10px;
    background: #f2f3f7;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    color: #4a4a4a;
    line-height: 20px;
  }
}
</style>