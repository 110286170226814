<template>
    <div class="page">
        <div class="loadBox" v-if="pageLoading">
            <van-loading size="24px" color="#0094ff" vertical>加载数据中...</van-loading>
        </div>
        <div v-else>
            <div class="conBox">
                <div class="banner">
                    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                        <div v-for="(item,index) in bannerData" :key="index">
                            <van-swipe-item>
                                <img style="width: 100%;height: 150px;border-radius:6px" :src="item" alt="">
                            </van-swipe-item>
                        </div>
                    </van-swipe>
                </div>
                <div class="title" v-if="recentlyUse.list.length!=0">最近使用</div>
                <div class="bannerBox" v-if="recentlyUse.list.length!=0">
                    <img :src="recentlyUse.list.avatar" alt="">
                    <div class="boxFlex">
                        <div class="flexTop">
                            <span>{{recentlyUse.list.name}}</span>
                            <!-- <span>{{recentlyUse.status==0?'未发送':'已发送'}}</span> -->
                        </div>
                        <div class="flexTips">{{recentlyUse.list.abstract}}</div>
                        <div class="flexBom" @click="saveSubmit(recentlyUse.nodeCareCategoryTwoId)">继续使用</div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="conNext">
                <van-tabs v-model="active" title-active-color="#3974C6" scrollspy sticky @click="onClick">
                    <van-tab v-for="(item, index) in categoryData" :title="item.name">
                        <div class="line" v-if="index!=0"></div>
                        <div class="conNext">
                            <div class="list">
                                <div class="listName">
                                    <div class="left">{{item.name}}</div>
                                    <div class="right" @click="goList(item)">
                                        <span>查看更多</span>
                                        <img src="../../assets/images/sop/jiantou.png" alt="">
                                    </div>
                                </div>
                                <div class="listTips">{{item.abstract}}</div>
                                <div class="scollBox">
                                    <div class="itemBox" v-for="(iteml,indexl) of item.childlist" :key="indexl"
                                        @click="goPage(iteml)">
                                        <div class="box"> <img :src="iteml.avatar" alt=""></div>
                                        <div class="name">{{iteml.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCategory, getBanner, getLog, saveStatus } from "@/api/festival";
    export default {
        data() {
            return {
                pageLoading: false,
                bannerData:[
                require("@/assets/images/festival/banner1.png"),
                require("@/assets/images/festival/banner2.png"),
                require("@/assets/images/festival/banner3.png"),
                require("@/assets/images/festival/banner4.png"),
                require("@/assets/images/festival/banner5.png"),
                require("@/assets/images/festival/banner6.png"),
                require("@/assets/images/festival/banner7.png"),
                ],
                homeData: {},
                active: 0,
                categoryData: [],
                selectIndex: 0,
                recentlyUse: {}
            };
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                //获取分类
                this.pageLoading = true
                var that = this;
                let p1 = new Promise((resolve, reject) => {
                    getCategory().then((res) => {
                        this.categoryData = res.data
                        resolve()
                    })
                })
                //获取banner
                let p2 = new Promise((resolve, reject) => {
                    getBanner().then((res) => {
                        this.homeData.bankBannerImg = res.data
                        resolve()
                    })
                })//获取最近使用
                let p3 = new Promise((resolve, reject) => {
                    getLog().then((res) => {
                        this.recentlyUse = res.data
                        resolve()
                    })
                })
                Promise.all([p1, p2, p3]).then((result) => {
                    setTimeout(() => {
                        this.pageLoading = false
                    }, 1000)
                }).catch((error) => {
                    this.pageLoading = false
                    console.log(error)
                })
            },
            //tab点击  筛选分类
            onClick(name, title) {
                setTimeout(() => {
                    this.active = name
                }, 500)
            },
            goList(data) {
                this.$router.push({ path: "/festival/festivalList?pidData=" + JSON.stringify(data) });
            },
            //继续使用
            saveSubmit(id) {
                this.$router.push({ path: "/festival/festivalUse?id=" + id });
            },
            goPage(row) {
                this.$router.push({ path: "/festival/festivalUse?id=" + row.id });
            },
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .van-tabs__nav--line {
        padding-bottom: 0;
    }
    /deep/ .van-tab__text {
        font-size: 17px;
    }
    /deep/ .van-tabs__line {
        width: 20px;
        bottom: 1px;
        background-color: #3974C6
    }
    /deep/ .van-tabs__wrap {
        border-bottom: 1px solid #EFEFEF;
    }
    /deep/ .van-loading {
        margin: 0 auto;
        margin-top: 50%;
        display: flex;
        justify-content: center;
    }
    .page {
        width: 100%;
        background: #FFFFFF;
        .loadBox {
            width: 100%;
            height: 100vh;
            padding-top: 50px;
            /* display: flex;
            justify-content: center;
            align-items: center; */
        }
        .conBox {
            padding: 14px;
        }
        .banner {
            border-radius: 6px;
            width: 100%;
        }
        .title {
            margin-left: 7px;
            font-size: 17px;
            font-weight: bold;
            color: #262626;
            padding: 14px 0;
        }
        .bannerBox {
            width: 100%;
            padding: 14px 10px;
            background: #FFFFFF;
            box-shadow: 0px 0px 3px 0px rgba(214, 214, 214, 0.5);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            img {
                width: 135px;
                height: 90px;
                border-radius: 4px;
                box-shadow: 0px 0px 3px 0px rgba(214,214,214,0.5);
            }
            .boxFlex {
                margin-left: 10px;
                position: relative;
                .flexTop {
                    line-height: 0.5;
                    :first-child {
                        font-size: 17px;
                        font-weight: bold;
                        color: #262626;
                    }
                    /* :last-child {
                        margin-left: 8px;
                        padding: 1px 7px;
                        background: #65A4F5;
                        border-radius: 4px;
                        text-align: center;
                        font-size: 11px;
                        font-weight: 400;
                        color: #FFFFFF;
                    } */
                }
                .flexTips {
                    margin-top: 8px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #717174;
                    overflow: hidden; //溢出内容隐藏
                    text-overflow: ellipsis; //文本溢出部分用省略号表示
                    display: -webkit-box; //特别显示模式
                    -webkit-line-clamp: 2; //行数
                    line-clamp: 2;
                    -webkit-box-orient: vertical; //盒子中内容竖直排列
                }
                .flexBom {
                    position: absolute;
                    bottom: 0;
                    width: 76px;
                    padding: 4px 13px;
                    background: #FD8927;
                    border-radius: 12px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
        .line {
            width: 100%;
            height: 10px;
            background: #F2F3F7;
        }
        .conNext {
            .list {
                padding: 10px 14px 20px;
                .listName {
                    width: 100%;
                    height: 23px;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    .left {
                        font-size: 17px;
                        font-weight: bold;
                        color: #262626;
                    }
                    .right {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 12px;
                            font-weight: 400;
                            color: #A5A5A5;
                        }
                        img {
                            margin-left: 2px;
                            width: 6px;
                            height: 10px;
                        }
                    }
                }
                .listTips {
                    margin-top: 5px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #717174;
                }
                .scollBox {
                    margin-top: 14px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    white-space: nowrap;
                    overflow: hidden;
                    overflow-x: scroll;
                    .itemBox {
                        &:nth-child(n+2) {
                            margin-left: 10px;
                        }
                        .box {
                            width: 135px;
                            height: 90px;
                            background: #D8D8D8;
                            border-radius: 4px;
                            img {
                                width: 135px;
                                height: 90px;
                                border-radius: 4px;
                                box-shadow: 0px 0px 3px 0px rgba(214,214,214,0.5);
                            }
                        }
                        .name {
                            max-width: 135px;
                            margin-top: 5px;
                            text-align: left;
                            font-size: 12px;
                            font-weight: 400;
                            color: #717174;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
</style>